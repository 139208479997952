<template style="overflow-y: visible">
  <v-dialog width="700"  v-model="$store.state.action.displayRegister" >

    <v-sheet class="pa-8 py-8" >

      <div class="d-flex justify-space-between align-center">
        <h2 class="font-weight-medium">Соціальна програма</h2>
        <v-btn icon small @click="$store.state.action.displayRegister = false"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-divider class="mt-3 mb-6" />

        <div v-if="!$store.state.auth.loggedIn" >
          <h5 class="mt-3 font-weight-medium text-center">Для продовження зареєеструйтесь в системі </h5>
          <authWindow no-toggle   />
        </div>
        <div v-else>

          <div v-if="!user.actionSuccess">
            <h5 class="font-weight-medium mt-3 mb-3 text-center" >Вкажіть вашу адресу (введіть окремо назву вулиці та окрему номер домe)</h5>

            <v-sheet style="border-radius: 25px " color="grey lighten-2" outlined class="d-flex pa-3">
              <v-icon class="mr-1">mdi-google-maps</v-icon>
              <gmap-autocomplete :key="'address_' + gMapKeyAddress" @input="resetGoogleAddress"
                                 :value="address"
                                 @change="gmapAddressChange"
                                 ref="gAddress"
                                 placeholder="Назва вулиці"
                                 language="UA"
                                 style="width: 100% ;  outline: none;"
                                 :bounds="gmapBounds"
                                 :options="{ bounds : gmapBounds,
                                                  strictBounds: true,
                                                  componentRestrictions: {
                                                    country : this.order.country,
                                                  },
                                                  language : 'UA'
                                       } "
                                 @place_changed="updateGooleLocation($event)"/>
              <v-progress-circular v-if="loading" color="grey darken-2" indeterminate></v-progress-circular>
              <v-icon size="28" v-else :color="gMapAddressStreetCheck ? 'green lighten-2' : ''"
                      class="mr-1">mdi-check
              </v-icon>
            </v-sheet>
            <v-text-field class="mt-3" @change="getGeocode" hide-details v-model="street" dense filled rounded label="Дім"/>

            <div class="d-flex align-center mt-6 mb-6">

              <v-btn :color="condition ? 'green lighten-1' : ''" class="mr-2" @click="condition = !condition" icon >
                <v-icon >{{ condition ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
              </v-btn>
              <h5 class="font-weight-light">З умовами акції ознайомлений та даю згоду на обробку персональних даних</h5>
            </div>
            <h5 v-if="!image" class="font-weight-medium text-center">Підтвердити наявність дитини від 0-7 років</h5>
            <h5 v-if="!image" class="font-weight-light mb-6 text-center">Завантажити <span class="font-weight-bold">документ або скріншот із Дії</span>, що підтверджує вік дитини</h5>
            <wsImageUpload v-if="!image" @upload="uploadImage" />
            <h5 v-else ><v-icon color="green" class="mr-1">mdi-check</v-icon>Документ завантажено</h5>
            <v-btn block color="primary lighten-2 mt-9 mb-3" large dark @click="orderAction">Замовити</v-btn>
          </div>
          <div v-else>
            <h4 class="my-16 font-weight-light text-center">Заявку надіслано! На сьогодні вже більше 500 сімей забезпечуються питною водою. Ви є учасником програми - другої черги, яка почне реалізуватись за 5 тижнів ( орієнтовно з 25 липня )</h4>
            <v-btn class="noCaps" block text large @click="$store.state.action.displayRegister = false"> Повернутися</v-btn>
          </div>

        </div>


    </v-sheet>

  </v-dialog>
</template>

<script>

import authWindow from "@/components/auth/authWindow";
import {mapMutations, mapActions, mapState} from "vuex";
import {gmapApi} from "vue2-google-maps"

import wsImageUpload from "@/components/UI/wsImageUpload";
export default {
  name: "checkoutMenu",
  props: ['value'],
  components: {
    authWindow,
    wsImageUpload
  },
  data() {
    return {

      image : '',


      success : false,
      condition : false,

      userHasRentedProduct: false,
      rentedProducts: [],

      phoneCountryCode: '+38',
      phoneNumber: '',

      phoneInputFocus: false,
      showPaymentButton: false,

      firstTimeWelcome: false,
      showRentProductWarning: [],

      onlineRefuseTextData: {
        hasArea: "Адреса вашого замовлення не попадає в нашу зону обслуговування, найближчим часом з Вами зв'яжеться наш менеджер",
      },
      onlineRefuseText: "Оплату буде прийнято після підтверждення замовлення, очійкуйте на зв'язок із менеджером",
      onlineRefuse: false,
      onlineRefuseType: 'default',

      countriesSelect: [],

      loading: false,

      liqPayData: null,
      liqButtonData: 'null',

      orderSuccess: false,
      orderNumber: '',
      readyToOrder: false,

      order: {
        name: "",
        phone: "",
        delivery: "address",
        payment: "online_liqpay",
        products: [],
        cityName: "Mykolaiv",
        cityPlaceId: "ChIJ1RNy-4nLxUARgFbgufo5Dpc",
        address: "",
        npWarehouseName: "",
        npWarehouseId: "",
        time: '2022-05-29 12:00',
        country: "UA",
        countryName: "Ukraine",
        gPaths: {},
        user: null,
      },

      userAddress: null,

      //technical
      deliveryTimeSelect: [
        {text: 'Як найшвидше', value: 'instant'},
        {text: 'На певний час', value: 'onTime'},
      ],
      address: null,
      flat  : null,
      street: null,
      hasDeliveryTime: 'instant',
      deliveryMinutes: null,
      deliveryHours: null,
      time: '',
      date: '',
      npCity: {text: '', value: ''},
      npWarehouse: {},

      step: 0,
      tabs: [
        {text: 'Корзина', icon: 'mdi-cart-outline'},
        {text: 'Доставка', icon: 'mdi-phone-outline'},
        {text: 'Оплата', icon: 'mdi-credit-card-outline'}
      ],
      onRecieve: [
        {text: 'Готівка', icon: 'mdi-cash', value: 'onRecieve_cash'},
        {text: 'Термінал', icon: 'mdi-credit-card-outline', value: 'onRecieve_card'},
      ],
      onlinePay: [
        {text: 'Оплата на карту', icon: 'mdi-credit-card-outline', value: 'online_card_transfer'},
        {text: 'LiqPay', icon: 'mdi-credit-card-marker-outline', value: 'online_liqpay'},
        {text: 'Fondy', icon: 'mdi-credit-card-marker-outline', value: 'online_fondy'},
      ],
      deliveryTabs: [
        {text: 'Cамовивіз', icon: 'mdi-cash', value: 'self'},
        {text: 'Доставка  ', icon: 'mdi-credit-card-outline', value: 'address'},
        {text: 'Нова Пошта ', icon: 'mdi-credit-card-outline', value: 'novaPoshta'},
      ],
      onRecieveValue: '',
      onlinePayValue: '',

      //google vars
      selectedCityData: null,
      gCityName: '',
      gMapKey: 1,
      gMapKeyAddress: 1,
      gMapAddressStreetCheck: false,
      gMapAddressStreet: null,

      gmapBounds: {}
    }
  },
  computed: {




    ...mapState('cart', ['cart', 'quantity', 'deliveries', 'payments', 'showCheckout']),
    ...mapState('templates', ['products', 'businessName', 'businessAlias', 'locations', 'deliveryAllowCountries', 'deliveryCountries']),
    ...mapState('auth', ['user','userID']),

    mapCenter() {
      let coordinates = {lat: 46.975033, lng: 31.994583}
      return coordinates
    },

    phoneValidationError() {
      if (this.order.phone) {
        if (this.order.phone.length !== 13) {
          return 'Номер телефона складається з 10 цифр'
        }
        return
      }
      return ''
    },

    google: gmapApi,
    gMapOptionsCity() {
      let country = this.order.country
      return country ? {
        types: ['(cities)'],
        componentRestrictions: {
          country: country,
        },
      } : {}
    },
    gMapOptionsAddress() {
      let data = this.selectedCityData
      return data ? {
        bounds: this.gmapBounds,
        strictBounds: true,
        componentRestrictions: {
          bounds: this.gmapBounds
        },
      } : {}
    },
    nextStepText() {
      var text = 'Далі'
      if (this.step === 2) {
        text = 'Оформити'
      }


      if (this.order.products &&
          this.order.phone &&
          this.order.delivery.type &&
          this.order.payment) {
        if (this.order.delivery.type === 'self') {
          text = 'Оформити'
        }
        if (this.order.delivery.type === 'address') {
          if (this.order.delivery.data.address) {
            text = 'Оформити'
          }
        }
      }


      return text

    },
    onRecieveFiltered() {
      let output = []
      this.onRecieve.forEach((payment) => {
        if (this.payments.findIndex(el => el.value === payment.value) !== -1) {
          output.push(payment)
        }
      })
      return output
    },
    onlinePayFiltered() {
      let output = []
      this.onlinePay.forEach((payment) => {
        if (this.payments.findIndex(el => el.value === payment.value) !== -1) {
          output.push(payment)
        }
      })
      return output
    },
    addresses() {
      if (this.user) {

        var locations = this.user.locations.length > 0 ? this.user.locations.filter(el => el.type === 'address') : []


        // if ( !this.deliveryPermissions.includes('CITIES') && this.permittedCities.length >0 ) {
        //   locations = locations.filter(el =>
        //       this.permittedCities.filter( elem =>
        //           elem.city.text === el.data.city.text ).length > 0 )
        // }
        //
        // if ( !this.deliveryPermissions.includes('COUNTRIES')  && this.permittedCountries.length > 0  ) {
        //   locations = locations.filter(el =>
        //       this.permittedCountries.filter( elem => elem === el.data.country ).length > 0 )
        // }

        return locations

      }


      return []

    },
    addresses_select() {
      var items = [];
      this.addresses.forEach((address, i) => {
        items.push({
          text: address.data.address + ', ' + address.data.city.text,
          value: i
        })
      })
      return items
    },
    deliveries_items() {
      let output = []
      this.deliveryTabs.forEach((delivery) => {
        if (this.deliveries.findIndex(el => el.value === delivery.value) !== -1) {
          output.push(delivery)
        }
      })
      return output
    },
    permittedCitiesSelect() {
      let cities = [];
      if (this.order.country) {

        let index = this.deliveryCountries.findIndex(el => el.cca2 === this.order.country)
        if (index !== -1) {
          if (!this.deliveryCountries[index].allCities) {
            this.deliveryCountries[index].permittedCities.forEach((city) => {
              cities.push({
                text: city.name,
                value: city.placeId
              })
            })
          }
        }

      }

      return cities;
    },

    //technical
    deliveryAddress() {
      if (this.flat) {
        let string =  this.address + " " + this.street + ", Кв. " + this.flat
        return string
      } else {
        return this.address
      }

    },
    deliveryTime() {
      return this.date + ' ' + this.time
    },
  },
  watch: {
    userID(val) {
      if ( val ) {
        this.order.user = val
      }
    },
    address(value) {
      if (value && this.street) {
        this.getGeocode()
      }
    },
    phoneNumber(value) {
      this.order.phone = this.phoneCountryCode + value
    },
    selectedCityData() {
      this.getGmapBounds()
    },
    showCheckout(val) {
      if (val === true) {
        this.initCheckout()
      }
    },

    value(value) {
      this.$emit('input', this.value)
      if (value === false && this.orderSuccess) {
        this.orderSuccess = false;
        this.firstTimeWelcome = false;
        this.onlineRefuse = false;
      }
    },
    deliveryAddress(val) {
      this.order.address = val
    },
    user() {
      this.setUser()
    },
    'order.country'(val) {
      let country = this.countriesSelect.find(el => el.value === val)
      if (country) {
        this.order.countryName = country.text
      }
    },
    deliveryTime(val) {
      this.order.time = val
    },
    hasDeliveryTime(val) {
      if (val === 'instant') {
        this.order.time = ''
        this.time = ''
        this.date = ''
      }
    },
    npWarehouse(val) {
      this.order.npWarehouseName = val.text
      this.order.npWarehouseId = val.value
    },
    npCity() {
    }
  },
  methods: {

    ...mapActions('templates',  ['UPLOAD_IMAGE' ]),
    ...mapActions('orders',  ['NEW_PARTICIPANT' ]),

    async uploadImage(file) {

      this.loading = true

      this.UPLOAD_IMAGE(file).then((out)=>{
        if ( out.result ) {
          this.image = out.data.url
        }
        this.loading = false
        this.dropzoneHover = false
      }).catch(()=>{
        this.loading = false
        this.dropzoneHover = false
      })

      // let result = await this.UPLOAD_IMAGE(file)
      // if  (!result) { return false }
      // this.value.src = result.url

    },

    async newActionParticipant() {
      if ( !this.image ) { return this.notify('Завантажте документ') }
      let out =  await this.NEW_PARTICIPANT({image: this.image})
      if ( out ) { return true}
      return false
    },

    async orderAction() {
      if ( !this.validateOrder()) {
        return
      }

      if ( !await this.newActionParticipant() ) {
        this.notify('Помилка! Обліковий запис вже приймає участь у соціальній програмі')
        return
      }

      let time = new Date()
      let year = time.getFullYear()

      let month = time.getMonth() + 1

      let date = time.getDate()
      time.setDate(date + 30 )

      date = time.getDate()

      this.order.products = [
        { id :"ce047596-eef9-459c-a524-2532458d690e", quantity :2 }  ,
        { id :"eddb081b-fa4f-48cf-bd93-4b130b035d16", quantity :2 }  ,
      ]
      this.order.time = year + '-' + month + '-' + date
      this.order.isAction = true

      time.setDate(date + 14 )

      await this.sendOrder()


       month = time.getMonth() + 1
       date = time.getDate()
      this.order.products = [
        { id :"ce047596-eef9-459c-a524-2532458d690e", quantity :2 }
      ]
      this.order.time = year + '-' + month + '-' + date
      this.order.isAction = true

      time.setDate(date + 14 )
      await this.sendOrder()

       month = time.getMonth() + 1
       date = time.getDate()

      this.order.products = [
        { id :"ce047596-eef9-459c-a524-2532458d690e", quantity :1 }
      ]
      this.order.time = year + '-' + month + '-' + date
      this.order.isAction = true

      await this.sendOrder()

      this.$store.state.auth.user.actionSuccess = true

    },


    ...mapActions('cart', ['MAKE_ORDER', 'ADD_CART_ITEM', 'REMOVE_CART_ITEM',]),
    ...mapActions('liqpay', ['GET_BUSINESS_BUTTON']),
    ...mapMutations('cart', ['REMOVE_CART_ITEM_ALL']),
    ...mapActions('auth', ['GET_USER']),
    ...mapActions('location', ['GMAP_GEOCODE_ADDRESS']),

    onlyNumberInput(input) {

      input = (input) ? input : window.event;
      var charCode = (input.which) ? input.which : input.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        input.preventDefault();
      } else {
        return true;
      }


    },


    goToPaymentCleanUp() {

    },

    initCheckout() {

      // if ( this.order.gPaths ) {
      //   this.$refs.gAddress.$el.value = this.order.address
      // }
      this.checkFastDeliveryAddress()

    },
    checkGMapText() {
      if (this.order.gPaths) {
        this.$refs.gAddress.$el.value = this.order.address
      }
    },
    validateOrder() {

      let needToFill = ''

      if (!this.condition) {
        return this.notify('Умови акції не погоджено')
      }
      if (!this.image) {
        return this.notify('Завантажте документ')
      }

      if (this.phoneValidationError) {
        this.notify('Не правильний формат номера телефона. Введіть 10 цифр')
        return false
      }

      if (!this.order.phone) {
        needToFill += `${needToFill ? ', ' : ''}Телефон`;
      }
      if (!this.order.name) {
        needToFill += `${needToFill ? ', ' : ''}Ім'я`;
      }
      if (!this.order.delivery) {
        needToFill += `${needToFill ? ', ' : ''}Тип доставки`;
      }
      if (!this.order.payment) {
        needToFill += `${needToFill ? ', ' : ''}Тип оплати`;
      }

      if (this.order.delivery === 'address') {
        if (this.countriesSelect.length > 1) {
          if (!this.order.country) {
            needToFill += `${needToFill ? ', ' : ''}Країна`;
          }
        }
        if (!this.order.cityName) {
          needToFill += `${needToFill ? ', ' : ''}Місто`;
        }
        if (!this.address) {
          needToFill += `${needToFill ? ', ' : ''}Адреса`;
        }

        if (this.moduleIsActive('googleMaps')) {
          if (!this.gMapAddressStreetCheck) {
            this.notify('Номер вулиці не вказано, введіть номер та оберіть із списку адресу')
            return false
          }
          if (!this.order.gPaths.lat) {
            this.notify('Google Maps адресу не знайдено, введіть адресу знову.Окремо назву вулиці окремо номер дому ')
            return false
          }
        }


      }

      if (!this.address) {
        this.notify('Google Maps адресу не знайдено, введіть адресу знову.Окремо назву вулиці окремо номер дому ')
      }
      if (!this.order.address) {
        this.notify('Google Maps адресу не знайдено, введіть адресу знову.Окремо назву вулиці окремо номер дому ')
      }
      if (!this.order.gPaths.lat) {
        this.notify('Google Maps адресу не знайдено, введіть адресу знову.Окремо назву вулиці окремо номер дому ')
      }


      if (needToFill) {
        this.notify('Заповніть необхідні поля: ' + needToFill)
        return false
      }


      return true

    },

    nextStep() {
      this.sendOrder()
    },
    sendOrder() {
      this.showPaymentButton = false
      if (this.validateOrder()) {
        this.step = 4

        this.loading = true
        if ( this.flat ) {
          this.order.address = this.address + ' ' + this.street + ', кв. ' + this.flat
        } else {
          this.order.address = this.address + ' ' + this.street
        }
        this.order.origin = this.DEVICE
        this.MAKE_ORDER({
          alias: this.businessAlias,
          data: this.order
        }).then((out) => {
          if (out.result) {

            if (out.data.rentedProducts) {
              this.$store.state.auth.userRentedProducts = out.data.rentedProducts
              this.$store.state.auth.user.rent_product = out.data.rentedProducts
            }
            if (out.data.user) {
              this.firstTimeWelcome = true
              this.$store.state.auth.accessToken = out.data.user
              localStorage.setItem('access_token', out.data.user)

              this.GET_USER({
                token: out.data.user,
                router: {
                  router: this.$router,
                  path: this.$route.path
                },
                redirect: false,
              })
            }

            if (this.order.payment === 'online_liqpay') {
              this.onlineRefuse = false;
              if (out.data.liqPayPermission) {
                this.GET_BUSINESS_BUTTON({
                  business: this.businessAlias,
                  data: {
                    order_number: out.data.order_number,
                    total: this.getTotalPrice(),
                    return_url: location.href
                  },
                }).then((button) => {
                  this.showPaymentButton = true
                  this.liqButtonData = button.data
                  // this.LIQPAY_checkout(button.data)
                }).catch((error) => {
                  console.log(error)
                })
              } else {
                this.onlineRefuse = true
                if (this.onlineRefuseTextData[out.data.liqPayPermissionType]) {
                  this.onlineRefuseText = this.onlineRefuseTextData[out.data.liqPayPermissionType]
                }
              }
            }

            this.notify('Замовлення створено')
            this.orderSuccess = true
            this.clearOrder();
            this.orderNumber = out.data.order_number
            this.loading = false


          } else {
            this.step = 2;
          }

        }).catch(() => {
          this.loading = false
        })
      }
    },
    clearOrder() {
      this.step = 0
      this.$store.state.cart.cart = {}
      this.$store.state.cart.quantity = 0
      this.order.products = []
    },

    addToCart(product) {
      // this.$store.state.cart.cart = product
      let addProduct = this.products.find(el => el.id === product.id)
      this.ADD_CART_ITEM(addProduct)
      this.notify(`${product.name} додано до корзини`)
      // this.$forceUpdate()
    },
    removeFromCart(product) {
      let addProduct = this.products.find(el => el.id === product.id)
      this.REMOVE_CART_ITEM(addProduct)
      this.notify(`${product.name} видалено із корзини`)
    },
    removeFromCartAll(product) {
      this.REMOVE_CART_ITEM_ALL(product)
      this.notify(`${product.name} видалено із корзини`)
    },
    setPayment(type) {
      switch (type) {
        case 'onRecieve' :
          this.onlinePayValue = null
          this.order.payment = this.onRecieveValue
          break;
        case 'onlinePay' :
          //  this.notify('im online')
          this.onRecieveValue = null
          this.order.payment = this.onlinePayValue
          break;
      }
    },
    setUser() {

      if (this.user) {
        this.order.name = this.user.firstname
        this.order.phone = this.user.phone
        if (this.user.phone) {
          this.phoneCountryCode = this.order.phone.slice(0, 3)
          this.phoneNumber = this.order.phone.slice(3, 13)
        }
        this.order.user = this.user.id

        // if ( this.user.last_order ) {
        //
        //   if ( this.user.last_order.delivery.type === 'self' ) {
        //     this.order.delivery.type = 'self'
        //   } else {
        //     this.order.delivery = this.user.last_order.delivery
        //   }
        //
        //   this.order.payment = this.user.last_order.payment
        // }

      } else {

        this.order.name = ''
        this.order.phone = ''
        delete this.order.user

      }

    },
    loadAddress(index = null) {

      this.order.delivery = this.addresses[index]

    },

    //google
    resetGoogleAddress() {
      this.order.gPaths = null
      this.gMapAddressStreetCheck = false
      this.gMapAddressStreet = null

    },
    updateGooleLocation($event) {

      let streetName = $event.address_components.find(el => el.types.includes('route'))
      //   let streetNumber = $event.address_components.find( el=> el.types.includes('street_number') )
      this.address = streetName ? streetName.long_name : ''
      //  this.street = streetNumber ?  streetNumber.long_name : ''
      this.$refs.gAddress.$el.value = this.address

    },
    updateGooleLocationCity($event) {
      let postal_code = $event.address_components.find(el => el.types.includes('postal_code'))
      let city = {
        postal_code: postal_code.long_name,
        name: $event.name,
        placeId: $event.place_id,
        bounds: $event.geometry.viewport.toJSON(),
        center: $event.geometry.location.toJSON(),
      }
      this.selectedCityData = city
      this.$refs.gCity.$el.value = city.name
      this.gMapKeyAddress++;

    },

    updateContry() {
      this.order.cityName = ''
      this.order.cityPlaceId = ''
      this.gMapKey++;
      this.selectedCityData = null
      this.gCityName = ''
    },
    updateCity(val) {

      let index = this.deliveryCountries.findIndex(el => el.cca2 === this.order.country)
      if (index !== -1) {
        let cityData = this.deliveryCountries[index].permittedCities.find(el => el.placeId === val)
        if (cityData) {
          this.selectedCityData = cityData
          this.order.cityName = cityData.name
          this.order.cityPlaceId = cityData.placeId

          this.order.address = ''
          this.order.gPaths = {}
          this.gMapAddressStreetCheck = false
          this.street = ''
        }
      }
    },

    // technical
    deliveryName(id) {
      let delivery = 'не обрано';
      let item = this.deliveries.find(el => el.value === id)
      if (item) {
        delivery = item.text
      }
      return delivery
    },
    paymentName(id) {
      let payment = 'не обрано';
      let item = this.payments.find(el => el.value === id)
      if (item) {
        payment = item.text
      }
      return payment
    },
    getTotalPrice() {
      let total = 0;
      Object.keys(this.cart).forEach((key) => {
        total += parseFloat(this.cart[key].quantity) * parseFloat(this.cart[key].price)
      })
      return total
    },
    changeDelivery(val) {
      this.order.delivery = val
    },
    ftTimeUpdateDate(val) {
      this.date = val
    },
    npUpdateCity(val) {
      this.order.cityName = val.text
      this.order.cityPlaceId = val.value
    },
    checkRentedFirstTime(product) {

      if (!this.$store.state.auth.loggedIn) {

        let quantity = 0;
        let parent = this.products.find(el => el.id === product.id)
        if (parent.rentedProduct) {

          if (this.cart[parent.rentedProduct.id]) {
            quantity += this.cart[parent.rentedProduct.id].quantity
          }
          let warnIndex = this.showRentProductWarning.indexOf(product.id)

          if (product.quantity > quantity) {
            if (warnIndex === -1) {
              this.showRentProductWarning.push(product.id)
            }
          } else {
            if (warnIndex !== -1) {
              this.showRentProductWarning.splice(warnIndex, 1)
            }
          }
        }
      }
    },
    getRentedProductQuantity(product) {
      let quantity = product.quantity
      if (this.cart[product.id]) {
        quantity = quantity + this.cart[product.id].quantity
      }
      let parent = this.products.find(el => el.id === product.id)
      if (parent) {
        if (this.cart[parent.parentProduct]) {
          quantity = quantity - this.cart[parent.parentProduct].quantity
        }
        let warnIndex = this.showRentProductWarning.indexOf(product.id)
        if (quantity < 0) {
          if (warnIndex === -1) {
            this.showRentProductWarning.push(product.id)
          }
        } else {
          if (warnIndex !== -1) {
            this.showRentProductWarning.splice(warnIndex, 1)
          }
        }


      }
      return quantity
    },

    displayRentedProductQuantity(quantity, total) {
      if (quantity < 0) {
        return 0
      }
      if (quantity > total) {
        return total
      }
      return quantity
    },

    getGmapBounds() {

      let data = this.selectedCityData

      this.$gmapApiPromiseLazy().then(() => {
        this.gmapBounds = data ? new this.google.maps.LatLngBounds(
            new this.google.maps.LatLng(data.bounds.south, data.bounds.west),
            new this.google.maps.LatLng(data.bounds.north, data.bounds.east),
        ) : {}
      })
      this.gMapKeyAddress++
      console.log("** ======= GMAP BOUNDS ======== **")
    },
    checkFastDeliveryAddress() {

      if (this.$store.state.cart.quickDeliveryField.fromGoogle) {

        if (this.$store.state.cart.quickDeliveryField.street_name) {
          this.address = this.$store.state.cart.quickDeliveryField.street_name
        } else {
          this.address = ''
        }
        if (this.$store.state.cart.quickDeliveryField.street_number) {
          this.street = this.$store.state.cart.quickDeliveryField.street_number
        } else {
          this.street = ''
        }
      } else {
        this.address = this.$store.state.cart.quickDeliveryField.string
      }
      this.getGeocode()
      this.$store.state.cart.quickDeliveryField.complete = true

    },
    gmapAddressChange(value) {
      this.address = value.target.value
      this.getGeocode()
    },
    getGeocode() {
      this.gMapAddressStreetCheck = false
      if (this.street && this.address) {
        let querry = this.address + ', ' + this.street
        // if ( this.order.country ) { querry = this.order.cityName + ', ' + querry }
        // if ( this.order.countryName ) { querry = this.order.countryName + ', ' + querry }
        let bounds = this.gmapBounds.toString()
        bounds = bounds
            .replace('((', '')
            .replace('))', '')
            .replace('), (', '|')

        let data = {
          address: querry,
          bounds: bounds,
          country: this.order.country,
          postal_code: this.selectedCityData.postal_code
        }
        this.loading = true
        this.GMAP_GEOCODE_ADDRESS(data).then((out) => {

          if (out.result) {
            if (out.data.status !== 'OK') {
              this.gMapAddressStreetCheck = false
              this.loading = false
              // this.notify('Адресу не знайдено,  оберіть адресу із випадаючого списку')
              return
            }
            if (out.data.results.length === 1) {
              let street_check = out.data.results[0].address_components.find(el => el.types.includes('street_number'))
              if (!street_check) {
                this.loading = false
                return this.notify('Адресу не знайдено,  оберіть адресу із випадаючого списку')
              }
              this.order.gPaths = out.data.results[0].geometry.location
              this.order.address = querry
              this.gMapAddressStreetCheck = true
            }
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
    }
  },

  mounted() {
    this.rentedProducts = JSON.parse(JSON.stringify(this.$store.state.templates.rentedProducts))
    this.rentedProducts.forEach((product) => {
      product.quantity = 0
    })
    this.firstTimeWelcome = false
    this.onlineRefuse = false

    // Set Delivery type if business has only one type

    if (this.deliveries_items.length === 1) {
      this.order.delivery = this.deliveries_items[0].value
    }
    if (this.payments.length === 1) {
      this.order.payment = this.payments[0].value
    }

    if (this.deliveryAllowCountries.includes('COUNTRIES')) {
      fetch('https://restcountries.com/v3.1/all').then((resp) => resp.json()).then((out) => {
        out.forEach((country) => {
          this.countriesSelect.push({text: country.name.common, value: country.cca2})
        })
      })
    } else {

      this.deliveryCountries.forEach((country) => {
        this.countriesSelect.push({text: country.name, value: country.cca2})
      })
      if (this.deliveryCountries.length === 1) {
        this.order.country = this.deliveryCountries[0].cca2
        if (this.deliveryCountries[0].permittedCities.length > 0) {
          this.order.cityName = this.deliveryCountries[0].permittedCities[0].name
          this.order.cityPlaceId = this.deliveryCountries[0].permittedCities[0].placeId
          this.selectedCityData = this.deliveryCountries[0].permittedCities[0];
          this.gMapKeyAddress++;
        }
      }
    }

    this.countriesSelect.sort((a, b) => a.text.localeCompare(b.text))
    this.setUser()

    this.getGmapBounds()









  },

}
</script>

<style scoped>
.smallText {
  font-size: 0.875rem
}
</style>