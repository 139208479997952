<template>
<div>

  <div v-if="!editorMode" >
          <v-img :style="`border-radius: ${params.imageParams.radius} !important ;
                         ${getAlignment(params.align)} `"
                 :width="params.imageParams.width"
                 :height="params.imageParams.height"
                 :ratio="params.imageParams.ratio"
                 :position="params.imageParams.position"
                 :src="value" />

  </div>

  <div v-else
       @click="showParamsEdit"
       v-click-outside="closeParamsEdit"
       class="pa-3 editWindow " >
      <v-sheet :set="hover = false"
               @dragover.prevent="dropzoneHover=true"
               @dragleave.prevent="dropzoneHover=false"
               @drop.prevent="drop"
               style="transition: all 0.3s ease; position: relative"
               :color="!value ? 'grey darken-1' : 'transparent' "
               :min-height="this.params.imageParams.height || this.params.imageParams.width  ? this.params.imageParams.height :  '200px'" >

        <v-sheet :color="dropzoneHover ? '#000000cc' : '#00000000' "
                 style="position:absolute; width: 100%; height: 100% !important;"
                 class=" d-flex align-center justify-center  ">

          <div class="text-center " v-if="!value">
            <v-fade-transition mode="out-in">
              <div v-if="!loading">
                <v-icon x-large>mdi-tray-arrow-down</v-icon>
                <h4 class="font-weight-light"><b>Оберіть файл</b> або перетягніть сюди</h4>
              </div>
              <v-progress-circular v-if="loading" indeterminate />
            </v-fade-transition>
          </div>

        </v-sheet>
        <v-expand-transition>



            <v-img
                :style="`border-radius: ${params.imageParams.radius} !important ;
                        ${getAlignment(params.align)} `                          "
                :width="params.imageParams.width"
                :height="params.imageParams.height"
                :ratio="params.imageParams.ratio"
                :position="params.imageParams.position"
                :src="value" />




        </v-expand-transition>

      </v-sheet>

      <v-menu v-model="displayParamsEdit" absolute
            :position-y="paramsEditY"
            :position-x="paramsEditX"
            :close-on-click="false"
            :close-on-content-click="false" >

      <v-sheet dark  elevation="0" @click="displayParamsEdit = true">
        <div class="d-flex" >
          <ftParamsBar v-model="$store.state.templates.page.blocks[block].value.items[row][item].params"
                       @delete="deleteItem"
                       @hover="hoverAction"
                       :auto-input="['imageParams','margin','align','display','delete']" >

            <template slot="prepend">

              <v-btn @click="openImageLibrary"
                     color="grey darken-3" x-large class="noCaps caption text-left" >

                <v-icon  size="30"  class="mr-1">mdi-image-multiple</v-icon>
                <span class="font-weight-light">Обрати      <br>
                  <span class="mt-2 font-weight-medium">
                      З Бібліотеки
                   </span>
                </span>

              </v-btn>

            </template>

          </ftParamsBar>
        </div>
      </v-sheet>

    </v-menu>
      <div ref="paramsEditCanvas" style="position: relative" > </div>
  </div>



</div>
</template>

<script>
import {mapState,mapActions} from "vuex";
import ftParamsBar from "@/components/templates/editor/UI/ftParamsBar";
// import imago from "@/components/UI/imago";

export default {
  name: "ft-text",
  props : ['value','item','block','row','params'],
  components : {
    // imago,
    ftParamsBar
  },
  data() {
      return {
        loading : false,
        paramsEditX : 0,
        paramsEditY : 0,
        displayParamsEdit : false,
        dropzoneHover : false,
        showUpload : false,
        imageLibraryId : this.$uuid.v4(),

        // OLD STUFF
        displayEdit : false,


      }
    },
  computed : {
    ...mapState('templates',  ['editorMode','businessAlias','imageLibraryFile','imageLibrarySenderId' ]),
  },
  watch : {
    imageLibraryFile(val){
     if ( this.imageLibraryId === this.imageLibrarySenderId) {
       this.$store.state.templates.page.blocks[this.block].value.items[this.row][this.item].value = val
     }
    }
  },
  methods : {
    ...mapActions('templates',  ['UPLOAD_IMAGE' ]),

    drop(e) {

      this.loading = true
      this.UPLOAD_IMAGE(e.dataTransfer.files[0]).then((out)=>{
        if ( out.result ) {
          this.saveImage(out.data.url)
        }
        this.loading = false
        this.dropzoneHover = false
      }).catch((error)=>{
        this.loading = false
        this.dropzoneHover = false
        console.log(error)
      })

    },
    saveImage(img) {
      this.$store.state.templates.page.blocks[this.block].value.items[this.row][this.item].value = img
      this.showUpload = false
    },
    deleteItem(){
      this.$store.state.templates.openEditorBlockElement   = true
      this.$store.state.templates.openEditorBlockDeleteElement   = true
      this.$store.state.templates.selectedBlock     = this.block
      this.$store.state.templates.selectedBlockItem = this.item
      this.$store.state.templates.selectedBlockRow = this.row
    },

    showParamsEdit() {

      this.paramsEditX       = this.$refs.paramsEditCanvas.getBoundingClientRect().left
      this.paramsEditY       = this.$refs.paramsEditCanvas.getBoundingClientRect().top + 10
      this.displayParamsEdit = true

    },
    closeParamsEdit() {
      if ( !this.preventClose && this.displayParamsEdit  ) {
        this.displayParamsEdit = false
      }
    },
    hoverAction(val) {
      this.preventClose = val
    },

    openImageLibrary() {
      this.$store.state.templates.openImageLibrary = true
      this.$store.state.templates.imageLibrarySenderId = this.imageLibraryId
    },

    // technical
    getAlignment(alignment) {
      switch ( alignment ) {
        case 'left'   : return ''
        case 'right'  : return 'margin-left : auto;'
        case 'center' : return 'margin-left : auto; margin-right : auto'
        default : return ''
      }
    }

  },
  beforeMount() {
     }
}
</script>

<style scoped>
.sideEditor {
  height: 100%;
  position: fixed;
  left: 0;
  top:0;
  width: 450px;
  z-index: 50000;
}
.editWindow {
  border : 1px #ffffffbb dashed;
}
.editWindow:hover {
  background-color: #ffffff55;
  cursor: pointer;
}
.dropzone {
  border: 2px whitesmoke dashed      !important ;
  transition: all 0.3s ease;
}
</style>