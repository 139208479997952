<template>
  <div :class="displayHide(['sm']) ? 'pa-6 px-8' : 'pa-3' ">

    <!--    Header Title And Navigation-->
    <div v-if="displayHide('sm')"
         class="d-flex justify-space-between mb-6">
      <div class="d-flex align-center">
        <v-btn outlined  icon class="mr-3" color="blue-grey">
          <v-icon  >mdi-view-dashboard-outline</v-icon>
        </v-btn>

        <div>
          <h3 style="line-height: 1.2"> Замовлення </h3>
          <h5 class="font-weight-medium">Таблиця</h5>
        </div>

      </div>

      <v-sheet  class="align-center justify-space-between pb-4"
                :class="displayShow('sm') ? '' : 'd-flex' ">
        <div v-if="displayHide('sm')"  class="d-flex justify-end">
          <v-tabs   :centered="displayShow('sm')"  v-model="orderView" color="blue-grey">
            <v-tab    ><v-icon> mdi-reorder-horizontal </v-icon></v-tab>
            <v-tab ><v-icon> mdi-google-maps </v-icon></v-tab>
            <!--              <v-tab ><v-icon> mdi-view-grid-outline </v-icon></v-tab>-->
            <!--              <v-tab ><v-icon> mdi-chart-bar </v-icon></v-tab>-->
          </v-tabs>
        </div>
      </v-sheet >

    </div>
    <!-- Header statistics-->
    <v-row v-if="displayHide('sm')" >
      <v-col v-for="(block,i) in headerBlocks" :key="i"
             cols="6" sm="6" xs="6" md="6" lg="3" xl="3" class="" >
        <v-sheet height="100" dark :color="block.color" class="pb-4 pt-2 px-4 wsRoundedHalf">
          <div class="mb-0 caption mb-2">{{  block.title  }}</div>

          <div class="d-flex align-center">
            <v-icon size="32" class="mr-3">{{ block.icon }}</v-icon>
            <div>
              <div v-for="(line,k) in block.content" :key="k"  class="d-flex align-center">
                <h5 style="width: 75px" class="mt-0 font-weight-light">{{ line.text }}: </h5>
                <h4  class="mt-0 font-weight-light">{{ line.value }}  </h4>
              </div>

            </div>
          </div>


        </v-sheet>
      </v-col>
    </v-row>

    <v-divider class="mt-3" v-if="displayHide('sm')"  />
    <!-- Filters -->
    <div v-if="displayHide('sm')"
         class="justify-space-between align-center  pa-3" :class="displayShow('sm') ? '' : 'd-flex'">
      <div :class="displayShow('sm') ? '' : 'd-flex'">
        <v-chip v-if="displayShow('sm')"
                :style="displayShow('sm') ? 'width: 100%' : '' "
                small dark color="teal ">
          <v-icon small class="mr-2">mdi-text-box-check-outline</v-icon>
          {{ orders_filtered.length }}
        </v-chip>

        <v-chip   :style="displayShow('sm') ? 'width: 100%' : 'padding : 13px 10px; border-radius: 26px; width: 120px'"
                  :class="displayShow('sm') ? 'mt-2' : ''"
                  class="mr-2 "  small outlined color="grey darken-3">
          <v-icon small class="mr-2">mdi-magnify</v-icon>
          <v-text-field class="caption ml-n3" v-model="searchProducts" filled background-color="transparent"
                        style=" padding-bottom:2px; margin-left: 0" placeholder="Пошук" dense hide-details ></v-text-field>
        </v-chip>
        <ftSelect v-model="filters.status" :items="filterTypes" >
          <v-btn
              :block="displayShow('sm')"
              :class="displayShow('sm') ? 'mt-2' : ''"
              :dark="filters.status !== ''"
              :outlined="filters.status === ''"
              :color="filters.status !== '' ? 'blue-grey ' : 'grey darken-3' "
              small rounded class="noCaps mr-2" >
            <v-icon small>mdi-list-status</v-icon>
           {{ getStatusText(filters.status) }}
          </v-btn>
        </ftSelect>
        <v-menu ref="dateFilter"  :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn :block="displayShow('sm')"
                   :class="displayShow('sm') ? 'mt-2' : ''"
                   v-bind="attrs" v-on="on"
                   :dark="filters.date.length >1"
                   :outlined="filters.date.length <2"
                   :color=" filters.date.length >1 ? 'blue-grey ' : 'grey darken-3' "
                   small rounded class="noCaps mr-2" >
              <v-icon small class="mr-2">mdi-calendar</v-icon>
              {{ filters.date.length > 1 ? `з  ${filters.date[0]} по ${filters.date[1]} ` : 'Весь період' }}
            </v-btn>
          </template>
          <v-date-picker   v-model="filters.date" range
                           no-title
                           min="2020-01-01" max="2150-01-01"
                           @change="$refs.dateFilter.save(filters.date)" />
        </v-menu>
        <ftSelect v-model="filters.payment" :items="distinct_payment" >
          <v-btn
                 :block="displayShow('sm')"
                 :class="displayShow('sm') ? 'mt-2' : ''"
                 :dark="filters.payment !== ''"
                 :outlined="!filters.payment"
                 :color="filters.payment ? 'blue-grey ' : 'grey darken-3' "
                 small rounded class="noCaps mr-2" >
            <v-icon small>mdi-cash</v-icon>
            {{filters.payment ?  paymentName(filters.payment) : 'Оплата' }}
          </v-btn>
        </ftSelect>
        <ftSelect v-model="filters.delivery" :items="distinct_deliveries" >
          <v-btn
              :block="displayShow('sm')"
              :class="displayShow('sm') ? 'mt-2' : ''"
              :dark="filters.delivery !== ''"
              :outlined="!filters.delivery"
              :color="filters.delivery ? 'blue-grey ' : 'grey darken-3' "
              small rounded class="noCaps mr-2" >
            <v-icon small>mdi-cash</v-icon>
            {{filters.delivery ?  getDeliveryType(filters.delivery) : 'Доставка' }}
          </v-btn>
        </ftSelect>
        <ftSelect v-model="filters.warehouse" :items="distinct_warehouses" >
          <v-btn
              :block="displayShow('sm')"
              :class="displayShow('sm') ? 'mt-2' : ''"
              :dark="filters.warehouse !== ''"
              :outlined="!filters.warehouse"
              :color="filters.warehouse ? 'blue-grey ' : 'grey darken-3' "
              small rounded class="noCaps mr-2" >
            <v-icon small>mdi-cash</v-icon>
            {{filters.warehouse ?  filters.warehouse : 'Склад' }}
          </v-btn>
        </ftSelect>
        <v-btn @click="resetFilter"
               :block="displayShow('sm')"
               :class="displayShow('sm') ? 'mt-2' : ''"
               small rounded text class="noCaps" >
          <v-icon small>mdi-close</v-icon>
          {{ displayShow('sm') ?  'Скинути' : '' }}</v-btn>
      </div>
      <v-chip v-if="!displayShow('sm')" :style="displayShow('sm') ? 'width: 100%' : '' "
              small dark color="teal ">
        <v-icon small class="mr-2">mdi-text-box-check-outline</v-icon>
        {{ orders_filtered.length }}
      </v-chip>
    </div>
    <v-divider v-if="displayHide('sm')" />

      <!-- mobile-->
    <div v-if="displayShow('sm')" class="d-flex px-4 justify-space-between align-center">
      <h4> Всього: {{ orders_filtered.length }}</h4>
      <ftSelect v-model="filters.status" :items="filterTypes" >
        <v-btn
            :block="displayShow('sm')"
            :class="displayShow('sm') ? 'mt-2' : ''"
            :dark="filters.status !== ''"
            :outlined="filters.status === ''"
            :color="filters.status !== '' ? 'blue-grey ' : 'grey darken-3' "
            small rounded class="noCaps mr-2" >
          <v-icon small>mdi-list-status</v-icon>
          <h3>{{ getStatusText(filters.status) }}</h3>
        </v-btn>
      </ftSelect>
    </div>


        <!-- Table View -->
        <div v-if="orderView === 0">

          <v-data-table v-if="displayHide(['sm'])"
                        :items="orders_filtered"
                        :headers="orders_headers"
                        class="mt-0 pt-0"
                        selectable-key="id"
                        :disable-sort="displayShow('sm')"
                        v-model="selectedProducts"
                        :search="searchProducts"
                        :item-class="itemRowBackground" >


            <template v-slot:body="{items}">
              <tbody>
              <tr v-for="(item,i) in items" :key="i">

                <!-- Date-->
                <td align="right" class="px-0 text-no-wrap" width="10px">
                  <h5>{{ parseTime(item.date) }} </h5>
                </td>
                <!-- OrderNumber-->
                <td align="center"  width="10px">
                  <h5 class="font-weight-light">{{ item.order_id }} </h5>
                </td>
                <!-- Name-->
                <td>
                  <h5 class="">{{ item.name }} </h5>
                  <h5 class="font-weight-light grey--text text--darken-3 ">{{ item.phone }}</h5>
                </td>
                <!-- Delivery-->
                <td class="pl-2">
                  <h5 class="grey--text text--darken-2 font-weight-light">
                    <span v-if="item.delivery ==='address' ">
                      {{ item.address }}
                    </span>
                    <span style="line-height: 1.2" class="caption" v-if="item.delivery ==='novaPoshta' ">
                      {{ item.city  }}<br>
                      {{ item.npWarehouse  }}
                    </span>
                  </h5>
                </td>
                <!-- Price-->
                <td>
                  <div class="d-flex">
                    <v-icon small color="grey" >mdi-cash</v-icon>
                    <h5 class="text-no-wrap">{{ item.price }} ГРН</h5>
                  </div>
                </td>
                <!-- Warehouse-->
                <td >

                  <div>
                    <ftSelect :set="val = ''"
                              v-model="val"
                              @input="editOrder(item.id, { warehouse : val }  )"
                              :items="warehouses" >
                      <div class="pa-1 d-flex"
                           small rounded >
                        <v-icon small class="mr-1">mdi-warehouse</v-icon>
                        <h5> {{ item.warehouse ? item.warehouse.name : 'Склад' }} </h5>
                      </div>

                    </ftSelect>
                  </div>


                </td>

                <!--Payemnt Status-->
                <td align="center" class="px-0" >
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip :color="item.payed ? 'teal lighten-3' : 'blue-grey ' "  dark small outlined  v-bind="attrs" v-on="on">
                        <h4> {{getPayedText(item.payed)}} </h4>
                      </v-chip>
                    </template>
                    <v-list dense dark>
                      <v-list-item-group >
                        <v-list-item   @click="openConfirmation('payment_status',item, true)">
                          <v-list-item-title>Оплачено</v-list-item-title>
                        </v-list-item>
                        <v-list-item   @click="openConfirmation('payment_status',item, 'false')">
                          <v-list-item-title>Не оплачено</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
                <!-- Delivery Status -->
                <td align="center" class="px-0">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip dark small  v-bind="attrs" v-on="on" outlined
                              :color="item.delivery_status === 'recieved' ? 'teal lighten-3' : 'blue-grey '" >
                        <h4> {{ getDeliveryStatusText(item.delivery_status) }} </h4>
                      </v-chip>
                    </template>
                    <v-list dense dark>
                      <v-list-item-group >
                        <v-list-item   @click="openConfirmation('delivery_status',item, 'new')">
                          <v-list-item-title>Готується</v-list-item-title>
                        </v-list-item>
                        <v-list-item   @click="openConfirmation('delivery_status',item, 'sent')">
                          <v-list-item-title>Відправлено</v-list-item-title>
                        </v-list-item>
                        <v-list-item   @click="openConfirmation('delivery_status',item, 'recieved')">
                          <v-list-item-title>Доставлено</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
                <!-- Accept buttons-->
                <td v-if="filters.status === -1 ">
                  <v-btn @click="editOrder(item.id, { status : 'accept' } )"
                         elevation="0" icon color="teal " dark small>
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                  <v-btn @click="editOrder(item.id, { status : 2 } )" elevation="0" icon color="red lighten-3" dark small>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </td>


                <!-- Action-->
                <td>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon small v-bind="attrs" v-on="on" >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item v-for="(menuItem, i) in editActions" :key="i" @click="editButton(menuItem.action,item)">
                        <v-list-item-title class="font-weight-light">{{ menuItem.label }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>

                <td class="pa-0">
                  <v-icon small :color="item.sync ? 'teal' : 'grey lighten-2' ">mdi-autorenew</v-icon>
                </td>

              </tr>
              </tbody>

            </template>

          </v-data-table>

          <v-data-iterator v-else
                           disable-sort

                           hide-default-footer
                           items-per-page="1000"
                           :items="orders_filtered"
                           :headers="orders_headers"
                           v-model="selectedProducts"
                           :search="searchProducts" >
            <template v-slot:default="{ items  }">
              <v-sheet v-for="(item,i) in items" :key="i"
                       @click="openDetails(item)"
                        class="pointer">

                <v-sheet dark :color="item.payed ? 'teal' : 'blue-grey' "
                         class="d-flex wsRoundedHalfTop align-center justify-space-between mt-5 px-4 py-1">
                  <h6 class="font-weight-light">{{ parseTime(item.date) }} </h6>
                  <h5 class="font-weight-medium" >{{ item.order_id }} </h5>
                  <div class="d-flex">
                    <v-icon small class="mr-1"  >mdi-cash</v-icon>
                    <h6 class="text-no-wrap font-weight-light">{{ item.price }} ГРН</h6>
                  </div>
                </v-sheet>

                  <v-sheet color="grey lighten-4" class="wsRoundedHalfBottom  py-2 px-3 " >

                    <v-simple-table style="background-color: transparent" dense>
                      <tbody>
                      <tr style="background-color: transparent" >
                        <td width="100px">
                          <h5 class="font-weight-light">Адреса </h5>
                        </td>
                        <td>
                          <h5>
                              <span v-if="item.delivery ==='address' ">
                                {{ item.address }}
                              </span>
                            <span style="line-height: 1.2" class="caption" v-if="item.delivery ==='novaPoshta' ">
                                {{ item.city  }}<br>
                                {{ item.npWarehouse  }}
                              </span>
                          </h5>
                        </td>
                      </tr>
                      <tr style="background-color: transparent">
                        <td width="100px">
                          <h5 class="font-weight-light"> Ім'я </h5>
                        </td>
                        <td>
                          <h5 class="font-weight-medium">{{ item.name }}</h5>
                        </td>
                      </tr>
                      <tr style="background-color: transparent">
                        <td width="100px">
                          <h5 class="font-weight-light">Телефон</h5>
                        </td>
                        <td>
                          <h5 class="font-weight-medium">{{ item.phone }}</h5>
                        </td>
                      </tr>
                      <tr style="background-color: transparent">
                        <td width="100px">
                          <h5 class="font-weight-light">Коментар</h5>
                        </td>
                        <td>
                          <h5 class="font-weight-medium">{{ item.comment }}</h5>
                        </td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                    <!-- Name-->



                  </v-sheet>

              </v-sheet>

            </template>
          </v-data-iterator>
        </div>

        <div v-if="orderView === 4">
          <v-data-iterator v-if="!loading"
                        :items="orders_filtered"
                        :headers="orders_headers"
                        show-select
                        selectable-key="id"
                        v-model="selectedProducts"
                        :search="searchProducts" >
            <template v-slot:default="{ items  }">
              <v-row v-masonry  >
                <v-col  cols="4"  v-for="(order,i) in items" :key="i">
                  <v-hover v-slot="{ hover }" >
                  <v-sheet
                           :dark="hover" :elevation="hover ? 2 : 0"
                           :color="hover ? '#646464' : order.payed ? 'teal lighten-4' : '' "
                           class="pt-8 pb-4 px-2 anim"
                           style="border-radius:10px" outlined  >
                    <div class=" mx-4 d-flex justify-space-between ">
                      <div>
                        <h3 class="font-weight-light">#{{ order.order_id }}</h3>
                        <span class="caption">{{ order.date }}</span>
                      </div>

                      <v-btn class="noCaps " @click="openDetails(order)" small text   :color="hover ? 'white' : ''" >
                        <h3 class="font-weight-light">
                          <v-icon small>mdi-information-outline</v-icon>
                          Детальніше</h3>
                      </v-btn>
                    </div>

                    <div class="smallText mt-8 mx-4 d-flex justify-space-between">
                      <b>Виконання:</b>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <div class="d-flex"   v-bind="attrs" v-on="on">
                            <h3 class="smallText">Активно</h3>
                            <v-icon small>mdi-chevron-down </v-icon>
                          </div>
                        </template>
                        <v-list dense dark>
                          <v-list-item-group v-model="filterStatus">
                            <v-list-item  v-for="(item, index) in filterTypes" :key="index"
                                          :value="item" >
                              <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </div>
                    <div class="smallText mt-2 mx-4 d-flex justify-space-between">
                      <b>Оплата</b>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <div class="d-flex"  v-bind="attrs" v-on="on">
                            <h3 class="smallText">{{ getPayedText(order.payed) }}</h3>
                            <v-icon small>mdi-chevron-down </v-icon>
                          </div>
                        </template>
                        <v-list dense dark>
                          <v-list-item-group >
                            <v-list-item   @click="openConfirmation('payment_status',order, true)">
                              <v-list-item-title>Оплачено</v-list-item-title>
                            </v-list-item>
                            <v-list-item   @click="openConfirmation('payment_status',order, 'false')">
                              <v-list-item-title>Не оплачено</v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </div>
                    <div class="smallText mt-2 mx-4 d-flex justify-space-between">
                      <b>Статус</b>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <div class="d-flex"  v-bind="attrs" v-on="on">
                            <h3 class="smallText">{{ getDeliveryStatusText(order.delivery_status) }}</h3>
                            <v-icon small>mdi-chevron-down </v-icon>
                          </div>
                        </template>
                        <v-list dense dark>
                          <v-list-item-group >
                            <v-list-item   @click="openConfirmation('delivery_status',order, 'new')">
                              <v-list-item-title>Готується</v-list-item-title>
                            </v-list-item>
                            <v-list-item   @click="openConfirmation('delivery_status',order, 'sent')">
                              <v-list-item-title>Відправлено</v-list-item-title>
                            </v-list-item>
                            <v-list-item   @click="openConfirmation('delivery_status',order, 'recieved')">
                              <v-list-item-title>Доставлено</v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </div>

                    <div v-if="order.name"
                         class="smallText mt-6 mx-4 d-flex justify-space-between">
                      <b>Ім'я:</b>  {{ order.name }}
                    </div>

                    <div class="smallText mt-2 mx-4 d-flex justify-space-between">
                      <b>Всього товарів:</b>  {{ getQuantity(order) }} штук
                    </div>
                    <div v-if="order.phone"
                         class="smallText mt-2 mx-4 d-flex justify-space-between">
                      <b>Телефон:</b>  {{ order.phone }}
                    </div>
                    <div class="smallText mt-2 mx-4 d-flex justify-space-between">
                      <b>Доставка:</b>  <h4>{{ getDeliveryType(order.delivery) }}</h4>
                    </div>
                    <div class="smallText mt-2 mx-4 d-flex justify-space-between">
                      <b>Оплата:</b>  {{ paymentName(order.payment)}}
                    </div>
                    <div  class="smallText mt-6 mx-4 ">
                      <b>Місто:</b> <br> {{ order.city }}
                    </div>
                    <div class="smallText mt-2 mx-4 ">
                      <b>Адреса:</b> <br>
                      {{ order.address }}<br>
                    </div>
                    <div v-if="order.delivery==='novaPoshta'"
                         class="smallText mt-2 mx-4 ">
                      <b>Відділення:</b> <br>
                      {{ order.npWarehouse }}<br>
                    </div>
                    <v-divider class="mt-5" />
                    <div class="smallText mt-4 mx-4 d-flex justify-end">
                      <h2>{{ order.price }} грн</h2>
                    </div>



                  </v-sheet>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </div>
        <!-- Google Maps-->
        <div v-if="orderView === 1">

          <gmap-map  :center="{lat: 46.975033, lng: 31.994583}"
                     :options="{
                      disableDefaultUI : true,
                      zoomControl : true,
                  }"
                     :zoom="12"
                     style="width: 100%; height: 600px" ref="map">
            <GmapMarker v-for="(order,i) in orders_gMap" :key="i" :position="order.gPaths"  >
            </GmapMarker>

          </gmap-map>

        </div>

        <orderStatistics :orders="orders" v-if="orderView === 2" />
        <orderDetails          v-if    =  "displayOrderDetails"
                               v-model =  "displayOrderDetails"
                               :order  =  "orderDetailsInput"
                               @changeOrder="changeOrder" />
        <orderEditConfirmation v-if    = "displayConfirm"
                               v-model = "displayConfirm"
                               :data   = "confirmType"
                               @onConfirm="confirmation"/>


  </div>
</template>

<script>
import orderEditConfirmation from "@/components/pages/businessDashboard/businessOrders/orderEditConfirmation";
import orderDetails from "@/components/pages/businessDashboard/businessOrders/orderDetails";
import {mapActions, mapState} from "vuex";
import orderStatistics from "@/components/pages/businessDashboard/businessOrders/orderStatistics";
import ftSelect from "@/components/UI/ftSelect";
export default {
  name: "BusinessDashboardOrders",
  components : {
    orderDetails,
    orderEditConfirmation,
    orderStatistics,
    ftSelect
  },
  data (){
    return {
      loading : false,

      filters : {
        status : 0,
        payment : '',
        delivery : '',
        warehouse : '',
        manager : '' ,
        interval : '',
        intervalValue : '',
        date : [],
        hide : false,
      },

      displayConfirm : false,
      confirmType : {type:'',data : {}},

      displayOrderDetails : false,
      orderDetailsInput : {},

      orderView : 0,

      categories : [],
      orders : [],
      productsQuantity : [],
      warehouses : [],

      showEditCategory : false,
      selectedCategory : '',

      selectedProducts : [],
      searchProducts : '',

      editActions : [

        { label: 'Переглянути', action: 'edit'   },
        { label: 'Скасувати',   action: 'cancel' },

      ],

      editGroupActions : [

          { label: 'Видалити',   action: 'delete_group' },

      ],

      filterStatus : { text : 'Активні', value : 0 },
      filterTypes : [
        { text : 'Всі',       value : null },
        { text : 'Заявки',    value : -1   },
        { text : 'Активні',   value : 0    },
        { text : 'Виконані',  value : 1    },
        { text : 'Cкасовані', value : 2    },
      ],

      displayEditor : false,
      editorType : 'category',
      editorInput : false,
      editorRemove : false,
    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
    ...mapState('notifications',['notifications']),

    //distinct filter values
    distinct_payment() {
      let items = [{ text : 'Всі' , value : '' }]
      if (this.orders.length > 0) {
       let payments = this.orders.map(item => item.payment)
            .filter((value, index, self) => self.indexOf(value) === index)
        payments.forEach((payment)=>{
          items.push({ text : this.paymentName(payment), value : payment })
        })
      }

      return items
    },
    distinct_deliveries() {
      let items = [{ text : 'Всі' , value : '' }]
      if (this.orders.length > 0) {
        let deliveries = this.orders.map(item => item.delivery)
            .filter((value, index, self) => self.indexOf(value) === index)

        deliveries.forEach((delivery)=>{
          items.push({ text : this.getDeliveryType(delivery), value : delivery })
        })
      }

      return items
    },
    distinct_warehouses() {
      let items = [{ text : 'Всі' , value : '' }]
      if (this.orders.length > 0) {
        let warehouses = this.orders.map(item => item.warehouse)
            .filter((value, index, self) => self.indexOf(value) === index)
        warehouses = warehouses.filter(el=> el !== undefined)
        let warehouseNames = warehouses.map(item => item.name)
            .filter((value, index, self) => self.indexOf(value) === index)

        warehouseNames.forEach((warehouse)=>{
          items.push({ text : warehouse, value : warehouse })
        })
      }

      return items
    },
    distinct_managers() {
      let items = []
      if (this.orders_filtered.length > 0) {
        let payments = this.orders_filtered.map(item => item.payment)
            .filter((value, index, self) => self.indexOf(value) === index)
        payments.forEach((payment)=>{
          items.push({ text : this.paymentName(payment), value : payment })
        })
      }

      return items
    },
    distinct_dates() {
      let items = []
      if (this.orders_filtered.length > 0) {
        let payments = this.orders_filtered.map(item => item.payment)
            .filter((value, index, self) => self.indexOf(value) === index)
        payments.forEach((payment)=>{
          items.push({ text : this.paymentName(payment), value : payment })
        })
      }

      return items
    },

    // ORDERS
    orders_filtered() {
      var orders = this.orders
      // orders = orders.sort( (a,b) => {
      //
      //   if ( new Date(a.time).getTime() < new Date(b.time).getTime() ){
      //     return -1;
      //   }
      //   if ( new Date(a.time).getTime() > new Date(b.time).getTime() ){
      //     return 1;
      //   }
      //   return 0;
      // } )

      if ( this.filters.status !== null ) {
        orders = orders.filter(el=> el.status === this.filters.status)
      }
      if ( this.filters.warehouse ) {
        orders = orders.filter(el=> el.warehouse )
        orders = orders.filter(el=> el.warehouse.name === this.filters.warehouse )
      }
      if ( this.filters.payment ) {
        orders = orders.filter(el=> el.payment === this.filters.payment )
      }
      if ( this.filters.delivery ) {
        orders = orders.filter(el=> el.delivery === this.filters.delivery )
      }
      if ( this.filters.date.length > 1 ) {
        orders = orders.filter(el=> new Date(el.date) >= this.dateObjectStart && new Date(el.date) <= this.dateObjectEnd  )
      }
      if ( this.filters.search ) {
        orders = orders.filter((el)=> {
          return el.name.toLowerCase().includes(this.filters.search.toLowerCase()) ||
              el.phone.toLowerCase().includes(this.filters.search.toLowerCase())
            }
        )
      }
      return orders
    },

    orders_gMap() {
      let orders = this.orders_filtered
      orders = orders.filter(el=> el.gPaths )
      return orders
    },
    orders_headers() {
      var headers = [


        {text:"Дата",value:'date', sortable : false},
        {text:"Номер",value:'order_id', sortable : false},
        {text:"Клієнт",value:'name', sortable : false},
        {text:"Доставка",value:'delivery', align : 'center', sortable : false},
        {text:'Ціна',value:'price', align : 'center'},
        {text:"Оплата", value:'name'},
        {text:'Доставка',value:'status',align : 'center', width:"100"},
        {text:'',value:'action'},
        {text:"",value:'sync', sortable : false , width: 5},


      ]
     if ( this.moduleIsActive('warehouse') ) {
       headers.splice(5,0, {text:"Cклад", align : 'center', value:'warehouse',width:"150"},)
     }
      if ( this.filters.status === -1 ) {
        headers.splice(-2,0, {text:"Прийняти", align : 'center', value:'accept',width:"100"},)
      }
      return headers
    } ,

    // date objects
    dateObjectStart() {
      let date = false
      if ( this.filters.date.length > 1 ) {
        date = new Date(this.filters.date[0])
        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)
      }
      return date
    },
    dateObjectEnd() {
      let date = false
      if ( this.filters.date.length > 1 ) {
        date = new Date(this.filters.date[1])
        date.setHours(23)
        date.setMinutes(59)
        date.setSeconds(59)
      }
      return date
    },
    dateObjectMonth() {
      let date = new Date();
      date.setDate(1)
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      return date
    },
    dateObjectDay() {
      let date = new Date();
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      return date
    },
    // statistics
    headerBlocks() {
      return [
        { title: 'Поточні Замовлення',
          content: [
            { text : 'Активні' , value : this.activeOrders },
            { text : 'Заявки' , value : this.applyOrders },
          ],
          icon: 'mdi-text-box-check-outline',
          color: 'blue-grey'},
        { title: 'Виконано за місяць',
          content: [
            { text : 'Замовлень' , value : this.monthOrders.length },
            { text : 'Доход' , value : this.monthOrdersTotal + ' грн' },
          ],
          icon: 'mdi-calendar-month',
          color: 'blue-grey '},
        { title: 'Виконано за день',
          content: [
            { text : 'Замовлень' , value : this.dayOrders.length },
            { text : 'Доход' , value : this.dayOrdersTotal + ' грн'},
          ],
          icon: 'mdi-calendar',
          color: 'teal '},
        { title: 'Виконано період',
          content: [
            { text : 'Замовлень' , value : this.periodOrders.length },
            { text : 'Доход' , value : this.periodOrdersTotal + ' грн' },
          ],
          icon: 'mdi-calendar-range',
          color: 'teal'},
      ]
    },

    activeOrders() {
      let orders = this.orders
      orders = orders.filter(el=> el.status === 0 )
      return orders.length
    },
    applyOrders() {
      let orders = this.orders
      orders = orders.filter(el=> el.status === -1 )
      return orders.length
    },
    monthOrders() {
      let orders = this.orders
      orders = orders.filter(el=>
          new Date(el.date) >= this.dateObjectMonth && el.status === 1
      )
      return orders
    },
    dayOrders() {
      let orders = this.orders
      orders = orders.filter(el=>
          new Date(el.date) >= this.dateObjectDay &&  el.status === 1 )
      return orders
    },
    periodOrders() {
      let orders = this.orders_filtered
      // orders = orders.filter(el=>
      //     new Date(el.date) >= this.dateObjectStart &&
      //     new Date(el.date) <= this.dateObjectEnd &&
      //         el.status === 1)
      return orders
    },
    monthOrdersTotal() {
      return this.getOrdersTotal(this.monthOrders)
    },
    dayOrdersTotal() {
      return this.getOrdersTotal(this.dayOrders)
    },
    periodOrdersTotal() {
      return this.getOrdersTotal(this.periodOrders)
    },
  },
  watch : {
    notifications(){

      var notify = this.notifications[this.notifications.length-1]

      if (notify.type === 'order_new') {
        this.getNewOrder()
      }
    },
  },
  methods : {
    ...mapActions('orders', [ 'GET_BUSINESS_ORDERS','GET_LAST_ORDER','EDIT_BUSINESS_ORDER']),

    changeOrder(input){
      this.displayOrderDetails = input
    },
    getOrdersTotal(orders){
      let total = 0;
      orders.forEach((order)=>{
        total +=this.getTotalOrder(order)
      })
      return total
    },
    resetFilter() {
      this.filters = {
        status : 0,
        payment : '',
        delivery : '',
        warehouse : '',
        manager : '' ,
        interval : '',
        intervalValue : '',
        date : [],
        hide : false,
      }
    },
    editOrder(id,data) {
      this.loading = true
      this.EDIT_BUSINESS_ORDER({id:id,data:data}).then((out)=>{

        if(out.result) {


          let index = this.orders.findIndex(el=>el.id === id)
          let notifyText = 'Cтатус замовлення змінено'

          if (index !== -1) {

            if (data.payed) { data.payed = data.payed !== 'false' }
            if (data.status === 2 ) {  notifyText = 'Замовлення скасовано !' }
            if ( data.status === 'accept') { data.status = 0 }
            if ( data.warehouse ) {  data.warehouse = out.data.warehouse   }

            if(out.data) {
              if (out.data.completed) {
                data.status = 1
                notifyText = 'Замовлення виконане !'
              }

            }
            let order = Object.assign({},this.orders[index],data)
            this.$set(this.orders,index,order)
            this.notify(notifyText)

          }

        }

        this.loading = false
      })
      .catch(()=>{this.loading = false})
    },
    getNewOrder() {
      this.loading = true
      this.GET_LAST_ORDER(this.selectedBusiness.alias).then((out) => {
        out.data.new = true
        this.orders.unshift(out.data)
        this.loading = false
      }).catch( () => this.loading = false)
    },
    openDetails(order) {
      this.displayOrderDetails = true
      this.orderDetailsInput = order
    },
    getCategoryCount(category) {
      return this.products.filter(el=>el.category_id === category).length
    },
    editButton(action,item) {

      switch(action) {
        case 'edit'         : this.openDetails(item)                    ;   break;
        case 'cancel'       : this.openConfirmation('cancel',item) ;   break;
        case 'delete_group' : this.deleteProduct(this.selectedProducts) ;   break;
      }

    },
    confirmation(data) {
      switch(data.type) {
        case 'cancel'          : return this.cancelOrder(data.data) ;
        case 'delivery_status' : return this.editOrder(data.data.id,{ delivery_status : data.value});
        case 'payment_status'  : return this.editOrder(data.data.id,{ payed           : data.value})
      }
    },
    openConfirmation(type,order,value=null){
      this.displayConfirm = true
      switch(type) {

        case 'cancel'          : this.confirmType    =  { type : 'cancel',          data : order } ; break;
        case 'delivery_status' : this.confirmType    =  { type : 'delivery_status', data : order, value : value } ; break;
        case 'payment_status'  : this.confirmType    =  { type : 'payment_status',  data : order, value : value } ; break;
      }
    },
    cancelOrder(item){
      this.editOrder(item.id,{ status : 2 })
    },

    // technical
    getTotalOrder(order) {
      let price = 0;
      order.products.forEach((item)=>{
        price += item.quantity * item.price
      })
      return price
    },
    getDeliveryType(type){

      switch(type) {
        case 'address'    : return 'Доставка';
        case 'self'       : return 'Самовивіз';
        case 'novaPoshta' : return 'Нова Пошта';
        default : return 'не обрано';
      }

    },
    paymentName(id){
      let payment = ''

      switch (id) {
       case 'onRecieve_cash'        : payment =   'Готівка'          ;  break;
       case 'onRecieve_card'        : payment =   'Термінал'         ;  break;
       case 'online_card_transfer'  : payment =   'Оплата на карту'  ;  break;
       case 'online_liqpay'         : payment =   'LiqPay'           ;  break;
       case 'online_fondy'          : payment =   'Fondy'            ;  break;
      }

      return payment
    },
    getQuantity(order) {
      let quantity = 0;
      order.products.forEach((item)=>{
        quantity += item.quantity
      })
      return quantity
    },
    getPayedText(payed) {
      if (payed) { return 'Оплачено'} else return 'Не оплачено'
    },
    getDeliveryStatusText(status) {
      switch (status) {
        case 'sent'      :  return 'Відправлено'
        case 'new'       :  return 'Готується';
        case 'recieved'  :  return 'Доставлено';
        default          :  return 'Готується';
        }
    },
    getStatusText(status) {
     if ( status === null) { return 'Всі' }
     switch ( status ) {
       case -1 : return 'Заявки';
       case  0 : return 'Активні';
       case  1 : return 'Виконані';
       case  2 : return 'Cкасовані';
     }

    },

    //WarehouseModule
    getWarehouseSelect(warehouses){
      let selectData = []

      warehouses.forEach((ware)=>{
        selectData.push(
            {text : ware.name, value : ware.id}
        )
      })

      return selectData
    },
    itemRowBackground(item) {
      return item.new ?  'background-color : grey lighten-4' : ''
    },
    parseTime(timeInput) {
      let time = new Date(timeInput)
      let dateToday = new Date();
      let result = ''
      let timeString = ( "0" + time.getHours() ).slice(-2) +  ":" +( "0" + time.getMinutes() ).slice(-2)
      let dateString = ( "0" + time.getDate() ).slice(-2) + "." + ( "0" + (time.getMonth() + 1 ) ).slice(-2);

      if ( dateToday.getDate() === time.getDate() && dateToday.getMonth() === time.getMonth() &&  dateToday.getFullYear() === time.getFullYear() ) {
        result = timeString;
      } else {
        result = dateString + " " +  timeString ;
      }

      return result
    },

  },
  mounted(){
    this.loading = true
    this.GET_BUSINESS_ORDERS(this.selectedBusiness.alias).then((out)=>{
      if (out.data) {

        this.orders = out.data.orders

        this.productsQuantity = out.data.products
        out.data.warehouses.forEach((item)=>{
          this.warehouses.push({text:item.name, value : item.id})
        })


        this.orders.reverse()
        this.loading = false


      }
    }).catch(()=>{
      this.loading = false
      this.notify('Network error')
    })
  }

}
</script>

<style scoped>
.anim {
  cursor : pointer;
  transition: all 0.3s ease;
}
.smallText {
  font-size : 0.875rem
}
</style>