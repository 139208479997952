const actions = {
    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route: body.route,
                method: body.method,
                headers: {'Auth': body.token},
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return false
        }
    },

    async GET_NEWS_ALL({dispatch,rootState}) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: 'news_manage', method: 'GET'})
    },
    async GET_NEWS({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `news/${body}`, method: 'GET'})
    },
    async GET_NEWS_PUBLIC({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `news/public/${body}`, method: 'GET'})
    },
    async EDIT_NEWS({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: 'news_manage', method: 'PUT',data:body})
    },


}

export default {
    namespaced:true,
    actions
}