<template>
  <div>
    <transition>
      <v-sheet dark color="#34343499"  v-if="value" class="sideMenu pa-8" light >

        <div class="d-flex  justify-space-between pa-3 mb-10 mt-3">
          <h2 class="font-weight-light">Корзина</h2>
          <v-btn dark icon plain color="black" @click="toggleCartMenu">
            <v-icon dark color="white">mdi-window-close</v-icon>
          </v-btn>
        </div>



        <v-simple-table style="background-color: #dddddd22">
          <tbody >
          <tr v-for="(product,key) in cart" :key="key" >
            <td class="px-4 py-4" >
<!--              <v-avatar class="mr-2"-->
<!--                        size="40"  >-->
<!--                <v-img src="@/assets/img/bg_slide_1.png" ratio="1" />-->
<!--              </v-avatar>-->
              {{ product.name }}
            </td>
            <td> <b>{{ product.quantity }} x {{ product.price }} грн</b> </td>
          </tr>
          <tr v-if="Object.keys(cart).length === 0">
            <td>
              <h3 class="font-weight-light text-center">Ваша корзина порожня</h3>
            </td>
          </tr>
          </tbody>
        </v-simple-table>

        <div v-if="Object.keys(cart).length > 0"
             class="d-flex justify-end mt-8 mr-1 ">
          <h3 class="font-weight-light">
            Вcього : <span class="font-weight-medium"> {{ getTotalPrice() }} грн</span>
          </h3>
        </div>

        <v-btn  v-if="Object.keys(cart).length > 0"
                @click="gotoCheckout"
                large block dark outlined
                class="mt-12 noCaps" >
          <v-icon class="mr-1">mdi-check</v-icon>
          Оформити замовлення
        </v-btn>
        <v-btn  @click="toggleCartMenu"
                class="mt-4 noCaps"
                block dark text>
          <v-icon>mdi-chevron-left</v-icon>
          Повернутись
        </v-btn>


      </v-sheet>
    </transition>

    <v-overlay z-index="1000" :value="value"  >
      <div class="stopScroll" @click="toggleCartMenu"></div>
    </v-overlay>

  </div>
</template>

<script>


import {mapState} from "vuex";

export default {
  name: "cartSideBar",
  props: ['value','vars'],

  computed : {
    ...mapState('cart', [ 'cart']),
  },
  methods : {
    gotoCheckout() {
      this.$store.state.cart.showCheckout = true
      this.$emit('input',!this.value)
    },
    toggleCartMenu(){
      this.$emit('input',!this.value)
    },
    getTotalPrice(){
      let total = 0;
      Object.keys(this.cart).forEach((key)=>{
        total += parseFloat(this.cart[key].quantity) * parseFloat(this.cart[key].price)
      })
      return total
    }
  }
}
</script>

<style scoped>

.sideMenu {
  position:fixed;
  top:0;
  right:0;
  height: 100%;
  width: 420px;
  z-index: 999999000;
  transition: width 0.5s ease-out;
  overflow-y: auto;
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  transition: all .6s ease;
}
.cartItems {
  background-color: transparent;
}
.stopScroll {
  height: 100vh;
  width: 100% !important;
  top:0;
  left:0;
  position: fixed;

}


/*ANIMATION */
.v-enter {
  opacity: 0 !important;
  transform: translateX(-50px) ;
}
.v-enter-active {

  transition: all 0.2s ease-out;
}
.v-leave-active {
  transition: all 0.2s ease-out;
  opacity: 0 !important;
  transform: translateX(-50px) ;
}

</style>