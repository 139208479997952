<template>
  <v-dialog :fullscreen="displayShow(['sm'])"  v-model="value" width="1100" persistent>

    <v-sheet height="100%"  color="blue-grey" dark  class="py-6 px-4 overflow-y-auto"  >

      <div class="d-flex justify-space-between  ">

        <!--    Header Title And Navigation-->
            <div>
              <h3 > Замовлення : {{  order.order_id }} </h3>
              <h5 class="font-weight-light"> Cтатус: {{ filterTypes[order.status] }}</h5>

            </div>
        <v-btn  icon  @click="value = false"><v-icon>mdi-close</v-icon></v-btn>

      </div>

      <v-sheet  light class="wsRoundedHalf mt-6  justify-space-between"
                :class="displayHide(['sm']) ? 'd-flex' : '' " >
        <div style="width: 100%" class="px-6 py-6">
          <h4 >Товари</h4>

          <v-simple-table class="mt-6" dense >
            <tbody >
            <tr v-for="(product,key) in order.products" :key="key" >
              <td class="px-4 py-2" >
                <div class="d-flex align-center">
                  <v-avatar class="mr-2"
                            size="44"  >
                    <v-img :src="product.image_small" ratio="1" />
                  </v-avatar>
                  {{ product.name }}
                </div>

              </td>
              <td class="text-no-wrap">
                <b> {{  product.quantity }} шт</b>
              </td>
              <td class="text-no-wrap"><b> x {{ product.price }} грн</b></td>
            </tr>
            </tbody>
          </v-simple-table>
          <div class="smallText mt-6 mx-4 d-flex justify-end">
            <b>Вcього: </b> {{ getTotal(order) }} грн
          </div>
        </div>
        <h4 class="px-6" v-if="displayShow(['sm'])" >Товари</h4>
        <v-sheet  :width="displayHide(['sm']) ? 400 : '' "
                  :class="displayHide(['sm']) ? 'wsRoundedHalfRight' : 'wsRoundedHalfBottom' "
                  :color="displayHide(['sm']) ? '#e2e2e2' : '' "
                  class=" px-2 py-6 ">

            <div class="smallText  mx-4 d-flex justify-space-between">
              <b>Cтатус:</b>
              <h3 class="smallText">{{ getStatusText(order.status) }}</h3>
            </div>
            <div class="smallText mt-2 mx-4 d-flex justify-space-between">
              <b>Оплата</b>
              <h3 class="smallText">{{ getPayedText(order.payed) }}</h3>
            </div>
            <div class="smallText mt-2 mx-4 d-flex justify-space-between">
              <b>Доставка</b>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip dark small  v-bind="attrs" v-on="on" outlined
                          :color="order.delivery_status === 'recieved' ? 'teal' : 'blue-grey '" >
                    <h4> {{ getDeliveryStatusText(order.delivery_status) }} </h4>
                  </v-chip>
                </template>
                <v-list dense dark>
                  <v-list-item-group >
                    <v-list-item   @click="editOrder(order.id,{ delivery_status : 'new'});">
                      <v-list-item-title>Готується</v-list-item-title>
                    </v-list-item>
                    <v-list-item   @click="editOrder(order.id,{ delivery_status : 'sent'});">
                      <v-list-item-title>Відправлено</v-list-item-title>
                    </v-list-item>
                    <v-list-item   @click="editOrder(order.id,{ delivery_status : 'recieved'})">
                      <v-list-item-title>Доставлено</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>


            </div>

            <div v-if="order.time "
                 class="smallText mt-4 mx-4 d-flex justify-space-between">
              <b>На час:</b>  {{ formatTime(order.time) }}
            </div>

            <div v-if="order.name "
                 class="smallText mt-4 mx-4 d-flex justify-space-between">
              <b>Ім'я:</b>  {{ order.name }}
            </div>


            <div v-if="order.phone"
                 class="smallText mt-2 mx-4 d-flex justify-space-between">
              <b>Телефон:</b>  {{ order.phone }}
            </div>
            <div class="smallText mt-2 mx-4 d-flex justify-space-between">
              <b>Доставка:</b>  <h4>{{ getDeliveryType(order.delivery) }}</h4>
            </div>
            <div class="smallText mt-2 mx-4 d-flex justify-space-between">
              <b>Оплата:</b>  {{ paymentName(order.payment) }}
            </div>
            <div v-if="order.city_name"
                 class="smallText mt-6 mx-4 ">
              <b>Місто:</b> <br> {{ order.city_name }}
            </div>
            <div v-if="order.address"
                 class="smallText mt-2 mx-4 ">
              <b>Адреса:</b> <br>
              {{ order.address }}<br>
            </div>
            <div v-if="order.comment"
                 class="smallText mt-2 mx-4 ">
              <b>Коментар:</b> <br>
              {{ order.comment }}<br>
            </div>
            <div v-if="order.npWarehouse" class="smallText mt-2 mx-4 ">
              <b>Відділення:</b> <br>
              {{ order.npWarehouse }}<br>
            </div>
          </v-sheet>

      </v-sheet>




    </v-sheet>
  </v-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "orderDetails",
  props : ['value','order'],
  data() {
    return {
      filterTypes :
          {
            0: 'Активний',
            1: 'Виконаний',
            2: 'Cкасований',
          }
    }
  },
  watch: {
    value() {
      this.$emit('input',this.value)
    }
  },
  methods : {
    ...mapActions('orders', [ 'EDIT_BUSINESS_ORDER']),

    changeOrder(input) {
      this.$emit('changeOrder',{id:input.id,data:input.data})
    },
    editOrder(id,data) {
      this.loading = true
      this.order.delivery_status = data.delivery_status
      this.EDIT_BUSINESS_ORDER({id:id,data:data}).then((out)=>{

        if(out.result) {

          let notifyText = 'Cтатус замовлення змінено'


            if (this.order.payed) { data.payed = data.payed !== 'false' }
            if (this.order.status === 2 ) {  notifyText = 'Замовлення скасовано !' }
            if ( this.order.status === 'accept') { data.status = 0 }
            if ( this.order.warehouse ) {  data.warehouse = out.data.warehouse   }

            if(out.data) {
              if (out.data.completed) {
                this.order.status = 1
                notifyText = 'Замовлення виконане !'
              }

            }
            this.$emit('changeOrder',{id:this.order.id,data:this.order.data})
            this.notify(notifyText)



        }

        this.loading = false
      })
          .catch(()=>{this.loading = false})
    },
    // technical
    getQuantity(order) {
      let quantity = 0;
      order.products.forEach((item)=>{
        quantity += item.quantity
      })
      return quantity
    },
    getTotal(order) {
      let price = 0;
      order.products.forEach((item)=>{
        price += item.quantity * item.price
      })
      return price
    },
    getPayedText(payed) {  return payed ? 'Оплачено' : 'Не оплачено'  },
    getDeliveryType(type){

      switch(type) {
        case 'address'    : return 'Доставка';
        case 'self'       : return 'Самовивіз';
        case 'novaPoshta' : return 'Нова Пошта';
        default : return 'не обрано';
      }

    },
    getDeliveryStatusText(status) {
      switch (status) {
        case 'sent'      :  return 'Відправлено'
        case 'new'       :  return 'Готується';
        case 'recieved'  :  return 'Доставлено';
        default          :  return 'Готується';
      }
    },
    getStatusText(status) {
      if ( status === null) { return 'Всі' }
      switch ( status ) {
        case -1 : return 'Заявка';
        case  0 : return 'Активне';
        case  1 : return 'Виконано';
        case  2 : return 'Cкасоване';
      }

    },
    paymentName(id){
      let payment = ''

      switch (id) {
        case 'onRecieve_cash'        : payment =   'Готівка'          ;  break;
        case 'onRecieve_card'        : payment =   'Термінал'         ;  break;
        case 'online_card_transfer'  : payment =   'Оплата на карту'  ;  break;
        case 'online_liqpay'         : payment =   'LiqPay'           ;  break;
        case 'online_fondy'          : payment =   'Fondy'            ;  break;
      }

      return payment
    },
  }
}
</script>

<style scoped>
.smallText {
  font-size : 0.875rem
}
</style>