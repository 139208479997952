<template>
  <div>
    <p class="text-h5 mt-10 text-center"> {{ message }}
       </p>
    <div class="d-flex justify-center">
      <v-progress-circular v-if="loader" class=" justify-center" indeterminate size="48" />
    </div>
    <div class="d-flex mt-10 justify-center" @click="resend">
      <v-btn v-if="show" text color="primary">Resend Activation Letter</v-btn>
    </div>
    <div class="d-flex mt-10 justify-center">
      <v-btn color="primary" @click="exit">OK</v-btn>
    </div>



  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "activateAccount",
  data() {
    return {
      show : true,
      message : "The account needs to be confirmed! Check your email for the instructions"
    }
  },
  computed : {
    ...mapState('auth',['resendToken','loader','modalAuth']),
    refresh() {
      return this.modalAuth
    },
  },
  methods : {
    ...mapMutations('auth',['TOGGLE_AUTH_MODAL']),
    ...mapActions('auth',['RESEND_CONFIRMATION']),
   async resend() {

     const request = await  this.RESEND_CONFIRMATION({code:this.resendToken});

     if (request.result) {
       this.message = 'Confirmation email has been sent on your address'
       this.show = false
     }

    },
    exit(){
      this.show = true;
      this.message= 'The account needs to be confirmed! Check your email for the instructions';
      this.TOGGLE_AUTH_MODAL();
    }
  },
  watch : {
    refresh() {
      this.show = true;
      this.message= 'The account needs to be confirmed! Check your email for the instructions';
    }
  }
}
</script>

<style scoped>

</style>