<template>
  <div class="main">
    <authModal />
    <div class="trans">
      <v-sheet dark class="loginSideBar" elevation="2">
        <img width="100%" alt="WeStudy" src="https://mdu.westudy.in.ua/assets/images/brand/logo.png" class="container--fluid mt-10 pl-8 pr-8 mb-5" >
        <authWindow  dark />
      </v-sheet>
    </div>

  </div>
</template>

<script>
import authWindow from "@/components/auth/authWindow";
import authModal from "@/components/auth/authModal";
export default {
name: "loginPage",
  components : {
    authWindow,
    authModal
  }

}
</script>

<style scoped>
.main {
  background: url('https://i.pinimg.com/originals/58/2f/f1/582ff10c69d9444726e8b361d7fd9f37.jpg');
  background-size: contain;
  background-position: center;
  height: 100%;
  left:300px;
}
.trans {
  background: #ffffff55;
  height: 100%;}
.loginSideBar {
  position:fixed;
  background-color: #4799cd;
  top:0;
  left:0;
  height: 100%;
  width: 400px;
  transition: width 0.5s ease-out;
  overflow-y: auto;
}
</style>