<template>
  <div>
    <p class="text-h5 mt-10 text-center font-weight-light"> Are you sure you want to log out ?</p>

    <div class="d-flex mt-10 justify-center">
      <v-btn color="accent" @click="TOGGLE_DASH_MODAL" class="mr-10">No</v-btn>
      <v-btn color="primary" @click="logout">Yes</v-btn>
    </div>
  </div>


</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "confirmLogout",
  methods : {
    ...mapMutations('dashboard', ['TOGGLE_DASH_MODAL']),
    ...mapMutations('auth',['LOGOUT']),

    logout() {
      this.$pusher.unsubscribe(`message-channel.${this.$store.state.auth.userID}`)
      this.LOGOUT( this.$router);
      this.TOGGLE_DASH_MODAL();

    }
  }
}
</script>

<style scoped>

</style>