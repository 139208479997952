<template>
  <v-sheet ref="element" >
    <v-fade-transition>
      <div v-if="!resizing">
        <div class="d-flex justify-space-between align-center pl-3">
          <div>
            <h4>Виконані замовлення </h4>
            <h5 class="font-weight-light">
              {{ filter.date.length > 1 ? `з  ${filter.date[0]} по ${filter.date[1]} ` : 'Весь період' }}
            </h5>
          </div>


           <div class="d-flex">
            <v-btn @click="ownPeriod = ! ownPeriod"
                   :dark="ownPeriod"
                   :outlined="!ownPeriod"
                   :color="ownPeriod ? 'primary lighten-2' : 'grey darken-3' "
                   small rounded class="noCaps mr-2" >
              <v-icon small>mdi-cash</v-icon>
              Окремий період
            </v-btn>
            <v-menu v-if="ownPeriod" ref="dateFilter"  :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on"
                       :dark="filter.date.length >1"
                       :outlined="filter.date.length <2"
                       :color=" filter.date.length >1 ? 'blue lighten-2' : 'grey darken-3' "
                       small rounded class="noCaps mr-2" >
                  <v-icon small class="mr-2">mdi-calendar</v-icon>
                  {{ filter.date.length > 1 ? `з  ${filter.date[0]} по ${filter.date[1]} ` : 'Весь період' }}
                </v-btn>
              </template>
              <v-date-picker   v-model="filter.date" range
                               no-title
                               min="2020-01-01" max="2150-01-01"
                               @change="$refs.dateFilter.save(filter.date)" />
            </v-menu>

            <ftSelect @input="addWarehouseLine"  :items="warehouses" >
              <template #default="item">
                <v-btn
                    :dark="filter.warehouse !== ''"
                    :outlined="!filter.warehouse"
                    :color="filter.warehouse ? 'primary lighten-2' : 'grey darken-3' "
                    small rounded class="noCaps mr-2" >
                  <v-icon small>mdi-cash</v-icon>
                  {{filter.warehouse ?  item.text : 'Склад' }}
                </v-btn>
              </template>
            </ftSelect>
            <v-btn small @click="$emit('delete')" icon><v-icon small>mdi-delete</v-icon></v-btn>
          </div>

        </div>
        <apexchart :height="height-95" :key="chartNum"   :options="chartOptions" :series="series" >
        </apexchart>

      </div>
    </v-fade-transition>


  </v-sheet>
</template>

<script>
import ftSelect from "@/components/UI/ftSelect";
import apexchart from "vue-apexcharts"
import {mapActions} from "vuex";
export default {
  name: "statisticsOrders",
  props : ['externalFilter','height','width','resizing','value'],
  components : {
    ftSelect,
    apexchart
  },
  data() {
    return {


      chartOptions: {
        chart: {
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar : {
            show : false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width : 2,
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: [],
        }
      },
      series: [],


      chartNum : 0,
      products : [],
      ownPeriod : false,
      arro : [],
      warehouses : [ { text : 'Всі' , value : '' } ],
      filter : {
        date : [],
        dataLines : [
            { type : 'all' , value : null },
        ],
        warehouse : ''
      },

    }
  },
  watch: {
    externalFilter :{

      handler(filter) {
        if ( filter && !this.ownPeriod ) {
          this.mutateFilter()
        }
      },
      deep : true

    },
    filter :  {
      handler(filter) {
        this.getData()
        this.$emit('input',filter)
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('statistics', ['GET_ORDERS_STATISTICS']),
    getData() {
      this.loading = true

      this.GET_ORDERS_STATISTICS({filter: this.filter}).then((out) => {
        if (out.data) {
          this.series                        = out.data.orders.values
          this.chartOptions.xaxis.categories = out.data.orders.xaxis
          this.chartNum++;
          this.warehouses = out.data.warehouses
          this.loading = false;
        }
      }).catch(() => {
        this.loading = false
        this.notify('Network error')
      })
    },
    addWarehouseLine(input) {
      // this.notify(input)
      let index = this.filter.dataLines.findIndex(el=>el.value === input)
      if ( index === -1 ) {
        this.filter.dataLines.push({type : 'warehouse' , value : input })
      } else {
        this.filter.dataLines.splice(index,1)
      }
    },
    //technical
    getTotalPrice() {
      let price = 0;
      this.products.forEach((product)=>{
        price += parseFloat(product.price)
      })
      return price
    },
    mutateFilter() {
      Object.keys(this.externalFilter).forEach((key)=>{
        this.filter[key] = this.externalFilter[key]
      })
      this.filter = Object.assign({},this.filter)
    }
  },
  mounted() {

    // console.log('THIS IS ORDERS')
    // console.log(this.$refs.element)
    // console.log(this.$refs.element.$el.clientHeight)
    if ( this.externalFilter ) {
      this.mutateFilter()
    }
    if ( this.value )  {
      this.filter = this.value
    }

    this.$emit('PISOK')
    this.getData()
    if (this.date ) {
      this.filter.date = this.date
    }
  }
}
</script>

<style scoped>

</style>