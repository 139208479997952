<template>
  <div >
      <div v-for="(item,i) in param" :key="i"
           class="mr-4">
        <router-link v-if="item.action === 'page' "
                     :to="{ path : getRouterPath(item.route) ,
                          hash : item.scroll }"
                     class="text-decoration-none">
          <v-btn @click="$store.state.templates.triggerScroll++"  text class="linkStyles">{{  item.title  }}  </v-btn>
        </router-link>
        <v-btn v-if="item.action === 'link' " class="linkStyles"
               :href="item.link"
               text>{{  item.title  }}
        </v-btn>
        <div v-if="item.action === 'submenu'">

          <v-menu   open-on-hover offset-y >

            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on" class="linkStyles">
                {{  item.title  }}
                <v-icon small class="ml-1">mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-sheet dark  class="py-3">
              <div v-ripple v-for="(subitem, i) in item.items" :key="i">

                <router-link v-if="subitem.action === 'page' "
                             :to="{ path : getRouterPath(subitem.route)  ,
                                  hash : subitem.scroll }"
                             class="headerMenuItem text-decoration-none">
                  <div @click="$store.state.templates.triggerScroll++"  class="headerMenuItem pl-3 pr-10  py-1"  >
                    <span>{{ subitem.title }}</span>
                  </div>
                </router-link>

                <a v-if="subitem.action === 'link'" :href="subitem.link" style="text-decoration: none">
                  <div  class="headerMenuItem pl-3 pr-10  py-1"  >
                    <span>{{ subitem.title }}</span>
                  </div>
                </a>

              </div>
            </v-sheet>

          </v-menu>



        </div>

      </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "avalonMainMenu",
  props : ['input'],
  computed : {
    ...mapState('templates',  [ 'templateVars_init' , 'templateVars_current','editorMode','openEditor','selectedItem' ]),

    param() {
      return this.editorMode ? this.templateVars_current[this.input].buffer : this.templateVars_init[this.input]
    },
  },
  methods : {
    getRouterPath(route) {
      let fullPath = ''
      if ( route !== 'homepage') {
        fullPath = !this.$store.state.domain ? `/${this.$store.state.business.selectedBusiness.alias}/` : '/'
        fullPath += 'page/' + route
        if (this.editorMode) {
          fullPath += '/edit'
        }
      } else {
        if ( this.$store.state.domain ) {
          fullPath = this.editorMode ? '/edit' : '/'
        } else {
          fullPath = this.editorMode ? `/${this.$store.state.business.selectedBusiness.alias}/edit` : `/${this.$store.state.business.selectedBusiness.alias}/`
        }
      }
      return fullPath
    }
  }
}
</script>

<style scoped>
.headerMenuItem {
  font-size: 1rem !important;
  transition: all 0.2s ease;
  text-decoration: none;
  color : #f1f1f1;
}
.headerMenuItem:hover {
  background-color: #343434;
}
.linkStyles {
  font-size: 14px;
  font-weight: 400;
  text-transform: none !important;
  letter-spacing: 1px !important;
}
</style>