import Vue from "vue";
import Router from "vue-router";

import DashAction from "@/components/pages/businessDashboard/businessOrders/DashAction";

import LoginPage from "@/components/pages/login/LoginPage";
import LoadingPage from "@/components/pages/LoadingPage";
import Dashboard from "@/components/pages/dashboard/PageDashboard";
import DashIndex from "@/components/pages/dashboard/dash/DashIndex";

import DashProfile from "@/components/pages/dashboard/profile/DashProfile";
import DashUsers from "@/components/pages/dashboard/users/DashUsers";

import DashMessenger from "@/components/pages/dashboard/messenger/DashMessenger";
import DashboardNews from "@/components/pages/dashboard/news/DashboardNews";
import PageHomepage from "@/components/pages/homepage/PageHomepage";
import HomeNews from "@/components/pages/homepage/news/HomeNews";
import HomeMain from "@/components/pages/homepage/mainpage/HomeMain";
import SingleNews from "@/components/pages/homepage/news/SingleNews";
import DashLeads from "@/components/pages/dashboard/leads/DashLeads";
import DashBusiness from "@/components/pages/dashboard/business/DashBusiness";
import TemplateLoader from "@/components/templates/TemplateLoader";
import PageBusinessDashboard from "@/components/pages/businessDashboard/PageBusinessDashboard";
import dashBusinessIndex from "@/components/pages/businessDashboard/dashBusiness/dashBusinessIndex";
import DashBusinessDesign from "@/components/pages/businessDashboard/design/DashBusinessDesign";
import DashTemplater from "@/components/pages/dashboard/ADMIN/templater/DashTemplater";
import DashDomains from "@/components/pages/dashboard/ADMIN/domains/DashDomains";

import BusinessDashboardNews from "@/components/pages/businessDashboard/businessNews/BusinessDashboardNews";
import BusinessDashboardPages from "@/components/pages/businessDashboard/businessPages/BusinessDashboardPages";
import BusinessDashboardProducts from "@/components/pages/businessDashboard/businessProducts/BusinessDashboardProducts";
import BusinessDashboardPayments from "@/components/pages/businessDashboard/businessSettings/BusinessDashboardPayments";
import BusinessDashboardDeliveries from "@/components/pages/businessDashboard/businessSettings/BusinessDashboardDeliveries";
import BusinessDashboardGeneral from "@/components/pages/businessDashboard/businessSettings/BusinessDashboardGeneral";
import BusinessDashboardOrders from "@/components/pages/businessDashboard/businessOrders/BusinessDashboardOrders";
import BusinessDashboardDomains from "@/components/pages/businessDashboard/businessSettings/BusinessDashboardDomains";
import BusinessDasboardSubscribers from "@/components/pages/businessDashboard/businessSubscribers/BusinessDasboardSubscribers";
import BusinessDasboardManagers from "@/components/pages/businessDashboard/businessManagers/BusinessDasboardManagers";
import BusinessDashboardWarehouse
    from "@/components/pages/businessDashboard/businessWarehouse/BusinessDashboardWarehouse";
import BusinessDashboardModules from "@/components/pages/businessDashboard/businessSettings/BusinessDashboardModules";
import BusinessDashboardChatBots from "@/components/pages/businessDashboard/businessSettings/BusinessDashboardChatBots";
import BusinessDashboardGoogleMaps
    from "@/components/pages/businessDashboard/businessGoogleLocations/BusinessDashboardGoogleMaps";
import BusinessUserCart from "@/components/pages/businessDashboard/businessUserCart/BusinessUserCart";
import BusinessUserRentProducts
    from "@/components/pages/businessDashboard/businessUserRentProducts/BusinessUserRentProducts";
import BusinessDashboardStatistics
    from "@/components/pages/businessDashboard/businessStatistics/BusinessDashboardStatistics";

import BusinessUserProducts from "@/components/pages/businessDashboard/businessUserProducts/BusinessUserProducts";

// import TemplateLoaderHome from "@/components/templates/TemplateLoaderHome";
import TemplateLoaderNews from "@/components/templates/TemplateLoaderNews";
import TemplateLoaderPages from "@/components/templates/TemplateLoaderPages";
import TemplateLoaderNewsSingle from "@/components/templates/TemplateLoaderNewsSingle";
import TemplateLoaderPagesSingle from "@/components/templates/TemplateLoaderPagesSingle";

import PaymentRedirectLiqpay from "@/components/pages/paymentRedirectPages/PaymentRedirectLiqpay";
import Page404 from "@/components/pages/homepage/Page404";

import auth from './middleware/auth'
import breadcrumbs from './middleware/breadcrumbs'
import withData from './middleware/withData'
import middlewarePipeline from './middlewarePipeline'



Vue.use(Router);
export default function getRouter(store) {


    var host = window.location.hostname;
    let routes = [];
    // FINE TRADING ROUTES

    let fineDomain = host === 'localhost' || host === store.state.hostname ||  host === 'www.' + store.state.hostname



    routes = [
        {   path: `/dashboard/${fineDomain ? ':alias' : ''}`,
            name: 'BusinessDashboard',
            props: true,
            component: PageBusinessDashboard,
            meta: { middleware : [breadcrumbs,auth] },
            children : [
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/`,             name: 'Головна', props:true, component: dashBusinessIndex,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/news`,         name: 'BusinessNewsDashboard', component: BusinessDashboardNews,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/pages`,        name: 'Сторінки', component: BusinessDashboardPages,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/products`,     name: 'Товари', component: BusinessDashboardProducts,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/orders`,       name: 'Замовлення', component: BusinessDashboardOrders,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/action`,       name: 'Акція', component: DashAction,meta: { middleware : [breadcrumbs,auth] } },


                { path: `/dashboard${fineDomain ? '/:alias' : ''}/design`,       name: 'Зовнішній вигляд', props:true, component: DashBusinessDesign,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/payment`,      name: 'Налаштування оплати', props:true, component: BusinessDashboardPayments,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/modules`,      name: 'Налаштування оплати', props:true, component: BusinessDashboardModules,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/delivery`,     name: 'Налаштування доставки', props:true, component: BusinessDashboardDeliveries,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/general`,      name: 'Загальні налаштування ', props:true, component: BusinessDashboardGeneral,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/domains`,      name: 'Доменна адреса ', props:true, component: BusinessDashboardDomains,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/subscribers`,  name: 'Підписники ', props:true, component: BusinessDasboardSubscribers,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/managers`,     name: 'Менеджери ', props:true, component: BusinessDasboardManagers,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/warehouse`,    name: 'Склади', component: BusinessDashboardWarehouse,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/chatBots`,     name: 'Чат Боти', component: BusinessDashboardChatBots,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/googleMaps`,   name: 'Google Maps Зони', component: BusinessDashboardGoogleMaps,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/cart`,         name: 'Корзина', component: BusinessUserCart,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/rentProducts`, name: 'Залогові товари', component: BusinessUserRentProducts,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/statistics`,   name: 'Звіти', component: BusinessDashboardStatistics,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/profile`,      name: 'Profile', component: DashProfile,meta: { middleware : [breadcrumbs,auth] } },
                { path: `/dashboard${fineDomain ? '/:alias' : ''}/viewProducts`, name: 'Товари', component: BusinessUserProducts,meta: { middleware : [breadcrumbs,auth] } },



            ]
        },
        { path: `${fineDomain ? '/:alias' : ''}/liqpay/:order`, props:true, name: 'LiqPayRedirectPage', component: PaymentRedirectLiqpay,},
        { path: `${fineDomain ? '/:alias' : '/'}`,      props:true, name: 'Business Loader', component: TemplateLoader,
            children : [
                { path: `${fineDomain ? '/:alias' : ''}`, name: 'BusinessHome', props:true, component: TemplateLoaderPagesSingle },
                { path: `${fineDomain ? '/:alias' : ''}/news/`, name: 'BusinessNews', props:true, component: TemplateLoaderNews },
                { path: `${fineDomain ? '/:alias' : ''}/news/:newsAlias`, name: 'BusinessNewsSingle', props:true, component: TemplateLoaderNewsSingle },
                { path: `${fineDomain ? '/:alias' : ''}/pages/`, name: 'BusinessPages', props:true, component: TemplateLoaderPages },
                { path: `${fineDomain ? '/:alias' : ''}/page/:pageAlias`, name: 'BusinessPageSingle', props:true, component: TemplateLoaderPagesSingle },
                { path: `${fineDomain ? '/:alias' : ''}/page/:pageAlias/edit`, name: 'BusinessPageSingleEdit', props:true, component: TemplateLoaderPagesSingle },
                { path: `${fineDomain ? '/:alias' : ''}/:notFound(.*)`, component: TemplateLoaderPagesSingle}
            ],
        },
        { path: `${fineDomain ? '/:alias' : ''}/edit`, props:true, name: 'BusinessTemplateLoaderEdit', component: TemplateLoader,
            children : [
                { path: `${fineDomain ? '/:alias' : ''}/edit`, name: 'BusinessTemplateLoaderHomeEdit', props:true, component: TemplateLoaderPagesSingle },
            ]},

        {
            path: '/confirm',
            name: 'confirm',
            component: LoadingPage,
            meta: {
                middleware : [withData]
            }
        },
        ]

    if ( fineDomain ) {
        routes.unshift(
            {
                path: '/',  component: PageHomepage, children : [
                    { path: '/', name: 'Головна', component: HomeMain },
                    { path: '/news/', name: 'FineNews', component: HomeNews },
                    { path: '/news/:alias', name: 'FineNewsSingle',props:true, component: SingleNews },
                ]
            },
        )
        routes.unshift(
            { path: '/login', name: 'login', component: LoginPage },
        )
        routes.unshift(
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: { middleware : [breadcrumbs,auth] },
                children : [
                    { path: '/dashboard', name: 'My Dashboard', component: DashIndex,meta: { middleware : [breadcrumbs,auth] } },
                    { path: '/dashboard/templater', name: 'Templater', component: DashTemplater,meta: { middleware : [breadcrumbs,auth] } },
                    { path: '/dashboard/domains', name: 'Domains', component: DashDomains,meta: { middleware : [breadcrumbs,auth] } },
                    { path: '/dashboard/business', name: 'Business', component: DashBusiness,meta: { middleware : [breadcrumbs,auth] } },
                    { path: '/dashboard/news', name: 'News', component: DashboardNews,meta: { middleware : [breadcrumbs,auth] } },
                    { path: '/dashboard/profile', name: 'Profile', component: DashProfile,meta: { middleware : [breadcrumbs,auth] } },
                    { path: '/dashboard/profile/:id', name: 'View Profile',props:true, component: DashProfile,meta: { middleware : [breadcrumbs,auth] } },
                    { path: '/dashboard/messenger', name: 'Messenger', component: DashMessenger,meta: { middleware : [breadcrumbs,auth] } },
                    { path: '/dashboard/messenger/:showUser', name: 'MessengerUser', props:true, component: DashMessenger,meta: { middleware : [breadcrumbs,auth] } },
                    { path: '/dashboard/leads', name: 'Leads', component: DashLeads,meta: { middleware : [breadcrumbs,auth] } },
                    { path: '/dashboard/users', name: 'Users', component: DashUsers,meta: { middleware : [breadcrumbs,auth] } },
                ]
            },
        )
    }

    routes.push( { path: "*", component: Page404, } )

    // if (host === 'localhost' || host === 'fine.trading') {
    //     routes = [
    //
    //         {
    //             path: '/',  component: PageHomepage, children : [
    //                 { path: '/', name: 'Головна', component: HomeMain },
    //                 { path: '/news/', name: 'FineNews', component: HomeNews },
    //                 { path: '/news/:alias', name: 'FineNewsSingle',props:true, component: SingleNews },
    //             ]
    //         },
    //         { path: '/login', name: 'login', component: LoginPage },
    //         {
    //             path: '/dashboard',
    //             name: 'Dashboard',
    //             component: Dashboard,
    //             meta: { middleware : [breadcrumbs,auth] },
    //             children : [
    //                 { path: '/dashboard', name: 'My Dashboard', component: DashIndex,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/templater', name: 'Templater', component: DashTemplater,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/domains', name: 'Domains', component: DashDomains,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/business', name: 'Business', component: DashBusiness,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/news', name: 'News', component: DashboardNews,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/profile', name: 'Profile', component: DashProfile,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/profile/:id', name: 'View Profile',props:true, component: DashProfile,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/messenger', name: 'Messenger', component: DashMessenger,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/messenger/:showUser', name: 'MessengerUser', props:true, component: DashMessenger,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/leads', name: 'Leads', component: DashLeads,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/users', name: 'Users', component: DashUsers,meta: { middleware : [breadcrumbs,auth] } },
    //
    //             ]
    //         },
    //         {   path: '/dashboard/:alias',
    //             name: 'BusinessDashboard',
    //             props: true,
    //             component: PageBusinessDashboard,
    //             meta: { middleware : [breadcrumbs,auth] },
    //             children : [
    //                 { path: '/dashboard/:alias/',             name: 'Мій Бізнес', props:true, component: dashBusinessIndex,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/news',         name: 'BusinessNewsDashboard', component: BusinessDashboardNews,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/pages',        name: 'Сторінки', component: BusinessDashboardPages,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/products',     name: 'Товари', component: BusinessDashboardProducts,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/orders',       name: 'Замовлення', component: BusinessDashboardOrders,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/design',       name: 'Зовнішній вигляд', props:true, component: DashBusinessDesign,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/payment',      name: 'Налаштування оплати', props:true, component: BusinessDashboardPayments,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/modules',      name: 'Налаштування оплати', props:true, component: BusinessDashboardModules,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/delivery',     name: 'Налаштування доставки', props:true, component: BusinessDashboardDeliveries,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/general',      name: 'Загальні налаштування ', props:true, component: BusinessDashboardGeneral,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/domains',      name: 'Доменна адреса ', props:true, component: BusinessDashboardDomains,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/subscribers',  name: 'Підписники ', props:true, component: BusinessDasboardSubscribers,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/managers',     name: 'Менеджери ', props:true, component: BusinessDasboardManagers,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/warehouse',    name: 'Склади', component: BusinessDashboardWarehouse,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/chatBots',     name: 'Чат Боти', component: BusinessDashboardChatBots,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/googleMaps',   name: 'Google Maps Зони', component: BusinessDashboardGoogleMaps,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/cart',         name: 'Корзина', component: BusinessUserCart,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/rentProducts', name: 'Залогові товари', component: BusinessUserRentProducts,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/statistics',   name: 'Звіти', component: BusinessDashboardStatistics,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/:alias/profile',      name: 'Profile', component: DashProfile,meta: { middleware : [breadcrumbs,auth] } },
    //
    //
    //             ]
    //         },
    //         {
    //             path: '/confirm',
    //             name: 'confirm',
    //             component: LoadingPage,
    //             meta: {
    //                 middleware : [withData]
    //             }
    //         },
    //         { path: '/:alias',      props:true, name: 'Business Loader', component: TemplateLoader,
    //             children : [
    //                 { path: '/:alias', name: 'BusinessHome', props:true, component: TemplateLoaderPagesSingle },
    //                 { path: '/:alias/news/', name: 'BusinessNews', props:true, component: TemplateLoaderNews },
    //                 { path: '/:alias/news/:newsAlias', name: 'BusinessNewsSingle', props:true, component: TemplateLoaderNewsSingle },
    //                 { path: '/:alias/pages/', name: 'BusinessPages', props:true, component: TemplateLoaderPages },
    //                 { path: '/:alias/page/:pageAlias', name: 'BusinessPageSingle', props:true, component: TemplateLoaderPagesSingle },
    //                 { path: '/:alias/page/:pageAlias/edit', name: 'BusinessPageSingleEdit', props:true, component: TemplateLoaderPagesSingle },
    //
    //             ],
    //         },
    //         { path: '/:alias/edit', props:true, name: 'BusinessTemplateLoaderEdit', component: TemplateLoader,
    //             children : [
    //                 { path: '/:alias/edit', name: 'BusinessTemplateLoaderHomeEdit', props:true, component: TemplateLoaderPagesSingle },
    //             ]},
    //
    //         // { path: '/:notFound(.*)', component: LoadingPage}
    //     ]
    // }
    // // PARKED DOMAIN ROUTES
    // else {
    //     routes = [
    //
    //         {   path: '/dashboard/',
    //             name: 'Dashboard',
    //             props: true,
    //             component: PageBusinessDashboard,
    //             meta: { middleware : [breadcrumbs,auth] },
    //             children : [
    //                 { path: '/dashboard/',         name: 'My Business', props:true, component: dashBusinessIndex,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/news',     name: 'News', component: BusinessDashboardNews,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/products', name: 'Products', component: BusinessDashboardProducts,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/orders',   name: 'Orders', component: BusinessDashboardOrders,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/design',   name: 'Зовнішній вигляд', props:true, component: DashBusinessDesign,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/payment',  name: 'Налаштування оплати', props:true, component: BusinessDashboardPayments,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/delivery', name: 'Налаштування доставки', props:true, component: BusinessDashboardDeliveries,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/general',  name: 'Загальні налаштування ', props:true, component: BusinessDashboardGeneral,meta: { middleware : [breadcrumbs,auth] } },
    //                 { path: '/dashboard/domains',  name: 'Доменна адреса ', props:true, component: BusinessDashboardDomains,meta: { middleware : [breadcrumbs,auth] } },
    //             ]
    //         },
    //         { path: '/',      props:true, name: 'Business Loader', component: TemplateLoader,
    //             children : [
    //                 { path: '/', name: 'Головна', props:true, component: TemplateLoaderHome },
    //                 { path: '/news/', name: 'Новини', props:true, component: TemplateLoaderNews },
    //                 { path: '/news/:newsAlias', name: 'Переглянути новину', props:true, component: TemplateLoaderNewsSingle },
    //                 { path: '/pages/', name: 'Сторікнки', props:true, component: TemplateLoaderPages },
    //                 { path: '/pages/:pageAlias', name: 'Переглянути сторінку', props:true, component: TemplateLoaderPagesSingle },
    //             ],
    //         },
    //         { path: '/edit', props:true, name: 'Business Loader', component: TemplateLoader,
    //             children : [
    //                 { path: '/edit', name: 'Режим редагування', props:true, component: TemplateLoaderHome },
    //             ]},
    //
    //         // { path: '/:notFound(.*)', component: LoadingPage}
    //     ]
    // }


    const router = new Router({
        mode: process.env.NODE_ENV === 'production' ? 'history' : 'history',
        routes : routes,
        linkActiveClass: null,
    });


    console.log(routes)




    router.beforeEach( (to,from,next) =>{

        if (!to.meta.middleware) { return next() }

        const middleware = to.meta.middleware
        const context = { to, from, next, store }

        return middleware[0]({
            ...context,
            next: middlewarePipeline(context, middleware, 1)
        })

    })
    // router.afterEach((to,from)=>{
    // })
    return router
}