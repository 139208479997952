import getRouter  from '@/router/router.js'
import getStore   from '@/store/store.js'
import makeAjax   from './plugins/ajax';
import makeSocket from "@/plugins/socket";

const ajax   = makeAjax( 'https://api.aquick.com.ua/' );
const store  = getStore(ajax, 'https://api.aquick.com.ua/', 'fine.trading')
const router = getRouter(store);
const socket = makeSocket(store, 'wss://aquick.com.ua:8090');

export default {
    ajax   ,
    router ,
    store  ,
    socket
}
