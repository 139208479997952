<template>
  <v-sheet>
    <div class="d-flex justify-space-between align-center">
      <h5>Продажі товарів : {{ getTotalPrice()}} грн </h5>
      <div class="d-flex align-center">
        <ftSelect v-model="filter.warehouse" :items="warehouses" >
          <template #default="item">
            <v-btn
                :dark="filter.warehouse !== ''"
                :outlined="!filter.warehouse"
                :color="filter.warehouse ? 'primary lighten-2' : 'grey darken-3' "
                small rounded class="noCaps mr-2" >
              <v-icon small>mdi-cash</v-icon>
              {{filter.warehouse ?  item.text : 'Склад' }}
            </v-btn>
          </template>
        </ftSelect>
        <v-btn small @click="$emit('delete')" icon><v-icon small>mdi-delete</v-icon></v-btn>
      </div>

    </div>

    <v-simple-table dense
        class=" mt-4" >
      <tbody >
      <tr v-for="(product,i) in products" :key="i" >
        <td class=" py-1"  >
          <v-avatar class="mr-2"
                    size="24"  >
            <v-img :src="product.img_small" ratio="1" />
          </v-avatar>
          {{ product.name }}
        </td>
        <td  ><h4> {{ product.quantity }} шт</h4>
        <td  ><h4> {{ product.price }} грн</h4>
        </td>
      </tr>
      </tbody>
    </v-simple-table>
  </v-sheet>
</template>

<script>
import ftSelect from "@/components/UI/ftSelect";
import {mapActions} from "vuex";
export default {
  name: "statisticsProducts",
  props : ['externalFilter','value'],
  components : {
    ftSelect
  },
  data() {
    return {
      products : [],
      warehouses : [ { text : 'Всі' , value : '' } ],
      filter : {
        date : [],
        warehouse : ''
      }
    }
  },
  watch: {
    externalFilter :{

      handler(filter) {
        if ( filter ) {
          this.mutateFilter()
        }
      },
      deep : true

    },
    filter :  {
      handler(filter) {
        this.getData()
        this.$emit('input',filter)
      },
      deep : true

    }
  },
  methods : {
    ...mapActions('statistics', ['GET_PRODUCTS_STATISTICS']),
    getData() {
      this.loading = true

      this.GET_PRODUCTS_STATISTICS({filter: this.filter}).then((out) => {
        if (out.data) {
          this.products = out.data.products
          this.warehouses = out.data.warehouses
          this.warehouses.unshift({ text : 'Всі' , value : '' })
          this.loading = false;
        }
      }).catch(() => {
        this.loading = false
        this.notify('Network error')
      })
    },

    //technical
    getTotalPrice() {
      let price = 0;
      this.products.forEach((product)=>{
        price += parseFloat(product.price)
      })
      return price
    },
    mutateFilter() {
      Object.keys(this.externalFilter).forEach((key)=>{
        this.filter[key] = this.externalFilter[key]
      })
      this.filter = Object.assign({},this.filter)
    }
  },
  mounted() {

    if ( this.externalFilter ) {
      this.mutateFilter()
    }

    if ( this.value )  {
      this.filter = this.value
    }


    this.getData()
    if (this.date ) {
      this.filter.date = this.date
    }
  }
}
</script>

<style scoped>

</style>