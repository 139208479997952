<template>
  <div>



      <div v-for="(button,index) in buttons" :key="index"
           class=" justify-space-between align-center mb-4" >

           <v-sheet outlined rounded class="mb-3 pa-3 px-6 ">
          <div class="d-flex justify-space-between align-center mb-4 ">

            <h4 style="line-height: 1" class="font-weight-light">Кнопка {{ index + 1}} </h4>
            <v-btn  v-if="showDeleteButton"
                    @click="deleteButton(index)"
                    class="noCaps font-weight-regular"
                    color="grey"
                    text >
              <v-icon small class="mr-1">mdi-delete</v-icon>
              Видалити
            </v-btn>
          </div>

          <v-row class="mb-2">
            <v-col cols="12" align-self="center">

              <ft-params-bar  class="text-center"
                              :auto-input="['size','fontWeight','caps']"
                              v-model="paramsText[index]" />
              <ft-params-bar class="text-center"
                  :auto-input="['text','color','dark']"
                  v-model="paramsColor[index]" />


            </v-col>

            <v-col cols="6" class="mt-0">
              <v-text-field  class="caption" dense
                            v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value[index].text"
                               hide-details  >
                <template slot="label"><span class="caption">Текст</span></template>
              </v-text-field>

            </v-col>
            <v-col cols=6>
                <v-select    class="caption" dense
                             v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value[index].action"
                             hide-details   :items="buttonActions"  >
                  <template slot="label"><span class="caption">Дія</span></template>
                </v-select>
            </v-col>
            <v-col cols="12" v-if="showProductsSelect(index) || showLinkInput(index) || showPageSelect(index) " >
              <v-select      class="caption" dense
                             v-if="showProductsSelect(index)" hide-details
                             v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value[index].params.products"
                             multiple :items="productsSelect" >
                <template slot="label"><span class="caption">Товари</span></template>
              </v-select>
              <v-text-field  class="caption" dense
                             v-if="showLinkInput(index)"  hide-details
                             v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value[index].params.link" >
                <template slot="label"><span class="caption">Посилання</span></template>
              </v-text-field>
              <v-select      class="caption" dense
                             :items="pagesSelect"
                             v-if="showPageSelect(index)"  hide-details
                             v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value[index].params.route" >
                <template slot="label"><span class="caption">Cторінка</span></template>
              </v-select>
            </v-col>

          </v-row>



        </v-sheet>



      </div>

      <div class="d-flex justify-end">
        <v-btn text class="noCaps mb-3" @click="addButton">
          <v-icon>mdi-plus</v-icon> Додати Кнопку
        </v-btn>
      </div>


  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ftParamsBar from "@/components/templates/editor/UI/ftParamsBar";

export default {
  name: "ftEditorBlockElementButton",
  props : [ 'selectedBlock',
            'selectedBlockRow',
            'selectedBlockItem'
  ],
  components : {
    ftParamsBar
  },
  data() {
    return {
      pagesSelect : [],
      paramsColor : [],
      paramsText : [],

      buttonActions: [

        { text : 'Перейти на сторінку' ,   value : 'gotoPage'      },
        { text : 'Перейти за посиланням' , value : 'gotoLink'      },
        { text : 'Оформити товари' ,       value : 'orderProducts' },
        { text : 'Відкрити корзину' ,      value : 'openCart'      },
        { text : 'Додати в корзину' ,      value : 'addToCart'     },
        { text : 'Зареєструватися на акцію' ,      value : 'registerAction'     },

      ],

    }
  },
  computed : {
    ...mapState('templates',  [ 'products','businessAlias' ]),

    paramsGeneral() {
      let generalParams = [];
      this.paramsColor.forEach((param,index)=>{
        generalParams[index] = { ...this.paramsColor[index], ...this.paramsText[index] }
      })
      return generalParams
    },

    buttons() {
      return this.$store.state.templates.page.blocks[this.selectedBlock]
                 .value.items[this.selectedBlockRow][this.selectedBlockItem]
                 .value
    },
    showDeleteButton() {
      return this.$store.state.templates.page.blocks[this.selectedBlock]
                 .value.items[this.selectedBlockRow][this.selectedBlockItem]
                 .value.length > 1
    },
    productsSelect() {
      var list = []
      this.products.forEach((product) => {
        list.push( { text : product.name, value : product.id } )
      })
      return list
    },


  },
  watch : {
    paramsGeneral : {
      handler(val) {
        val.forEach((param,i)=>{
          this.$store.state.templates.page.blocks[this.selectedBlock]
              .value.items[this.selectedBlockRow][this.selectedBlockItem]
              .value[i].params.css = param
        })

      },
      deep : true
    }
  },
  methods : {
    ...mapActions('business', ['GET_PAGES_LIST']),

    addButton() {
      this.$store.state.templates.page.blocks[this.selectedBlock]
          .value.items[this.selectedBlockRow][this.selectedBlockItem]
          .value.push(
          {
            text:'Кнопка',
            action : '',
            params : {
              products   : [],
              page       : {},
              link       : '',
              css : {
                color      : this.TEMPLATES_ACCENT_COLOR() ,
                size       : null ,
                caps       : false,
                fontWeight : 'regular',
                text       : false,
                dark       : true
              }
            }
          }
      )
    },
    deleteButton(index) {
      this.$store.state.templates.page.blocks[this.selectedBlock].value.items[this.selectedBlockRow][this.selectedBlockItem].value.splice(index,1)
    },
    showProductsSelect(index) {
      return this.$store.state.templates.page.blocks[this.selectedBlock].value.items[this.selectedBlockRow][this.selectedBlockItem].value[index].action === 'addToCart' ||
          this.$store.state.templates.page.blocks[this.selectedBlock].value.items[this.selectedBlockRow][this.selectedBlockItem].value[index].action === 'orderProducts'
    },
    showPageSelect(index) {
      return this.$store.state.templates.page.blocks[this.selectedBlock].value.items[this.selectedBlockRow][this.selectedBlockItem].value[index].action === 'gotoPage'
    },
    showLinkInput(index) {
      return this.$store.state.templates.page.blocks[this.selectedBlock].value.items[this.selectedBlockRow][this.selectedBlockItem].value[index].action === 'gotoLink'
    },
  },
  beforeMount() {
    this.$store.state.templates.page.blocks[this.selectedBlock]
        .value.items[this.selectedBlockRow][this.selectedBlockItem]
        .value.forEach((button)=>{
          this.paramsColor.push({
            text: button.params.css.text,
            color: button.params.css.color,
            dark : button.params.css.dark
          })
          this.paramsText.push({
            size: button.params.css.size,
            fontWeight: button.params.css.fontWeight,
            caps : button.params.css.caps })
    })


      this.GET_PAGES_LIST(this.businessAlias).then((out) => {
        if ( out.data ) {
          this.pagesSelect = out.data
        }
      })


  }

}
</script>

<style scoped>
.headerMenuItem {
  font-size: 0.9rem !important;
  transition: all 0.2s ease;
  text-decoration: none;
  color : #f1f1f1;
}
.headerMenuItem:hover {
  background-color: #343434;
}
</style>