<template>
  <div class="wrapper">

    <modalDashboard v-if="modalDash"/>
    <sideBar />

    <div :class="{mainWithMenu: sideMenu}" class="main">

        <headerDash/>
        <titleBread/>



        <div class="content">
            <router-view style="overflow-y: unset;"></router-view>
        </div>






    </div>
  </div>

</template>

<script>

import sideBar from "@/components/pages/dashboard/UI/sidebar/sideBar";
import modalDashboard from "@/components/pages/dashboard/UI/modal/modalDashboard";
import titleBread from "@/components/pages/dashboard/UI/titleBread";
import headerDash from "@/components/pages/dashboard/UI/headerDash";
import { mapState} from "vuex";

export default {
  name: 'HelloWorld',
  components: {
    sideBar,
    modalDashboard,
    titleBread,
    headerDash
  },
  data() {
    return {
      menuActive: false,
    }
  },
  computed: {
    ...mapState('dashboard', ['sideMenu','pageTitle','breadcrumbs','modalDash'])



  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.wrapper {
  background-color: whitesmoke;
  height:100%;
}
.main {
  margin-left: 60px;
  transition: margin-left 0.3s ease-out;
}
.mainWithMenu {
  margin-left: 250px !important;
}

.content {
  padding:0 25px 0 25px;
  overflow-y: unset;
  background-color: white;
}


</style>
