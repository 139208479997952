<template>
  <div>
    <v-menu v-if="editorMode" offset-y >

      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mt-3 noCaps" text  v-bind="attrs" v-on="on">
          <v-icon small>mdi-plus</v-icon>
          Додати елемент
        </v-btn>
      </template>
      <v-sheet min-width="300" dark  class="pa-4 pr-2">

        <div v-for="(elementGroup, key) in elements" :key="key" class="mb-4">
          <h3 class="font-weight-light">{{  elementGroup.title  }} </h3>
          <v-divider class="mb-3 mt-1 mr-2" />
          <div class="d-flex ">
            <div   v-for="(item, index) in elementGroup.elements" :key="index">

              <v-sheet color="grey darken-3" elevation="2" v-ripple @click="addElement(item)"  class="text-center pa-3 mr-2  pointer" >
                <v-icon large>{{ item.icon }} </v-icon>
                <div class="caption">{{ item.text }}</div>
              </v-sheet>

            </div>
          </div>
        </div>


      </v-sheet>

    </v-menu>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ftContainerElementsMenu",
  props : ['block','row'],
  data() {
    return {
    }
  },
  computed : {

    ...mapState('templates',  [  'editorMode' ]),
    elements() {
      return {
        base : {
          title : 'Базові Елементи',
          elements: [
            {
              text: 'Заголовок',
              element: 'ftText',
              subElement: 'heading',
              icon: 'mdi-format-header-1',
              value: 'Заголовок',
              params: {
                headingSize: 'h3',
                fontWeight: 'light',
                margin: {
                  mb: 3
                },
                display: ['sm', 'md', 'lg']
              }
            },
            {
              text: 'Напис',
              element: 'ftText',
              subElement: 'text',
              icon: 'mdi-text-short',
              value: 'Напис',
              params: {
                margin: {
                  mb: 3
                },
                display: ['sm', 'md', 'lg']
              }
            },
            // {
            //   text: 'Html текст',
            //   element: 'ftText',
            //   subElement: 'html',
            //   icon: 'mdi-text-long',
            //   value: 'Великий текст',
            //   params: {
            //     size: 'h1',
            //     color: '',
            //     weight: 'light',
            //     line: '1',
            //     margin: { mb: 3} ,
            //     display: ['sm', 'md', 'lg']
            //   }
            // },
            {
              text: 'Зображення',
              element: 'ftImg',
              subElement: null,
              icon: 'mdi-image',
              value: '',
              params: {
                imageParams : {
                  width : null,
                  height : null,
                  position : '',
                  radius : ''
                },
                margin: {
                  mb: 3
                },
                display: ['sm', 'md', 'lg']
              },

            },
            {
              text: 'Кнопка',
              element: 'ftTemplateElement',
              subElement: 'Button',
              icon: 'mdi-card-outline',
              value: [
                {
                  text: 'Кнопка',
                  action: '',
                  params: {
                    products: [],
                    page: {},
                    link: '',
                    css: {
                      color: this.TEMPLATES_ACCENT_COLOR(),
                      size: null,
                      caps: false,
                      fontWeight: 'regular',
                      text: false,
                      dark: true
                    },
                  }
                }
              ],
              params: {
                display: ['sm', 'md', 'lg'],
                margin: { mb: 0 } }
            },
            {
              text: 'Досавка',
              element: 'ftTemplateElement',
              subElement: 'DeliveryField',
              icon: 'mdi-map-marker',
              value: {
                action : 'openCart',
                location : 'Введіть адресу',
                buttonText : 'Замовити',
                products : []
              },
              params: {
                fieldStyle : {},
                buttonStyle : {
                  text : {},
                  color: {}
                },
                margin: { mb: 3 },
                display: ['sm', 'md', 'lg']
              }
            }
          ]
        },

        dynamic : {
          title : 'Динамічні Елементи',
          elements: [

            {
              text: 'Новини',
              element: 'ftTemplateElement',
              subElement: 'News',
              icon: 'mdi-newspaper-variant-outline',
              value : {},
              params: {
                element: 'News',
                margin: {mb: 3},
                display: ['sm', 'md', 'lg']
              }
            },
            {
              text: 'Товари',
              element: 'ftTemplateElement',
              subElement: 'News',
              icon: 'mdi-cart-variant',
              value : {},
              params: {
                element: 'News',
                margin: { mb: 3},
                display: ['sm', 'md', 'lg']
              }
            },
            {
              text: 'Соціалки',
              element: 'ftTemplateElement',
              subElement: 'Socials',
              icon: 'mdi-facebook',
              value : {
                header    : {
                  active : true,
                  text   : 'Підписуйся'
                },
                telegram  : { active : true  , link : '' },
                facebook  : { active : true  , link : '' },
                instagram : { active : false , link : '' },
              },
              params: {
                element: 'Socials',
                header : {
                  text : {
                    headingSize : 'h4'
                  },
                },
                margin: {mb: 3},
                display: ['sm', 'md', 'lg']
              }
            },
            {
              text: 'Додатки',
              element: 'ftTemplateElement',
              subElement: 'Apps',
              value : {},
              icon: 'mdi-cellphone-arrow-down',
              params: {
                element: 'Apps',
                margin: {mb: 3},
                display: ['sm', 'md', 'lg']
              }
            },

          ]
        },
        template : {
          title : 'Елементи шаблону',
          elements: []
        }

      }
    }
  },
  methods : {

    addElement( element ) {

      this.$store.state.templates.page.blocks[this.block].value.items[this.row].push({
        type   : element.element,
        params : JSON.parse(JSON.stringify(element.params) ),
        value  : JSON.parse(JSON.stringify(element.value) ),
        subElement : element.subElement,
        uuid : this.$uuid.v4()
      })

    }

  }
}
</script>

<style scoped>

</style>