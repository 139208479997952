<template>
  <div>

    <v-menu  ref="dimensionMenu"  :close-on-content-click="false" :close-on-click="true"  left >
      <template v-slot:activator="{ on, attrs }">
        <v-btn @click="calculateSlider"
            block color="grey darken-3" class="px-8 noCaps font-weight-light"  x-large rounded filled
               v-bind="attrs" v-on="on" >
          <v-icon class="mr-1">mdi-view-column-outline</v-icon>
          Розміри : {{ value }}
        </v-btn>
      </template>
      <v-sheet dark class="pa-3" width="400" >
        <div class="d-flex justify-space-between mt-3 mb-3">
          <h3>Оберіть розмір  </h3>
          <v-btn icon @click="$refs.dimensionMenu.save()" ><v-icon>mdi-close</v-icon></v-btn>
        </div>

        <v-slider       v-if="columns === 2 "
                        v-model="columnsDimension"
                        max="12" min="0" step="1"
                        :tick-labels="[0,'','1/6','1/4','1/3','','1/2','','2/3','2/4','2/6','' ]"
                        track-color="white"
                        track-fill-color="white"
                        ticks="always"
                        thumb-color="white"

                        tick-size="4"/>
        <v-range-slider v-if="columns === 3 "
                        v-model="tripleColumnsDimension"
                        track-color="white"
                        track-fill-color="white"
                        thumb-size="10"
                        max="12" min="0" step="1"
                        ticks="always"
                        tick-size="4"
                        :tick-labels="[0,1,2,3,4,5,6,7,8,9,10,11 ]" />


        <v-row class="px-5" >
          <v-col class="mb-1 px-1" v-for="i in columns" :key="i" :cols="rowsSize[i-1]"  >
            <v-sheet class="pa-4 pb-10" color="grey darken-2" />
          </v-col>
        </v-row>



      </v-sheet>

    </v-menu>
  </div>
</template>

<script>
export default {
  name: "ftColumnDimensionMenu",
  props: ['columns','value'],
  data() {
    return {
      columnsDimension : 0,
      tripleColumnsDimension : [4,8],
      rowsSize : []

    }
  },
  computed : {
    // rowsSize() {
    //   var size = [];
    //
    //   if ( this.columns === 1 ) { size = [12] }
    //   if ( this.columns === 2 ) {
    //
    //     size = [this.columnsDimension, 12 - this.columnsDimension]
    //   }
    //   if ( this.columns === 3 ) {
    //     if ( this.tripleColumnsDimension[0] < 1 ) { this.$set(this.tripleColumnsDimension,0,1) } else
    //     if ( this.tripleColumnsDimension[1] > 11 ) { this.$set(this.tripleColumnsDimension,1,11) }
    //     size = [this.tripleColumnsDimension[0], this.tripleColumnsDimension[1]-this.tripleColumnsDimension[0], 12 - this.tripleColumnsDimension[1]]
    //   }
    //   if ( this.columns === 4 ) { size = [3,3,3,3] }
    //   return size
    // },
  },
  watch : {
    rowsSize(val) {
        this.$emit('input',val)
    },
    input(val) {
      this.rowsSize = val
    },
    columnsDimension(val) {

      if ( val < 1  ) { this.columnsDimension = 1 }
      if ( val > 11)  { this.columnsDimension = 11 }
      this.rowsSize = [this.columnsDimension, 12 - this.columnsDimension]
    },
    tripleColumnsDimension: {
      handler(value){

        if ( value[0] < 1 ) {
          this.$set(this.tripleColumnsDimension,0,1)
        } else
        if ( value[1] > 11 ) {
          this.$set(this.tripleColumnsDimension,1,11)
        }

        this.rowsSize = [ this.tripleColumnsDimension[0], this.tripleColumnsDimension[1]-this.tripleColumnsDimension[0], 12 - this.tripleColumnsDimension[1] ]

      },
      deep: true
    },

    columns() {
      this.notify(this.value.length)
      this.calculateSlider()
      // this.notify( 'Length is : ' + this.rowsSize.length)
      // if ( this.columns === 1  ) { this.rowsSize = [12]  }
      // if ( this.columns === 2  ) { this.rowsSize = [6,6] }
      // if ( this.columns === 3  ) { this.rowsSize = [4,4,4]  }
      // if ( this.columns === 4  ) { this.rowsSize = [3,3,3,3] }

    }
  },
  methods : {
    calculateSlider() {
      if ( this.columns === 1 ) { this.rowsSize = [12] }
      if ( this.columns === 2 ) {

        if ( this.value.length === 2) {
          this.columnsDimension =  this.value[0]
        } else {
          this.columnsDimension =  6
          this.rowsSize = [6,6]
        }

      }
      if ( this.columns === 3 ) {
        if ( this.value.length === 3) {
          this.tripleColumnsDimension = [this.value[0], (this.value[0]+this.value[1])  ]
        } else {
          this.tripleColumnsDimension =  [4,8]
          this.rowsSize = [4,4,4]
        }

      }

      if ( this.columns === 4 ) { this.rowsSize = [3,3,3,3] }


    },

  },
  mounted() {

  }


}
</script>

<style scoped>

</style>