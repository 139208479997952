<template>
<div>
  <manageNews />
</div>
</template>

<script>
import manageNews from "@/components/pages/dashboard/news/manageNews";
export default {
  name: "DashboardNews",
  components : {
    manageNews
  }
}
</script>

<style scoped>

</style>