export default {
    methods : {
        LIQPAY_checkout(liqData) {


            var f = document.createElement("form");
            f.setAttribute('method',"POST");
            f.setAttribute('action',liqData.action);
            f.setAttribute('accept-charset',liqData.acceptCharset);

            var i = document.createElement("input"); //data, string
            i.setAttribute('type',"hidden");
            i.setAttribute('name',"data");
            i.setAttribute('value',liqData.data);

            var s = document.createElement("input"); //signature, strung
            s.setAttribute('type',"hidden");
            s.setAttribute('name',"signature");
            s.setAttribute('value',liqData.signature);

            f.appendChild(i);
            f.appendChild(s);

            document.getElementById('virtual_hidden').appendChild(f);

            console.log(document.getElementById('virtual_hidden'))
             f.submit();
            document.getElementById('virtual_hidden').removeChild(f)
        },
    }
}