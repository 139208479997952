<template>
  <div>
    <v-menu offset-y >
      <template v-slot:activator="{ on, attrs }">

        <v-btn icon v-bind="attrs" v-on="on" class="mr-2">
          <v-badge  :content="notifications.length"
                    :value="notifications.length"
                    color="green lighten-1"
                    overlap >

            <v-icon >mdi-bell-outline </v-icon>
          </v-badge>
        </v-btn>

      </template>

      <v-card class="rounded-0" width="270">

        <v-sheet dark  class="pa-3" >
          <h4 class="text-center">Останні події </h4>
        </v-sheet>

        <v-divider/>

        <div class="overflow-y-auto" style="max-height:300px">

          <div v-if="notifications.length < 1" class="caption pa-5 mt-3 text-center text--secondary">
            You have no new messages ...
          </div>

          <v-list style="" dense>

            <v-list-item-group>
              <v-list-item v-for="(item, i) in notifications" :key="i"
                           @click="getAction(item.action)"
                           style="border-bottom: 1px solid whitesmoke">

                <v-list-item-content class="pa-2">
                  <div class="d-flex  align-center pr-5">
                    <v-icon class="pr-3"> {{ item.icon }}</v-icon>
                    <div>
                      <h4 class="mb-n2 caption">{{ item.event}} </h4>
                      <span class="caption text--secondary mt-0">{{ item.description }} </span>
                    </div>
                  </div>
                </v-list-item-content>

              </v-list-item>


            </v-list-item-group>

          </v-list>
        </div>
        <v-divider />
        <v-list dense>
          <v-list-item  @click="$router.push(`/dashboard/messenger`);"  >

            <v-list-item-title class="pa-2">

              <div class="text-center">
                Центр Сповіщень
              </div>

            </v-list-item-title>

          </v-list-item>
        </v-list>

      </v-card>

    </v-menu>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "notificationMenu",
  computed : {
    ...mapState('notifications',['notifications']),
  },
  methods : {
    getAction(action) {
      this.notify(action)
    }
  }
}
</script>

<style scoped>

</style>