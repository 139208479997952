import Vue from "vue"
import Vuex from "vuex"
import modules from './modules';

Vue.use(Vuex);

export default function getStore($ajax, baseUrl, hostname )  {
    return new Vuex.Store({
        namespaced : true,
        modules,
        state : {
            $ajax,
            $socket : null,

            filesPublic : baseUrl + '/storage/',
            ajaxRoute : baseUrl,
            hostname : hostname,

            refresh: false,
            showLoginMenu: false,

            notification_text : '',
            notification : false,

            domain : false,

        },
        mutations : {
            toggleLoginMenu(state) {
                state.showLoginMenu=!state.showLoginMenu;
            },
            REFRESH(state) {
                state.refresh = true;
                setTimeout(()=>{
                    state.refresh = false;
                },100)
            },
            TOOGLE_NOTIFICATION(state,payload) {
                state.showNotification = !state.showNotification;
                if (payload) {
                    state.notificationMessage = payload.message
                }
            }
        }

    })
}