<template>
  <v-sheet class="editorHeader py-0 px-11 d-flex justify-space-between align-center"
           color="#111" dark>
    <div class="d-flex align-center">

      <div v-for="(menu,i) in headerMenu" :key="i" >
        <div v-if="menu.editorMode === 'null' || editorMode === menu.editorMode">
          <v-menu v-if="menu.items"  open-on-hover offset-y >

            <template v-slot:activator="{ on, attrs }">
              <v-btn :rounded="false" class="noCaps" dark
                     :icon="menu.icon === 'logo'"
                     :text="menu.icon !== 'logo'"
                     v-bind="attrs" v-on="on">
                <img v-if="menu.icon === 'logo'" style="height: 15px"  src="@/assets/brand/logo_fine_white_small.png" alt="" />
                <span v-else class="d-flex align-center">
                 <v-icon small class="mr-1">mdi-{{  menu.icon }}</v-icon>
                  {{  menu.text  }}
              </span>
              </v-btn>
            </template>
            <v-sheet dark  class="py-3">
              <div v-ripple v-for="(item, index) in menu.items" :key="index">
                <router-link
                    :target="item.newWindow ? '_blank' : ''"
                    v-if="item.route" :to="item.route" class="headerMenuItem">
                  <div  class="headerMenuItem pl-3 pr-10  py-1"  >
                    <span>{{ item.text }}</span>
                  </div>
                </router-link>
                <div v-else  class="headerMenuItem pl-3 pr-10  py-1 pointer"
                     @click="headerMenuAction(item.action)">
                  <span>{{ item.text }}</span>
                </div>


              </div>
            </v-sheet>

          </v-menu>
          <v-btn v-else  class="noCaps" dark text
                 @click="headerMenuAction(menu.action)">
            <v-icon small class="mr-1">mdi-{{  menu.icon }}</v-icon>
            {{  menu.text  }}
          </v-btn>
        </div>
      </div>


    </div>


    <div class="d-flex">
      <v-btn class="noCaps" dark text>
        Переглянути
      </v-btn>
      <v-btn :loading="loading" :disabled="loading" class="noCaps" dark text @click="saveChanges">
        <v-icon small class="mr-1" >mdi-check</v-icon>
        Зберегти
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>


import {mapActions,mapState} from "vuex";

export default {
  name: "ftEditorHeader",
  data(){
    return {
      loading : false,
    }
  },
  computed : {
    ...mapState('templates',  [ 'businessAlias','businessName','editorMode' ]),
    headerMenu() {
      return [
        {
          text: '',
          icon: 'logo',
          editorMode : 'null',
          items: [
            {text: 'Про FineTrading',    route: '/', newWindow: true},
            {text: 'Документація',       route: '/documentation', newWindow: true},
            {text: 'Підтримка',          route: '/support', newWindow: true},
            {text: 'Зворотній зв\'язок', route: '/feedback', newWindow: true},
          ]
        },
        {
          text: this.businessName,
          icon: 'cog',
          editorMode : 'null',
          items: [
            {text: 'Переглянути сайт',  route: '/' + this.businessAlias, newWindow: false},
            {text: 'Панель управління', route: '/dashboard/' + this.businessAlias, newWindow: true},
          ]
        },
        {
          text: 'Режим редагування',
          icon: 'pencil',
          items: [
            { text: 'Налаштування сайту',  action: 'openEditGeneral' },
            { text: 'Головне меню',        action: 'openEditGeneral' },
            { text: 'Сброс змін',          action: 'resetChanges'    },
            { text: 'Вийти з редагування', action: 'exitEditorMode'  },
          ],
          editorMode : true,
        },
        {
          text: 'Редагувати',
          icon: 'pencil',
          editorMode : false,
          action : 'enterEditorMode',
        },
        {
          text: 'Додати',
          icon: 'plus',
          editorMode : 'null',
          items: [
            { text: 'Новину',   action: 'addBusinessNews' },
            { text: 'Сторінку', action: 'addPage'         },
            { text: 'Продукт',  action: 'addProduct'      },
          ]
        }
      ]
    }
  },
  methods : {
    ...mapActions('templates',  [ 'SAVE_DRAFT_ITEM' , 'SAVE_TEMPLATE_EDIT' ]),
    ...mapActions('business',  [ 'GET_BUSINESS' ]),
    headerMenuAction(action){
      switch(action) {
          case 'openEditGeneral' : this.openEditGeneral() ; break;
          case 'resetChanges'    : this.resetChanges()    ; break;
          case 'exitEditorMode'  : this.exitEditorMode()  ; break;
          case 'addBusinessNews' : this.addBusinessNews() ; break;
          case 'addPage'         : this.addPage()         ; break;
          case 'addProduct'      : this.addProduct()      ; break;
          case 'enterEditorMode' : this.enterEditorMode() ; break;

          default : this.notify(action)
      }
    },

    enterEditorMode() {

      this.notify('Режим редагування')
      this.$router.push( this.$route.path + '/edit')
      this.$store.state.templates.templateVars_current = {}
      window.location.reload()


    },
    //headerMenu Functions
    openEditGeneral() {
      this.$store.state.templates.openEditorGeneral = true
    },
    resetChanges() {
      this.notify('resetChanges')
    },
    exitEditorMode() {
      this.$store.state.templates.editorMode

      if ( this.$route.path.includes('/edit') ) {
        this.$router.push( this.$route.path.slice(0,-5) )
      }

      this.notify('Режим редагування завершено')
      window.location.reload()
      this.notify('Режим редагування завершено TWICE')

    },
    addBusinessNews() {
      this.notify('addBusinessNews')
    },
    addPage() {
      this.notify('addPage PISKO')
      this.$store.state.templates.openEditorAddEntity = true
    },
    addProduct() {
      this.notify('addProduct')
    },

    // Save Template Changes
    saveChanges() {

      let data = []



      for (const key in this.$store.state.templates.templateVars_current) {

        let value = this.$store.state.templates.templateVars_current[key].value
        let item =
          {
            item  : this.$store.state.templates.templateVars_current[key].production,
            type  : this.$store.state.templates.templateVars_current[key].type,
            value : value
          }
        data.push(item)
      }

      this.loading = true;
      this.SAVE_DRAFT_ITEM(
          {
            alias:this.$store.state.templates.businessAlias,
            data: {items : data}
          })
          .then((out)=>{
            this.notify('Зміни збережено')
            this.loading = false;
            console.log(out)
          }).catch(()=>{
        this.loading = false;
      })

      this.loading = true
      let template_edit = {
        page : this.$store.state.templates.page
      }
      this.SAVE_TEMPLATE_EDIT(
          {
            alias: this.$store.state.templates.businessAlias,
            data: template_edit
          }
      ).then((out)=>{
        if ( out.result ) {
          this.notify('Зміни збережено')
        }
      })

    }
  }
}
</script>

<style scoped lang="scss">
.editorHeader {
  position:sticky;
  top: 0;
  left:0;
  right:0;
  z-index:600000;
  font-family: 'Arial', sans-serif;
}
.headerMenuItem {
  font-size: 0.9rem !important;
  transition: all 0.2s ease;
  text-decoration: none;
  color : #f1f1f1;
}
.headerMenuItem:hover {
  background-color: #343434;
}

</style>