<template>
  <div>

    <card  no-padding class="mb-10">
      <template slot="header">
        <v-btn-toggle mandatory dark v-model="step" group class="d-flex justify-end pa-0 ma-0" color="primary" >
          <v-btn text color="white" class="ma-0 "><v-icon class="mr-3">mdi-presentation</v-icon>Заявки</v-btn>
          <v-btn text color="white" class="ma-0 " ><v-icon class="mr-3">mdi-account</v-icon>Викладачі</v-btn>
        </v-btn-toggle>
      </template>
    </card>




    <card v-if="step==0" title="Заявкии на консультацію">

      <!-- HEADER WITH  Add new row button and diolog window -->
      <template slot="header" v-if="getPermission(['Admin'])">
        <!--      Add new row button and diolog window -->
        <v-dialog v-model="addDialog" width="500" class="rounded-0">
          <!--      Add row dialog button -->
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="rounded-0" outlined dark small v-bind="attrs" v-on="on" >
              <v-icon>mdi-plus </v-icon> Add type
            </v-btn>
          </template>
          <!--      Add row dialog body -->
          <card title="Add new Study Wave">
            <form @submit.prevent="addRow" class="compact-form text-center" >

              <v-text-field  v-model="addData.name"  label="Name" required />
              <v-row>
                <v-col cols="6">
                  <v-menu ref="enrollStart" v-model="menuEnrollStart" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="addData.enrollment_start"  label="Enrollment Start" prepend-icon="mdi-calendar"  readonly v-bind="attrs" v-on="on"  required
                      />
                    </template>
                    <v-date-picker
                        ref="picker1"
                        v-model="addData.enrollment_start"
                        min="2020-01-01" max="2150-01-01"
                        @change="$refs.enrollStart.save(date)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu ref="enrollEnd" v-model="menuEnrollEnd" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="addData.enrollment_end"  label="Enrollment End"
                          prepend-icon="mdi-calendar"  readonly v-bind="attrs" v-on="on"  required
                      />
                    </template>
                    <v-date-picker   v-model="addData.enrollment_end"
                                     min="2020-01-01" max="2150-01-01"
                                     @change="$refs.enrollEnd.save(date)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu ref="eduStart" v-model="menuEduStart" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="addData.education_start"  label="Education Start"
                          prepend-icon="mdi-calendar"  readonly v-bind="attrs" v-on="on"  required
                      />
                    </template>
                    <v-date-picker   v-model="addData.education_start"
                                     min="2020-01-01" max="2150-01-01"
                                     @change="$refs.eduStart.save(date)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-select
                      :items="waveStatus"
                      v-model="addData.status"
                      label="Status"
                      required


                  ></v-select>
                </v-col>
              </v-row>


              <div class="d-flex justify-end">
                <v-btn text class="rounded-0 mt-3" color="primary" :disabled="loading"   > Cancel </v-btn>
                <v-btn text class="rounded-0 mt-3" type="submit" color="primary" :disabled="loading" @click="addDialog = false"  > Add </v-btn>
              </div>
            </form>
          </card>

        </v-dialog>
      </template>
      <!-- Edit Row dialog -->
      <v-dialog v-model="editDialog" width="500" class="rounded-0">
        <!-- Edit row dialog body -->
        <card title="Edit Study Wave">
          <form @submit.prevent="editRow" class="compact-form text-center">
            <v-text-field  v-model="editData.name"  label="Name" required/>
            <v-row>
              <v-col cols="6">
                <v-menu ref="enrollStart" v-model="menuEnrollStart" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="editData.enrollment_start"  label="Enrollment Start" prepend-icon="mdi-calendar"  readonly v-bind="attrs" v-on="on"  required
                    />
                  </template>
                  <v-date-picker
                      ref="picker1"
                      v-model="editData.enrollment_start"
                      min="2020-01-01" max="2150-01-01"
                      @change="$refs.enrollStart.save(date)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu ref="enrollEnd" v-model="menuEnrollEnd" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="editData.enrollment_end"  label="Enrollment End"
                        prepend-icon="mdi-calendar"  readonly v-bind="attrs" v-on="on"  required
                    />
                  </template>
                  <v-date-picker   v-model="editData.enrollment_end"
                                   min="2020-01-01" max="2150-01-01"
                                   @change="$refs.enrollEnd.save(date)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu ref="eduStart" v-model="menuEduStart" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="editData.education_start"  label="Education Start"
                        prepend-icon="mdi-calendar"  readonly v-bind="attrs" v-on="on"  required
                    />
                  </template>
                  <v-date-picker   v-model="editData.education_start"
                                   min="2020-01-01" max="2150-01-01"
                                   @change="$refs.eduStart.save(date)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-select
                    :items="waveStatus"
                    v-model="editData.status"
                    label="Status"


                ></v-select>
              </v-col>
            </v-row>
            <div class="d-flex justify-end">
              <v-btn text class="rounded-0 mt-3" color="primary" :disabled="loading" @click="editDialog = false"  > Cancel </v-btn>
              <v-btn text class="rounded-0 mt-3" type="submit" color="primary" :loading="loading" :disabled="loading" @click="editDialog = false"  > Save </v-btn>
            </div>
          </form>
        </card>
      </v-dialog>
      <!-- Table header: Group actions and search-->
      <div class="d-flex justify-space-between align-center ">
        <!--  Group Actions -->
        <div> <v-fade-transition>
          <div v-if="selected[0]" class="d-flex align-center">
            <h5 class="float-left font-weight-light">Group actions:</h5>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="groupAction" small text v-bind="attrs" v-on="on" > Choose  </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in groupActions" :key="index" @click="groupAction(item.action)">
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-fade-transition> </div>
        <!--  Search bar -->
        <div class="compact-form d-flex" style="width: 100px; ">
          <v-text-field dense  append-icon="mdi-magnify" label="Search" v-model="search" />
        </div>
      </div>
      <!-- Edit row dialog body -->

      <!-- *** TABLE CONTENT *** -->
      <v-data-table
          :search="search"
          show-select :loading="loading"
          :headers="headers"
          v-model="selected"
          :items = "rowsData"
          :items-per-page="100"
          :footer-props="{ 'items-per-page-options': [50, 100, 150, -1] }"
          :item-class="resultStyle"

      >
        <!--  Student column-->
        <template v-slot:item.student="{ item }">
            <div>
              <h4 class="mb-0">{{ item.name}}</h4>
              <span class=" mt-0">{{ item.email }}</span>
          </div>
        </template>

        <template v-slot:item.specialty_name="{ item }">
          <div >
            <h4 > {{ item.specialty_name }} </h4>
            <v-chip x-small outlined  color="blue-grey darken-3" class="mt-1">
              <v-icon x-small class="mr-2" >mdi-school-outline</v-icon><b>{{ getDegree(item.degree) }} </b>
            </v-chip>

<!--            <v-chip small outlined  color="blue-grey darken-3">-->
<!--            <v-icon small class="mr-2" >mdi-school-outline</v-icon><b>{{ getDegree(item.degree) }} </b>-->
<!--            </v-chip>-->

          </div>

        </template>


        <template v-slot:item.application_degree="{ item }">
          <v-chip  color="success" light x-small>
            {{ item.application_degree }}
          </v-chip>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip dark  :color="getStatusColor(item.status)" light small>
            <b>{{ item.status }}</b>
          </v-chip>
        </template>

        <template v-slot:item.timing="{ item }">
          <div style="text-wrap: none">
            <span class="caption text--secondary">Отримано: </span> <span class="caption "><b>{{ item.created_at }}</b></span>             <br>
            <span class="caption text--secondary">Зміни: </span> <span class="caption "><b>{{ item.updated_at }}</b></span>            <br>
            <span class="caption text--secondary">В роботі з: </span> <span class="caption "><b>{{ item.work_start_time }}</b></span>
          </div>

        </template>

        <template v-slot:item.manager="{ item }">

          <div class="d-flex pa-3 align-center" v-if="item.manager">
            <v-avatar size="42"  class="mr-3">
              <v-img :src="$store.state.filesPublic+'users/avatars/'+item.manager" >
                <template slot="placeholder">
                  <v-img src="@/assets/placeholder/user_blank.jpg" />
                </template>
              </v-img>
            </v-avatar>
            <div>
              <h4 class="mb-0">{{ item.manager_name}}</h4>
              <div class="caption mt-0" style="line-height: 1.3">{{ item.manager_department }}</div>
            </div>
          </div>

          <div v-else class="d-flex">
            <v-btn  :disabled="loading"  text color="primary lighten-2" x-small @click="showAssignManager(item.uuid)">Призначити</v-btn>
            <v-btn :loading="loading" outlined color="primary lighten-2" x-small @click="takeLead(item.uuid)">Взяти в роботу</v-btn>
          </div>

        </template>


        <!--  actions button-->
       <template #item.result="{ item }">
         <div class="d-flex">
           <v-btn  :disabled="loading"  text color="red lighten-2 " x-small @click="leadResult(item.uuid,false)">Відмова</v-btn>
           <v-btn :loading="loading" outlined color="green lighten-1" x-small @click="leadResult(item.uuid,true)">Успіх</v-btn>
         </div>
       </template>


      </v-data-table>

    </card>

    <card v-if="step==1" title="Відповідальні за прийом">
<!--      <div class="d-flex justify-end">-->
<!--        <v-text-field style="width:200px" filled rounded v-model="searchTeachers" label="Пошук" ></v-text-field>-->
<!--      </div>-->

      <v-data-table dense   :loading="loading" item-key="uuid" :expanded.sync="expanded"
          :headers="headersTeachers" :items = "teachersDistinct" show-expand
          :items-per-page="100" :footer-props="{ 'items-per-page-options': [50, 100, 150, -1] }" >

        <template  v-slot:expanded-item="{ headers,item }">
          <td :colspan="headers.length" style="background-color: ghostwhite;" >
            <v-simple-table>
              <tbody>
              <tr v-for="lead in teacherLeads(item.uuid)" :key="lead.uuid">
                <td>{{  lead.name }}</td>
              </tr>
              </tbody>
            </v-simple-table>
          </td>
        </template>


      <template #item.teacher="{item}">
        <div class="d-flex pa-3 align-center" >
          <v-avatar size="42"  class="mr-3">
            <v-img :src="$store.state.filesPublic+'users/avatars/'+item.uuid" >
              <template slot="placeholder">
                <v-img src="@/assets/placeholder/user_blank.jpg" />
              </template>
            </v-img>
          </v-avatar>
          <div>
            <h4 class="mb-0">{{ item.name}}</h4>
            <div class="caption mt-0" style="line-height: 1.3">{{ item.department }}</div>
          </div>
        </div>
      </template>
      <template #item.leads="{item}">
        <v-chip small  outlined color="primary darken-2"><v-icon color="primary darken-2" small class="mr-1">mdi-cog</v-icon>
         <b> {{ teacherLeads(item.uuid).length }} </b>
        </v-chip>
      </template>
      <template #item.success="{item}">
        <v-chip small dark color="green lighten-1"><v-icon small class="mr-1">mdi-cog</v-icon>
          <b> {{ teacherLeads(item.uuid,'Успіх').length }} </b>
        </v-chip>
      </template>
      <template #item.deny="{item}">
        <v-chip small dark color="red lighten-2"><v-icon small class="mr-1">mdi-cog</v-icon>
          <b> {{ teacherLeads(item.uuid,'Відмова').length }} </b>
        </v-chip>
      </template>
      <template #item.inProcess="{item}">
        <v-chip small dark color="primary lighten-2"><v-icon small class="mr-1">mdi-cog</v-icon>
          <b> {{ teacherLeads(item.uuid,'В процесі').length }} </b>
        </v-chip>
      </template>
      <template #item.idle="{item}">
        <v-chip small dark color="orange lighten-2"><v-icon small class="mr-1">mdi-cog</v-icon>
          <b> {{ teacherLeads(item.uuid,'Доручено').length }} </b>
        </v-chip>
      </template>
      <template #item.rate="{}">
        <v-chip small dark color="indigo lighten-2"><v-icon small class="mr-1">mdi-cog</v-icon><b>30%</b></v-chip>
      </template>

      </v-data-table>

    </card>


    <v-dialog v-model="showAssignmentDialog" width="700">
      <card title="Оберіть відповідального за заявку">
        <template slot="header"><v-btn dark icon @click="showAssignmentDialog=false"><v-icon>mdi-close</v-icon></v-btn></template>

        <div class="pa-5">

          <v-subheader>Фільтри:</v-subheader>
          <v-autocomplete v-model="departmentFilter" class="compact-form"  filled rounded label="Кафедра" :items="departments" />

          <v-subheader>Оберіть виклдача:</v-subheader>
          <v-autocomplete v-model="assignedManager" class="compact-form"  filled rounded  label="Викладач" :items="teachersFiltered" >
            <template #item="{item}">
              <div class="d-flex">
                <v-avatar size="28"  class="mr-3">
                  <v-img :src="$store.state.filesPublic+'users/avatars/'+item.value" >
                    <template slot="placeholder">
                      <v-img src="@/assets/placeholder/user_blank.jpg" />
                    </template>
                  </v-img>
                </v-avatar>
                {{ item.text }} <br>
              </div>


            </template>
          </v-autocomplete>

          <div class="mt-8 d-flex justify-end pa-3">
            <v-btn :loading="loading" :disabled="loading" text color="primary lighten-2" @click="showAssignmentDialog=false">Скасувати</v-btn>
            <v-btn :disabled="loading" outlined color="primary lighten-2" @click="assignManager('Доручено')">Призначити</v-btn>
          </div>

        </div>



      </card>
    </v-dialog>



  </div>
</template>

<script>
import card from "@/components/pages/dashboard/UI/card";
//import icoBox from "@/components/pages/dashboard/UI/icoBox";
import {mapActions, mapState} from "vuex";
export default {
  name: "DashStudents",
  components : {
    card,
    //icoBox
  },
  data(){
    return {
      searchTeachers : null,
      step : 0,
      expanded: [],

      assignLeadID: null,
      assignedManager : null,
      showAssignmentDialog : false,
      departmentFilter : null,

      teachers : [],

      //technical
      loading : true,
      selected : [],
      search : '',
      editDialog : false,
      addDialog : false,
      editData : {
        id:'',
        name:''
      },
      addData : {
        name: ''
      },
      waveStatus : ['Enrollment opened','Study in process','Finished' ],
      //dataPickers variables
      menuEnrollStart : false,
      menuEnrollEnd : false,
      menuEduStart : false,
      // Table data
      headers: [
        { text: 'Статус', value: 'status' },
        { text: 'Заявка', value: 'student' },
        { text: 'Телефон', value: 'telephone' },
        { text: 'Спеціальність', value: 'specialty_name' },
        { text: 'Відповідальний', value: 'manager' },
        { text: 'В роботі', value: 'timing' },
        { text: 'Результат', value: 'result' },
      ],
      headersTeachers : [
        { text: 'Викладач', value: 'teacher' },
        { text: 'Заявок', value: 'leads' },
        { text: 'Успіх', value: 'success' },
        { text: 'Відмова', value: 'deny' },
        { text: 'В процесі', value: 'inProcess' },
        { text: 'В очікуванні', value: 'idle' },
        { text: 'Відсоток', value: 'rate' },
        { text: '', value: 'data-table-expand'}

      ],
      rowsData : [
        { uuid: '' ,
          student_name: '' ,
        },
      ],
      // actions
      editActions : [
        { label: 'View Student', action: 'view' }
      ],
      groupActions: [
        { label: 'Delete', action : 'delete' },
      ],

    }
  },
  computed : {
    ...mapState('auth',['accessToken']),
    teachersFiltered() {
      var teachers = this.teachers;
      if (this.departmentFilter) { teachers = teachers.filter(el=>el.department_id === this.departmentFilter) }
      return teachers
    },
    teachersDistinct() {

        var teachers =[];

        if (this.rowsData.length>0){
          const map = new Map();
          for (const item of this.rowsData) {
            if(!map.has(item.manager)){
              map.set(item.manager, true);    // set any value to Map
              teachers.push({
                uuid: item.manager,
                name: item.manager_name,
                department: item.manager_department
              });
            }
          }
        }
        return teachers


    }
  },
  methods : {
    ...mapActions('dashboard', ['GET_LEADS','EDIT_LEAD']),

    // action selectors
    editButton(action,item) {
      switch(action) {
        case 'view' : this.$router.push({path:'/dashboard/profile/'+item.uuid}); break;

      }
    },
    groupAction(action) {
      switch(action) {
        case 'delete' : this.deleteSelected();  break;
      }
    },
    // methods
    addRow() {
      this.loading = true;
      this.ADD_ENROLLMENT_WAVE({ token : this.accessToken, data : this.addData })
          .then((out)=>{ this.loading = false; this.rowsData.push(out.data); this.addData = {} })
          .catch(()=>{this.loading = false;})
    },
    editRow() {
      this.loading = true;
      this.EDIT_ENROLLMENT_WAVE({ token : this.accessToken, data : this.editData })
          .then(()=>{
            const index = this.rowsData.findIndex( row => row.id === this.editData.id );
            //this.$set(this.rowsData,index,this.editData)

            Object.assign(this.rowsData[index],this.editData)

            this.loading = false;
          })
          .catch(()=>{this.loading = false;})
    },
    deleteSelected() {
      var rows = [];
      this.selected.forEach((row)=>{
        rows.push(row.uuid)
      })
      this.deleteRow(rows);
    },


    showAssignManager(uuid) {
      this.assignLeadID = uuid;
      this.showAssignmentDialog = true;
    },
    takeLead(uuid) {
      this.assignLeadID = uuid;
      this.assignedManager = this.$store.state.auth.userID
      this.assignManager('В роботі')
    },
    assignManager(status) {
      this.loading = true;
      var data = {uuid:this.assignLeadID, status:status }
      if (this.assignedManager) { data.manager = this.assignedManager }


      if(status === 'В роботі') { data.work_start_time = true}
      this.EDIT_LEAD(data).then((out)=>{
       const index =  this.rowsData.findIndex(el=>el.uuid === out.data.uuid);

        this.loading = false
       if (out.result) {

         out.data.work_start_time = this.formatTime(out.data.work_start_time)
         out.data.created_at = this.formatTime(out.data.created_at)
         out.data.updated_at = this.formatTime(out.data.updated_at)


         this.$set(this.rowsData,index,out.data); this.showAssignmentDialog=false }
       this.assignedManager = null;
       this.assignLeadID = null;

      }).catch(error=>{ console.log(error); this.loading = false })

    },

    leadResult(uuid,result) {
      this.assignLeadID = uuid;
      var status = null;
      if (result) { status = 'Успіх'} else { status = 'Відмова'}
      this.assignManager(status)
    },

    //tecnical
    teacherLeads(uuid,status) {
      var leads = this.rowsData;
      leads = leads.filter(el=> el.manager === uuid)
      if (status) { leads = leads.filter(el=> el.status === status) }
      return leads
    },
    getColor(status) {
      switch(status) {
        case 'bsc' : return 'primary'
        case 'msc' : return 'success'
        case 'phd' : return 'info'
      }
    },
    getStatusColor(status) {
      switch(status) {
        case 'Новий' : return 'green lighten-1'
        case 'В роботі' : return 'primary lighten-2'
        case 'Відмова' : return 'red lighten-2'
        case 'Доручено' : return 'orange lighten-2'
        case 'Успіх' : return 'success'
      }
    },
    getDegree(val) {
      switch(val) {
        case 'bsc' : return 'Бакалавр'
        case 'msc' : return 'Магістр'
        case 'phd' : return 'Phd'
      }
    },
    resultStyle(item) {
      switch(item.status) {
        case 'Успіх' : return 'successStyle';
        case 'Відмова' : return 'refuseStyle';
      }
    },

  },
  async mounted() {
    this.GET_LEADS().then((res)=>{
      this.rowsData = res.data;
      this.teachers = res.teachers_select
      this.departments = res.departments
      this.departments.unshift({text:'All',value:null})
      this.rowsData.forEach((_,i)=>{


        console.log(this.rowsData)
        this.rowsData[i].created_at = this.formatTime(this.rowsData[i].created_at)
        this.rowsData[i].updated_at = this.formatTime(this.rowsData[i].updated_at)
        if (this.rowsData[i].work_start_time) {
          this.rowsData[i].work_start_time = this.formatTime(this.rowsData[i].work_start_time)
        }
      })
      console.log(this.rowsData)
      this.loading = false
    })
  }
}
</script>

<style lang="scss" scoped>
.groupAction {
  text-transform: none;
  letter-spacing: 0px;
  margin-top: 1px;
}
.successStyle td {
  background-color: green !important;
}
.refuseStyle td {
  background-color: #e99a9a !important;
}
</style>