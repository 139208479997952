<template>
  <div>
    <v-fade-transition mode="out-in">
      <avalonNews v-if="businessTemplate === 'avalon'" key="Avalon" />
    </v-fade-transition>
  </div>
</template>

<script>
import avalonNews from "@/components/templates/Avalon/UI/avalonNews";
import {mapState} from "vuex";
export default {
  name: "TemplateLoaderNews",
  components : {
    avalonNews
  },
  computed : {
    ...mapState('templates', ['businessTemplate']),
  }
}
</script>

<style scoped>

</style>