<template>
  <div>

    <card v-show="!editDialog" title="News">
      <template slot="header">
        <v-btn dark outlined smal @click="addNews"><v-icon class="mr-2">mdi-plus</v-icon>Add News</v-btn>
      </template>
      <v-simple-table>
        <thead>
        <tr>
          <td>No</td>
          <td>Title</td>
          <td>Date</td>
          <td>Author</td>
          <td>Status</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(record,i ) in news" :key="i">
          <td>{{ i+1 }}</td>
          <td> <b v-if="record.status === 'published'">{{  record.title  }}</b><span v-else>{{  record.title  }}</span></td>
          <td>{{  record.date  }}</td>
          <td>{{  record.author  }}</td>
          <td>
            <v-chip small dark :color="getColor(record.status)"><v-icon small class="mr-2">mdi-cog</v-icon><b>{{  getStatus(record.status)  }}</b></v-chip></td>

          <td>
            <v-btn small icon @click="openEdit(record.uuid)"><v-icon small icon >mdi-pencil</v-icon></v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </card>

<!--    <v-dialog v-if="editDialog" v-model="editDialog" width="800" >-->
<!--      <editNews v-if="editDialog" @close="editDialog=false" @refresh="refreshRow"-->
<!--                      :card="true" :type="dataEditType" :input="dataEditInput"  />-->
<!--    </v-dialog>-->

    <v-fade-transition>
      <newsEditor v-if="editDialog"  @close="editDialog=false" :type="dataEditType" :input="dataEditInput" />
    </v-fade-transition>



  </div>
</template>

<script>
import card from "@/components/pages/dashboard/UI/card";
// import editNews from "@/components/pages/dashboard/news/editNews";
import newsEditor from "@/components/pages/dashboard/news/newsEditor";
import {mapActions} from "vuex";
export default {
  name: "manageNews",
  components : {
    card,
  //  editNews,
    newsEditor
  },
  data() {
    return {
      loading : false,
      editDialog : false,
      dataEditType : null,
      dataEditInput : null,
      news : [],
    }
  },
  methods : {
    ...mapActions('news', [ 'GET_NEWS_ALL']),
    addNews(){
      this.editDialog = true;
      this.dataEditType = 'new'
    },
    openEdit(uuid) {
      this.editDialog = true;
      this.dataEditType = 'edit',
      this.dataEditInput = uuid;

    },
    refreshRow(data) {
      this.news.push(data.data)
    },
    getColor(status) {
      switch(status) {
        case 'published' : return 'green lighten-1'
        case 'draft' : return 'primary lighten-1'
      }
    },
    getStatus(status) {
      switch(status) {
        case 'published' : return 'Опубліковано'
        case 'draft' : return 'Чернтека'
      }
    }
  },
  async mounted(){

    this.loading = true
    this.GET_NEWS_ALL().then(out=>{
      if(out.result) {
        this.news = out.data
      }
      this.loading = false
    }).catch(error=>{ console.log(error) })
  }
}
</script>

<style scoped>

</style>