<template>
  <v-sheet color="blue-grey darken-1" >
    <!--    Business/Fine Logo-->
    <div  class="d-flex  justify-space-between align-center " >
      <router-link  class="mt-4"
                       :to="`/${ $store.state.domain ? '' : $store.state.business.selectedBusiness.alias}`">
            <img   v-if="$store.state.business.selectedBusiness.logo"
                   height="35px" class="ml-6"
                   :alt="$store.state.business.selectedBusiness.name"
                   :src="$store.state.business.selectedBusiness.logo"
                  >
            <v-img v-else  width="35px" alt="WeStudy" src="@/assets/brand/logo_fine_white.png" />
          </router-link>
      <div v-if="displayHide(['sm'])" class="py-2 pl-2 pb-2 mt-1">
        <v-btn  @click="TOGGLE_SIDEBAR"  icon  href="#" color="py-4 ml-3 grey darken-4">
          <v-icon v-if="displayHide(['sm'])">mdi-chevron-double-{{ sideMenu ? 'left' : 'right' }}</v-icon>
          <v-icon v-else> mdi-menu </v-icon>
        </v-btn>
      </div>
      <!-- User Name and Email-->
      <div v-if="sideMenu && displayShow(['sm']) " class="mr-5">
        <h4 style="line-height: 1.2"  class="font-weight-light text-right">{{ $store.state.auth.userName }}</h4>
        <h6 style="line-height: 1.2"  class="font-weight-light text-right">{{ $store.state.auth.userEmail }}</h6>
      </div>
    </div>

    <!-- Menu Elements-->
    <v-list dense dark color="#546e7a" class="mt-2">

      <v-list-item-group color="white" v-model="selectedItem">

        <div v-for="(section, i) in authData" :key="i">

          <!--        <div v-if="section.allow">-->
          <v-subheader v-if="sideMenu" class="ml-3">{{ section.section }}</v-subheader>
          <div v-for="(item, j) in section.items" :key="j" >

            <v-list-item v-if="!item.children" @click="select(item)">

              <v-list-item-icon :class="[{'ml-5': sideMenu},{'mr-5': sideMenu}]">
                <v-icon v-if="!item.counter"  v-text="item.icon"></v-icon>
                <v-badge v-if="item.counter" :content="item.counterValue" :value="item.counterValue" color="green lighten-2" overlap>
                  <v-icon >{{ item.icon }}</v-icon>
                </v-badge>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>

            </v-list-item>

            <v-list-group color="white darken-1" v-else :value="false" >

              <template v-slot:activator >
                <v-list-item-icon :class="[{'ml-5': sideMenu},{'mr-5': sideMenu}]">
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title > {{ item.text }}</v-list-item-title>

              </template>

              <div v-if="sideMenu">
                <v-list-item dark color="white"
                             v-for="(subitem,i) in item.children"
                             :key="i"
                             @click="select(subitem)"

                >
                  <v-list-item-title class="ml-16 font-weight-light"> {{ subitem.text }}</v-list-item-title>

                </v-list-item>

              </div>

            </v-list-group>

          </div>
          <!--        </div>-->

        </div>

      </v-list-item-group>
    </v-list>
  </v-sheet>

</template>

<script>

import {mapMutations, mapState} from 'vuex';

export default {
  name: "menuElementsBusiness",
  data: () => ({
    selectedItem: 0,
    businessManager : false,
    items_owner : [
      {
        section: 'Мій бізнес',
        items: [
          { text: 'Головна'    ,                                   icon: 'mdi-view-dashboard-outline'    , action: { type: 'route', path: ''            } } ,
          { text: 'Новини'     , permission:'MODULE_NEWS'        , icon: 'mdi-newspaper-variant-outline' , action: { type: 'route', path: 'news'        } , module : 'news'} ,
          // { text: 'Cторінки'   , permission:'MODULE_PAGES'       , icon: 'mdi-page-layout-sidebar-left'  , action: { type: 'route', path: 'pages'       } } ,
          { text: 'Товари'     , permission:'MODULE_PRODUCTS'    , icon: 'mdi-cart-variant'              , action: { type: 'route', path: 'products'    } , module : 'products'},
          { text: 'Замовлення' , permission:'MODULE_ORDERS'      , icon: 'mdi-text-box-check-outline'    , action: { type: 'route', path: 'orders'      } } ,
          { text: 'Акція'      , icon: 'mdi-text-box-check-outline'    , action: { type: 'route', path: 'action'      } } ,

          { text: 'GoogleMaps' , permission:'MODULE_GOOGLEMAPS'  , icon: 'mdi-google-maps'               , action: { type: 'route', path: 'googleMaps'  } , module : 'googleMaps' },
          { text: 'Склади'     , permission:'MODULE_WAREHOUSE'   , icon: 'mdi-warehouse'                 , action: { type: 'route', path: 'warehouse'   } , module : 'warehouse' },
          { text: 'Клієнти'    , permission:'MODULE_SUBSCRIBERS' , icon: 'mdi-account'                   , action: { type: 'route', path: 'subscribers' } },
          { text: 'Звіти'      , permission:'MODULE_STATISTICS'  ,  icon: 'mdi-chart-bar'                , action: { type: 'route', path: 'statistics'  } , module : 'statistics'},
          { text: 'Менеджери'  , permission:'MODULE_MANAGERS'    , icon: 'mdi-account-check'             , action: { type: 'route', path: 'managers'    } },
          {
            text: 'Налаштування',   permission:'MODULE_SETTINGS',  auth: ['businessman','user','superadmin','manager'],    icon: 'mdi-cog-outline', children : [
              { text : 'Загальні',auth: ['businessman','user','superadmin'], icon: 'mdi-view-dashboard-outline', action: {type: 'route', path: 'general'} },
              { text : 'Доменна Адреса'  , icon: 'mdi-cog'  ,  action: {type: 'route', path: 'domains'}, },
              { text : 'Зовнішній вигляд',auth: ['businessman','user','superadmin'], icon: 'mdi-view-dashboard-outline', action: {type: 'route', path: 'design'} },
              { text : 'Оплата',auth: ['businessman','user','superadmin'], icon: 'mdi-view-dashboard-outline', action: {type: 'route', path: 'payment'} },
              { text : 'Доставка',auth: ['businessman','user','superadmin'], icon: 'mdi-view-dashboard-outline', action: {type: 'route', path: 'delivery'} },
              { text : 'Модулі',auth: ['businessman','user','superadmin'], icon: 'mdi-view-dashboard-outline', action: {type: 'route', path: 'modules'} },
              { text : 'Чат Боти'  , icon: 'mdi-cog'  ,  action: {type: 'route', path: 'chatBots'}, module : 'chatBots' },
            ],
          },
        ]
      },
      {
        section: 'Профіль',
        items: [
          {
            text: 'Мій профіль',
            icon: 'mdi-badge-account-horizontal-outline',
            action: {type: 'route', path: 'profile'}
          },
          { text: 'Вийти', icon: 'mdi-logout-variant', action: {type: 'logout'}}
        ]
      },

    ],

  }),
  computed: {
    ...mapState('cart', [ 'quantity']),
    ...mapState('dashboard', ['sideMenu','showBusinessToManager']),
    ...mapState('business', ['selectedBusiness']),
    ...mapState('auth', ['userType']),
    items() {
      if (this.$store.state.dashboard.showBusinessToOwner || this.$store.state.dashboard.showBusinessToManager) {  return this.items_owner }
      else { return this.items_user }
    },
    authData() {


      var data = new Array();
      this.items.forEach((section) => {
        var filteredItems = [];
        let allowSection = true

        if (section.auth) {
          if (section.auth.includes(this.$store.state.auth.userType)) {
            allowSection = true
          } else { allowSection = false }
        }


        if (allowSection) {
          section.items.forEach((item) => {
            var allow = false;

            //check modules

            if (item.auth) {
              item.auth.forEach((uType) => {
                if (uType === this.userType) {
                  allow = true;
                }
              });
              if ( item.module ) {
                allow = false;
                let module = this.$store.state.business.selectedBusiness.modules.find(el=>el.name === item.module)
                if (module) { if (module.active) { allow=true } }
              }
              if (allow) {
                filteredItems.push(item) }

            } else
              {
                if ( item.module ) {
                  if ( this.$store.state.business.selectedBusiness.modules.length > 0 ) {
                    let module = this.$store.state.business.selectedBusiness.modules.find(el=>el.name === item.module)
                    if (module) { if (module.active) {  filteredItems.push(item)  } }
                  }

                } else {
                  filteredItems.push(item)
                }

              }

          })

          if (filteredItems.length > 0) {
            data.push({section: section.section, items: filteredItems})
          }
        }


      })

      if ( this.showBusinessToManager) {

        let permittedData = []

        data[0].items.forEach((item)=>{

          let permission = this.selectedBusiness.userPermissions.permissions.includes(item.permission)
          if ( !item.permission ) { permission = true }
          if ( permission ) {  permittedData.push(item) }
        })
        data[0].items = permittedData

      }




      return data;
    },
    items_user() {
      return [
        {
          section: 'Управління',
          items: [
            {text: 'Головна', icon: 'mdi-view-dashboard-outline', action: {type: 'route', path: ''}},
            {text: 'Товари', icon: 'mdi-cart-variant', action: {type: 'route', path: 'viewProducts'}},
            {
              text: 'Корзина',
              icon: 'mdi-cart',
              action: {type: 'route', path: 'cart'},
              counter: true,
              counterValue: this.quantity
            },
            // { text: 'Замовлення'     , icon: 'mdi-text-box-check-outline', action: {type: 'route', path: 'orders'} },


          ]
        },
        {
          section: 'Профіль',
          items: [
            {
              text: 'Мій профіль',
              icon: 'mdi-badge-account-horizontal-outline',
              action: {type: 'route', path: 'profile'}
            },
            {text: 'Вийти', icon: 'mdi-logout-variant', action: {type: 'logout'}}
          ]
        },
      ]
    }
  },
  methods: {
    ...mapMutations('dashboard', ['SHOW_MODAL_CONFIRM_LOGOUT', 'SET_PAGE_TITLE', 'TOGGLE_SIDEBAR']),
    select(item) {
      switch (item.action.type) {

        case 'route':
          this.SET_PAGE_TITLE(item.text);
          if (item.action.path[0] !== '/') {
            this.$router.push(`/dashboard/${
                !this.$store.state.domain ?  this.$store.state.business.selectedBusiness.alias + '/' : '' }${item.action.path}`);
          } else {
            this.$router.push(`${item.action.path}`);
          }
          break;

        case 'logout':
          this.logout()
          break;
      }

      if ( this.displayShow(['sm'])) {
        this.$store.state.dashboard.sideMenu = false
      }

    },
    logout() {
      this.SHOW_MODAL_CONFIRM_LOGOUT();
    },
  }
}
</script>

<style scoped>

</style>