<template>
<div>
  <p class="text-h5 mt-10 text-center font-weight-light"> Upload your profile picture ?</p>
  <v-image-input
      v-model="avatar"
      :image-quality="0.85"
      clearable
      image-format="jpeg"
      uploadIcon="mdi-help-circle-outline"
      uploadIconStyle="red"
      @file-info="success"
  />
  <div class="d-flex mt-10 justify-center">
    <v-btn color="accent" @click="TOGGLE_DASH_MODAL" class="mr-10">No</v-btn>
    <v-btn color="primary" @click="logout">Yes</v-btn>
  </div>
</div>
</template>

<script>

export default {
  name: "changeAvatar",
  data() {
    return {
      avatar : null
    }
  },
  methods : {
    success(){
      alert('HOOREAYE');
      console.log(this.avatar)
    }
  }
}
</script>

<style scoped>
.red {
  color : red !important;
  font-size: 50px;
}
</style>