<template>
  <BusinessDashboardOrders />
</template>

<script>
import BusinessDashboardOrders from "@/components/pages/businessDashboard/businessOrders/BusinessDashboardOrders";

export default {
  name: "dashBusinessIndex",
  props: ['alias'],
  components : {
    BusinessDashboardOrders
  },
  data() {
    return {
      business : {}
    }
  }
}
</script>

<style scoped>

</style>