<template>
  <v-sheet class="py-10 px-16 pt-16" >


    <v-fade-transition mode="out-in" >
      <!-- Business name and alias-->
      <div class="step_content mt-16" key="s1" v-if="step===0">
        <h1 class="text-h3 mt-8 font-weight-medium text-center">Додати Бізнес акаунт</h1>
        <h2 style="line-height: 1.2" class="mt-3 font-weight-light text-center">Всього декілька кроків і твій бізнес запрацює </h2>
        <div class="mt-16 px-6">
          <v-text-field rounded filled dense v-model="addData.name" placeholder="Назва бізнесу" label="Назва" required/>
          <v-text-field rounded filled dense v-model="addData.alias" placeholder="Постійне посилання" label="Посилання" required>
            <template v-slot:append>
              <v-tooltip bottom max-width="600" color="black" class="pa-6">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                </template>
                <div class="px-3 py-5">
                  Посилання це унікальна назва адреса, завдяки якій користувачі завжди зможуть знайти твій бізнес. Якщо ти не маєш доменного ім'я або користуєшся безкоштовною версією FineTrading,
                  доступ на сайт твого бізнесу відбувається через посилання <b>fine.trading/посилання</b><br><br>
                  <i>Наприклад: fine.trading/burgerking</i>
                </div>

              </v-tooltip>
            </template>
          </v-text-field>

        </div>
      </div>
      <div class="step_content mt-16" key="s2" v-if="step===1">
        <h1 class="text-h3 mt-8 font-weight-medium text-center">Чим ти займаєшся</h1>
        <h2 style="line-height: 1.2" class="mt-3 font-weight-light text-center">Обери тип бізнесу який підходить саме для тебе, для зручного та швидкого налаштування</h2>

        <!-- Business Type-->
        <div class="d-flex justify-center mt-16 mb-16">
          <v-chip v-for="(type,i) in templateType" :key="i" @click="selectTempalteType(i)"
                  :outlined="!type.active"  v-ripple active dark :color="type.color+' lighten-3'" class="mr-3 mt-3 px-8 " large rounded>
            <h3 class="font-weight-light ">{{ type.text }}</h3>
          </v-chip>
        </div>
      </div>
      <div class="step_content mt-16" key="s3" v-if="step===2">
        <h1 class="text-h3 mt-8 font-weight-medium text-center">Тарифний план</h1>
        <h2 style="line-height: 1.2" class="mt-3 font-weight-light text-center">Обери тарифний план який підходить саме тобі, або спробуй безкоштовний тестовий період 1 місяць тариф Базовий</h2>


      </div>
    </v-fade-transition>


    <!-- Navigation-->
    <div class="d-flex justify-center mt-16 mb-16">
      <v-btn :loading="loading" :disabled="loading"  @click="prevStep"
          large class="mt-3 px-8 " text rounded elevation="0" color="blue darken-2" dark>
        <h3 class="font-weight-light">Назад</h3>
      </v-btn>
      <v-btn :disabled="loading"  @click="nextStep"
          large class="mt-3 px-8 " rounded filled elevation="0" color="blue darken-2" dark>
        <h3 class="font-weight-light">Далі</h3>
      </v-btn>
    </div>



  </v-sheet>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "business",
  components : {
  },
  data(){
    return {

      loading : false,

      step : 0,
      addData : {},
      templateType : [
        { text : 'Ресторан',           value : 'restaurant', color:'blue', active:false },
        { text : 'Доставка Їжі',       value : 'restaurant', color:'teal', active:false },
        { text : 'Інтернет Магазин',   value : 'restaurant', color:'indigo', active:false },
        { text : 'Ручне налаштування', value : 'restaurant', color:'primary', active:false },
      ]
    }
  },
  methods : {
    ...mapActions('business',  [ 'ADD_BUSINESS' ]),
    addBusiness() {
      this.loading = true

      let data = {
        alias : this.addData.alias,
        name :  this.addData.name,
        shortAbout: 'Немає опису',
        fullAbout :  'Немає опису',
      }
      this.ADD_BUSINESS(data).then((out)=>{
        console.log(out)
        this.loading = false

          alert(out)
          this.$emit('onFinish')

      }).catch((error)=>{ alert(error); this.loading = false })

    },
    // technical
    selectTempalteType(index) {
      this.templateType.forEach((type,i)=>{
        this.templateType[i].active=false
      })
      this.templateType[index].active = true
    },
    nextStep() {
     this.step++
      if (this.step === 3) { this.addBusiness() }
    },
    prevStep(){
      if(this.step !==0) { this.step-- }
    }
  }
}
</script>

<style scoped>
  .step_content {
    min-height: 320px !important;
  }
</style>