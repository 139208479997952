<template>
  <v-dialog v-model="openEditor" :width="editorWidth" persistent>
    <v-sheet dark class="py-6 px-8"  >
      <div class="d-flex justify-space-between mt-2 ">
        <h3 style="line-height: 1.2" class="mt-3 mb-3  font-weight-light text-center">Редагування</h3>
        <v-btn plain icon  @click="cancelEdit"><v-icon>mdi-close</v-icon></v-btn>
      </div>

      <v-sheet class="pa-6 mt-8 mb-6" outlined rounded  >

        <h3 style="line-height: 1.2" class="mt-3 mb-10 font-weight-medium text-center">{{  selectedItem.title  }}</h3>

        <!-- Type Text Field-->
        <v-text-field v-if="selectedItem.type === 0"
                      v-model="$store.state.templates.templateVars_current[selectedItem.name].buffer"
                      class="mt-10"
                      rounded
                      filled
                      dense
                      placeholder="Value"
                      label="Введіть значення"
                      required />

        <!-- Type Image-->
        <editImage v-if="selectedItem.type === 1" v-model="$store.state.templates.templateVars_current[selectedItem.name].buffer" />

        <ftEditorImage v-if="selectedItem.type === 999" />

        <!-- Type Text Area-->
        <v-textarea   v-if="selectedItem.type === 2"
            v-model="$store.state.templates.selectedItem.buffer"
                      class="mt-10 noResize" rounded filled dense placeholder="Value" label="Введіть значення" required />

        <!-- Type HTML EDITOR Area-->

        <VueEditor  v-if="selectedItem.type === 3"
                    v-model="$store.state.templates.selectedItem.buffer" class="mt-3 mb-16 pa-1 "
                    style=" color:white; background-color: #434343;
                            border-radius:25px; min-height: 500px"
                    :editorOptions="{ theme: 'bubble',bounds : $refs['newsEditor'] }" />

        <!-- Type Color Field-->
        <v-color-picker v-if="selectedItem.type === 4" v-model="$store.state.templates.selectedItem.buffer" > </v-color-picker>



<!--        <div class="d-flex justify-space-between px-5">-->
<!--          <h3 style="line-height: 1.2" class="mt-3 mb-10 font-weight-light ">Активно</h3>-->
<!--          <v-switch large dark color="success lighten-2" ></v-switch>-->
<!--        </div>-->
      </v-sheet>



      <!-- Navigation-->
      <div class="d-flex justify-end mt-5 mb-3">
        <v-btn :loading="loading" :disabled="loading"  @click="cancelEdit"
               large class="mt-3 px-8" text rounded elevation="0"  dark>
          <h3 class="font-weight-light">Скасувати</h3>
        </v-btn>
        <v-btn :disabled="loading"  @click="saveChanges"
               large class="mt-3 px-8" rounded outlined elevation="0"  dark>
          <h3 class="font-weight-light">Зберегти</h3>
        </v-btn>
      </div>
    </v-sheet>

  </v-dialog>
</template>

<script>
import ftEditorImage from "@/components/templates/editor/ftEditorImage";
import editImage from "@/components/pages/businessDashboard/UI/editImage";
import {mapState,mapActions} from "vuex";
import {VueEditor} from "vue2-editor";

export default {
  name: "ftEditor",
  components : {
    ftEditorImage,
    editImage,
    VueEditor,
  },
  data(){
    return {
      loading : false,
    }
  },
  computed : {
    ...mapState('templates',  [ 'selectedItem' , 'openEditor' ]),
    editorWidth(){
      let width ;
      switch(this.selectedItem.type) {
        case 1 :  width = 1100; break;
        case 2 :  width = 800; break;
        case 3 :  width = 1000; break;
        default : width = 600;
      }
      return width
    }
  },
  methods : {
    ...mapActions('templates',  [ 'SAVE_DRAFT_ITEM' ]),
    cancelEdit(){
      this.$store.state.templates.openEditor = false
      this.$store.state.templates.selectedItem.buffer =
          this.$store.state.templates.templateVars_current[this.selectedItem.name].value
    },
    saveChanges() {

      this.notify('Чернетку збережено')
      this.$store.state.templates.templateVars_current[this.selectedItem.name].value =
          this.$store.state.templates.templateVars_current[this.selectedItem.name].buffer
      this.$store.state.templates.openEditor = false

      let data = [
        {
          item  : parseInt(this.selectedItem.item),
          type  : parseInt(this.selectedItem.type),
          value : this.$store.state.templates.templateVars_current[this.selectedItem.name].value}
      ]

      this.SAVE_DRAFT_ITEM(
          {
            alias: this.$store.state.templates.businessAlias,
            data : {items : data}
          }  )
          .then((out)=>{
          console.log(out)
      })

    }
  }
}
</script>

<style  lang="scss">

@import '~@/assets/css/quill.bubble.css';

.noResize textarea {
  resize: none !important;
}
</style>