var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-16"},[_c('v-row',[_c('v-col',{staticClass:"pr-16",attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex justify-space-between mb-10"},[_c('h1',{staticClass:"text-h4 font-weight-light"},[_vm._v("Ваші Новини")]),_c('div',{staticStyle:{"width":"250px"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","hide-details":"","dense":"","label":"Пошук"},model:{value:(_vm.searchNews),callback:function ($$v) {_vm.searchNews=$$v},expression:"searchNews"}})],1)]),_c('v-expand-transition',{attrs:{"mode":"out-in"}},[(_vm.selectedNews.length > 0)?_c('v-sheet',{attrs:{"height":"50"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"deep-purple","text":""}},'v-btn',attrs,false),on),[_vm._v(" Групові дії "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,655708005)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.editGroupActions),function(menuItem,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.editButton(menuItem.action,_vm.item)}}},[_c('v-list-item-title',{staticClass:"font-weight-light"},[_vm._v(_vm._s(menuItem.label))])],1)}),1)],1)],1):_vm._e()],1),_c('v-data-table',{attrs:{"items":_vm.newsFiltered,"headers":_vm.news_headers,"show-select":"","selectable-key":"id","search":_vm.searchNews},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex ma-3"},[_c('div',[_c('h4',[_vm._v(_vm._s(item.title)+" ")]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.date))])])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.editActions),function(menuItem,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.editButton(menuItem.action,item)}}},[_c('v-list-item-title',{staticClass:"font-weight-light"},[_vm._v(_vm._s(menuItem.label))])],1)}),1)],1)]}}]),model:{value:(_vm.selectedNews),callback:function ($$v) {_vm.selectedNews=$$v},expression:"selectedNews"}}),_c('div',{staticClass:"d-flex justify-end mt-8"},[_c('v-btn',{staticClass:"noCaps",attrs:{"text":"","elevation":"0","rounded":"","color":"deep-purple"},on:{"click":_vm.addNews}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Додати Новину ")],1)],1)],1)],1),_c('v-fade-transition',[(_vm.displayEditorNews)?_c('newsEditor',{attrs:{"type":_vm.dataEditType,"input":_vm.dataEditInput},on:{"close":function($event){_vm.displayEditorNews=false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }