const actions = {
    INIT_SOCKET({rootState},socket) {
        console.log('setting socket')
        rootState.$socket = socket
        console.log('socket set')
    },
    SOCKET_notification({dispatch},data) {
        console.log('We recieved a notification, this is great')
        console.log(data)
        dispatch('notifications/PUSH_NOTIFICATION',data)
    },
}
export default {
    namespaced : false,
    actions
}