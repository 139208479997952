import { render, staticRenderFns } from "./avalonMainMenu.vue?vue&type=template&id=737fb270&scoped=true&"
import script from "./avalonMainMenu.vue?vue&type=script&lang=js&"
export * from "./avalonMainMenu.vue?vue&type=script&lang=js&"
import style0 from "./avalonMainMenu.vue?vue&type=style&index=0&id=737fb270&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "737fb270",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VIcon,VMenu,VSheet})


/* vuetify-loader */
import installDirectives from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
