<template>
  <div >

    <div v-show="!specialtyWindow" class="justify-center" >

      <!-- MAIN SLIDER-->
      <v-sheet  color="primary" height="550px" style="position:relative">

          <v-parallax key="a"  v-if="!next" height="550" src="@/assets/img/bg_slide_7.jpeg"  >
            <div  class="px-3 " style=" font-family: sans-serif; width: 600px;margin-left: 30px">
              <h1 style="line-height: 1.2" class="font-weight-medium  mt-8 text-h2">FineTrading</h1>
              <h2 style="line-height: 1.2" class="font-weight-medium mb-3  ">Увімкни свій бізнес на повну</h2>
              <h5 class=" font-weight-medium mt-4  mr-16" style="font-size: 0.9rem"
              >Зручність та сучасність, це те що характеризує FineTrading. Отримай потужний універсальний інструмент для розвитку твого бізнесу. Спробувавши, ти запитаєш себе чому не робив це раніше!</h5>
              <v-btn  dark class="mt-8 pa-4  mb-8" color="white"  outlined> Детальніше</v-btn>
              <div class="d-flex pl-4 mt-3" >
                <img class="rounded mr-3" height="40" alt="WeStudy" src="@/assets/img/download_apple.png"  >
                <img class="rounded" height="40" alt="WeStudy" src="@/assets/img/download_google.png"  >
              </div>
            </div>
          </v-parallax>

      </v-sheet>

      <v-sheet ref="start" class="pa-16 d-flex justify-center"  >
        <div class="main_section text-center">
          <h1 class="text-h2 mt-16 font-weight-medium">Цифровізуй свій бізнес</h1>
          <h2 style="line-height: 1.2" class="mt-8 font-weight-light">З легкістю створи електронну платформу свого бізнесу. <br>
            Який самий бізнес ти хочеш прокачати із FineTrading ?
          </h2>
          <div class="my-12">
            <div>
              <v-chip color="teal"    outlined class="mr-3" > Ресторан </v-chip>
              <v-chip color="primary" outlined class="mr-3">Інтернет магазин</v-chip>
              <v-chip color="indigo"  outlined class="mr-3">Сфера послуг</v-chip>
              <v-chip color="blue"    outlined class="mr-3">Доставка їжі</v-chip>
            </div>
            <div class="mt-3">
              <v-chip color="blue"   outlined class="mr-3">Бронювання </v-chip>
              <v-chip color="indigo" outlined class="mr-3">Лідогенерація</v-chip>
              <v-chip color="teal"   outlined class="mr-3">Ресторанна справа </v-chip>
            </div>

          </div>
          <v-btn dark elevation="0" large rounded  color="blue darken-2" class="mb-10">Розпочати</v-btn>
        </div>
      </v-sheet>

      <!-- BENEFITS-->
      <v-sheet   class="d-flex justify-center pa-16 pt-3">
        <div class="main_section">
         <v-row class="">
            <v-col  cols="7" class="fill-height d-flex align-center">
              <div>
                <h1 class="text-h3 mt-16 font-weight-medium ">FineTrading</h1>
                <h2 style="line-height: 1.2" class="mt-3 mb-5 font-weight-light ">Електронна бізнес платформа </h2>
                <h4 v-for="(benefit,n) in benefits" :key="n"
                    class="text-light font-weight-light ">
                  <v-icon small >mdi-chevron-right</v-icon>
                  {{ benefit.title }}
                </h4>
              </div>

            </v-col>

            <v-col class="pt-8" cols="12" xs="12" sm="12" md="5" lg="5" xl="5" style="background: white">
              <img class="rounded mt-8 pt-6" width="100%" alt="WeStudy" src="@/assets/img/fine1.png"  >
            </v-col>
<!--            <v-col cols="7">-->

<!--              <div v-for="(benefit,n) in benefits" :key="n">-->
<!--                <div class="benefit_header d-flex align-center justify-space-between pa-3 mt-3 pl-6 pr-6"-->
<!--                     :class="benefit.visible ? 'benefit_header_active' : ''"-->
<!--                     @click="benefits[n].visible=!benefits[n].visible" >-->
<!--                  <v-icon :dark="benefit.visible" icon-->
<!--                          :class="benefit.visible ? 'benefit_button_active' : ''"-->
<!--                  >mdi-chevron-right</v-icon>-->
<!--                  <div>-->
<!--                    <h4 style="text-align: left !important;" class="text-left font-weight-medium ">{{ benefit.title }}</h4>-->
<!--                  </div>-->


<!--                </div>-->
<!--                <v-expand-transition mode="out-in">-->
<!--                  <div v-show="benefit.visible" >-->
<!--                    <div style="font-family: sans-serif; font-size:14px" class="font-weight-light pa-3 mt-3 pl-6 pr-6">-->
<!--                      {{ benefit.content }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </v-expand-transition>-->
<!--              </div>-->
<!--            </v-col>-->
          </v-row>
        </div>
      </v-sheet>

      <!-- STEPS ACTIVITY-->
      <v-sheet dark color="primary lighten-2" class=" pa-16" >
        <v-row class="pa-12">
          <v-col cols="3"  class="pr-8 pl-8">
            <h2 class="font-weight-light mb-5">Cтвори свій сайт</h2>
            <p class="caption font-weight-light">Cтворіть повноцінний сайт свого бізнесу всьоого за декілька хвилин. Обрай серед безлічі професійних дизайнів</p>
          </v-col>
          <v-col cols="3" class="pr-8 pl-8">
            <h2 class="font-weight-light mb-5">Інтегруйся</h2>
            <p class="caption font-weight-light">Всього декілька кліків і ви маєте персональні додатки на Android та IOS а також чат боти для ведення вашого бізнесу</p>
          </v-col>
          <v-col cols="3" class="pr-8 pl-8">
            <h2 class="font-weight-light mb-5">Замовлення</h2>
            <p class="caption font-weight-light">Ваш сайт інтегрована система продажів та оплат ваших товарів. Відстежуй свої продажі від покупки до отримання клієнтом</p>
          </v-col>
          <v-col cols="3" class="pr-8 pl-8">
            <h2 class="font-weight-light mb-5">Керуй процесом</h2>
            <p class="caption font-weight-light">Отримуй статистику продажів, графіки кількості нових клієнтів та багато іншого. Створюй оповіщення, акції та новини</p>

          </v-col>

        </v-row>
      </v-sheet>

      <v-sheet ref="students"  class="section pa-16">

        <h1 class="text-h3  font-weight-medium text-center">FineTrading powered by</h1>
        <h2 style="line-height: 1.2" class="mt-3 font-weight-light text-center">FineTrading інтеграції та технології
        </h2>

        <div class="d-flex justify-space-between align-center pa-6 mt-10">
          <img class="rounded" height="80" alt="WeStudy" src="@/assets/img/logos/vuejs.png"  >
          <img class="rounded" height="80" alt="WeStudy" src="@/assets/img/logos/ios.png"  >
          <img class="rounded" height="80" alt="WeStudy" src="@/assets/img/logos/android.png"  >
          <img class="rounded" height="80" alt="WeStudy" src="@/assets/img/logos/telegram.png"  >
        </div>
        <div class="d-flex justify-space-between align-center pa-6 mt-6 mb-6">
          <img class="rounded" height="80" alt="WeStudy" src="@/assets/img/logos/android.png"  >
          <img class="rounded" height="80" alt="WeStudy" src="@/assets/img/logos/telegram.png"  >
          <img class="rounded" height="80" alt="WeStudy" src="@/assets/img/logos/vuejs.png"  >
          <img class="rounded" height="80" alt="WeStudy" src="@/assets/img/logos/ios.png"  >
        </div>

      </v-sheet>

      <v-sheet ref="programs"   class=" pa-0" color="blue darken-2" dark >
        <v-row class="mb-0">
          <v-col cols="4" class="pa-0 text-left programs_img" ></v-col>
          <v-col cols="8" class="pa-16"  >
            <h1 class="text-h3 ml-3 mt-5 font-weight-medium ">Можливості FineTrading</h1>
            <h2 style="line-height: 1.2" class="mt-3 ml-3  mb-5 font-weight-light ">Електронна бізнес платформа </h2>
            <v-simple-table class="mt-6 text-no-wrap" dark
                            style="background: #1976d1; overflow: auto; max-height: 500px">
              <tbody>
              <tr v-for="(func,i) in fine_functions" :key="i">
                <td class=" font-weight-light">{{  func.name  }}</td>
                <td class=" font-weight-light">{{  func.details  }}</td>
              </tr>

              </tbody>

            </v-simple-table>

          </v-col>
        </v-row>
      </v-sheet>

      <v-sheet ref="students" class="section pa-16 text-center">
        <h1 class="text-h3 ml-3 mt-5 font-weight-medium text-center">Cпробуй FineTrading вже зараз!</h1>
        <h2 style="line-height: 1.2" class="mt-3 ml-3  mb-5 font-weight-light text-center">Спробуй безкоштовно free-trial period на 30 днiв без кредитноi картки </h2>
        <v-btn outlined  elevation="0" large rounded  color="blue darken-2" class="mb-10 align-center text-center">Розпочати</v-btn>

        <v-row class="pa-16 pt-6">
          <v-col cols="3">
            <v-card class="rounded-0" elevation="0">
              <v-sheet flat color="green lighten-2" class="pa-6 text-center" style="color:white"><h2 class="font-weight-light">Безкоштовний</h2></v-sheet>

              <div class="text-center pa-6">
                <h1 class="text-h2 text-center pa-6">$0</h1>
                <h4 class="mb-6">Загалом 0$ на рік</h4>
                <div class="caption text-secondary">Чудовй початок для знайомства із перевагамии FineTrading</div>
                <v-simple-table dense class="mt-9 text-left">
                  <tbody>
                  <tr> <td><v-icon small class="mr-2">mdi-information-outline</v-icon>1 бизнес профиль </td> </tr>
                  <tr> <td><v-icon small class="mr-2">mdi-information-outline</v-icon>20 товаров </td> </tr>
                  <tr> <td><v-icon small class="mr-2">mdi-information-outline</v-icon>Преимущество </td> </tr>
                  </tbody>
                </v-simple-table>
                <v-btn color="primary " outlined class="text-center mt-9 ma-6 justify-center" > Розпочати</v-btn>

              </div>

            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card class="rounded-0" elevation="0">
              <v-sheet flat color="primary lighten-2" class="pa-6 text-center" style="color:white"><h2 class="font-weight-light">Базовий</h2></v-sheet>

              <div class="text-center pa-6">
                <h1 class="text-h2 text-center pa-6">$8</h1>
                <h4 class="mb-6">Загалом 96$ на рік</h4>
                <div class="caption text-secondary">Чудовй початок для знайомства із перевагамии FineTrading</div>
                <v-simple-table dense class="mt-9 text-left">
                  <tbody>
                  <tr> <td><v-icon small class="mr-2">mdi-information-outline</v-icon>1 бизнес профиль </td> </tr>
                  <tr> <td><v-icon small class="mr-2">mdi-information-outline</v-icon>Повна інтеграція Чат Ботів </td> </tr>
                  <tr> <td><v-icon small class="mr-2">mdi-information-outline</v-icon>Перевага </td> </tr>
                  </tbody>
                </v-simple-table>
                <v-btn color="primary " outlined class="text-center mt-9 ma-6 justify-center" > Розпочати</v-btn>

              </div>

            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card class="rounded-0" elevation="0" >
              <v-sheet flat color="primary lighten-1" class="pa-6 text-center" style="color:white"><h2 class="font-weight-light">Стандарт</h2></v-sheet>

              <div class="text-center pa-6">
                <h1 class="text-h2 text-center pa-6">$20</h1>
                <h4 class="mb-6">Загалом 0$ на рік</h4>
                <div class="caption text-secondary">Чудовй початок для знайомства із перевагамии FineTrading</div>
                <v-simple-table dense class="mt-9 text-left">
                  <tbody>
                  <tr> <td><v-icon small class="mr-2">mdi-information-outline</v-icon>1 бизнес профиль </td> </tr>
                  <tr> <td><v-icon small class="mr-2">mdi-information-outline</v-icon>20 товаров </td> </tr>
                  <tr> <td><v-icon small class="mr-2">mdi-information-outline</v-icon>Преимущество </td> </tr>
                  </tbody>
                </v-simple-table>
                <v-btn color="primary " outlined class="text-center mt-9 ma-6 justify-center" > Розпочати</v-btn>

              </div>

            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card class="rounded-0 v-badge--bordered" elevation="0" >
              <v-sheet flat color="indigo lighten-2" class="pa-6 text-center" style="color:white"><h2 class="font-weight-light">Безкоштовний</h2></v-sheet>

              <div class="text-center pa-6">
                <h1 class="text-h2 text-center pa-6">$0</h1>
                <h4 class="mb-6">Загалом 0$ на рік</h4>
                <div class="caption text-secondary">Чудовй початок для знайомства із перевагамии FineTrading</div>
                <v-simple-table dense class="mt-9 text-left">
                  <tbody>
                  <tr> <td><v-icon small class="mr-2">mdi-information-outline</v-icon>1 бизнес профиль </td> </tr>
                  <tr> <td><v-icon small class="mr-2">mdi-information-outline</v-icon>20 товаров </td> </tr>
                  <tr> <td><v-icon small class="mr-2">mdi-information-outline</v-icon>Преимущество </td> </tr>
                  </tbody>
                </v-simple-table>
                <v-btn color="primary " outlined class="text-center mt-9 ma-6 justify-center" > Розпочати</v-btn>

              </div>

            </v-card>
          </v-col>


        </v-row>
      </v-sheet>



    </div>




  </div>
</template>

<script>


import {mapActions} from "vuex";

export default {
  name: "HomeMain",
  components : {
  },
  data: ()=> ({
    stickyMenu : false,
    loading : false,
    leadDialog : false,
    specialtyWindow : false,
    specialties : [],
    specialty : [],
    selectedSpecialty : null,
    specialtyNav : 0,
    disciplines : null,
    newsSlidePosition : 0,
    news : [],
    specialtyTeachers : [],
    teachers : [],
    specialtiesSelect : [],
    application : {},
    applicationCheck : {},
    errorEmail : null,
    errorPhone : null,
    errorName : null,
    mobileMenu : false,
    next : false,
    degree : 1,
    degree_search : null,

    degreeSelect : [
      { text : 'Коледж', value : 'jsp'},
      { text : 'Бакалавріат', value : 'bsc'},
      { text : 'Магістратура', value : 'msc'},
      { text : 'PhD', value : 'phd'},
    ],

    menu : {
      information : [
        { text : 'Історія університету', route : '/'},
        { text : 'Керівництво', route : '/'},
        { text : 'Ректорат', route : '/'},
        { text : 'Вчена Рада універсиитету', route : '/'},
        { text : 'Статут', route : '/'},
        { text : 'Ліцензування та акредитація', route : '/'},
        { text : 'Реєстр нрмативної бази', route : '/'},
        { text : 'Доступ до публічної інформації', route : '/'},
        { text : 'Імплементація закону "Про вищу освіту"', route : '/'},
        { text : 'Публічні закупівлі', route : '/'},
        { text : 'Вибори ректора', route : '/'},
        { text : '3D тур', route : '/'},
        { text : 'Фотогалерея', route : '/'},
      ]
    },

    activities : [
      { title: 'Спеціальності',icon:'mdi-notebook-multiple',
        content: 'Обирайте свою спеціальність та не зволікайте із подачею заявкии на навчання'},
      { title: 'Наука',icon:'mdi-cog',
        content: 'Обирайте свою спеціальність та не зволікайте із подачею заявкии на навчання'},
      { title: 'Міжнародне співробітництво',icon:'mdi-earth',
        content: 'Обирайте свою спеціальність та не зволікайте із подачею заявкии на навчання'},
      { title: 'Cтудрада',icon:'mdi-account-multiple',
        content: 'Обирайте свою спеціальність та не зволікайте із подачею заявкии на навчання'},
      { title: 'Бібліотека',icon:'mdi-library',
        content: 'Обирайте свою спеціальність та не зволікайте із подачею заявкии на навчання'},
      { title: 'WeStudy', icon:'mdi-school',
        content: 'Електронна навчальна платформа диистанційної та доповненої освіти'},

    ],
    fine_functions : [
      {name: 'Персональнй Веб-Сайт із сучасним диизайном персоналізованй під ваші виимоги',details:'Vue.js powered  Web Application'},
      {name: 'Мобільний додаток Вашого бізнесу',details:'iOS, Android'},
      {name: 'Інтеграція оповіщень та чат боти у популярних месенджерах',details:'Telegram, Viber'},
      {name: 'Електронна система завмовлень - Корзна замовлень',details:'Web, App'},
      {name: 'Доставка замовлень: Інтеграція із сервісами доставки',details:'Нова Пошта, Web'},
      {name: 'Інтеграція із платіжним системам идля оплати товарів',details:'Liqpay, Privat24,Favro'},
      {name: 'QR код генератор',details:'Web, App'},
    ],

    benefits : [
      {visible:false, title: 'Персональний Сайт та лендінг пейдж бізнесу',
        content: 'Персональний Сайт та лендінг пейдж бізнесу'},
      {visible:false,title: 'Електронна система замовлень та оплати',
        content: 'Електронна система замовлень та оплати'},
      {visible:false,title: 'Чат бот у всіх популярних соц мережах',
        content: 'Чат бот у всіх популярних соц мережах'},
      {visible:false,title: 'Персональні мообільні додатки для вашого бізнесу',
        content: 'Персональні мообільні додатки для вашого бізнесу'},
      {visible:false,title: 'Персональна система зв\'язку та реклами із вашими клієнтами',
        content: 'Персональна система зв\'язку та реклами із вашими клієнтами'},
    ],
    reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ ,

  }),
  computed : {
    specialtiesSelect_filtered() {
      var spec = this.specialtiesSelect;
      if(this.application.degree) {
        spec = spec.filter(el => el[this.application.degree] === 1)
      }
      return spec
    },
    specialties_filtered() {
      var spec = this.specialties;
      var degr = null;
      if (this.degree) {
        switch(this.degree) {
          case 0 : degr = 'bsc' ; break;
          case 1 : degr = 'bsc' ; break;
          case 2 : degr = 'msc' ; break;
          case 3 : degr = 'phd' ; break;
        }
        spec = spec.filter(el => el[degr] === 1)
      }
      if (this.degree_search) {
        spec = spec.filter(el => el.name.toLowerCase().includes(this.degree_search.toLowerCase()) === true)
      }
      return spec
    },
  },
  watch : {
    'application.email'() {
      this.errorEmail = null
    },
    'application.telephone'() {
      this.errorPhone = null
    },
    'application.name'() {
      this.errorName = null
    },
    'window.top.scrollY'(val) {
      console.log(val)
    }
  },
  methods : {
    ...mapActions('public',  [ 'INIT_PUBLIC','NEW_LEAD' ]),
    newLead(){

      var check = true;
      if (!this.reg.test(this.application.email)) {check = false; this.errorEmail = ['Введено не вірну електронну адресу'] }
      if (!this.application.telephone) { check = false; this.errorPhone = ['Введіть номер телефону']  }
      if (!this.application.email) { check = false; this.errorEmail = ['Введіть Вашу пошту']  }
      if (!this.application.name) { check = false; this.errorName = ["Введіть Ваше ім'я"]  }


      if (check) {
        this.loading = true
        this.NEW_LEAD(this.application).then((res)=>{
          console.log(res)
          this.applicationCheck = this.application
          this.application = {}
          this.errorEmail = null
          this.errorPhone = null
          this.loading = false
          this.leadDialog = true
        }).catch((error)=>{this.loading = false; console.log(error)})
      }

    },
    showSpecialty(uuid){
      this.specialtyWindow = true
      this.selectedSpecialty = uuid
    },

    newsSlideStep(val){
      var pos = 0;
      switch(val) {
        case 'next' :
          if(this.$refs.newsWindow.scrollTop<520)  {
            pos = this.$refs.newsWindow.scrollTop+110 }
          break;
        case 'back' :
          if(this.$refs.newsWindow.scrollTop>520)  {
          pos = this.$refs.newsWindow.scrollTop }  break;
      }

      this.$vuetify.goTo(pos,
          {
            duration: 300,
            offset: 0,
            easing: 'easeInOutQuad',
            container: this.$refs.newsWindow
          })
    },
    scrolling(){
      if (window.top.scrollY > 44) { this.stickyMenu = true} else { this.stickyMenu = false}
    },
    scroll(target) {
      this.$vuetify.goTo(this.$refs[target],
          {
            duration: 300,
            offset: 70,
            easing: 'easeInOutQuad',
          })
    },


  },
  async mounted() {
    document.onreadystatechange = () => {
      console.log(document.readyState);
      if (document.readyState == "complete") {

        // fetch to next page or some code
      }
    }

  }

}
</script>


<style lang="scss" scoped>
tbody {
  tr:hover {
    background-color: #00000033 !important;
  }
}

.programs_img {
  background: url('~@/assets/img/fine2.jpg');
  background-size: cover;
}


@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

.main_section {
  width:1100px;
}

#app .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1)
}

#app .dropdown li:last-child {
  border-bottom: none;
}

#app .dropdown li a {
  padding: 10px 20px;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 1.25em;
}

#app .dropdown li a .fa {
  padding-right: 0.5em;
}




@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
.expand-transition-enter-active,
.expand-transition-leave-active {
  transition-duration: 1.2s
}
.active_benefit .benefit_header {
  background-color: #459ad2 !important;
  color:white !important;
  border : 1px solid #459ad2;
}
.benefit_header {
  color:rgba(0, 0, 0, 0.7) !important;
  border :  1px solid #dddddd;
  transition: all .2s ease;
}
.benefit_header_active {
  background-color: #459ad2;
  color:white !important;
  border :  1px solid #459ad2;
}
.benefit_button_active  {
  transform: rotate(45deg);
}
.benefit_header:hover{
  background-color: #459ad2;
  color:white !important;
  border :  1px solid #459ad2;
  cursor: pointer;
}
.benefit_header:hover i{
  color:white !important;
}
.specialtySearch {
  transform: scale(0.69);
  transform-origin: center;
  border:3px solid #dddddd;
  width:100px;
}
.sideNews  {
  text-decoration: none;
  line-height: 1.3 ;
  font-size: 1rem;
  font-weight: 400;

  font-family: sans-serif;
  color: #393939;
}


</style>