<template>
  <div style="width: 600px">
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex align-center  mb-4">
        <v-btn icon @click="$emit('close')"><v-icon>mdi-chevron-left</v-icon></v-btn>
        <h1 class="text-h4 font-weight-light">
          Редагувати дозволи
        </h1>
      </div>
      <v-btn @click="saveChanges" rounded dark class="noCaps" color="teal" elevation="1">Зберегти</v-btn>
    </div>


    <h3 class="mb-6">
      <span class="font-weight-light">Manager:</span>
      {{ manager.firstname + ' ' + manager.lastname }}</h3>

    <div v-for="(module,i) in modules" :key="i">
      <div class="d-flex justify-space-between align-center">
        <h3 class="">{{ module.text }}</h3>
        <v-switch color="teal" v-model="activePermissions[module.value]" dense hide-details />
      </div>

      <div v-for="(modulePermission,key) in permissionModules[module.value]" :key="key"
           class="pa-3">

        <div class="d-flex  align-center mt-4">
          <h4 class="font-weight-light">{{ key }} : </h4>
          <v-select v-if="modulePermission.params.type === 'select'"
                    v-model="userPermissionModules[modulePermission.module][modulePermission.name]"

                    :items="modulePermission.params.selectItems"
                    :label="key"
                    class="compact-form"
                    rounded filled dense hide-details />
          <v-switch v-if="modulePermission.params.type === 'switch'"
                    v-model="userPermissionModules[modulePermission.module][modulePermission.name]"
                    class="compact-form"
                    rounded filled dense hide-details />
        </div>




      </div>

      <v-divider />

    </div>





<!--    <v-select label="Склади" rounded filled v-if="manager.roles.includes('MODULE_WAREHOUSE')"-->
<!--              :items="wareSelect" v-model="manager.warehouse" />-->


  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "manageManagerPermissions",
  props : [ 'value', 'manager','permissions'],
  data() {
    return {
      loading : false ,

      activePermissions : {
        MODULE_TEMPLATER: false,
        MODULE_NEWS: false,
        MODULE_PRODUCTS: false,
        MODULE_WAREHOUSE: false,
        MODULE_DELIVERY: false,
        MODULE_STATISTICS: false
      },
      permissionModules : {
        MODULE_TEMPLATER  : {},
        MODULE_NEWS       : {},
        MODULE_PRODUCTS   : {},
        MODULE_WAREHOUSE  : {},
        MODULE_DELIVERY   : {},
        MODULE_STATISTICS : {}
      },
      userPermissionModules : {

      },

      modules: [
        {text: 'Шаблони',  value: 'MODULE_TEMPLATER'},
        {text: 'Новини',   value: 'MODULE_NEWS'},
        {text: 'Товари',   value: 'MODULE_PRODUCTS'},
        {text: 'Склад',     value: 'MODULE_WAREHOUSE'},
        {text: 'Доставка',   value: 'MODULE_DELIVERY'},
        {text: 'Замовлення', value: 'MODULE_ORDERS'},
        {text: 'Cтатистика', value: 'MODULE_STATISTICS'}
      ]

    }
  },
  methods : {
    ...mapActions( 'business', [ 'EDIT_MANAGER_PERMISSIONS' ] ),

    saveChanges() {
      this.notify('Save changes')
      let permissions = []
      let modulePermissions = []
      Object.keys(this.userPermissionModules).forEach((module)=>{

        Object.keys(this.userPermissionModules[module]).forEach((name)=>{
          modulePermissions.push({
          module : module,
          name   : name,
          value  : this.userPermissionModules[module][name]})
        })
      })

      Object.keys(this.activePermissions).forEach((key)=>{
        if ( this.activePermissions[key]) {  permissions.push(key) }
      })

      permissions = permissions.length > 0 ? permissions : 'none'
      this.loading = true
      this.EDIT_MANAGER_PERMISSIONS({
        user : this.manager.id,
        permissions:permissions,
        modulePermissions : modulePermissions
      }).then((out)=>{
          if ( out.result ) {
            permissions = permissions === 'none' ? [] : permissions
            this.$emit('input',permissions)
          }
        this.loading = false
      }).catch(()=>{ this.loading = false })
    },
  },
  mounted() {
    if ( this.manager ) {
      this.manager.permissions.forEach((permission)=>{
        this.activePermissions[permission] = true
      })
    }
    if ( this.permissions ) {
      this.permissions.forEach((permission)=>{
        this.userPermissionModules[permission.module] = {
          [permission.name] : null
        }


        if ( !this.permissionModules['MODULE_' + permission.module.toUpperCase() ] ) {
          this.permissionModules['MODULE_' + permission.module.toUpperCase() ] = {
            [permission.name] :
                { module : permission.module,
                  name   : permission.name,
                  params : permission.params,
                }
          }
        } else {
          this.permissionModules['MODULE_' + permission.module.toUpperCase() ][permission.name] = {
            module : permission.module,
            name   : permission.name,
            params : permission.params,
          }
        }




      })
      this.userPermissionModules = Object.assign({},this.userPermissionModules)

    }
    if ( this.manager.modulesPermissions ) {
      this.manager.modulesPermissions.forEach((permission)=>{
        this.userPermissionModules[permission.module][permission.permission] = permission.value
      })

      this.userPermissionModules = Object.assign({},this.userPermissionModules)
    }


  }

}
</script>

<style scoped>

</style>