<template>
<div>
  <v-sheet color="grey lighten-2"  class="d-flex justify-center  pa-6">
   <div class="main_section d-flex justify-space-between align-center">
     <h2 class="bitter font-weight-medium">Новини Університету</h2>
     <h4 class="caption font-weight-light" style="font-family: sans-serif">ГОЛОВНА \ НОВИНИ</h4>
   </div>

  </v-sheet>

  <v-sheet class="d-flex justify-center pa-16">
    <div class="main_section">
      <v-row>
        <v-col cols="4">YJDCNB</v-col>
        <v-col cols="4">YJDCNB</v-col>
        <v-col cols="4">YJDCNB</v-col>
      </v-row>
    </div>
  </v-sheet>
</div>
</template>

<script>
export default {
  name: "HomeNews"
}
</script>

<style lang="scss" scoped>
.main_section {
  width:1100px
}
</style>