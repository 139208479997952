<template>
  <v-sheet>
    <div class="d-flex justify-space-between align-center">
      <h4>Склад : </h4>
      <div class="d-flex align-center">
        <ftSelect v-model="filter.warehouse" :items="warehouses" >
          <template #default="item">
            <v-btn
                :dark="filter.warehouse !== ''"
                :outlined="!filter.warehouse"
                :color="filter.warehouse ? 'primary lighten-2' : 'grey darken-3' "
                small rounded class="noCaps mr-2" >
              <v-icon small>mdi-cash</v-icon>
              {{filter.warehouse ?  item.text : 'Склад' }}
            </v-btn>
          </template>


        </ftSelect>
        <v-btn small @click="$emit('delete')" icon><v-icon small>mdi-delete</v-icon></v-btn>
      </div>
    </div>



    <v-simple-table dense
        class="mb-8 mt-4" >
      <thead>
      <tr>
        <th>Товар</th>
        <th>Фактична наявність </th>
        <th>Із бронюванням</th>
        <th>Заброньовано</th>
      </tr>
      </thead>
      <tbody >


      <tr v-for="(product,i) in products" :key="i" >
        <td class=" py-1"  >
          <v-avatar class="mr-2"
                    size="24"  >
            <v-img :src="product.img_small" ratio="1" />
          </v-avatar>
          {{ product.name }}
        </td>
        <td  ><h4> {{ product.actual }} шт</h4> </td>
        <td  ><h4 class="font-weight-regular"> {{ product.quantity }} шт</h4> </td>
        <td  ><h4 v-if="product.booked !== 0" class="font-weight-regular"> {{ product.booked }} шт</h4> </td>
      </tr>
      </tbody>
    </v-simple-table>
  </v-sheet>
</template>

<script>
import ftSelect from "@/components/UI/ftSelect";
import {mapActions} from "vuex";
export default {
  name: "statisticsWarehouses",
  props : ['externalFilter','value'],
  components : {
    ftSelect
  },
  data() {
    return {
      products : [],
      warehouses : [],
      warehousesSelect : [ { text : 'Всі' , value : '' } ],
      filter : {
        date : [],
        warehouse : ''
      }
    }
  },
  watch: {
    externalFilter :{

      handler(filter) {
        if ( filter ) {
          Object.keys(filter).forEach((key)=>{
            this.filter[key] = filter[key]
          })
          this.filter = Object.assign({},this.filter)
        }
      },
      deep : true

    },
    filter :  {
      handler(filter) {
        if ( filter ) {
          this.mutateFilter()
        }
      },
      deep : true

    }
  },
  methods : {
    ...mapActions('statistics', ['GET_WAREHOUSES_STATISTICS']),
    getData() {
      this.loading = true

      this.GET_WAREHOUSES_STATISTICS({filter: this.filter}).then((out) => {
        if (out.data) {
          this.products = out.data.products
          this.warehouses = out.data.warehouses
          this.warehouses.unshift({ text : 'Всі' , value : '' })
          this.loading = false;
        }
      }).catch(() => {
        this.loading = false
        this.notify('Network error')
      })
    },
  },
  mounted() {

    if ( this.value )  {
      this.filter = this.value
    }

    this.getData()
    if (this.date ) {
      this.filter.date = this.date
    }
  }
}
</script>

<style scoped>

</style>