<template>
  <div>

    <p v-html="message" class="text-h6 mt-10 text-center font-weight-light"> </p>

    <div class="d-flex justify-center">
      <v-progress-circular v-if="loader" class=" justify-center" indeterminate size="48" />
    </div>


    <form @submit.prevent="submit" class="compact-form">

      <v-text-field v-if="step == 1" type="text" required v-model="email" :error-messages="errorEmail" label="Your email"></v-text-field>
      <v-text-field v-if="step == 2" type="text" required v-model="resetCode" :error-messages="errorCode" label="Password Reset Code"></v-text-field>
      <v-text-field v-if="step == 3" type="password" required v-model="password" :error-messages="errorPassword" label="New Password"></v-text-field>
      <v-text-field v-if="step == 3" type="password" required v-model="password2" :error-messages="errorPassword" label="New Password again"></v-text-field>


      <div class="butons d-flex mt-10" :class="hideCancel ? 'justify-center' : 'justify-space-between' ">

        <v-btn :disabled="loader"  v-if="!hideCancel" large type="button" @click="exit"  color="accent" class="rounded-0">Cancel</v-btn>
        <v-btn :disabled="loader"    large type="submit" color="primary" class="rounded-0">{{ buttonText }}</v-btn>

      </div>

    </form>

  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "passwordReset",
  data(){
    return {

      change_token : '',
      step : 1,

      hideCancel : false,


      message: 'To reset your password please enter your email',
      buttonText : 'Send Confirmation',

      requestResponse : '',

      email : '',
      resetCode : '',
      password : '',
      password2 : '',
      errorPassword : [],
      errorEmail : [],
      errorCode : [],

      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ ,

    }
  },
  methods : {
    ...mapMutations('auth',['TOGGLE_AUTH_MODAL','FLUSH_CHANGE_PASSWORD']),
    ...mapActions('auth',['REQUEST_PASSWORD','REQUEST_CHANGE_CONFIRM','CHANGE_PASSWORD']),
    async submit() {

      switch(this.step) {

        case 1 :
          if(this.reg.test(this.email)) {

            this.buttonText = 'Next';
            this.message = 'Requesting password change'

            this.requestResponse = await this.REQUEST_PASSWORD({email:this.email});

            if (this.requestResponse.result) {
              this.message = 'Reset code was sent on your email. Enter the reset code'
              this.step++;
            } else {
              this.message = this.requestResponse.error
            }

          } else {
            this.errorEmail = ['Enter proper email'];
          }
          break;

        case 2 :
          this.message = 'Checking reset code'
          this.requestResponse = await this.REQUEST_CHANGE_CONFIRM({code: this.resetCode})

            if (this.requestResponse.result) {
              this.message = 'Enter your new password'
              this.step++;
              this.change_token = this.requestResponse.change_token;
            } else {
              this.message = this.requestResponse.error
              this.errorCode = [this.requestResponse.error];
            }

              break;
        case 3 :

          if (this.password === this.password2) {
            const request = await this.CHANGE_PASSWORD({code: this.change_token ,password: this.password})

            if (request.result) {
              this.step++;
              this.message = 'Password changed'
              this.hideCancel = true;
            }

          } else {
            this.errorPassword = ['Confirmation password doesnt match']
          }
              break;

        case 4 :
          this.exit();
            break ;
      }
    },
    exit() {
        this.TOGGLE_AUTH_MODAL();
        this.FLUSH_CHANGE_PASSWORD();
    }
  },
  computed : {
    ...mapState('auth',['modalAuth','loader','changePasswordMailReady','changePasswordMailResult']),
    refresh() {
      return this.modalAuth
    },
    emailResult() {
      return this.changePasswordMailReady
    }
  },
  watch : {
    refresh() {
      this.step = 1;

      this.message= 'To reset your password please enter your email';
      this.buttonText = 'Send Confirmation';

      this.email = '';
      this.resetCode = ''
      this.password = '';
      this.password2 = '';
      this.errorPassword = [];
      this.errorEmail = [];


    },
    email() {
      this.errorEmail = [];
    },
    password() {
      this.errorPassword = [];
    },
    resetCode() {
      this.errorCode = [];
    },
    emailResult() {
      if (this.changePasswordMailResult) {
        this.message = "Confirmation email has been sent to: <b>"+this.email+".</b><br> Please enter access code from email to change your password"
        this.step = 2;
      } else {
        this.message = "No user found with email: <b>"+this.email ;
      }
    }
  }
}
</script>

<style scoped>

</style>