<template>
  <div style="margin-top: -144px"  >

    <v-fade-transition>
      <loadingTemplate v-if="loading" />
    </v-fade-transition>

    <v-sheet min-height="600"
             :style="`background: url(${parametr('intro_bg')});
                      background-attachment: fixed ;
                      background-size : cover;`">
      <v-sheet min-height="600" class="fill-height  d-flex  pt-16" color="#000000bb">

        <div class="section-content mt-16 py-16">

          <v-row>
            <v-col cols="8">

              <v-sheet color="#44444444" class="pa-8">

                <div v-if="article !== {}">
                  <div class="d-flex align-center">
                    <h3 style="letter-spacing: 2px" class="font-weight-medium">НОВИНИ</h3>
                    <div class="ml-6" style="width: 150px"><v-divider /><v-divider /></div>
                  </div>

                  <h1 class="mt-3 mb-8" :style="accentColor" style="line-height: 1.2">{{  article.title  }}</h1>
                  <imago :src="article.image" > </imago>
                  <div class="mt-8" v-html="article.content"></div>
                </div>
                <div v-else>
                  <v-skeleton-loader type="text" />
                </div>
              </v-sheet>

            </v-col>
            <v-col cols="4" class="pl-8">
              <h2 :style="accentColor">НОВИНИ</h2>
              <div v-for="(item,i) in news" :key="i" class="mt-3">
                <span class="caption">{{  item.date  }}</span>
                <v-divider />
                <router-link :to="`/${businessAlias}/news/${item.alias}`"
                              style="color:white; text-decoration: none">
                  <h4>{{ item.title }}</h4>
                </router-link>
              </div>


              <h2 class="mb-3 mt-8" :style="accentColor" >ПІДПИСУЙСЯ</h2>
              <div class="d-flex">
                <v-icon class="mr-2">mdi-telegram</v-icon> Telegram
              </div>
              <div class="d-flex mt-2">
                <v-icon class="mr-2">mdi-instagram</v-icon> Instagram
              </div>
              <div class="d-flex mt-2">
                <v-icon class="mr-2">mdi-facebook</v-icon> Facebook
              </div>
            </v-col>

          </v-row>
        </div>

      </v-sheet>

    </v-sheet>
  </div>
</template>

<script>
import imago from "@/components/UI/imago";
import {mapActions, mapState} from "vuex";
import loadingTemplate from "@/components/templates/loadingTemplate";

export default {
  name: "news",
  props : ['alias','newsAlias'],
  components : {
    loadingTemplate,
    imago
  },
  data() {
    return {
      loading : true,
      article : {}
    }
  },
  computed : {
    ...mapState('templates',  [ 'news', 'businessAlias', 'editorMode', 'templateVars_init', 'templateVars_current'  ]),
    accentColor() {
      return `color: ${this.parametr('accent_color')}`
    }
  },
  watch : {
    newsAlias() {
      this.loadArticle()
    }
  },
  methods : {
    ...mapActions('business', [ 'GET_NEWS']),

    loadArticle() {
      this.loading = true;
      this.GET_NEWS({business:this.alias, news:this.newsAlias}).then((out)=>{
        this.article =  out.data;
        this.$store.commit('REFRESH');
        this.loading = false;
      }).catch(()=>{ this.loading = false;})
    },

    // techical
    parametr(varName) {
      let param = '';
      if (  this.editorMode ) { param = this.templateVars_current[varName].value }
      if ( !this.editorMode ) { param = this.templateVars_init[varName]    }
      return param
    },

 },
  mounted() {
   this.loadArticle()
  }
}
</script>

<style scoped>
.section-content {
  max-width   : 1100px;
  width       : 100%;
  margin      : auto;
}
</style>