<template>

    <v-dialog @click:outside="exit" :persistent="persistent" class="z-index:999000"
              transition="dialog-bottom-transition" max-width="600" v-model="modalAuth"  >
      <v-card >
        <v-toolbar height="150px" :color="color" dark class="d-flex  justify-center" >
          <v-icon  class="text-h1">{{ icon }}</v-icon>
        </v-toolbar>
        <v-card-text>
          <p class="text-h5 mt-10 text-center"> {{ message }} </p>
          <passwordReset v-if="showPasswordReset"/>
          <activateAccount v-if="showActivateAccount" />
        </v-card-text>
      </v-card>
    </v-dialog>

</template>

<script>
import {mapMutations, mapState} from "vuex";
import passwordReset from "@/components/auth/modal_content/passwordReset";
import activateAccount from "@/components/auth/modal_content/activateAccount";

export default {
  name: "authModal",
  components : {
    passwordReset,
    activateAccount
  },
  data() {
    return {
      color: '',
      message: '',
      icon : '',
      persistent : true,

      // content types
      showPasswordReset : false,
      showActivateAccount : false,
    }
  },
  watch : {
    refresh() {
      this.flushContent();
      switch(this.modalType) {
        case 'AUTH_REGISTER_CONFIRM' :
          this.color = 'success';
          this.message = 'Confirmation email has been sent on your address. Please follow the instructions in the email';
          this.icon = 'mdi-check-circle-outline';
          this.persistent = false;
          break;
        case 'AUTH_CONFIRM_INFO' :
          this.showActivateAccount = true;
          this.color = 'primary';
          this.message = '';
          this.icon = 'mdi-check-circle-outline';
          this.persistent = true;
          break;
        case 'AUTH_PASSWORD_RESET' :
            this.showPasswordReset = true;
            this.color = 'primary';
            this.icon = 'mdi-check-circle-outline';
            this.persistent = true;
            break;

      }
    }
  },
  computed : {
    ...mapState('auth',['modalAuth','modalType']),
    refresh() {
      return this.modalType
    },
  },
  methods : {
    ...mapMutations('auth',['TOGGLE_AUTH_MODAL','FLUSH_CHANGE_PASSWORD']),
    flushContent() {
      this.color = '';
      this.message = '';
      this.icon = '';
      this.showPasswordReset = false;
      this.showActivateAccount = false;
      this.persistent = true;
    },
    exit(){
      if (!this.persistent) {
        this.FLUSH_CHANGE_PASSWORD();
        this.TOGGLE_AUTH_MODAL();
      }
    }
  }
}
</script>

<style>
.butons {
  margin-right: -45px;
  margin-left : -45px;
}
</style>