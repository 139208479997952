<template>
  <div class="bitter" >

    <v-fade-transition>
      <loadingTemplate v-if="pageLoading" />
    </v-fade-transition>

    <authModal />
    <login-side-bar/>
    <checkoutMenu v-model="$store.state.cart.showCheckout" />
    <cartSideBar  v-model="$store.state.cart.showCart"  />

    <ftEditorHeader v-if="loggedIn && userBusinesses.includes(business)"/>


    <clearWaterHeader />


    <div >
      <router-view />
    </div>

    <avalonFooter />

  </div>
</template>

<script>

import loginSideBar from "@/components/pages/homepage/UI/loginSideBar";
import authModal from "@/components/auth/authModal";
import cartSideBar from "@/components/templates/Avalon/UI/cartSideBar";
import ftEditorHeader from "@/components/templates/editor/ftEditorHeader";
import clearWaterHeader from "@/components/templates/ClearWater/UI/clearWaterHeader";
import avalonFooter from "@/components/templates/Avalon/UI/avalonFooter";
import loadingTemplate from "@/components/templates/loadingTemplate";


import {mapState} from "vuex";

import checkoutMenu from "@/components/templates/Avalon/checkoutMenu";
// import imago from "@/components/UI/imago";
export default {
  name: "ClearWaterMain",
  props : ['business'],
  components : {

    loginSideBar,
    authModal,
    cartSideBar,
    checkoutMenu,
    loadingTemplate,

    ftEditorHeader,
    clearWaterHeader,
    avalonFooter

  },
  data(){
    return {

      pageLoading : true,

      displayEdit : true,

      //styles
      accentColor: '#b73737',

      //cart
      showCartMenu : false,

      vars : {},

      // menu vars
      menuData: [],
      menuParent : null,
      menuItems : []
    }
  },
  computed : {
    ...mapState('templates',  [  'editorMode', 'templateVars_init', 'templateVars_current'  ]),
    ...mapState('auth',['loggedIn','userBusinesses']),
  },
  methods : {

    buttonAction(action) {
      switch (action) {
        case 'gotoProducts'    : alert('I will slide to products'); break;
        case 'gotoReservation' : alert('I will slide to Reservation'); break;
        default : console.log('no action')
      }
    },
    showCart(){
      this.showCartMenu = !this.showCartMenu
    },


    // technical

    scroll() {

      this.$vuetify.goTo(this.$refs['products'],
          {
            duration: 300,
            offset: 70,
            easing: 'easeInOutQuad',
          })
    },
  },
  mounted() {
    this.pageLoading = false
  }


}
</script>

<style scoped>

.section-content {
  max-width   : 1100px;
  width       : 100%;
  margin      : auto;
}

.sideEditor {
  height     : 100%;
  position   : fixed;
  left: 0;
  width: 250px;
  z-index:10000;
}

.slider {
  height: 100vh !important;
  position: relative;
}

.slider-content {
  background-color: #00000055;
  position: absolute;
  top:109px;
  bottom: 0;
  right: 0;
  left:0
}

.slider-content-inner {
  min-width: 90%;
  max-width: 1000px
}

</style>