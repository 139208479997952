<template>
  <div class="pa-6">

    <div class="d-flex justify-space-between mb-4">
      <h1 class="text-h4 font-weight-light">Налаштування профілю</h1>
    </div>
    <v-row class="mt-16">
      <v-col cols="12" md="6" lg="4" xl="4">

        <v-sheet >

          <!-- avatar  -->
          <div  class="d-flex justify-space-between align-center mb-3">
            <h4 class="font-weight-medium">Зображення:</h4>
            <v-btn small class="noCaps"  icon  @click="avatarButton">
              <v-icon small>
                {{ showUploadImage ? 'mdi-close' : 'mdi-pencil'}}
              </v-icon>
            </v-btn>
          </div>

          <v-fade-transition mode="out-in">

            <div v-if="!showUploadAvatar" class=" text-center mt-12 mb-5" >

              <v-avatar size="128" key="profile_picture" >
                <v-img :src="$store.state.filesPublic+'users/avatars/'+this.user.uuid" >
                  <template slot="placeholder">
                    <v-img src="@/assets/placeholder/user_blank.jpg" />
                  </template>
                </v-img>
              </v-avatar>

              <h4 class="mt-4" >{{ user.firstname }} {{ user.lastname }}</h4>
              <div class="caption">{{ user.email }}</div>
              <v-chip dark class="ma-2" color="teal">
                {{ getUserType($store.state.auth.userType) }}
              </v-chip>
            </div>
            <dropzone v-if="showUploadAvatar" @vdropzone-complete="afterUpload"
                      @vdropzone-sending="sendingPhoto"
                      key="change_picture" class="mb-5"
                      ref="myVueDropzone" id="dropzone" v-model="profilePhoto" :options="dropzoneOptions" />

          </v-fade-transition>

          <v-divider />

<!--          <div class="mt-6 d-flex  align-center justify-space-between">-->
<!--            <h4>Безпека</h4>-->
<!--            <v-btn icon @click="showSecurity = !showSecurity">-->
<!--              <v-icon >mdi-chevron-down</v-icon>-->
<!--            </v-btn>-->
<!--          </div>-->
<!--          <v-expand-transition>-->
<!--            <div v-if="showSecurity">-->

<!--              <div class="py-5">-->
<!--                <v-simple-table>-->
<!--                  <tbody>-->
<!--                  <tr>-->
<!--                    <td width="100px">Пароль</td>-->
<!--                    <td class="text-right"><v-btn text class="noCaps" color="teal" link>Змінити</v-btn></td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <td width="100px">Email</td>-->
<!--                    <td class="text-right"><v-btn  text class="noCaps" color="teal" link>Змінити</v-btn></td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <td width="100px">Телефон</td>-->
<!--                    <td class="text-right"><v-btn text class="noCaps" color="teal" link>Змінити</v-btn></td>-->
<!--                  </tr>-->
<!--                  </tbody>-->
<!--                </v-simple-table>-->
<!--              </div>-->

<!--            </div>-->
<!--          </v-expand-transition>-->

          <div class="mt-2 d-flex  align-center justify-space-between">
            <h4>Інтеграції</h4>
            <v-btn icon @click="showIntegrations = !showIntegrations">
              <v-icon  >mdi-chevron-down</v-icon>
            </v-btn>
          </div>
          <v-expand-transition>
            <div v-if="showIntegrations">

              <div class="py-5">

                <v-simple-table class="">
                  <tbody>
                  <tr>
                    <td style="border-bottom : none" width="100px">Telegram</td>
                    <td style="border-bottom : none" class="text-right" >
                      <v-btn v-if="!user.telegram" text class="noCaps" color="teal" link target="_blank"
                             :href="telegramBotLink" >
                        Telegram Bot
                      </v-btn>
                      <div v-else>
                        <v-btn @click="unlinkTelegram" small text class="noCaps">Від'єднати</v-btn>
                        <v-chip small color="success" dark>
                          <v-icon small class="mr-1">
                            mdi-check
                          </v-icon>
                          <b>Під'єднано</b>
                        </v-chip>
                      </div>

                    </td>
                  </tr>
                  <tr v-if="!user.telegram">
                    <td colspan="2" class="py-3" style="border-top : none">
                      Для активації перейдіть до Telegram бота та введіть ключ інтеграції ( <b>{{user.link_key}}</b> )
                    </td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </div>
          </v-expand-transition>

        </v-sheet>
      </v-col>
      <v-col class="pl-6" cols="12" md="6" lg="8" xl="8" >
        <v-sheet  >
          <div  class="d-flex justify-space-between align-center mb-3">
            <h4 class="font-weight-medium">Інформація профілю:</h4>
            <v-btn @click="saveChanges" rounded outlined   color="teal">
              <v-icon small class="mr-2">mdi-check</v-icon>
              Зберегти
            </v-btn>
          </div>

          <v-row class="ma-0 mt-8">
              <v-col cols="12" md="6" lg="6" xl="6">
                <v-text-field v-model="user.firstname"   :error-messages="errorFirstname" label="Ім'я"  rounded filled  /></v-col>
              <v-col cols="12" md="6" lg="6" xl="6"><v-text-field v-model="user.lastname"   :error-messages="errorLastname" label="Прізвище"  rounded filled    /></v-col>
              <v-col cols="12" md="6" lg="6" xl="6">
                <v-menu
                    ref="menu"
                    v-model="menuBirth"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        rounded filled
                        v-model="user.birthday"
                        label="Birthday date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        :error-messages="birthError"
                    />
                  </template>
                  <v-date-picker
                      ref="picker"
                      v-model="user.birthday"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @change="birthSave"
                  ></v-date-picker>
                </v-menu></v-col>
          </v-row>

<!--          <div class="d-flex justify-space-between">-->
<!--            <h4>Адреса</h4>-->
<!--            <v-btn small text color="teal" class="noCaps" @click="addAddress">-->
<!--              <v-icon small>mdi-plus</v-icon>-->
<!--              Додати адресу-->
<!--            </v-btn>-->
<!--          </div>-->
<!--          <div v-if="addresses.length > 0" class="mt-6">-->
<!--            <v-row v-for="(address,i) in addresses" :key="i">-->
<!--              <v-col cols="6">-->
<!--                <v-select-->
<!--                    @change="address.data.city = { text : '', value : null } "-->
<!--                    v-model="address.data.country"-->
<!--                    :items="['Ukraine','Lituania','Estonia']"-->
<!--                    rounded filled-->
<!--                    label="Країна"   />-->
<!--              </v-col>-->
<!--              <v-col cols="6">-->
<!--                <ftCitySelect v-if="address.data.country === 'Ukraine' "-->

<!--                              v-model="address.data.city" />-->
<!--                <v-text-field v-model="address.data.city.text" v-else rounded filled    label="Місто"  />-->
<!--              </v-col>-->
<!--              <v-col cols="6" >-->
<!--                <v-text-field v-model="address.data.address"   rounded filled  label="Адреса"   /></v-col>-->
<!--              <v-col cols="6" >-->
<!--                <v-text-field v-model="address.data.telephone" rounded filled label="Телефон"   /></v-col>-->
<!--            </v-row>-->
<!--          </div>-->
<!--          <div class="d-flex justify-space-between">-->
<!--            <h4>Нова Пошта</h4>-->
<!--            <v-btn small text color="teal" class="noCaps" @click="addNovaPoshta"><v-icon small>mdi-plus</v-icon>Додати відділення</v-btn>-->
<!--          </div>-->
<!--          <div class="mt-6" v-if="novaPoshta.length > 0">-->
<!--            <v-row v-for="(np,i) in novaPoshta" :key="i" >-->
<!--              <v-col cols="6">-->
<!--                <ftCitySelect v-model="np.data.city" />-->
<!--              </v-col>-->
<!--              <v-col cols="6">-->
<!--                <ftNovaPoshtaSelect v-model="np.data.warehouse" :city="np.data.city.value"  />-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </div>-->



        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
// import ftCitySelect from "@/components/UI/ftCitySelect";
// import ftNovaPoshtaSelect from "@/components/UI/ftNovaPoshtaSelect";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import {mapActions, mapState} from "vuex";
export default {
  name: "DashProfile",
  components : {
    dropzone: vue2Dropzone,
    // ftCitySelect,
    // ftNovaPoshtaSelect
  },
  props : ['user_id'],
  computed : {
    ...mapState('auth',['accessToken']),
    ...mapState('notifications',['notifications']),

    telegramBotLink(){
      let link = 'https://t.me/finetrading_bot'

      if ( this.$store.state.business.selectedBusiness.telegramAlias ) {
        link = this.$store.state.business.selectedBusiness.telegramAlias
      }
      if ( !this.$store.state.domain && !this.$route.params.alias ) {
        link = 'https://t.me/finetrading_bot'
      }

      return link
    },

    novaPoshta() {
      var locations = []

      if ( this.user.locations.length > 0) {
        locations = this.user.locations.filter( el => el.type === 'nova_poshta')
      }


      return locations
    },
    addresses() {
      var locations = []

      if ( this.user.locations.length > 0) {
        locations = this.user.locations.filter( el => el.type === 'address')
      }

      return locations
    }
  },
  watch: {
    notifications(){
      var notify = this.notifications[this.notifications.length-1]

      if (notify.type === 'link_telegram') {
        this.user.telegram = true
      }
    }
  },
  data() {
    return {

      city : '',

      loading : false,

      showSecurity     : false,
      showIntegrations : true,
      showUploadAvatar : false,

      //profile
      user : {
        id          : null,
        email       : null,
        firstname   : null,
        lastname    : null,
        phone       : null,
        birthday    : null,
        registered  : null,
        telegram    : null,
        link_key    : null,
        locations   : []
      },
      errorLastname : [],
      errorFirstname : [],
      birthError : [],
      menuBirth : false,

      //profile photo
      profilePhoto : null,
      dropzoneOptions: {
        url: this.$store.state.ajaxRoute+'upload/public',
        method: 'POST',
        maxFilesize: 1.5,
        headers: { "Auth": this.accessToken },
        addRemoveLinks : true,
        uploadMultiple : false,
        maxFiles : 1,
        thumbnailWidth: 150,
      },


    }
  },
  methods: {
    ...mapActions('user',['GET_PROFILE','EDIT_PROFILE','UNLINK_TELEGRAM']),

    saveChanges() {
      this.loading = true
      this.EDIT_PROFILE(this.user).then((out) => {
        console.log(out) ;
        if (out.result) {
          this.notify('Зміни збережено')
        }
        this.loading = false

      }).catch( () => this.loading = false )

    },

    //locations
    addNovaPoshta() {
      this.user.locations.push( {
        type : 'nova_poshta',
        data : {
          city : {
            text : '',
            value : '',
          },
          warehouse : {
            text : '',
            value : '',
          }
        }
      })
    },
    addAddress() {
      this.user.locations.push( {
        type : 'address',
        data : {
          country : '',
          city : {
            text : '',
            value : ''
          },
          address : '',
          telephone : '',
        }
      })
    },


    //upload and documents methods
    afterUpload() {
      this.$store.commit('REFRESH');
      this.showUploadAvatar = false;
    },
    sendingPhoto(file, xhr, formData) {
      formData.append('Folder','users/avatars');
      formData.append('Name',this.user.uuid);
    },


    // technical
    birthSave(date) {
      this.birthError = []
      this.$refs.menu.save(date)
    },
    avatarButton() {
      this.showUploadAvatar = !this.showUploadAvatar
    },
    getUserType(val) {
      switch(val) {
        case 'superadmin' : return 'Мастер Контроль'
        case 'user' : return 'Користувач'
        case 'businessman' : return 'Бізнесмен'
        case 4 : return 'Manager'
        case 5 : return 'Admin'
      }
    },


    //city select
    setCity(val) {
      this.city = val
    },

    //
    unlinkTelegram() {

      this.loading = true
      this.UNLINK_TELEGRAM().then((out)=>{
        if ( out.result) {
          this.$store.state.auth.user.telegram = false
          this.user.telegram = null
          this.notify("Телеграм акаунт від'єднано")

        }
        this.loading = false
      }).catch(()=> { this.loadinng = false })
    }

  },
  async mounted() {

    this.loading = true
    this.GET_PROFILE().then((out) => {

      this.user = out.result ? out.data : null
      if ( this.user.locations.length === 0 ) {
        this.addAddress()
      } else if ( this.user.locations.filter(el => el.type === 'address' ).length === 0 ) {
        this.addAddress()
      }
      this.loding = false

    }).catch( () => this.loding = false )

  }
}
</script>

<style scoped>
.form {
  margin : -25px !important;
}
.documents {
  border : 1px solid #dddddd;
  text-align: center;
  margin-bottom: 12px;
}
.uploaded {
  border-color : #cdeac1;
}
</style>
