<template>
  <div class="pa-16">
    <div class="d-flex justify-space-between align-center mb-16">
      <h1 class="text-h4 font-weight-light ">Налаштування способів оплати</h1>
      <v-btn @click="saveChanges" rounded outlined dark large  color="deep-purple">
        <v-icon class="mr-2">mdi-check</v-icon>Зберегти</v-btn>
    </div>

    <div style="max-width: 600px">
      <div v-for="(payment,i) in payments" :key="i" >

        <div class="d-flex  align-center justify-space-between">
          <div style="width : 250px">
            <div v-if="payment.id === 'online_liqpay'">
              <v-btn icon @click="liqpaySettings = !liqpaySettings">
                <v-icon  >mdi-chevron-down</v-icon>
              </v-btn>
              <span class="font-weight-medium">{{ payment.name }}</span>
            </div>
            <div v-else>
              <span class="font-weight-medium ml-8">{{ payment.name }}</span>
            </div>
            </div>


          <div>
            <div v-if="payment.id === 'online_liqpay'">
              <v-btn icon v-if="!liqPaySet" class="mr-8" @click="liqpaySettings = !liqpaySettings"><v-icon>mdi-pencil</v-icon></v-btn>
              <v-switch v-else color="teal" v-model="payment.value" class="mr-6" />
            </div>
            <v-switch v-else color="teal" v-model="payment.value" class="mr-6" />
          </div>

        </div>

        <div  v-if="payment.id === 'online_liqpay'" >
        <v-expand-transition>
          <div v-if="liqpaySettings" >
              <v-divider />


            <div v-if="liqPaySet">

              <div class="px-6 mt-5 mb-5 d-flex justify-space-between align-center">
                <v-subheader > <v-icon  class="mr-2" color="teal">mdi-information-outline</v-icon> Акаунт LiqPay підключено</v-subheader>
                <v-btn color="teal" dark  rounded @click="liqPaySet = false" > Змінити</v-btn>
              </div>

            </div>

            <div v-if="!liqPaySet">

              <div class="px-6 mt-5 d-flex justify-space-between align-center">

                <v-subheader>
                  <v-icon  class="mr-2" color="teal">mdi-information-outline</v-icon>
                  Інтеграція LiqPay
                </v-subheader>

                <v-btn color="teal" dark  rounded @click="linkLiqPay"> Активувати</v-btn>

              </div>

              <v-text-field v-model="liqpay.public_key"
                            :error-messages="liqpayError"
                            label="LiqPay Public Key"
                            class="mx-6 mt-8"
                            filled rounded  />
              <v-text-field v-model="liqpay.private_key"
                            :error-messages="liqpayError"
                            label="LiqPay Private Key"
                            class="mx-6 mb-3"
                            filled rounded  />
              <v-checkbox   v-model="liqPayConfirm"
                            :error-messages="liqpayConfirmError"
                            class="mx-6 mb-6"
                            dense  >
                <template v-slot:label>
                  <i class="caption" style="line-height: 1.2 !important">Я підтверджую, що в моєму LiqPay акаунті не підключено додаткові функції проведення зовнішніх платежів
                  </i>
                </template>
                <template v-slot:append>
                  <v-tooltip top max-width="600"  >
                    <template v-slot:activator="{ on, attrs }" class="d-flex align-center">
                      <v-btn icon v-bind="attrs" v-on="on" >
                        <v-icon color="grey lighten-1"> mdi-information </v-icon>
                      </v-btn>
                    </template>
                    <div class="pa-3" >В стандартному акаунті LiqPay відключено функцію зовнішніх платежів через API, тому користуючись API ключами можливо тільки виставляти рахунки клієнтам. Для безпеки вашого рахунку, Fine Trading рекомендує використовувати API ключі без можливості зовнішніх платежів. У разі якщо ви користуєтесь додатковим API LiqPay, в якому є можливість здійснення зовнішніх платежів, рекомендуємо створити додатковий LiqPay акаунт без таких функцій, та використовувати його з Fine Trading</div>
                  </v-tooltip>
                </template>
              </v-checkbox>

            </div>

            <v-divider />

          </div>

        </v-expand-transition>

        </div>


      </div>
    </div>



  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "BusinessDashPayments",
  data(){
    return {

      loading : false,

      payments : [],
      businessPayment : [],

      showLiqSettings    : false,
      liqpaySettings     : false,
      liqPaySet          : false,
      liqpay : {
        public_key       : '',
        private_key      : ''
      },
      liqPayConfirm      : false,
      liqpayError        : [],
      liqpayConfirmError : []

    }
  },
  watch : {
    liqPayConfirm() {
      this.liqpayConfirmError = []
    },
    'liqpay.public_key'() {
      this.liqpayError = []
    },
    'liqpay.private_key'() {
      this.liqpayError = []
    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
  },
  methods : {
    ...mapActions('business', [ 'GET_PAYMENT_TYPES','EDIT_PAYMENT_TYPES']),
    ...mapActions('liqpay', ['LINK_BUSINESS','CHECKOUT_AUTH']),

    saveChanges(){
      let data = { payments : [] };
      this.payments.forEach((payment)=>{
        if (payment.value) { data.payments.push(payment.id) }
      })
      this.loading = true
      this.EDIT_PAYMENT_TYPES({alias:this.selectedBusiness.alias,data:data}).then((out)=>{
        console.log(out)
        this.loading = false
      }).catch(()=>{this.loading = false})
    },
    linkLiqPay() {
      if (this.liqPayConfirm) {

        this.loading = true
        this.liqpay.business = this.selectedBusiness.alias
        this.LINK_BUSINESS( this.liqpay ).then((out)=>{
          if (out.result) {
            this.notify("LiqPay Акаунт підключено ! ")
            this.liqPaySet = true
          } else {
            if ( out.error === 'Keys not found') {
              this.liqpayError = ['Невірні кллючі LiqPay']
            } else {
              this.notify('Помилка мережі')
            }

          }
          this.loading = false
        }).catch(()=>{
          this.loading = false
        })

      } else {

        this.liqpayConfirmError = ['Підтвердіть дію']

      }

    }
  },
  mounted(){
    this.loading = true
    this.GET_PAYMENT_TYPES(this.selectedBusiness.alias).then((out)=>{
      if (out.result) {
        this.payments        = out.data.payments
        this.liqPaySet       = out.data.liqpay
        this.businessPayment = out.data.businessPayments
            this.payments.forEach((payment)=>{
              if (this.businessPayment.length > 0 ) {
                  if ( this.businessPayment.findIndex(el=>el.id === payment.id) !== -1 ) {
                      payment.value = true
                  }
              } else {  payment.value = false }
        })
      }
    }).catch(()=>{this.loading=false})
  }
}
</script>

<style scoped>

</style>