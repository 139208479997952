<template>
  <v-sheet>
    <div class="d-flex justify-space-between">
      <div>
        <h4>Активність клієнтів :</h4>
        <h5 class="font-weight-light">
          {{ filter.date.length > 1 ? `з  ${filter.date[0]} по ${filter.date[1]} ` : 'Весь період' }}
        </h5>
      </div>
      <v-chip dark color="green lighten-2" class="mr-1" small> <v-icon small> mdi-account-multiple </v-icon> {{ clients.length }} </v-chip>
    </div>


    <!-- filters-->
    <v-divider class="mt-2" />
    <div class="my-2 d-flex justify-space-between align-center">
      <div class="d-flex align-center">
        <v-chip style="padding : 13px 10px; border-radius: 26px" class="mr-2 "  small outlined color="grey darken-3">
          <v-icon small class="mr-2">mdi-magnify</v-icon>
          <v-text-field class="caption ml-n3" v-model="nameSearch" filled background-color="transparent"  style="width:100px;  padding-bottom:2px; margin-left: 0" placeholder="Пошук" dense hide-details ></v-text-field>
        </v-chip>
        <v-chip style="padding : 13px 10px; border-radius: 26px" class="mr-2 "  small outlined color="grey darken-3">
          <v-icon small class="mr-2">mdi-magnify</v-icon>
          <v-text-field type="number" class="caption ml-n3" v-model="filter.total" filled background-color="transparent"  style="width:100px;  padding-bottom:2px; margin-left: 0" placeholder="Виручка" dense hide-details ></v-text-field>
        </v-chip>
        <v-chip style="padding : 13px 10px; border-radius: 26px" class="mr-2 "  small outlined color="grey darken-3">
          <v-icon small class="mr-2">mdi-magnify</v-icon>
          <v-text-field type="number"  class="caption ml-n3" v-model="filter.quantity" filled background-color="transparent"  style="width:100px;  padding-bottom:2px; margin-left: 0" placeholder="Замовлень" dense hide-details ></v-text-field>
        </v-chip>
      </div>
      <v-btn small rounded @click="resetFilter" text class="noCaps caption">
        <v-icon small>mdi-close</v-icon>
        Скинути
      </v-btn>
      <v-btn small @click="$emit('delete')" icon><v-icon small>mdi-delete</v-icon></v-btn>
    </div>
    <v-divider />
    <!-- clients table-->
    <v-data-table dense class="mb-8 mt-4"
                  :items="clients"
                  :headers="headers">
      <template v-slot:item.client="{item}">
       <h5>{{ item.client}}</h5>
      </template>
      <template v-slot:item.orders="{item}">
        <v-chip small color="primary lighten-2" dark>
          <v-icon x-small class="mr-1">mdi-text-box-check-outline</v-icon>
          <h4>{{ item.orders }}</h4>
        </v-chip>
      </template>
      <template v-slot:item.total="{item}">
        <v-chip small color="green lighten-2" dark>
          <v-icon x-small class="mr-1">mdi-cash-multiple</v-icon>
          <h4>{{ item.total ?  item.total : 0 }}</h4>
        </v-chip>
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
// import ftSelect from "@/components/UI/ftSelect";
import {mapActions} from "vuex";
export default {
  name: "statisticsClientFull",
  props : ['externalFilter','value'],
  components : {
    // ftSelect
  },
  data() {
    return {
      nameSearch : '',
      clients : [],
      filter : {
        date : [],
      }
    }
  },
  computed : {
    headers() {
      let items = [
        { text : 'Клієнт' ,    value : 'client' },
        { text : 'Замовлень' , value : 'orders' },
        { text : 'Загалом' , value : 'total' },
      ] ;
      return items
    },
    clientsFiltered(){
      let items = this.clients
      if ( this.filter.hideZero ) {
        items = items.filter(el => el.orders > 0)
      }
      return items
    }
  },
  watch: {
    externalFilter :{

      handler(filter) {
        if ( filter ) {
         this.mutateFilter()
        }
      },
      deep : true

    },
    filter :  {
      handler(filter) {
        this.getData()
        this.$emit('input',filter)
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('statistics', ['GET_CLIENTS_STATISTICS_FULL']),
    getData() {
      this.loading = true

      this.GET_CLIENTS_STATISTICS_FULL({filter: this.filter}).then((out) => {
        if (out.data) {
          this.clients = out.data.clients
          this.loading = false;
        }
      }).catch(() => {
        this.loading = false
        this.notify('Network error')
      })
    },

    //technical
    resetFilter(){
      if ( this.filter.quantity ) { delete this.filter.quantity  }
      if ( this.filter.total    ) { delete this.filter.total     }


    },
    mutateFilter() {
      Object.keys(this.externalFilter).forEach((key)=>{
        this.filter[key] = this.externalFilter[key]
      })
      this.filter = Object.assign({},this.filter)
    }
  },
  mounted() {
    if ( this.externalFilter ) {
      this.mutateFilter()
    }
    if ( this.value )  {
      this.filter = this.value
    }
    this.getData()
    if (this.date ) {
      this.filter.date = this.date
    }
  }
}
</script>

<style scoped>

</style>