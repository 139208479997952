<template>
  <v-sheet color="blue-grey darken-1 py-2" dark class=" d-flex justify-space-between align-center">

    <div>
      <v-btn v-if="displayShow(['sm'])" class="pl-3"  @click="TOGGLE_SIDEBAR"  icon  href="#" color="py-4 ml-3 grey darken-4">
        <v-icon> mdi-menu </v-icon>
      </v-btn>
    </div>

    <div v-if="displayShow(['sm'])" class="pr-6">
      <h4 class="font-weight-light ml-3" >{{ pageTitle }}</h4>
    </div>
    <div v-if="displayShow(['sm'])" class="pr-6">
    </div>

    <div v-if="displayHide(['sm'])" class="d-flex align-center" style="height: 100%">

<!--      <v-icon >mdi-email-outline </v-icon>-->
<!--      <notificationMenu />-->
      <userDropdown :dark="true" img_size="22" class="userDropdown" />

    </div>


  </v-sheet>
</template>

<script>
// import notificationMenu from "@/components/UI/header/notificationMenu";
import userDropdown from "@/components/UI/userDropdown";
import {mapMutations,mapState} from "vuex";
export default {
  name: "headerDash",
  components : {
    userDropdown,
    // notificationMenu
  },
  computed : {
    ...mapState('dashboard', ['sideMenu','pageTitle']),
  },
  methods: {
    ...mapMutations('dashboard', ['TOGGLE_SIDEBAR']),
  },
}
</script>

<style scoped>


.header {
  color: #2c3e50;
}
.userDropdown {
  margin : 0px 25px;
}

</style>