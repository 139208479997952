<template>
  <div>

    <v-scroll-x-transition mode="out-in">

      <card v-if="!displayEditTemplate" title="Templates">


        <template slot="header">
          <v-btn dark rounded outlined @click="displayAddTemplate  = !displayAddTemplate">
            <v-icon>mdi-plus</v-icon>
            Add Template
          </v-btn>
        </template>
        <v-data-table :items  = "templates"  :headers= "headers"  >

          <template v-slot:item.action="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" v-on="on" >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(menuItem, i) in editActions" :key="i" @click="editButton(menuItem.action,item)">
                  <v-list-item-title class="font-weight-light">{{ menuItem.label }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

        </v-data-table>
      </card>
      <editTemplate   v-model="displayEditTemplate" v-if="displayEditTemplate" :uuid="editTemplateID"     @onFinish="editTemplate"/>

    </v-scroll-x-transition>

    <v-dialog       v-model="displayAddTemplate"  width="700" >
      <addTemplate  v-model="displayAddTemplate"  v-if="displayAddTemplate"  @onFinish="addTemplate "  />
    </v-dialog>

  </div>
</template>

<script>
import addTemplate from "@/components/pages/dashboard/ADMIN/templater/addTemplate";
import editTemplate from "@/components/pages/dashboard/ADMIN/templater/editTemplate";
import card from "@/components/pages/dashboard/UI/card";
import {mapActions} from "vuex";
export default {

  name: "DashTemplater",
  components : {
    card,
    addTemplate,
    editTemplate
  },
  data(){
    return {

      loading : false,

      displayAddTemplate  : false,
      displayEditTemplate : false,
      editTemplateID      : '',

      templates : [],
      headers   : [

        { text: 'Template Name'  ,  value: 'name'   },
        { text: 'Template Alias' ,  value: 'alias'  },
        { text: ''               ,  value: 'action' }

      ],

      editActions : [

        { label: 'Edit', action: 'edit' },

      ],

    }
  },
  methods : {
    ...mapActions('templates',  [ 'GET_TEMPLATES_PUBLIC' ]),
    addTemplate(addData){
      console.log('new data')
      console.log(addData)
      this.notify(`New Template ${addData.name} created`)
      this.templates.push(addData)
    },
    editTemplate(uuid){

      this.editTemplateID       =  uuid
      this.displayEditTemplate  =  true

    },
    editButton(action,item) {

      switch(action) {

        case 'edit' : this.editTemplate(item.id);  break;

      }

    },

  },
  mounted(){
    this.loading = true;
    this.GET_TEMPLATES_PUBLIC().then((out)=>{
      this.templates=out.data.templates
      this.loading = false
    }).catch(()=>{this.loading = false})
  }
}
</script>

<style scoped>

</style>