<template>
  <v-dialog v-model="openEditorAddEntity" width="600" persistent>
    <v-sheet dark class="py-6 px-8"  >
      <div class="d-flex justify-space-between mt-2 ">
        <h3 style="line-height: 1.2" class="mt-3 mb-3  font-weight-light text-center">Додати {{ entityName }} </h3>
        <v-btn plain icon  @click="cancelEdit"><v-icon>mdi-close</v-icon></v-btn>
      </div>



      <v-text-field v-model="page.title" label="Назва сторінки" />
      <v-text-field v-model="page.alias" label="Посилання" />



      <!-- Navigation-->
      <div class="d-flex justify-end mt-5 mb-3">
        <v-btn :loading="loading" :disabled="loading"  @click="cancelEdit"
               large class="mt-3 px-8" text rounded elevation="0"  dark>
          <h3 class="font-weight-light">Скасувати</h3>
        </v-btn>
        <v-btn :disabled="loading"  @click="saveChanges"
               large class="mt-3 px-8" rounded outlined elevation="0"  dark>
          <h3 class="font-weight-light">Створити</h3>
        </v-btn>
      </div>
    </v-sheet>

  </v-dialog>
</template>

<script>

import {mapState,mapActions} from "vuex";


export default {
  name: "ftEditorBlockElement",
  components : {
  },
  data(){
    return {
      loading : false,

      page : {}
    }
  },
  computed : {
    ...mapState('templates',  [ 'businessAlias' , 'selectedItem', 'selectedBlock', 'selectedBlockItem' , 'openEditorAddEntity' ]),

    entityName() {
      var addEntityType = 'page'
      switch (addEntityType) {
        case 'page' : return 'Cторінку';
        default : return '';
      }
    },
  },
  methods : {
    ...mapActions('business',  [ 'ADD_PAGE' ]),

    cancelEdit(){
      this.$store.state.templates.openEditorAddEntity = false
    },
    saveChanges() {

      this.ADD_PAGE(
          {
            business: this.businessAlias,
            alias : this.page.alias,
            title : this.page.title

          }  )
          .then((out)=>{
            if(out.result) {
              this.notify('Cторінку створено')
              this.$router.push( '/' + this.businessAlias + '/page/' + this.page.alias + '/edit')
              this.cancelEdit()
            }
      }).catch( () => {
        this.notify("Помилка зв'язку")
      })

    }
  }
}
</script>

<style  lang="scss">

@import '~@/assets/css/quill.bubble.css';

.noResize textarea {
  resize: none !important;
}
</style>