<template>
  <div>


    <div class="d-flex justify-space-between align-center">
      <h4 class="mt-3" >Заголовок</h4>
      <v-switch v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value.header.active"
                dense hide-details />
    </div>
    <ft-params-bar  v-if="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value.header.active"
                    class="text-center mt-3"
                    :auto-input="['headingSize','fontWeight','lineHeight','color']"
                    v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].params.header.text" />

    <h4 class="mt-6" >Cоціальні мережі: </h4>


    <v-select       class="caption mt-6" dense multiple
                    v-model="activeSocials"
                    hide-details   :items="socialsSelect" >
      <template slot="label">
        <span class="caption"> Соціальні мережі </span>
      </template>
    </v-select>

    <v-text-field   v-if="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value.telegram.active"
                    class="caption mt-6" dense
                    v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value.telegram.link"
                    hide-details  >
      <template slot="label">
        <span class="caption"> Telegram </span>
      </template>
    </v-text-field>

    <v-text-field   v-if="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value.facebook.active"
                    class="caption mt-6" dense
                    v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value.facebook.link"
                    hide-details  >
      <template slot="label">
        <span class="caption"> Facebook </span>
      </template>
    </v-text-field>

    <v-text-field   v-if="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value.instagram.active"
                    class="caption mt-6" dense
                    v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value.instagram.link"
                    hide-details  >
      <template slot="label">
        <span class="caption"> Instagram </span>
      </template>
    </v-text-field>


  </div>
</template>

<script>
// import ftParamsBar from "@/components/templates/editor/UI/ftParamsBar";

import ftParamsBar from "@/components/templates/editor/UI/ftParamsBar";
import {mapState} from "vuex";

export default {
  name: "ftEditorBLockElementDelivery",
  props : [ 'selectedBlock',
    'selectedBlockRow',
    'selectedBlockItem'
  ],
  components : {
    ftParamsBar
  },
  data() {
    return {
      activeSocials: [],
      socialsSelect: [

        { text : 'Telegram'  ,  value : 'telegram'  },
        { text : 'Facebook'  ,  value : 'facebook'  },
        { text : 'Instagram' ,  value : 'instagram' },

      ],
    }
  },
  computed : {
    ...mapState('templates',  [ 'products' ]),
  },
  watch : {
    activeSocials(val) {
      this.socialsSelect.forEach((social)=>{
        this.$store.state.templates.page.blocks[this.selectedBlock].value.items[this.selectedBlockRow][this.selectedBlockItem].value[social.value].active = false
      })
      val.forEach((social)=>{
        this.$store.state.templates.page.blocks[this.selectedBlock].value.items[this.selectedBlockRow][this.selectedBlockItem].value[social].active = true
      })
    }
  },
  mounted() {
    let socials = Object.keys(this.$store.state.templates.page.blocks[this.selectedBlock].value.items[this.selectedBlockRow][this.selectedBlockItem].value).filter(el=>el !=='header')

    socials.forEach((key)=>{
      if ( this.$store.state.templates.page.blocks[this.selectedBlock].value.items[this.selectedBlockRow][this.selectedBlockItem].value[key].active ) {
        this.activeSocials.push(key)
      }
    })

  }
}
</script>

<style scoped>

</style>