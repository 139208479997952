<template>
  <div>

    <h4>User Type: {{ $store.state.auth.userType }}</h4>

<!--    <dashApplicant v-if="$store.state.auth.userType === 'Applicant'" />-->
<!--    <dashStudent v-if="$store.state.auth.userType === 'Student'"  />-->
<!--    <dashTeacher v-if="$store.state.auth.userType === 'Teacher'"  />-->
<!--    <dashManager v-if="$store.state.auth.userType === 'Manager'"  />-->
<!--    <dashAdmin v-if="$store.state.auth.userType === 'Admin'"  />-->
<!--    <dashPartner v-if="$store.state.auth.userType === 'Partner'"  />-->


  </div>


  </template>

<script>


export default {
  name: "DashIndex",
  components : {

  }

}
</script>

<style scoped>

</style>