<template>
  <div class="wrapper fill-height">


    <sideBarBusiness />

    <v-sheet color="blue-grey darken-1"  :class="bodyStyleClass" class="transition fill-height">
        <headerDash/>

        <v-sheet color="blue-grey darken-1"
                 class="content fill-height"
                 :class="displayHide(['sm']) ? 'pb-6' : '' ">

           <v-sheet class="overflow-y-auto fill-height"
                    :class="displayHide(['sm']) ? 'wsRounded mr-6' : 'wsRoundedHalfTop' " >
             <router-view style="overflow-y: unset;"></router-view>
           </v-sheet>

        </v-sheet>


    </v-sheet>

    <v-dialog v-if="$store.state.dashboard.showLogoutConfirm"
              v-model="$store.state.dashboard.showLogoutConfirm"
              width="400">

      <v-sheet class="pa-4">
        <div class="d-flex justify-space-between align-center">
          <h3 class="font-weight-light">Вийти</h3>
          <v-btn @click="$store.state.dashboard.showLogoutConfirm = false"
              small icon><v-icon small>mdi-close</v-icon></v-btn>
        </div>
        <v-divider class="mt-1" />
        <h5 class="my-12 text-center font-weight-light">Ви впевнені, що хочети вийти із вашого акаунту ?</h5>
        <div class="d-flex justify-end mb-1">
          <v-btn @click="$store.state.dashboard.showLogoutConfirm = false" outlined class="noCaps" small text>Скасувати</v-btn>
          <v-btn @click="logout" small text class="noCaps">Так</v-btn>
        </div>

      </v-sheet>

    </v-dialog>
  </div>

</template>

<script>


import sideBarBusiness from "@/components/pages/businessDashboard/UI/sidebarBusiness/sideBarBusiness";
// import titleBread from "@/components/pages/dashboard/UI/titleBread";
import headerDash from "@/components/pages/dashboard/UI/headerDash";
import {mapState, mapActions, mapMutations} from "vuex";

export default {
  name: 'PageBusinessDashboard',
  props: ['alias'],
  components: {
    sideBarBusiness,
    // titleBread,
    headerDash
  },
  data() {
    return {
      menuActive: false,
    }
  },
  computed: {
    ...mapState('dashboard', ['sideMenu','pageTitle','breadcrumbs','modalDash']),

    bodyStyleClass() {
      if ( this.sideMenu ) { return 'mainWithMenu' }
      return this.displayShow(['sm']) ? 'mainMobile' : 'main'
    }

  },
  methods : {
    ...mapActions('business', ['GET_BUSINESS_DASHBOARD']),
    ...mapMutations('auth',['LOGOUT']),
    logout() {
      this.$store.state.dashboard.showLogoutConfirm = false
      this.LOGOUT(this.$router)

    }
  },
  watch: {
    windowSize(val,old) {

      if ( val === 'sm' ) {
        this.$store.state.dashboard.sideMenu = false
      }
      if ( old === 'sm' ) {
        this.$store.state.dashboard.sideMenu = true
      }
    }
  },

  beforeMount() {
    let data = {
      alias : this.alias
    }
    if ( this.$store.state.domain ) {
      data.domain = location.host
    }
    this.GET_BUSINESS_DASHBOARD(data)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.wrapper {
  background-color: whitesmoke;
  height:100%;
}
.main {
  margin-left: 60px;
  transition: margin-left 0.3s ease-out;
}
.transition {
  transition: margin-left 0.3s ease-out;
}
.mainWithMenu {
  margin-left: 250px !important;
}


.content {
  overflow-y: unset;
  background-color: white;
}


</style>
