<template>
  <span>
    <!-- NOTIFICATION FOR CHANGE-->
    <v-snackbar key="notify" dark v-model="$store.state.notification" timeout="2000"  class="rounded-0 mb-3">
      <span class="notranslate">{{  $store.state.notification_text  }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="$store.state.notification = false" >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!--  NOTIFICATION TOP RIGHT-->
    <v-dialog v-model="$store.state.notifications.notify"  hide-overlay >
      <v-sheet elevation="3" width="350" dark
               style="position:absolute !important; top:80px !important; right:10px !important"
               class="pa-3">

        <div class="d-flex justify-space-between align-start">
          <div class="d-flex">
            <v-icon size="30" class="mr-2 mt-1">mdi-message-outline</v-icon>
            <div>
              <div class="caption mt-1"><b>{{  notify_title  }}</b></div>
              <div class="caption mt-n1">{{  notify_text  }}</div>
            </div>
          </div>
          <v-btn small  icon  @click="$store.state.notifications.notify = false" > <v-icon small >mdi-close</v-icon> </v-btn>
        </div>

      </v-sheet>
    </v-dialog>

  </span>

</template>

<script>


import {mapState} from "vuex";

export default {
  name: "notification",
  computed : {
    ...mapState('notifications',['notifications','notify_title','notify_text']),
  },
  watch : {
    notifications(val) {
      this.notifyTop(val[val.length-1].event,val[val.length-1].description)
    }
  }
}
</script>

<style scoped>

</style>