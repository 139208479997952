<template>
  <v-sheet class="pa-1" :color="child ? '#343434' : ''" >

    <v-row align="center" >
      <v-col cols="1" class="pt-2 mt-0">
        <v-icon style="cursor: grab"
            :class="child ? 'subHandle' : 'handle' " size="28" class="pr-0" >
          mdi-drag
        </v-icon>
      </v-col>
      <v-col cols="3" class="pt-2 mt-0">
        <h5 style="line-height: 1">         {{ getTitle() }} </h5>
<!--        <h5 class="mt-0 font-weight-light"> {{ getSubtitle() }} </h5>-->
      </v-col>
      <v-col cols="7" class="pt-2 mt-0">
        <div class="text-left "> <h5> {{ getAction()  }}  </h5></div>
      </v-col>
<!--      <v-col cols="5">-->
<!--        <code v-if="value.action === 'link'   "> {{ getLink() }} </code>-->
<!--        <code v-if="value.action === 'page'   "> {{ getLink() }} </code>-->
<!--        <code v-if="value.action === 'scroll' "> {{ getLink() }} </code>-->
<!--      </v-col>-->
      <v-col cols="1" class="pt-2 mt-0">
        <v-menu dense :key="value.title" offset-y eager>

          <template  v-slot:activator="{ on, attrs }">
            <v-btn icon  v-bind="attrs" v-on="on">
              <v-icon small  class="pr-0 "  >mdi-pencil</v-icon>
            </v-btn>
          </template>

          <v-list dense dark>
            <v-list-item dense v-for="(item, index) in editActions" :key="index" @click=" $emit('editRow',item.action)" >
              <v-list-item-title class="caption font-weight-regular">{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-col>
    </v-row>

  </v-sheet>
</template>

<script>
export default {
  name: "ftMainMenuEditElement",
  props : ['value','child'],
  data() {
    return {

      editActions : [
        {text: 'Редагувати', action : 'edit'},
        {text: 'Видалити', action : 'delete'},
      ],

      actions: [
        {text: 'Сторінка',  value: 'page'},
        {text: 'Навігація', value: 'scroll'},
        {text: 'Посилання', value: 'link'},
        {text: 'Підменю',   value: 'submenu'},
      ],

      pages: [
        {text: 'Новини',     value: '/news'},
        {text: 'Всі твоари', value: '/products'},
        {text: 'Контакти',   value: '/contacts'},
      ],

      blocks: [
        {text: 'Інформація',     value: '  news'    },
        {text: 'Новини',     value: '  news'    },
        {text: 'Товари',     value:   'products'},
        {text: 'Контакти',   value: 'contacts'},
      ]

    }
  },
  methods : {

    getLink(){

      var link_output = this.value.link;
      if (this.value.link.length > 25) { link_output = link_output.slice(0,30) + '...'}
      return link_output;

    },
    getAction() {

      let action = this.actions.find( el => el.value === this.value.action ).text

      switch ( this.value.action ) {
        case 'page' : action += ': ' + this.value.route + ' ' +  (this.value.scroll ? '(' + this.value.scroll +')' : '') ; break;
        case 'link' : action +=  this.value.link ? ': ' + this.getLink() : '' ; break
      }

      return action

    },
    getTitle(){

      return this.value.title === '' ? 'Назва' :  this.value.title

    },
    getSubtitle(){

      return this.value.subtitle === '' ? 'Підзаголовок' :  this.value.subtitle

    },
  }
}
</script>

<style scoped>

</style>