<template>
  <div>
    <dashBusinessAdmin v-if="showBusinessToOwner" />
    <dashBusinessManager v-if="showBusinessToManager" />
    <dashBusinessUser  v-if="!showBusinessToManager && !showBusinessToOwner" />
  </div>
</template>

<script>
import dashBusinessAdmin from "@/components/pages/businessDashboard/dashBusiness/dashBusinessAdmin";
import dashBusinessUser from "@/components/pages/businessDashboard/dashBusiness/dashBusinessUser";
import dashBusinessManager from "@/components/pages/businessDashboard/dashBusiness/dashBusinessManager";
import {mapState} from "vuex";
export default {
  name: "dashBusinessIndex",
  props: ['alias'],
  components : {
    dashBusinessAdmin,
    dashBusinessUser,
    dashBusinessManager
  },
  data() {
    return {
      business : {}
    }
  },
  computed : {
    ...mapState('dashboard', ['sideMenu','showBusinessToManager','showBusinessToOwner']),
  }
}
</script>

<style scoped>

</style>