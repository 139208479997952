const state = {
    notifications : [],

    notify       : '',
    notify_title : '',
    notify_text  : '',
}

const actions = {
    PUSH_NOTIFICATION({state},notification) {

        switch (notification.event) {

            case 'order_new' :
                notification.icon = 'mdi-cart'
                notification.action = 'openOrderds'
                notification.event = 'Нове замовлення'
                notification.description = 'Дивитись'
                notification.type = 'order_new'
                break;

            case 'link_telegram' :
                notification.icon = 'mdi-send'
                notification.action = ''
                notification.event = 'Інтеграція'
                notification.description = "Telegram акаунт під'єднано"
                notification.type = 'link_telegram'
                break;

            default :
                notification.icon = 'mdi-cog'
                notification.action = 'idle'
        }

        state.notifications.push(notification)

    },
}
export default {
    namespaced : true,
    state,
    actions
}