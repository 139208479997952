<template>
<div>
  <div v-if="!editorMode">
    <component  :is="TEMPLATES_GET_ELEMENT(subElement + 'Element') "
                :value="value"
                :params="params"
                :block="block"
                :row="row"
                :item="item"
                :editor="false" />

  </div>
  <div v-else>
    <div class="pa-3 editWindow" @click="openEdit">
      <component  :is="TEMPLATES_GET_ELEMENT(subElement + 'Element') "
                  :value="value"
                  :params="params"
                  :block="block"
                  :row="row"
                  :item="item"
                  :editor="true" />
    </div>

  </div>





</div>
</template>

<script>
import { mapState } from "vuex";

import avalonNewsElement from "@/components/templates/Avalon/UI/elements/avalonNewsElement";
import avalonSocialsElement from "@/components/templates/Avalon/UI/elements/avalonSocialsElement";
import avalonButtonElement from "@/components/templates/Avalon/UI/elements/avalonButtonElement";
import avalonAppsElement from "@/components/templates/Avalon/UI/elements/avalonAppsElement";
import avalonDeliveryFieldElement from "@/components/templates/Avalon/UI/elements/avalonDeliveryFieldElement";
export default {
  name: "ft-template-element",
  props : ['value','item','block','row','params','subElement'],
  components : {
    avalonNewsElement,
    avalonSocialsElement,
    avalonButtonElement,
    avalonAppsElement,
    avalonDeliveryFieldElement
  },
  data() {
      return {
        loading : false,
        addData : {},

        displayEdit : false,

      }
    },

  computed : {
    ...mapState('templates',  ['editorMode' ]),
  },
  methods : {
    openEdit() {
      this.$store.state.templates.openEditorBlockElement   = true
      this.$store.state.templates.selectedBlock     = this.block
      this.$store.state.templates.selectedBlockItem = this.item
      this.$store.state.templates.selectedBlockRow = this.row
    },
    cancelEdit(){
      this.displayEdit = false
      this.reactiveParam = this.templateVars_current[this.input].value
    },
    saveChanges() {
      this.displayEdit = false
    },
    handleNextLine() {
      this.value = this.value.replace(/\n/gi, '<br>')
    }
  },
  beforeMount() {
      // this.handleNextLine()
  }
}
</script>

<style scoped>
.sideEditor {
  height: 100%;
  position: fixed;
  left: 0;
  top:0;
  width: 450px;
  z-index: 50000;
}
.editWindow {
  border : 1px #ffffffbb dashed;
}
.editWindow:hover {
  background-color: #ffffff55;
  cursor: pointer;
}
</style>