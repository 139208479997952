<template>
  <div class="pa-16">

    <v-row>
      <v-col cols="9" class="pr-16">
        <div class="d-flex justify-space-between mb-10">
          <h1 class="text-h4 font-weight-light">Ваші Новини</h1>
          <div style="width: 250px">
              <v-text-field  append-icon="mdi-magnify"  v-model="searchNews" hide-details dense    label="Пошук"></v-text-field>
          </div>
        </div>

        <v-expand-transition mode="out-in" >
          <v-sheet v-if="selectedNews.length > 0"  height="50">
              <v-menu   offset-y >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small   color="deep-purple" text v-bind="attrs" v-on="on" >
                    Групові дії <v-icon small>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item v-for="(menuItem, i) in editGroupActions" :key="i" @click="editButton(menuItem.action,item)">
                    <v-list-item-title class="font-weight-light">{{ menuItem.label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
          </v-sheet>
        </v-expand-transition>

        <v-data-table class=""
                      :items="newsFiltered"
                      :headers="news_headers"
                      show-select
                      selectable-key="id"
                      v-model="selectedNews"
                      :search="searchNews"
        >

          <template v-slot:item.title="{ item }">
            <div class="d-flex ma-3">

              <div>
                <h4>{{ item.title }} </h4>
                <span class="caption">{{ item.date }}</span>
              </div>
            </div>
          </template>


          <template v-slot:item.action="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" v-on="on" >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(menuItem, i) in editActions" :key="i" @click="editButton(menuItem.action,item)">
                  <v-list-item-title class="font-weight-light">{{ menuItem.label }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

        </v-data-table>

        <div class="d-flex justify-end mt-8">
          <v-btn @click="addNews" class="noCaps"
              text  elevation="0" rounded color="deep-purple" >
            <v-icon small class="mr-2">mdi-plus</v-icon> Додати Новину </v-btn>
        </div>

      </v-col>
    </v-row>


    <v-fade-transition>
      <newsEditor v-if="displayEditorNews"  @close="displayEditorNews=false" :type="dataEditType" :input="dataEditInput" />
    </v-fade-transition>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import newsEditor from "@/components/pages/businessDashboard/businessNews/newsEditor";
export default {
  name: "BusinessDashboardNews",
  components : {
    newsEditor
  },
  data (){
    return {
      loading : false,

      categories : [],
      news : [],

      showEditCategory : false,
      selectedCategory : '',

      selectedNews : [],
      searchNews : '',
      news_headers : [

        { text:'Новина',   value:  'title'  },
        { text:'Дії',      value:  'action' },

      ],

      editActions : [

        { label: 'Редагувати', action: 'edit'   },
        { label: 'Видалити',   action: 'delete' },

      ],

      editGroupActions : [

        { label: 'Видалити',   action: 'delete_group' },

      ],

      displayEditorNews : false,
      dataEditType      : '',
      dataEditInput     : '',

      displayEditor : false,
      editorType : 'category',
      editorInput : false,
      editorRemove : false,
    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
    newsFiltered() {
      var news = this.news
      if (this.selectedCategory) {
        news = news.filter(el=> el.category_id === this.selectedCategory)
      }
      return news
    }
  },
  methods : {
    ...mapActions('business', [ 'GET_NEWS_ALL']),

    getCategoryCount(category) {
      return this.news.filter(el=>el.category_id === category).length
    },

    addCategory() {
      this.editorType = 'category'
      this.displayEditor = true
      this.editorInput = false
    },
    addNews() {
      this.displayEditorNews = true;
      this.dataEditType = 'new',
      this.dataEditInput = null
    },
    editCategory(input) {
      this.editorType = 'category'
      this.displayEditor = true
      this.editorRemove = false
      this.editorInput = input
    },
    editNews(input) {
      this.displayEditorNews = true;
      this.dataEditType = 'edit',
      this.dataEditInput = input;
    },
    deleteProduct(input) {
      this.editorType = 'products'
      this.displayEditor = true
      this.editorInput = input
      this.editorRemove = true
    },
    editProductOut(data) {
      let index = this.products.findIndex(el=>el.id === data.id)
      this.$set(this.products,index,data)
    },
    deleteProductOut(data) {
      data.forEach((id)=>{
        let index = this.products.findIndex(el=>el.id === id)
        this.products.splice(index,1)
      })
    },
    editorOutput(data){
      switch(data.type) {
        case 'new_category'   : this.categories.push(data.data); break;
        case 'new_product'    : this.products.push(data.data); break;
        case 'edit_product'   : this.editProductOut(data.data); break;
        case 'delete_product' : this.deleteProductOut(data.data); break;
      }
    },
    editButton(action,item) {

      switch(action) {
        case 'edit'         : this.editNews(item.alias)                 ;   break;
        case 'delete'       : this.deleteProduct([item])          ;  break;
        case 'delete_group' : this.deleteProduct(this.selectedNews) ;  break;


      }

    },
  },
  mounted(){
    this.GET_NEWS_ALL(this.selectedBusiness.alias).then((out)=>{
      if (out.data) {
        this.news = out.data
        this.news.reverse();
        this.loading = false;

        console.log(out)
      }
    }).catch(()=>{
      this.loading = false
      this.notify('Network error')
    })
  }

}
</script>

<style scoped>

</style>