<template>
  <v-menu v-model="dropdown" offset-y transition="slide-y-transition">
    <template v-slot:activator="{attrs,on}">
      <v-sheet color="transparent" rounded v-ripple style="cursor: pointer" @click="activate">
        <slot :text="selectedText"  v-bind="attrs" v-on="on" default="name"></slot>
      </v-sheet>
    </template>
    <v-sheet>
      <v-list dense v-if="items.length > 0">
        <v-list-item-group>
          <v-list-item @click="data = item.value; $emit('input',item.value)"  v-for="(item,i) in items" :key="i">
            <v-list-item-title >{{item.text}}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <div v-else class="px-3 py-1">
        <slot name="noItems"></slot>
      </div>
    </v-sheet>
  </v-menu>
</template>

<script>
export default {
  name: "ftSelect",
  props : ['value','items','text'],
  data(){
    return {
      data : null,
      dropdown : false,
    }
  },
  computed : {
    selectedText() {
      let text = ''
      if (this.value ) {
        let item = this.items.find(el=> el.value === this.value)
        if ( item ) {
          text = item.text
        }

      }
      return text
    }
  },
  watch : {
    // data(val) {
    //   this.$emit('input',val)
    // },
    value(val) {
      this.data = val
    }
  },
  methods : {
    activate() {
      this.dropdown = true;

    }
  },
  mounted() {
    if ( this.value || this.value === false  ) {
      this.data = this.value
    } else {
      this.data = 'Select'
    }

  }
}
</script>

<style scoped>

</style>