<template>
  <div>


<!--    <ft-params-bar  class="text-center"-->
<!--                    :auto-input="['size','fontWeight',,'caps']"-->
<!--                    v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].params.fieldStyle" />-->

    <v-select       class="caption mt-6" dense
                    v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value.action"
                    hide-details   :items="buttonActions" label="Кнопка" />

    <v-text-field   v-if="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value.action"
                    class="caption mt-6" dense
                    v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value.buttonText"
                    hide-details label="Текст Кнопки" />

    <v-select      v-if="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value.action === 'orderProducts' "
                   class="caption mt-6" dense
                   v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value.products"
                   multiple :items="productsSelect" label="Товари">
      <template slot="label">
        <span class="caption"> Товари </span>
      </template>
    </v-select>

    <ft-params-bar  v-if="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value.action"
                    class="text-center mt-9"
                    :auto-input="['size','fontWeight','caps']"
                    v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].params.buttonStyle.text" />

    <ft-params-bar  v-if="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].value.action"
                    class="text-center"
                    :auto-input="['text','color','dark']"
                    v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].params.buttonStyle.color" />




  </div>
</template>

<script>
// import ftParamsBar from "@/components/templates/editor/UI/ftParamsBar";

import ftParamsBar from "@/components/templates/editor/UI/ftParamsBar";
import {mapState} from "vuex";

export default {
  name: "ftEditorBLockElementDelivery",
  props : [ 'selectedBlock',
    'selectedBlockRow',
    'selectedBlockItem'
  ],
  components : {
    ftParamsBar
  },
  data() {
    return {
      buttonActions: [

        { text : 'Немає' ,                 value : null      },
        { text : 'Оформити товари' ,       value : 'orderProducts' },
        { text : 'Відкрити корзину' ,      value : 'openCart'      },

      ],
    }
  },
  computed : {
    ...mapState('templates',  [ 'products' ]),
    productsSelect() {
      var list = []
      this.products.forEach((product) => {
        list.push( { text : product.name, value : product.id } )
      })
      return list
    },
  }
}
</script>

<style scoped>

</style>