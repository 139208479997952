<template>
  <v-sheet dark class="fill-height d-flex align-center justify-center">

    <div>
      <div class="d-flex justify-center mb-16">
        <h3 class="text-center">FineTrading: <br> <span class="font-weight-light">Business payment module</span></h3>
      </div>
      <div class="d-flex justify-center">
        <v-progress-circular v-if="loading"  indeterminate size="48" />
      </div>
      <h1 class="font-weight-light text-center">Замовлення : {{ order }}</h1>
      <h1 class="font-weight-light text-center">{{ loding ? 'Перевіряємо статус оплати' : message }}</h1>

    </div>

  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "PaymentRedirectLiqpay",
  props: ['alias','order'],
  data() {
    return {
      message : ''
    }
  },
  computed : {
    domain() {
      return location.host
    }
  },
  methods : {
    ...mapActions  ('liqpay', [ 'GET_BUSINESS_BUTTON' ]),
  },
  mounted(){

    let data = {
      business: this.alias ? this.alias : this.domain,
      data : {
        order_number: this.order,
      },
    }

    this.loading = true
    this.GET_BUSINESS_BUTTON(data).then((out)=>{
      this.loading = false
      if ( out.result ) {
        this.LIQPAY_checkout(out.data)
      } else {
        if ( out.error === 'Order already payed') {
          this.message = 'Замовлення вже оплачено'
        }
        if ( out.error === 'Order not exist') {
          this.message = 'Замовлення не знайдено'
        }

      }
    }).catch(()=>{
      this.loading = false;
      this.notify('Network Error')
    })
  }
}
</script>

<style scoped>

</style>