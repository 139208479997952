<template>
  <v-sheet class="loadingTemplate d-flex align-center justify-center"
           color="#262626">

        <v-progress-circular class=" justify-center"
                             indeterminate
                             color="white"
                             size="64" />

  </v-sheet>
</template>

<script>
export default {
  name: "loadingTemplate",
}
</script>

<style scoped>
.loadingTemplate {
  position : absolute;
  top : 0;
  bottom : 0;
  left : 0;
  right : 0;
  z-index:100000000;
}
</style>