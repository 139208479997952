<template>
  <div v-scroll="scrolling">
    <authModal />
    <login-side-bar/>

    <div v-show="!specialtyWindow" class="justify-center" style="position:absolute;width: 100%">

      <!-- PRE HEADER SMALL-->
      <v-sheet  class="d-flex justify-space-between  align-center"
               style="padding: 0px 50px ">


        <div class="d-flex  d-md-none">
          <v-btn icon class="ml-n10" @click="mobileMenu=!mobileMenu"><v-icon>{{ mobileMenu ? 'mdi-close' : 'mdi-menu'}}</v-icon></v-btn>
        </div>
        <div class="d-none  d-md-flex ">

          <span class="caption pa-3">News</span>

          <span class="caption pa-3">Templates</span>

          <span class="caption pa-3">Events</span>

          <span class="caption pa-3">Contacts</span>

        </div>
        <div class="d-flex align-center">
          <span class="caption pa-3 mr-6">Lang: UA </span>

<!--          <v-icon class="caption pa-3">mdi-facebook</v-icon>-->
<!--          <v-icon class="caption pa-3">mdi-instagram</v-icon>-->
          <v-divider vertical></v-divider>
          <userDropdown img_size="0" :main_menu="true" class="userDropdown"/>
        </div>

      </v-sheet>
      <!-- Mobile Menu-->
      <v-expand-transition>
        <v-sheet v-show="mobileMenu" dark width="100%"  style="position: fixed; z-index: 999"  >
          <v-list dense class="pb-0 pt-0" >

            <v-list-group color="white" >

              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="pa-3 pl-8">Sisko  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item style="background: #474747">
                <v-list-item-content class="caption d-flex justify-space-between">asdfasdf</v-list-item-content>
              </v-list-item>
              <v-list-item style="background: #474747">
                <v-list-item-content class="caption d-flex justify-space-between">KSDoefg</v-list-item-content>
              </v-list-item>

            </v-list-group>
            <v-divider />
            <v-list-group color="white" >

              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="pa-3 pl-8">Sisko  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item style="background: #474747">
                <v-list-item-content class="caption d-flex justify-space-between">asdfasdf</v-list-item-content>
              </v-list-item>
              <v-list-item style="background: #474747">
                <v-list-item-content class="caption d-flex justify-space-between">KSDoefg</v-list-item-content>
              </v-list-item>

            </v-list-group>
            <v-divider />
            <v-list-group color="white" >

              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="pa-3 pl-8">Sisko  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item style="background: #474747">
                <v-list-item-content class="caption d-flex justify-space-between">asdfasdf</v-list-item-content>
              </v-list-item>
              <v-list-item style="background: #474747">
                <v-list-item-content class="caption d-flex justify-space-between">KSDoefg</v-list-item-content>
              </v-list-item>

            </v-list-group>
            <v-divider />


          </v-list>
        </v-sheet>
      </v-expand-transition>
      <!-- HEADER MAIN-->
      <v-sheet :style="stickyMenu ? 'opacity:0.85' : ''"
               elevation="4" style="backdrop-filter: blur(14px);
    -webkit-backdrop-filter: blur(14px);
    padding-left: 60px;
    padding-right: 60px;
        position:sticky;top:0; z-index:5;transition: all .6s ease" dark color="blue darken-2" class="d-flex justify-space-between  align-center"
      >
        <router-link to="/ua" >
          <img style="height: 50px"  class="mt-2" src="@/assets/brand/logo_fine_white.png" alt="" />
        </router-link>
        <div class="d-none  d-md-flex  justify-center" >
          <v-menu v-for="(menu,i) in menuItems" :key="i" offset-y open-on-hover class="pt-15"  :close-on-content-click="false"
                  @input="menuParent=null"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="d-flex align-center"
                   style=" height:100px; width:100%; position:relative">
                <div class="d-flex">
                  <v-divider vertical />
                  <div class=" pl-5 pr-4">
                    <h5>{{  menu.title  }} </h5>
                    <span class="caption ">{{ menu.subtitle  }}</span>
                  </div>
                </div>
                <v-expand-x-transition>
                  <div v-if="attrs[`aria-expanded`] === 'true'"   style="
                background: white;
                position:absolute;
                bottom:0;
                height: 3px;
                width:100%"></div>
                </v-expand-x-transition>
              </div>
            </template>
            <v-card  color="#ffffffee"  class="pa-6 rounded-0" style="transition: all 0.4s ease">
              <div  class="d-flex">
                <div class="mr-6">
                  <h3 class="text-center bitter font-weight-medium">{{  menu.title  }}</h3>
                  <ul class="mt-6" style="list-style: none">
                    <li v-ripple v-for="(item,j) in menu.items" :key="item.text" @click="menuAction(menu,item,j)"
                        class="font-weight-light ml-n6">
                      <v-icon x-small>mdi-chevron-right</v-icon>
                      {{  item.text }}
                    </li>
                  </ul>
                </div>
                <v-divider v-if="menu.widget" vertical />

                <div v-if="menu.widget === 'child'" class="ml-4 pa-4" >

                  <div v-if="menuParent != null && menu.items[menuParent].children">
                    <h3 class="text-center bitter font-weight-medium mt-n3 mb-2">{{  menu.items[menuParent].text  }}</h3>
                    <div class="mt-n2" style="width:300px" >
                      <ul v-if="menu.items[menuParent].children.type === 'auto'"
                           class="mt-6" style="list-style: none" >
                        <li v-ripple v-for="(child,k) in menuData[menu.items[menuParent].children.source]" :key="k"
                            class="font-weight-light ml-n6">
                          <v-icon x-small>mdi-chevron-right</v-icon>
                          {{  child.name }}
                        </li>
                      </ul>
                      <ul v-if="menu.items[menuParent].children.type === 'manual'"
                          class="mt-6" style="list-style: none" >
                        <li v-ripple v-for="(child,k) in menu.items[menuParent].children.items" :key="k"
                            class="font-weight-light ml-n6">
                          <v-icon x-small>mdi-chevron-right</v-icon>
                          {{  child.text }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div v-else>

                  </div>


                </div>



              </div>
            </v-card>
          </v-menu>
        </div>

        <v-btn large rounded outlined>Розпочати</v-btn>

      </v-sheet>

      <v-fade-transition mode="out-in">
        <router-view ></router-view>
      </v-fade-transition>


      <!-- FOOTER -->
      <v-sheet dark  class="pa-12 d-flex justify-center">
        <div style="width: 1000px">

          <v-divider class="mt-6" height="10px" style="height: 10px"/>
          <v-divider height="10px" style="height: 10px"/>

          <div class="d-flex justify-center mt-n6" >
            <v-sheet style="border: 2px solid #393939" class="pa-2">
              <v-btn text color="white" class="text-center rounded-0 ">Get Started <v-icon>mdi-chevron-right</v-icon></v-btn>
            </v-sheet>
          </div>

          <v-row class="mb-16 mt-8">
            <v-col cols="6" >
              <router-link to="/ua" >
                <img style="height: 70px"  class="" src="@/assets/brand/logo_fine_white.png" alt="" />
              </router-link>
              <p class="caption font-weight-light mt-5" style="line-height: 1.5">
                Увімкни свій бізнес на повну!
                Зручність та сучасність, це те що характеризує FineTrading. Отримай потужний універсальний інструмент для розвитку твого бізнесу. Спробувавши, ти запитаєш себе чому не робив це раніше!
              </p>
            </v-col>
            <v-col cols="3" >
              <h3>Інформація</h3>
              <ul class="mt-6" style="list-style: none">
                <li class="caption ml-n6">Документація</li>
                <li class="caption ml-n6">Відео</li>
                <li class="caption ml-n6">Умови конфіденційності</li>
                <li class="caption ml-n6">Умови повернення</li>
                <li class="caption ml-n6">Оферта</li>
              </ul>
            </v-col>
            <v-col cols="3" >
              <h3>Можливості</h3>
              <ul class="mt-6" style="list-style: none">
                <li class="caption ml-n6">Інтеграції</li>
                <li class="caption ml-n6">Мобільні додатки</li>
                <li class="caption ml-n6">Месенджери</li>
                <li class="caption ml-n6">Власний домен</li>
                <li class="caption ml-n6">Чат боти</li>
              </ul>
            </v-col>
          </v-row>

          <v-divider height="10px" style="height: 10px"/>
          <v-divider height="10px" style="height: 10px"/>
          <div class="d-flex justify-center mt-n6 mb-4" >
            <v-sheet style="border: 2px solid #393939" >
              <v-btn text color="white" class="text-center rounded-0 ">TOP</v-btn>
            </v-sheet>
          </div>

          <div class="d-flex justify-space-between align-center mb-6">
            <span class="caption font-weight-light">© 2021 - FineTrading: Eleectronic Business Platform</span>
            <div>
              <v-btn icon><v-icon>mdi-facebook</v-icon></v-btn>
              <v-btn icon><v-icon>mdi-instagram</v-icon></v-btn>
              <v-btn icon><v-icon>mdi-youtube</v-icon></v-btn>
            </div>
          </div>
          <v-divider height="10px" style="height: 10px"/>
          <v-divider height="10px" style="height: 10px"/>
          <!--        <div class="d-flex justify-space-between align-center mb-6 mt-6">-->
          <!--          <p class="caption font-weight-light mt-1">Web site is powered by © WeStudy Virtual University Platform. All rightes reserved. Details-->
          <!--            <a  href="#" style="text-decoration: none; color:white"><b>www.westudy.org</b> </a>-->
          <!--          </p>-->
          <!--          <img style="height: 30px"  class="mr-6" src="@/assets/brand/logo_white.png" alt="" />-->
          <!--        </div>-->




        </div>


      </v-sheet>

    </div>





  </div>
</template>

<script>




import {mapActions} from "vuex";
import loginSideBar from "@/components/pages/homepage/UI/loginSideBar";
import authModal from "@/components/auth/authModal";
import userDropdown from "@/components/UI/userDropdown";

export default {
  name: "PageHomepage",
  components : {
    loginSideBar,
    authModal,
    userDropdown,
  },
  data: ()=> ({
    stickyMenu : false,
    loading : false,
    leadDialog : false,
    specialtyWindow : false,
    specialties : [],
    specialty : [],
    selectedSpecialty : null,
    specialtyNav : 0,
    disciplines : null,
    news : [],
    specialtyTeachers : [],
    teachers : [],
    specialtiesSelect : [],
    application : {},
    applicationCheck : {},
    errorEmail : null,
    errorPhone : null,
    errorName : null,
    mobileMenu : false,
    next : false,
    degree : 1,
    degree_search : null,

    degreeSelect : [
      { text : 'Коледж', value : 'jsp'},
      { text : 'Бакалавріат', value : 'bsc'},
      { text : 'Магістратура', value : 'msc'},
      { text : 'PhD', value : 'phd'},
    ],
    menuData: [],
    menuParent : null,
    menuItems : [
        { title: 'Інформація', subtitle: 'FineTrading', widget: 'news', items : [
              { text : 'Про Компанію', route : '/'},
              { text : 'Оферта', route : '/'},
              { text : 'Конфеденційність', route : '/'},
              { text : 'Умови використання', route : '/'},
              { text : 'Умови повернення', route : '/'},
          ] },
      { title: 'Документація', subtitle: 'Інструкція',widget:'child', items : [
          { text : 'Повна документація', route : '/', children: { type : 'auto', source : 'faculties' }},
          { text : 'Мобільні додатки', route : '/', children: { type : 'auto', source : 'departments' }},
          { text : 'Інтеграції', route : '/'},
          { text : 'Можливості', route : '/'},
        ] },
      { title: 'Можливості', subtitle: 'Кейси', widget: 'specialties', items : [
        ] },
      { title: 'Тарифи', subtitle: 'Вступ', widget: 'specialties', items : [
        ] },
      { title: 'Контакти', subtitle: "Зв'язок", items : [
          { text : 'Спеціальності', route : '/'},
          { text : 'Підрозділи', route : '/'},
        ] }
    ],

  }),

  watch : {
    'window.top.scrollY'(val) {
      console.log(val)
    }
  },
  methods : {
    ...mapActions('public',  ['INIT_PUBLIC_MAIN' ]),
    scrolling(){
      if (window.top.scrollY > 44) { this.stickyMenu = true} else { this.stickyMenu = false}
    },
    scroll(target) {
      this.$vuetify.goTo(this.$refs[target],
          {
            duration: 300,
            offset: 70,
            easing: 'easeInOutQuad',
          })
    },
    menuAction(menu,item,i) {

      console.log(item,i)
      if(menu.widget && menu.widget ==='child') {
        this.menuParent = i
      }
    },
  },
  mounted() {

  }

}
</script>


<style lang="scss" scoped>

@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

.main_section {
  width:1100px
}

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");



</style>