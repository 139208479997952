<template>
  <div class="d-block">

    <v-hover v-if="!noMainImage"   v-slot="{ hover }">
      <div  style="overflow: hidden; position:relative" class="transition"
        :class="mainImageLoaded ? 'hover-mask' : '' ">

        <v-img  :aspect-ratio="ratio" :height="height" :width="width"
                 @load="mainImageLoaded = true" @error="noMainImage=true"
                 v-if="!$store.state.refresh"
                 class="transition" :class="hover && mainImageLoaded ? 'imageHover' : ''"
                 :src="src">

            <template slot="placeholder" >
                <div  style="background-color: #dddddd;" class=" fill-height d-flex align-center justify-center">

                    <v-progress-circular indeterminate color="primary lighten-2" />
                </div>
            </template>
        </v-img>

        <div style="position: absolute; top:0;bottom:0;right: 0;left: 0; z-index:3;" >
          <slot></slot>
        </div>

      </div>
    </v-hover>

    <v-hover v-if="noMainImage"   v-slot="{ hover }">
      <div  style="overflow: hidden; position:relative" class="transition"
            :class="mainImageLoaded ? 'hover-mask' : '' ">

        <slot  name="placeholder">
          <v-img  :aspect-ratio="ratio" :height="height" :width="width"
                  v-if="!$store.state.refresh"
                  class="transition" :class="hover && mainImageLoaded ? 'imageHover' : ''"
                  :src="defaultImage">
              <template slot="placeholder" >
                  <div  style="background-color: #dddddd;" class=" fill-height d-flex align-center justify-center">
                      <v-progress-circular indeterminate color="primary lighten-2" />
                  </div>
              </template>
          </v-img>
        </slot>

        <div style="position: absolute; top:0;bottom:0;right: 0;left: 0; z-index:3;" >
          <slot></slot>
        </div>

      </div>
    </v-hover>

  </div>
</template>

<script>
export default {
  name: "imago",
  props: {
    defaultImage : {
      default:"https://wallpaperaccess.com/full/516089.png",
      type:String
    },
    src : { type:String },
    ratio : { type:String, default:null },
    width : { type:String,default:null },
    height : { type:String,default:null },
  },
  watch : {
    src(){
      this.checkSrc()
    }
  },
  data() {
    return {
      mainImageLoaded : false,
      noMainImage : false,
    }
  },
  methods : {
    checkSrc() {
      if ( this.src === null || this.src === undefined ) {
        this.noMainImage = true
      } else {
        this.noMainImage = false
      }
    },
  },
  mounted() {
    this.checkSrc()
  }
}
</script>

<style lang="scss" scoped>

.transition { transition: all .4s ease-out; }
.hover-mask { background-color: #00000000; }
.imageHover {
  opacity: 0.8;
  transform: scale(1.1);
}
</style>