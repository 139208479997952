<template>
  <v-sheet class="py-6 px-8">
    <div class="px-1 d-flex justify-space-between align-center mb-8 ">
      <h2 class="font-weight-light">Додати менеджера</h2>
      <v-btn-toggle v-model="manager.userType" rounded>
        <v-btn value="new" class="noCaps font-weight-light">Новий користувач</v-btn>
        <v-btn value="subscriber" class="noCaps font-weight-light">Серед Підписників</v-btn>
      </v-btn-toggle>
    </div>



    <v-select v-if="manager.userType === 'subscriber'" v-model="manager.user" rounded filled :items="users" label="Користувач"/>
    <div v-else>
      <v-row>
        <v-col cols="6">
          <v-text-field v-model="manager.name"             label="Ім'я"     rounded filled />
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="manager.sirname"          label="Прізвище" rounded filled />
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="manager.email"            label="Email"    rounded filled />
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="manager.phone"            label="Телефон"  rounded filled />
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="manager.password"         label="Пароль"    rounded filled />
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="manager.passwordAgain"   label="Пароль ще раз"    rounded filled />
        </v-col>
      </v-row>



    </div>

    <v-select v-model="manager.roles"  rounded filled  multiple :items="roles" label="Права"/>



    <div class="d-flex justify-end">
      <div>
        <v-btn @click="$emit('close')" rounded text>Скасувати</v-btn>
        <v-btn rounded outlined @click="saveChanges">Додати</v-btn>
      </div>
    </div>

  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "editManager",
  props : ['users','wareSelect'],
  data(){
    return {
      loading : false,
      subscribers : [
        { text : 'Jeff Bezos', value : 'piske'},
        { text : 'Mr Bean', value : 'piskerrr'}
      ],
      manager : {
        userType : 'new',
        user : '',
        roles : [],
      },
      roles : [
        { text: 'Замовлення', value: 'MODULE_ORDERS'},
        { text : 'Доставка', value : 'MODULE_DELIVERY' },
        { text : 'Шаблони',  value : 'MODULE_TEMPLATER'},
        { text : 'Новини',   value : 'MODULE_NEWS' },
        { text : 'Товари',   value : 'MODULE_PRODUCTS'},
        { text : 'Склад',    value : 'MODULE_WAREHOUSE'},

      ]
    }
  },
  methods : {
    ...mapActions( 'business', [ 'ADD_MANAGER' ] ),
    saveChanges() {
      this.loading = true
      this.ADD_MANAGER(this.manager).then((out)=>{

        if ( out ) {
        if ( out.result ) {
          this.notify('Менеджера додано')
          this.$emit('update',out.data)
        } } else {
          this.notify('Не правильні дані або менеджер вже існує')
        }
        this.loading = false
      }).then(()=>{
        this.loading = false })


    }
  }
}
</script>

<style scoped>

</style>