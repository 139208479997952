<template>
  <v-expand-transition mode="out-in">
    <v-sheet  dark width="100%"  style="position: absolute; top:93px; z-index: 999" color="rgb(100, 181, 246)"  >
    <v-divider />


      <v-list  dense class="pb-0 pt-0" color="rgb(100, 181, 246)" >

        <!-- MAIN MENU ITEMS-->
        <v-subheader class="pl-6">Меню</v-subheader>
        <div v-for="(item,i) in param" :key="i">
          <router-link  v-if="item.action === 'page' "
                        @click="mobileMenu = false"
                        :to="{ path : getRouterPath(item.route) , hash : item.scroll }"
                        class="text-decoration-none">
            <v-list-item  @click="$emit('collapse');$store.state.templates.triggerScroll++" v-ripple v-if="item.items.length === 0" >
                      <v-list-item-content>
                        <v-list-item-title class="pl-8">{{ item.title }}   </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
          </router-link>
          <v-list-item v-if="item.action === 'link' "
                       @click="$emit('collapse')"
                       :href="item.link"  v-ripple  >
            <v-list-item-content>
              <v-list-item-title class=" pl-8">{{ item.title }}   </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-if="item.action === 'submenu'" color="white"  >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class=" pl-8">{{ item.title }}   </v-list-item-title>
              </v-list-item-content>
              <!--            <v-list-item-icon v-if="item.items.length > 0"><v-icon>mdi-chevron-down</v-icon></v-list-item-icon>-->
            </template>
            <v-list-item-group>

              <div  v-for="(subitem, j) in item.items" :key="j"
                            style="background: rgb(133,189,233)">
                <router-link  v-if="subitem.action === 'page' "
                              @click="$emit('collapse')"
                              :to="{ path : getRouterPath(subitem.route) , hash : subitem.scroll }"
                              class="text-decoration-none">
                  <v-list-item @click="$emit('collapse'); $store.state.templates.triggerScroll++"
                               color="white"
                               dense  class="pl-12" >
                    <v-list-item-content>
                      <v-list-item-title class=" pl-8">{{ subitem.title }}   </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </router-link>
                <v-list-item dense class="pl-12"
                             color="white"
                             v-if="subitem.action === 'link' "
                             @click="$emit('collapse')"
                             :href="subitem.link"  v-ripple  >
                  <v-list-item-content>
                    <v-list-item-title class=" pl-8">{{ subitem.title }}   </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>




            </v-list-item-group>


          </v-list-group>
        </div>
        <!-- AUTH  WINDOW-->
        <v-subheader class="pl-6">Профіль</v-subheader>
        <v-list-item-group>

          <v-list-item v-if="!loggedIn"
                       @click="toggleLoginMenu(); $emit('collapse')"
                       class="pl-11"    >
            <v-list-item-content >
              <v-list-item-title class="d-flex align-center" >
                <v-icon small class="mr-3" >mdi-login-variant</v-icon>
                Увійти / Зереєструватися
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="loggedIn"
                       @click="$router.push(`/dashboard/${$store.state.auth.rootPlace}`); $emit('collapse')"
                       class="pl-11">
            <v-list-item-content>
              <div class="d-flex align-center">
                <v-avatar class="mr-2"  size="22" key="profile_picture" >
                  <v-img :src="$store.state.filesPublic+'users/avatars/'+$store.state.auth.userID">
                    <template slot="placeholder">
                      <v-img src="@/assets/placeholder/user_blank.jpg" />
                    </template>
                  </v-img>
                </v-avatar>
                <div>
                  <h5 class="">{{ userName }}</h5>
                  <span class="caption my-0 py-0" style="line-height: 1">{{ userEmail  }}</span>
                </div>

              </div>


            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="loggedIn"
                       @click="$router.push(`/dashboard/${$store.state.auth.rootPlace}`); $emit('collapse')"
                       class="pl-11"    >
            <v-list-item-content >
              <v-list-item-title class="d-flex align-center" >
                <v-icon small class="mr-3" >mdi-view-dashboard</v-icon>
                Користувацька панель
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="loggedIn"
                       @click="logout(); $emit('collapse')"
                       class="pl-11"    >
            <v-list-item-content >
              <v-list-item-title class="d-flex align-center" >
                <v-icon small class="mr-3" >mdi-logout-variant</v-icon>
                Вийти
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-item-group>

        <v-divider class="mt-4" />
        <v-divider  />



      </v-list>
    </v-sheet>
  </v-expand-transition>

</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "avalonMainMenu",
  props : ['input','mobileMenu'],
  computed : {
    ...mapState('templates',  [ 'templateVars_init' , 'templateVars_current','editorMode','openEditor','selectedItem' ]),
    ...mapState('auth',['loggedIn','userName','userEmail']),
    param() {
      return this.editorMode ? this.templateVars_current[this.input].buffer : this.templateVars_init[this.input]
    },
  },
  methods : {
    ...mapMutations(['toggleLoginMenu']),
    ...mapMutations('auth',['LOGOUT']),

    logout() {
      this.LOGOUT(this.$router);
    },
    getRouterPath(route) {
      let fullPath = ''
      if ( route !== 'homepage') {
        fullPath = !this.$store.state.domain ? `/${this.$store.state.business.selectedBusiness.alias}/` : '/'
        fullPath += 'page/' + route
        if (this.editorMode) {
          fullPath += '/edit'
        }
      } else {
        if ( this.$store.state.domain ) {
          fullPath = this.editorMode ? '/edit' : '/'
        } else {
          fullPath = this.editorMode ? `/${this.$store.state.business.selectedBusiness.alias}/edit` : `/${this.$store.state.business.selectedBusiness.alias}/`
        }
      }
      return fullPath
    },
    click(){
      return true
    }
  },
}
</script>

<style scoped>
.headerMenuItem {
  font-size: 1rem !important;
  transition: all 0.2s ease;
  text-decoration: none;
  color : #f1f1f1;
}
.headerMenuItem:hover {
  background-color: #343434;
}
.linkStyles {
  font-size: 14px;
  font-weight: 400;
  text-transform: none !important;
  letter-spacing: 1px !important;
}
</style>