<template>
  <div >




      <v-sheet color="white" class="py-8"  title="Бізнес Акаунти">


        <v-row>
          <v-col cols="6" v-for="(business,i) in $store.state.auth.user.businesses" :key="i">
            <v-sheet   outlined class="pa-8 pt-6 mb-2" >
              <v-simple-table >
                <tbody class="businessTable">
                <tr>
                  <td  colspan="3" class="text-center businessTable pb-6"><h1 class="font-weight-thin">{{ business.name }}</h1></td>
                </tr>
                <tr>
                  <td width="150px">Cтатус: </td>
                  <td><h3 class="light-green--text font-weight-medium">Активний</h3></td>
                  <td></td>
                </tr>
                <tr>
                  <td width="150px">Дата реєстрації: </td>
                  <td><h3>{{ business.created }}</h3></td>
                  <td></td>
                </tr>
                <tr>
                  <td width="150px">Посилання: </td>
                  <td><h3>{{ business.alias }}</h3></td>
                  <td></td>
                </tr>
                <tr>
                  <td width="150px">Тарифний план: </td>
                  <td><h3>FREE TRIAL</h3></td>
                  <td></td>
                </tr>
                <tr>
                  <td width="150px">Власний домен: </td>
                  <td><h3>Ні</h3></td>
                  <td><v-btn text rounded class="px-6 " color="blue darken-2 " >Замовити</v-btn></td>
                </tr>
                <tr>
                  <td width="150px">Підписка до: </td>
                  <td><h3>{{ business.created }}</h3></td>
                  <td><v-btn text color="blue darken-2" >Продовжити</v-btn></td>
                </tr>
                <tr>
                  <td width="150px">Автопроодовження: </td>
                  <td><h3>Так</h3></td>
                  <td><v-btn text color="blue darken-2" >Налаштування</v-btn></td>
                </tr>
                </tbody>
              </v-simple-table>

              <div class="d-flex justify-center mt-6">
                <v-btn rounded outlined large color="blue darken-2" >Панель управління</v-btn>
              </div>

            </v-sheet>
          </v-col>
          <v-col cols="6">

              <v-btn block dark class="rounded-0 py-8" filled color="blue darken-2"   :loading="loading" :disabled="loading"
                     @click="displayAddBusiness = true">
                <v-icon>mdi-plus</v-icon> Додати Бізнес
              </v-btn>

          </v-col>
        </v-row>

      </v-sheet>

    <v-dialog width="900" v-model="displayAddBusiness">
      <addBusiness v-if="displayAddBusiness" @onFinish="businessAdded" />
    </v-dialog>

    <!-- NOTIFICATION FOR CHANGE-->
    <v-snackbar v-model="notification" timeout="2000" color="#459ad2" class="rounded-0 mb-3">
      {{  notification_text  }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="notification = false" > Close </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
//import card from "@/components/pages/dashboard/UI/card";
import addBusiness from "@/components/pages/dashboard/business/addBusiness";

import {mapActions} from "vuex";
export default {
  name: "education",
  components : {

    addBusiness
  },
  props : ['user_id'],
  data() {
    return {

      displayAddBusiness : false,

      loading : false,
      notification : false,
      notification_text : '',


      userId : '',

      education : [],
      menuGraduation : [],
      showUploadDialog : false,

      documentTypes : [],
      degrees : [],

      //upload data
      uploadItem : {},
      documentsUploadOptions: {
        url:this.$store.state.ajaxRoute+'upload/applicationEducation',
        method: 'POST',
        thumbnailWidth: 150,
        thumbnailHeight: 50,
        maxFilesize: 5,
        headers: { "Auth": this.$store.state.auth.accessToken },
        addRemoveLinks : true,
        uploadMultiple : false,
        maxFiles : 1
      },
    }
  },
  methods : {
    ...mapActions('business',
        [ 'ADD_BUSINESS' ]),

    businessAdded() {
      this.displayAddBusiness = false
    },

    addRecord(degree){
      this.loading = true;
      var data = {
        user_id : this.userId,
        degree : degree
      }

      this.ADD_EDUCATION(data).then((res)=> {
        this.education.push(res.data)
        this.loading = false;
      }).catch(()=>{
        this.loading = false;
      })

    },
    saveRecord(item){
      this.loading = true;
      this.EDIT_EDUCATION(item).then((res)=> {

        if(res.data) {
          this.notification = true;
          this.notification_text = 'Education information saved'
        } else {
          this.notification = true;
          this.notification_text = 'Connection error'
        }
        this.loading = false;
      }).catch(()=>{
        this.notification = true;
        this.notification_text = 'Connection error'
        this.loading = false;
      })

    },
    deleteRecord(id){

      this.DELETE_EDUCATION(id).then((res)=> {

        if(res.data) {
          var index = this.education.findIndex(edu => edu.id == id)
          this.education.splice(index,1);
        }
        this.loading = false;
      }).catch(()=>{
        this.loading = false;
      })

    },
    //upload documents
    openUploads(item,type,index) {

        this.uploadItem = {
          'document_text' : type.name,
          'degree' : item.degree,
          'id' : item.id,
          'document' : type.alias,
          'index' : index
        }

        this.showUploadDialog = true;

    },
    sendingDocument( file, xhr, formData) {
      formData.append('DocumentType',this.uploadItem.document)
      formData.append('RecordId',this.uploadItem.id)
      formData.append('Degree',this.uploadItem.degree)
      formData.append('UserID',this.userId);
    },
    afterUploadDocument(response) {

      this.showUploadDialog = false

      var doc_index = this.education[this.uploadItem.index].uploads.findIndex(doc => doc.alias === this.uploadItem.document)

      if (doc_index != -1) {
        this.education[this.uploadItem.index].uploads[doc_index].file_id = JSON.parse(response.xhr.response).file_id;
        this.education[this.uploadItem.index].uploads[doc_index].name  = JSON.parse(response.xhr.response).file_name;
      } else {

        this.education[this.uploadItem.index].uploads.push({

          file_id : JSON.parse(response.xhr.response).file_id,
          name : JSON.parse(response.xhr.response).file_name,
          id : JSON.parse(response.xhr.response).id,
          alias: JSON.parse(response.xhr.response).alias,
          education_id: JSON.parse(response.xhr.response).education_id,
          user_id: JSON.parse(response.xhr.response).user_id

        })


      }


    },
    deleteDocument(id,index) {

      this.loading = true;

      this.DELETE_EDUCATION_DOCUMENT({id : id }).then((out)=>{
        if (out.result) {
          var doc_index = this.education[index].uploads.findIndex(doc => doc.id === id);
          this.education[index].uploads.splice(doc_index,1)
        }

        this.loading = false;
      }).catch(()=>{
        this.loading = false;
      })

    },
    // documents files
    docData(id,type) {
      var index = this.education.findIndex(edu => edu.id === id)
      var doc_index = this.education[index].uploads.findIndex(doc => doc.alias === type)


      if (doc_index != -1) {
        return {
          name : this.education[index].uploads[doc_index].name,
          link: this.$store.state.ajaxRoute+'files?id='+this.education[index].uploads[doc_index].file_id,
          id: this.education[index].uploads[doc_index].id
        }
      }
      else { return { name : 'no file', link : false }
      }

    },
  },
  async mounted() {

  }
}
</script>

<style scoped lang="scss">
 .businessTable {

       border: none;

 }
</style>