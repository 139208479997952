export default {
    computed: {
        windowSize()  {
            let breakPoint = this.$vuetify.breakpoint.name
            if ( breakPoint === 'xs' ) { breakPoint = 'sm' }
            if ( breakPoint === 'xl' ) { breakPoint = 'lg' }
            return breakPoint
        },
        DEVICE() {
            let device = 'desktop'

            if ( this.$device.mobile ) {
                device = "mobile"
            }


            return device
        }

    },
    methods : {
        displayHide(sizes) {
            return !sizes.includes(this.windowSize )
        },
        displayShow(sizes) {
            return sizes.includes(this.windowSize )
        },
        TEMPLATES_GET_ELEMENT(element) {

            if ( this.$store.state.templates.templatesConfig[this.$store.state.templates.businessTemplate ].elements.includes(element) ) {
                return this.$store.state.templates.businessTemplate + element
            } else {
                return 'avalon' + element
            }

        },
        TEMPLATES_GET_PARAM(varName) {
                let param = '';
                if (  this.$store.state.templates.editorMode ) { param = this.$store.state.templates.templateVars_current[varName].value }
                if ( !this.$store.state.templates.editorMode ) { param = this.$store.state.templates.templateVars_init[varName]    }
                return param
        },
        TEMPLATES_ACCENT_COLOR() {
            return this.TEMPLATES_GET_PARAM('accent_color')
        },
        TEMPLATES_flexAlignment(alignment) {
            switch(alignment) {
                case 'left' : return 'justify-space-center'
                case 'center' : return 'justify-center'
                case 'right' : return 'justify-end'
                case 'justify' : return 'justify-space-between'
                default : return ''
            }
        }

    }
}