<template>
  <div class="pa-16" style="width: 600px">

    <div class="d-flex justify-space-between align-center mb-16">
      <h1 class="text-h4 font-weight-light ">Інтеграції чат ботів</h1>
      <v-btn @click="saveChanges" rounded text large  color="teal" class="ml-4 mt-1">
        <v-icon class="mr-2">mdi-check</v-icon>Зберегти</v-btn>
    </div>


    <div class="d-flex  align-center justify-space-between my-3">
      <div class="d-flex align-center">
        <v-btn icon >
          <v-icon  >mdi-chevron-down</v-icon>
        </v-btn>
        <h3 style="line-height: 1.2" class="font-weight-medium mb-0">Telegram</h3>
      </div>

    </div>

    <v-divider/>
    <div class="text-center mt-6 px-3">Акаунт використовує загальний чат бот <b>FineTrading</b></div>


    <div class="d-flex justify-space-between align-center pa-3 mt-6">
      <v-subheader > <v-icon color="teal" class="mr-1">mdi-information-outline</v-icon>Інтеграція Telegram</v-subheader>
      <v-btn @click="editTelegram" color="teal" dark rounded class="px-6">Під'єднати</v-btn>
    </div>

    <v-text-field   v-model="businessServices.telegram_token"
                    label="Telegram TOKEN"
                    hide-details dense rounded filled
                    class=" mb-6 mt-3"  />
    <v-text-field   v-model="businessServices.telegram_alias"
                    label="Telegram Bot Link"
                    hide-details dense rounded filled
                    class=" mb-6 mt-3"  />
    <v-text-field   v-model="businessServices.gTag"
                    label="Telegram Bot Link"
                    hide-details dense rounded filled
                    class=" mb-6 mt-3"  />

    <v-divider />

  </div>
</template>

<script>
import {mapState,mapActions} from "vuex";

export default {
  name: "BusinessDashboardModules",
  data() {
    return {
      loading : false,
      modules : [ ],
      businessServices : {
        telegram_token : '',
      },
      serviceName : '',
      serviceValue : '',
    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
  },
  methods : {
    ...mapActions('business', ['UPDATE_BUSINESS_SERVICES_KEYS','GET_BUSINESS_SERVICES_DATA']),


    editTelegram() {
      let data = []
      Object.keys(this.businessServices).forEach((key)=>{
        data.push(
            { name : key, value : this.businessServices[key] }
        )
      })
      this.serviceName = 'telegram_token'
      this.serviceValue = this.businessServices.telegram_token
      this.saveChanges(data)
    },

    saveChanges(data){

      this.loading = true

      this.UPDATE_BUSINESS_SERVICES_KEYS({data : data}).then((out)=>{
        if ( out.result ) {
          this.notify('Налаштування збережено')
        }
        this.loading = false
      }).catch(()=>this.loading = false)
    }
  },
  mounted() {
    this.loading = true
    this.GET_MODULES().then((out)=>{
      if ( out.data) {
        this.modules = out.data
      }
      this.loading = false
    }).catch(()=>{ this.loading = false })

  }
}
</script>

<style scoped>

</style>