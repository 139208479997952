var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-8"},[_c('v-row',_vm._l((_vm.headerBlocks),function(block,i){return _c('v-col',{key:i,attrs:{"cols":"6","sm":"6","xs":"6","md":"6","lg":"3","xl":"3"}},[_c('v-sheet',{staticClass:"py-4 px-4 d-flex align-center",attrs:{"height":"100","dark":"","color":block.color}},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"32"}},[_vm._v(_vm._s(block.icon))]),_c('div',[_c('div',{staticClass:"mb-0 caption"},[_vm._v(_vm._s(block.title))]),_c('h3',{staticClass:"mt-0 font-weight-light"},[_vm._v(_vm._s(block.content)+" ")])])],1)],1)}),1),_c('v-divider',{staticClass:"mt-5"}),_c('div',{staticClass:"d-flex justify-space-between align-center  pa-3"},[_c('div',{staticClass:"d-flex"},[_c('v-chip',{staticClass:"mr-2 ",staticStyle:{"padding":"13px 10px","border-radius":"26px"},attrs:{"small":"","outlined":"","color":"grey darken-3"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-magnify")]),_c('v-text-field',{staticClass:"caption ml-n3",staticStyle:{"padding-bottom":"2px","margin-left":"0"},attrs:{"filled":"","background-color":"transparent","placeholder":"Пошук","dense":"","hide-details":""},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('v-menu',{ref:"dateFilter",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"noCaps mr-2",attrs:{"dark":_vm.filter.date.length >1,"outlined":_vm.filter.date.length <2,"color":_vm.filter.date.length >1 ? 'blue lighten-2' : 'grey darken-3',"small":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(_vm.filter.date.length > 1 ? ("з " + (_vm.filter.date[0]) + " по " + (_vm.filter.date[1]) + " ") : 'Весь період')+" ")],1)]}}])},[_c('v-date-picker',{attrs:{"range":"","no-title":"","min":"2020-01-01","max":"2150-01-01"},on:{"change":function($event){return _vm.$refs.dateFilter.save(_vm.filter.date)}},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}})],1),_c('v-btn',{staticClass:"noCaps",attrs:{"dark":_vm.filter.hide,"outlined":!_vm.filter.hide,"color":_vm.filter.hide ? 'blue lighten-2' : 'grey darken-3',"small":"","rounded":""},on:{"click":function($event){_vm.filter.hide = !_vm.filter.hide}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")]),_vm._v(" Тільки із Замовленнями ")],1),_c('v-btn',{staticClass:"noCaps",attrs:{"small":"","rounded":"","text":""},on:{"click":_vm.resetFilter}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]),_vm._v("Скинути")],1)],1),_c('v-chip',{attrs:{"small":"","dark":"","color":"blue darken-3"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-account-multiple")]),_vm._v(" "+_vm._s(_vm.subscriberFiltered.length)+" ")],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"items":_vm.subscriberFiltered,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.firstname",fn:function(ref){
var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(item.firstname)+" "+_vm._s(item.lastname))]),_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.subscribedAt)+" ")])]}},{key:"item.ordersCount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"teal lighten-2","dark":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v("mdi-cog")]),_c('h4',[_vm._v(_vm._s(item.ordersCount))])],1)]}},{key:"item.contacts",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" "),_c('br'),_vm._v(" "+_vm._s(item.phone)+" ")]}},{key:"item.totalIncome",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"green lighten-2","dark":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v("mdi-cash-multiple")]),_c('h4',[_vm._v(" "+_vm._s(item.totalIncome)+" грн")])],1)]}},{key:"item.rentedProductsCount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"primary lighten-2","dark":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v("mdi-cart")]),_c('h4',[_vm._v(" "+_vm._s(item.rentedProductsCount)+" шт")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }