<template>
  <div>
    <v-row>
      <v-col cols="5">
        <h5 class="mb-6 text-center">Поточне зображення:</h5>
        <imago style="border: 3px solid #444" ratio="1.4" :src="$store.state.templates.selectedItem.buffer" />
      </v-col>
      <v-col>
        <h5 class="mb-6 text-center">Параметри: </h5>
      </v-col>
    </v-row>

    <h3 style="line-height: 1.2" class="mt-9 mb-10 font-weight-medium text-center">Посилання</h3>
    <v-text-field
                  v-model="$store.state.templates.selectedItem.buffer"
                  class="mt-10" rounded filled dense placeholder="Value" label="Введіть значення" required />

    <h3 style="line-height: 1.2" class="mt-3 mb-10 font-weight-medium text-center">Завантажити</h3>

    <dropzone dark  @vdropzone-complete="afterUpload"
               @vdropzone-sending="sendingPhoto"
               key="change_picture" class="mb-3 mt-5"
               ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" />


  </div>
</template>

<script>
import imago from "@/components/UI/imago";

import {mapState,mapActions} from "vuex";
import vue2Dropzone from "vue2-dropzone";

export default {
  name: "ftEditorImage",
  components : {
    imago,
    dropzone : vue2Dropzone,
  },
  data(){
    return {
      dropzoneOptions: {
        url: this.$store.state.ajaxRoute+'image',
        method: 'POST',
        maxFilesize: 2.5,
        headers: {
          "Authorization": 'Bearer '+this.$store.state.auth.accessToken },
        addRemoveLinks : true,
        uploadMultiple : false,
        maxFiles : 1
      },
    }
  },
  computed : {
    ...mapState('templates',  [ 'selectedItem'  ]),
  },
  methods : {
    ...mapActions('templates',  [ 'SAVE_DRAFT_ITEM'  ]),

    afterUpload(response){
      this.showUpload = false
      const name = JSON.parse(response.xhr.response).data.name
      this.saveChanges(name)


    },
    sendingPhoto(file, xhr, formData) {
      console.log(file, xhr, formData)
      // formData.append('Folder','specialties/'+this.$route.params.alias);
      formData.append('type','title');
    },
    saveChanges(image) {

      let data = [
        { item  : parseInt(this.selectedItem.item),
          type  : parseInt(this.selectedItem.type),
          value : { new : true , src : image}
        }
      ]

      this.SAVE_DRAFT_ITEM(
          {
            alias: this.$store.state.templates.businessAlias,
            data: {items : data}
          }  )
          .then((out)=>{
            this.notify('Чернетку збережено')
            this.$store.state.templates.selectedItem.buffer = out.data

          })

    }
  }
}
</script>

<style scoped>

</style>