<template>
  <v-sheet  color="#0b0b0b" dark min-height="600" >
    <div class="section-content py-16">

      <!-- Slot for edit block menu-->
      <slot name="edit"> </slot>

      <div class="d-flex align-center">
        <h4 style="letter-spacing: 2px" class="font-weight-medium">НОВИНИ</h4>
        <div class="ml-6" style="width: 150px"><v-divider /><v-divider /></div>
      </div>
      <h1 :style="accentColor" class="">Останні Події</h1>
      <v-row class="pt-16 mb-8">
        <v-col v-for="(article,i) in news" :key="i" cols="4">
          <div>

            <imago :src="article.image_medium" ratio="1.4" >
              <div  class=" fill-height d-flex align-end">
                <router-link style="color:white; text-decoration: none" :to="`/${businessAlias}/news/${article.alias}`">
                  <v-sheet color="#222222bb" class="d-block v-text-field--full-width pa-5"   >
                    <span class="caption">{{  article.date  }}</span>
                    <h2 class="font-weight-medium" style="line-height: 1.2">{{ article.title }}</h2>
                  </v-sheet>
                </router-link>
              </div>
            </imago>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
import imago from "@/components/UI/imago";
import { mapState} from "vuex";
export default {
  name: "avalonBlockNews",
  components : {
    imago
  },
  computed : {
    ...mapState('templates',  [ 'news', 'businessAlias', 'editorMode', 'templateVars_init', 'templateVars_current'  ]),
    accentColor() {
      return `color: ${this.parametr('accent_color')}`
    }
  },
  methods : {
  //  ...mapActions('business', [ 'GET_NEWS']),
    parametr(varName) {
      let param = '';
      if (  this.editorMode ) { param = this.templateVars_current[varName].value }
      if ( !this.editorMode ) { param = this.templateVars_init[varName]    }
      return param
    },
  },
}
</script>

<style scoped>

</style>