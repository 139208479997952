<template>
<div class="main d-flex  align-center justify-center">
   <div>
     <p>Confirming account ...</p>
     <div class="d-flex justify-center">
       <v-progress-circular color="white" class=" justify-center" indeterminate size="48" />
     </div>

   </div>

</div>
</template>

<script>
export default {
  name: "LoadingPage",
  async mounted() {
    var request = await this.$store.dispatch('auth/CONFIRM_ACCOUNT',{code:this.$route.query.data})
    if (request.result) {
      this.$router.push('/dashboard')
    } else {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.main {
  width:100%;
  height:100%;
  background-color: #2d83de;
  color: white;
}
</style>