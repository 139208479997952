<template>
  <div style="transition: all .6s ease" class="px-3 mt-3">

    <div class="d-flex justify-space-between align-center  pb-1   ">
      <h5 class="text-center font-weight-medium " > Видалити елемент меню   </h5>
      <v-btn small icon @click="cancelEdit()">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-divider class="mb-3" />

    <h4 class="text-center mt-6" > Ви впевнені, що хочете видалити елемент :   </h4>

    <h5 class="text-center mt-6"> {{ value.title }} ?</h5>
    <div v-if="value.action === 'submenu' && value.items.length > 0"
         class="d-flex justify-center" >
          <v-switch dense v-model="deleteChild"  >
            <template slot="label">
              <span class="caption">Видалити елементи підменю</span>
            </template>
          </v-switch>
    </div>


    <div class="d-flex justify-end align-center mb-5 mt-9 ">
      <v-btn text class="noCaps" small  @click="$emit('closeDelete')" >
        <v-icon>mdi-save</v-icon>
        Cкасувати
      </v-btn>
      <v-btn outlined class="noCaps" small   @click="$emit('deleteRow',deleteChild)" >
        <v-icon>mdi-save</v-icon>
        Так
      </v-btn>
    </div>



  </div>
</template>

<script>
export default {
  name: "ftMainMenuEditElement",
  props : ['value','child'],
  data() {
    return {

      deleteChild : false ,

      buffer : {},

      actions: [
        {text: 'Сторінка',  value: 'page'},
        {text: 'Навігація', value: 'scroll'},
        {text: 'Посилання', value: 'link'},
        {text: 'Підменю',   value: 'submenu'},
      ],

      pages: [
        {text: 'Новини',     value: '/news'},
        {text: 'Всі твоари', value: '/products'},
        {text: 'Контакти',   value: '/contacts'},
      ],

      blocks: [
        {text: 'Інформація',     value: '  news'    },
        {text: 'Новини',     value: '  news'    },
        {text: 'Товари',     value:   'products'},
        {text: 'Контакти',   value: 'contacts'},
      ]

    }
  },
  watch : {
    value(value){
      this.$emit('input',value)
    }
  },
  methods : {
    cancelEdit() {
      this.$emit('input',this.buffer)
      this.$emit('editRow')
    }
  },
  mounted() {
    this.buffer = JSON.parse( JSON.stringify(this.value) )
    if (this.child) {
      this.actions.pop()
    }
  }
}
</script>

<style scoped>

</style>