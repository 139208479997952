<template>
  <div class="headerWrapper"
        :style="loggedIn && userBusinesses.includes(businessAlias) ? 'top: 35px !important' : 'top:0'">

    <avalonMainMenu_mobile_var_2
        v-if="displayShow(['xs','sm']) && mobileMenu"
        :mobile-menu="mobileMenu"
        @collapse="mobileMenu = false"
        input="mainMenu" />
    <!-- HEADER MAIN-->
    <v-sheet :color="stHeader || mobileMenu ? 'rgb(100, 181, 246)' : '#00000000'  "
             class="d-flex align-center header py-3"
             dark>
      <div class="section-content d-flex justify-space-between align-center px-3">

        <v-btn v-if="displayShow(['xs','sm'])" icon  @click="mobileMenu=!mobileMenu"><v-icon>{{ mobileMenu ? 'mdi-close' : 'mdi-menu'}}</v-icon></v-btn>

        <v-sheet color="transparent" >
          <router-link :to="`/${ $store.state.domain ? '' : businessAlias}`">
            <img style="height: 55px" class="mt-2" :src="parametr('logo')" alt=""/>
          </router-link>
        </v-sheet>


        <avalonMainMenu_var_2 v-if="displayHide(['xs','sm'])" class="d-flex" input="mainMenu" />



        <v-sheet color="transparent" >

          <div class="d-flex justify-end align-center">
            <v-btn large  icon class="mr-2" @click="$store.state.cart.showCheckout = true">
              <v-badge :content="quantity" :value="quantity" :color="parametr('accent_color')" overlap>
                <v-icon >{{ quantity > 0 ? 'mdi-cart' : 'mdi-cart-outline' }}</v-icon>
              </v-badge>
            </v-btn>
<!--            <notificationMenu v-if="$store.state.auth.loggedIn" class="ml-2" />-->
            <userDropdown v-if="$store.state.auth.loggedIn && displayHide(['xs','sm'])"
                          :main_menu="false"
                          class="userDropdown"
                          img_size="0"/>
            <v-btn v-if="!$store.state.auth.loggedIn && displayHide(['xs','sm'])"
                   class="linkStyles px-6 mr-2"
                   color="#00000022"
                   rounded elevation="0"
                   @click="toggleLoginMenu">Увійти</v-btn>
          </div>
        </v-sheet>






      </div>


    </v-sheet>
    <v-divider class="section-content" dark />
    <v-divider class="section-content" dark />




  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
// import notificationMenu from "@/components/UI/header/notificationMenu";
import userDropdown from "@/components/UI/userDropdown";
import avalonMainMenu_var_2 from "@/components/templates/Avalon/UI/elements/avalonMainMenu_var_2";
import avalonMainMenu_mobile_var_2 from "@/components/templates/Avalon/UI/elements/avalonMainMenu_mobile_var_2";
export default {
  name: "avalonHeader",
  props: ['sticky'],
  components: {
    // notificationMenu,
    userDropdown,
    avalonMainMenu_var_2,
    avalonMainMenu_mobile_var_2
  },
  data() {
    return {
      scrollPosition: 0,

      mobileMenu: false,
      logo: '',
      accentColor: '',



    }
  },
  computed: {
    ...mapState('templates', [ 'businessAlias', 'editorMode', 'menuItems' , 'templateVars_init', 'templateVars_current']),
    ...mapState('cart', [ 'quantity']),
    ...mapState('auth', ['loggedIn', 'userBusinesses']),

    collapseMobileMenuBool() {
      return this.displayHide(['xs','sm'])
    },
    routeChange(){
      return this.$route.path
    },

    stHeader() {
      if ( this.scrollPosition > 150) { return true }
      return false
    },
  },
  watch: {
    collapseMobileMenuBool(val){
      if ( val ) { this.mobileMenu = false }
    },
    routeChange() {
      this.mobileMenu = false
    }
  },
  methods: {
    ...mapMutations(['toggleLoginMenu']),

    buttonAction(action) {
      this.notify(action)
    },
    showCart() {
      this.$store.state.cart.showCart = !this.$store.state.cart.showCart
    },
    menuAction(a, b, c) {
      console.log(a, b, c)
      this.notify('menuAction')
    },
    //technical
    parametr(varName) {
      let param = '';
      if (this.editorMode) {
        param = this.templateVars_current[varName] ? this.templateVars_current[varName].buffer : 'null'
      }
      if (!this.editorMode) {
        param = this.templateVars_init[varName]
      }
      return param
    },

    onScroll() {
      this.scrollPosition = window.top.scrollY
    }

  },

  mounted() {
    this.logo = 'http://localhost:8000/stor/avalon_tmpl_logo.png'

    if (this.parametr('accent_color') != 'null') {
      this.accentColor = this.parametr('accent_color')
    } else {
      this.accentColor = '#fbc02d'
    }





    window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
}
</script>

<style scoped>
.headerWrapper {
  position: fixed;
  top:0px;
  width: 100%;
  z-index : 10;
}
.header {
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  transition: all .6s ease;
}

.stickyHeader {

  position: fixed !important;
  left: 0;
  right: 0;
  z-index: 20;

}

.stickyHeaderEdit {
  top: 36px !important;
}

.section-content {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}

.preHeader {
  padding: 0px 50px;
  background-color: #ffffff00;

  top : 0;
  width: 100%;
  z-index: 21;
}

.w-90vh {
  width: 90%
}
.linkStyles {
  font-size: 14px !important;
  font-weight: 400;
  text-transform: none !important;
  letter-spacing: 1px !important;
}
</style>