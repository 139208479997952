<template>
  <div>
    <v-sheet dark class="pa-4 text-center">
      2021 - {{ $store.state.templates.businessName }} - Всі права захищено
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "avalonFooter"
}
</script>

<style scoped>

</style>