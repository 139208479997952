<template>
  <div class="pa-16">
    <v-row>

      <v-col cols="9" class="pr-16">
        <div class="d-flex justify-space-between mb-4">
          <h1 class="text-h4 font-weight-light">Ваші товари</h1>
          <div style="width: 250px">
              <v-text-field  append-icon="mdi-magnify"  v-model="searchProducts" hide-details dense    label="Пошук"></v-text-field>
          </div>
        </div>
<!--        <div style="width: 250px">-->
<!--          <v-select label="Групові дії" hide-details dense></v-select>-->
<!--        </div>-->

        <v-expand-transition mode="out-in" >
          <v-sheet v-if="selectedProducts.length > 0"  height="50">
              <v-menu   offset-y >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small   color="deep-purple" text v-bind="attrs" v-on="on" >
                    Групові дії <v-icon small>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item v-for="(menuItem, i) in editGroupActions" :key="i" @click="editButton(menuItem.action,item)">
                    <v-list-item-title class="font-weight-light">{{ menuItem.label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
          </v-sheet>

        </v-expand-transition>

        <v-data-table class=""
                      :items="productsFiltered"
                      :headers="products_headers"
                      show-select
                      selectable-key="id"
                      v-model="selectedProducts"
                      :search="searchProducts"
        >

          <template v-slot:item.name="{ item }">
            <div class="d-flex ma-3">
              <v-avatar class="mr-3"
                        size="48"  >
                <v-img :src="item.image_small" ratio="1" />
              </v-avatar>
              <div>
                <h4>{{ item.name }} </h4>
                {{ item.shortAbout }}
              </div>
            </div>
          </template>
<!--          <template v-slot:item.rating>-->
<!--            <v-rating readonly small-->
<!--                      color="orange"-->
<!--                      background-color="grey"-->
<!--                      empty-icon="mdi-star-outline"-->
<!--                      full-icon="mdi-star"-->
<!--                      half-icon="mdi-star-half-full"-->
<!--                      hover-->
<!--                      :length="5"-->
<!--                      :value="3" />-->
<!--          </template>-->
          <template v-slot:item.price="{item}">
            <b>{{ item.price }} ГРН</b>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" v-on="on" >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(menuItem, i) in editActions" :key="i" @click="editButton(menuItem.action,item)">
                  <v-list-item-title class="font-weight-light">{{ menuItem.label }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

        </v-data-table>

        <div class="d-flex justify-end mt-8">
          <v-btn @click="addProduct"
              text  elevation="0" rounded color="deep-purple" ><v-icon class="mr-3">mdi-plus</v-icon> Додати товар </v-btn>
        </div>

      </v-col>

      <v-col cols="3" class="">
        <div class="d-flex justify-space-between align-center">
          <h1 class="text-h4 font-weight-light">Категорії</h1>
          <v-btn  icon @click="showEditCategory = !showEditCategory"><v-icon >{{ showEditCategory ? 'mdi-close' : 'mdi-pencil' }}</v-icon></v-btn>
        </div>

        <div class="mt-8 pt-3">

          <v-list dense>
            <v-list-item-group color="deep-purple" mandatory v-model="selectedCategory">
              <v-list-item  :value="null">
                <v-list-item-title>
                  <div class="d-flex justify-space-between align-center">
                    <h3 class="font-weight-light"> Всі товари </h3>
                    <v-chip color="deep-purple" small outlined dark > <span class="caption">{{  products.length  }}</span></v-chip>
                  </div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-for="( category, i ) in categories" :key="i" :value="category.id">
                <v-list-item-title>
                  <div class="d-flex justify-space-between align-center">
                    <h3 class="font-weight-light">{{ category.name }} </h3>
                    <div v-if="!showEditCategory">
                      <v-chip v-if="getCategoryCount(category.id) > 0" color="deep-purple" small outlined dark > <span class="caption">{{ getCategoryCount(category.id) }}</span></v-chip>
                    </div>
                    <v-btn v-else icon small @click="editCategory(category)"><v-icon small>mdi-pencil</v-icon></v-btn>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <div class="d-flex justify-end mt-8">
            <v-btn  @click="addCategory"
                text  elevation="0" rounded color="deep-purple" ><v-icon class="mr-3">mdi-plus</v-icon> Додати </v-btn>
          </div>


        </div>

      </v-col>
    </v-row>

    <productsEditor v-if            =  "displayEditor"
                    v-model         =  "displayEditor"
                    :type           =  "editorType"
                    :input          =  "editorInput"
                    :categories     =  "categories"
                    :remove         =  "editorRemove"
                    :productsSelect = "productsSelect"
                    @onFinish       =  "editorOutput"/>

  </div>
</template>

<script>
import productsEditor from "@/components/pages/businessDashboard/businessProducts/productsEditor";
import {mapActions, mapState} from "vuex";
export default {
  name: "BusinessDashboardProducts",
  components : {
    productsEditor
  },
  data (){
    return {
      loading : false,

      categories : [],
      products : [],

      showEditCategory : false,
      selectedCategory : '',

      selectedProducts : [],
      searchProducts : '',
      products_headers : [

        {text:'Назва',value:'name'},
        // {text:'Популярність',value:'rating'},
        {text:'Ціна',value:'price'},
        {text:'Дії',value:'action'},

      ],

      editActions : [

        { label: 'Редагувати', action: 'edit'   },
        { label: 'Видалити',   action: 'delete' },

      ],

      editGroupActions : [

        { label: 'Видалити',   action: 'delete_group' },

      ],

      displayEditor : false,
      editorType : 'category',
      editorInput : false,
      editorRemove : false,
    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
    productsSelect() {
      let items = []

      this.products.forEach((product)=>{
        items.push({
          text: product.name,
          value: product.id
        })
      })

      return items
    },
    productsFiltered() {
      var products = this.products
      if (this.selectedCategory) {
        products = products.filter(el=> el.category_id === this.selectedCategory)
      }
      return products
    }
  },
  methods : {
    ...mapActions('products', [ 'GET_ALL']),

    getCategoryCount(category) {
      return this.products.filter(el=>el.category_id === category).length
    },

    addCategory() {
      this.editorType = 'category'
      this.displayEditor = true
      this.editorRemove = false
      this.editorInput = false
    },
    addProduct() {
      this.editorType = 'products'
      this.displayEditor = true
      this.editorInput = false
    },
    editCategory(input) {
      this.editorType = 'category'
      this.displayEditor = true
      this.editorRemove = false
      this.editorInput = input
    },
    editProduct(input) {
      this.editorType = 'products'
      this.displayEditor = true
      this.editorRemove = false
      this.editorInput = input
    },
    deleteProduct(input) {
      this.editorType = 'products'
      this.displayEditor = true
      this.editorInput = input
      this.editorRemove = true
    },
    editProductOut(data) {
      console.log(data)
      let index = this.products.findIndex(el=>el.id === data.id)
      console.log('index is : '+index)
      this.$set(this.products,index,data)
    },
    deleteProductOut(data) {
      data.forEach((id)=>{
        let index = this.products.findIndex(el=>el.id === id)
        this.products.splice(index,1)
      })
    },
    editorOutput(data){

      switch(data.type) {
        case 'new_category'   : this.categories.push(data.data)         ; break ;
        case 'new_product'    : this.products.push(data.data)           ; break ;
        case 'edit_product'   : this.editProductOut(data.data)          ; break ;
        case 'delete_product' : this.deleteProductOut(data.data)        ; break ;
      }

    },
    editButton(action,item) {

      switch(action) {
        case 'edit'         : this.editProduct(item.id)                 ; break;
        case 'delete'       : this.deleteProduct([item])          ; break;
        case 'delete_group' : this.deleteProduct(this.selectedProducts) ; break;
      }

    },
  },
  mounted(){
    this.GET_ALL(this.selectedBusiness.alias).then((out)=>{
      if (out.data) {
        this.products = out.data.products
        this.categories = out.data.categories
        this.loading = false;

        console.log(out)
      }
    }).catch(()=>{
      this.loading = false
      this.notify('Network error')
    })
  }

}
</script>

<style scoped>

</style>