<template>
  <v-dialog v-model="$store.state.templates.openEditorBlock" width="600">
    <v-sheet dark class="py-6 px-8"  >
      <div class="d-flex justify-space-between mt-2 ">
        <h3 style="line-height: 1.2" class="mt-3 mb-3  font-weight-light text-center">Додати блок</h3>
        <v-btn plain icon  @click="cancelEdit"><v-icon>mdi-close</v-icon></v-btn>
      </div>

      <div class="mt-3" v-if="openEditorBlockType === 'delete'">
        <h2 class="font-weight-light">Ви впевнені, що хочете видалити блок ?</h2>
      </div>
      <div v-if="openEditorBlockType === 'new'">
        <v-text-field rounded filled v-model="blockParams.title" label="Назва блоку" />
        <v-text-field rounded filled v-model="blockParams.subtitle" label="Підзаголовок" />
        <v-select rounded filled v-model="selectedBlockType"
                  label="Оберіть блок"
                  :items="blocks" />
      </div>
      <div v-if="openEditorBlockType === 'edit' ">

        <br>
        <v-btn rounded x-large class="mb-5 mr-3"
               :outlined="!$store.state.templates.page.blocks[selectedBlock].value.heading"
               :filled="$store.state.templates.page.blocks[selectedBlock].value.heading"
               :light="$store.state.templates.page.blocks[selectedBlock].value.heading"
               :color="$store.state.templates.page.blocks[selectedBlock].value.heading ? 'white' : '' "
               @click="$store.state.templates.page.blocks[selectedBlock].value.heading = !$store.state.templates.page.blocks[selectedBlock].value.heading ">Заголовок</v-btn>
        <v-btn rounded class="mb-5"
               :outlined="!$store.state.templates.page.blocks[selectedBlock].value.noPadding"
               :filled="$store.state.templates.page.blocks[selectedBlock].value.noPadding"
               :light="$store.state.templates.page.blocks[selectedBlock].value.noPadding"
               :color="$store.state.templates.page.blocks[selectedBlock].value.noPadding ? 'white' : '' "
               x-large @click="$store.state.templates.page.blocks[selectedBlock].value.noPadding = !$store.state.templates.page.blocks[selectedBlock].value.noPadding ">Без відступів</v-btn>

        <v-text-field rounded filled v-model="$store.state.templates.page.blocks[selectedBlock].value.title" label="Назва блоку" />
        <v-text-field rounded filled v-model="$store.state.templates.page.blocks[selectedBlock].value.subtitle" label="Підзаголовок" />
        <v-text-field rounded filled v-model="$store.state.templates.page.blocks[selectedBlock].anchor" label="alias" />
        <div class="d-flex justify-space-between align-center">
          <v-select v-model="$store.state.templates.page.blocks[selectedBlock].value.dark"
                    :items="[{text:'Темний',value:true},{text:'Світлий',value:false}]"
                    rounded filled hide-details
                    class="mr-6"
                    label="Режим" />
          <v-select v-model="$store.state.templates.page.blocks[selectedBlock].value.background_type"
                    :items="[{text:'Колір',value:'color'},{text:'Зображення',value:'img'}]"
                    rounded filled hide-details
                    class="mr-6"
                    label="Фон блоку" />
          <v-menu  v-if="$store.state.templates.page.blocks[selectedBlock].value.background_type === 'color'"
                   ref="backgroundColorMenu"  :close-on-content-click="false" :close-on-click="true"  left >

            <template v-slot:activator="{ on, attrs }">
              <v-btn :color="$store.state.templates.page.blocks[selectedBlock].value.background_color"
                     x-large rounded filled
                     v-bind="attrs" v-on="on" >
                <v-icon>mdi-palette</v-icon>

              </v-btn>
            </template>
            <v-sheet dark class="pa-3" >
              <div class="d-flex justify-space-between mt-3 mb-3">
                <h3>Оберіть колір  </h3>
                <v-btn plain icon @click="$refs.backgroundColorMenu.save()"><v-icon>mdi-close</v-icon></v-btn>
              </div>

              <v-color-picker v-model="$store.state.templates.page.blocks[selectedBlock].value.background_color"
                              hide-inputs show-swatches  />

            </v-sheet>

          </v-menu>
          <v-btn v-if="$store.state.templates.page.blocks[selectedBlock].value.background_type === 'img' "
                 @click="openImageSelect = true"
                 class="noCaps font-weight-light" color="grey darken-3"
                 x-large rounded filled  >
            <v-icon>mdi-upload</v-icon>
          </v-btn>
          <v-menu  v-if="$store.state.templates.page.blocks[selectedBlock].value.background_type === 'img'"
                   ref="backgroundColorMenu"  :close-on-content-click="false" :close-on-click="true"  left >

            <template v-slot:activator="{ on, attrs }">
              <v-btn :color="$store.state.templates.page.blocks[selectedBlock].value.overlay_color"
                     x-large rounded filled
                     v-bind="attrs" v-on="on" >
                <v-icon>mdi-palette</v-icon>

              </v-btn>
            </template>
            <v-sheet dark class="pa-3" >
              <div class="d-flex justify-space-between mt-3 mb-3">
                <h3>Оберіть колір  </h3>
                <v-btn plain icon @click="$refs.backgroundColorMenu.save()"><v-icon>mdi-close</v-icon></v-btn>
              </div>

              <v-color-picker v-model="$store.state.templates.page.blocks[selectedBlock].value.overlay_color"
                              hide-inputs show-swatches  />

            </v-sheet>

          </v-menu>
        </div>

        <v-dialog v-model="openImageSelect">
          <editImage v-if=openImageSelect  v-model="$store.state.templates.page.blocks[selectedBlock].value.background_img" label="Зображення фону" />
        </v-dialog>

        <div class="d-flex align-center mt-8">
          <v-sheet width="350" >
            <v-select hide-details  rounded filled    dark :items="[1,2,3,4]" v-model="blockRowsLg" label="Колонки" />
          </v-sheet>
          <ftColumnDimensionMenu v-model="$store.state.templates.page.blocks[selectedBlock].value.rows_dimensions.lg"
                                 class="ml-8"  :columns="blockRowsLg" />
        </div>
        <div class="d-flex align-center mt-8">
          <v-sheet width="350" >
            <v-select hide-details  rounded filled    dark :items="[1,2,3,4]" v-model="blockRowsMd" label="Колонки" />
          </v-sheet>
          <ftColumnDimensionMenu v-model="$store.state.templates.page.blocks[selectedBlock].value.rows_dimensions.md"
                                 class="ml-8"  :columns="blockRowsMd" />
        </div>
        <div class="d-flex align-center mt-8">
          <v-sheet width="350" >
            <v-select hide-details  rounded filled    dark :items="[1,2,3,4]" v-model="blockRowsSm" label="Колонки" />
          </v-sheet>
          <ftColumnDimensionMenu v-model="$store.state.templates.page.blocks[selectedBlock].value.rows_dimensions.sm"
                                 class="ml-8"  :columns="blockRowsSm" />
        </div>


      </div>

      <!-- Navigation-->
      <div class="d-flex justify-end mt-5 mb-3">
        <v-btn :loading="loading" :disabled="loading"  @click="cancelEdit"
               large class="mt-3 px-8" text rounded elevation="0"  dark>
          <h3 class="font-weight-light">Скасувати</h3>
        </v-btn>
        <v-btn :disabled="loading"  @click="saveChanges"
               large class="mt-3 px-8" rounded outlined elevation="0"  dark>
          <h3 class="font-weight-light">{{ confirmText }}</h3>
        </v-btn>
      </div>

    </v-sheet>
  </v-dialog>
</template>

<script>
import { mapState, mapActions  } from "vuex";
import ftColumnDimensionMenu from "@/components/templates/editor/UI/ftColumnDimensionMenu";
import editImage from "@/components/pages/businessDashboard/UI/editImage";

export default {
  name: "ftEditorBlock",
  components : {
    ftColumnDimensionMenu,
    editImage
  },
  data()  {
    return {
      openImageSelect : false,
      loading : false,
      blocks : [],
      selectedBlockType : 'container',
      blockParams : {},
      blockRowsLg :0,
      blockRowsMd :0,
      blockRowsSm :0,
      columnsDimensions : [],




      showColorPicker : false,
    }
  },
  computed : {
    ...mapState('templates', [ 'selectedBlock', 'selectedBlockIndex', 'openEditorBlock' , 'openEditorBlockType'   ]),
    confirmText() {
      switch ( this.openEditorBlockType ) {

        case 'new'    : return 'Додати' ;
        case 'edit'   : return 'Зберегти';
        case 'delete' : return 'Видалити';

        default : return 'Зберегти';

      }
    }
  },
  watch : {
    blockRowsLg(rows) {
      if ( this.openEditorBlockType !== 'edit' ) { return }
      let length = this.$store.state.templates.page.blocks[this.selectedBlock].value.items.length
      if (rows > length) {
        let diff = rows-length
        for ( let i = 0 ; i < diff ;i++) {
          this.$store.state.templates.page.blocks[this.selectedBlock].value.items.push([])
          this.$store.state.templates.page.blocks[this.selectedBlock].value.vertical_align.push('')

        }
      }
      else if ( rows < length ) {
        let  elements = this.$store.state.templates.page.blocks[this.selectedBlock].value.items[rows].concat( ...this.$store.state.templates.page.blocks[this.selectedBlock].value.items.slice(rows+1) )

        this.$store.state.templates.page.blocks[this.selectedBlock].value.vertical_align.splice(rows)
        this.$store.state.templates.page.blocks[this.selectedBlock].value.items.splice(rows)

        console.log(elements)
        elements.forEach((element) => {
          this.$store.state.templates.page.blocks[this.selectedBlock].value.items[rows-1].push(element)
        })
      }

    },
    columnsDimensions(dimensions) {
      this.$store.state.templates.page.blocks[this.selectedBlock].value.rows_dimensions = dimensions
    }
  },
  methods : {
    ...mapActions('templates', [ 'GET_TEMPLATE_BLOCKS_SELECT' , 'ADD_BLOCK' , 'SAVE_BLOCK' , 'DELETE_BLOCK']),
    cancelEdit() {
      this.$store.state.templates.openEditorBlock = false
    },
    saveChanges() {
      switch ( this.openEditorBlockType ) {
        case 'new'    : this.addBlock()    ; break;
        case 'edit'   : this.editBlock()   ; break;
        case 'delete' : this.deleteBlock() ; break;
      }
    },
    addBlock() {
      this.loading = true
      this.ADD_BLOCK(
          {
            alias: this.$store.state.templates.businessAlias,
            data: {
              page : this.$store.state.templates.page.alias,
              block : this.selectedBlockType,
              params : this.blockParams
            }
          }
      ).then((out)=> {
        if ( out.result ) {

          this.$store.state.templates.page.blocks.push(out.data)
          this.notify('Новий блок створено')
          this.$store.state.templates.openEditorBlock = false

        }
        this.loading = false
      }).catch(()=> {
        this.loading = false
        this.notify('Помилка сервера')
      })


    },
    editBlock() {
      this.$store.state.templates.openEditorBlock = false
    },
    deleteBlock() {
      this.loading = true
      this.DELETE_BLOCK(
          {
            alias: this.$store.state.templates.businessAlias,
            data: {
              index : this.selectedBlockIndex,
              page  : this.$store.state.templates.page.alias,
            }
          }
      ).then((out)=> {
        if ( out.result ) {
          let index = this.$store.state.templates.page.blocks.findIndex ( el=> el.id === out.data.index)
          if ( index !== -1) {
            this.$store.state.templates.page.blocks.splice( index, 1 )
          }

          this.notify('Блок Видалено')
          this.$store.state.templates.openEditorBlock = false
        }
        this.loading = false
      }).catch(()=> {
        this.loading = false
        this.notify('Помилка сервера')
      })
    },
  },
  mounted() {

    if ( this.openEditorBlockType === 'edit' ) {
      this.blockRowsLg =  this.$store.state.templates.page.blocks[this.selectedBlock].value.items.length
      this.blockRowsMd =  this.$store.state.templates.page.blocks[this.selectedBlock].value.rows_dimensions.md.length
      this.blockRowsSm =  this.$store.state.templates.page.blocks[this.selectedBlock].value.rows_dimensions.sm.length

    }

    if ( this.openEditorBlockType === 'new' ) {
      this.GET_TEMPLATE_BLOCKS_SELECT( this.$store.state.templates.businessAlias ).then((out)=>{

        if( out.result ) {
          this.blocks = out.data
        }
      })
    }

  }
}
</script>

<style scoped>

</style>