<template>
  <v-sheet :color="block.value.background_color">
    <v-sheet  :color="block.value.background_color" :dark="block.value.dark" :min-height="!block.value.noPadding ? 600 : null"
              :style="backgroundImage" >


      <v-sheet :color="block.value.overlay_color"
               :class="blockTopPaddingHeaderHandle" >
        <div class="section-content" :class="block.value.noPadding ? '' : 'py-16'">
          <!-- Slot for edit block menu-->
          <slot name="edit"> </slot>
          <div class="d-flex align-center">
            <h4 style="letter-spacing: 2px" class="font-weight-medium">{{  block.value.title }}</h4>
            <div v-if="block.value.subtitle" class="ml-6" style="width: 150px"><v-divider /></div>
          </div>
          <h1 :style="accentColor" class="">{{  block.value.subtitle   }}</h1>
          <v-row class="px-6 mx-0" >
            <v-col v-for="(row,i) in block.value.items" :key="i"
                   :cols="block.value.rows_dimensions.sm[i]"
                   :xs="block.value.rows_dimensions.sm[i]"
                   :sm="block.value.rows_dimensions.sm[i]"
                   :md="block.value.rows_dimensions.md[i]"
                   :lg="block.value.rows_dimensions.lg[i]"
                   :xl="block.value.rows_dimensions.lg[i]"
                   class="d-flex"
                   :class="[ block.value.vertical_align[i] ,  block.value.noPadding ? '' : 'mt-12']"
                   >

              <div style="width : 100% !important">

                <div v-if="editorMode" class="d-flex justify-center">
                  <v-btn-toggle dark class="text-center" v-model="$store.state.templates.page.blocks[index].value.vertical_align[i]">
                    <v-btn value=""><v-icon>mdi-format-align-top</v-icon></v-btn>
                    <v-btn value="align-center"><v-icon>mdi-format-align-middle</v-icon></v-btn>
                    <v-btn value="align-end"><v-icon>mdi-format-align-bottom</v-icon></v-btn>
                  </v-btn-toggle>
                </div>



                <draggable  v-model="$store.state.templates.page.blocks[index].value.items[i]"
                            group="rows"
                            handle=".elementDraggable"
                            :forceFallback="true"
                            :empty-insert-threshold="100"
                            tag="div" >

                  <div v-for="(item,j) in row" :key="j" >

                    <div class="d-flex align-baseline">

                      <v-icon v-if="editorMode" style="cursor: grab;" class="elementDraggable noSelect" size="28"  >
                        mdi-drag
                      </v-icon>

                      <v-fade-transition>
                        <component v-if="item.params.display.includes(windowSize) || editorMode "
                                   :is="item.type"
                                   style="width : 100% !important"
                                   :value="item.value"
                                   :params="item.params"
                                   :subElement="item.subElement"
                                   :block="index"
                                   :row="i"
                                   :item="j"
                                   :class="getMargin(item.params.margin)"
                        />
                      </v-fade-transition>

                    </div>


                  </div>
                </draggable>
                <ftContainerElementsMenu :block="index" :row="i" :templateElements="templateElements" />
              </div>





            </v-col>

          </v-row>


        </div>
      </v-sheet>

    </v-sheet>
  </v-sheet>

</template>

<script>
import {mapState,mapActions} from "vuex";
import ftText from "@/components/templates/editor/elements/ftText";
import ftImg from "@/components/templates/editor/elements/ftImg";

import ftTemplateElement from "@/components/templates/editor/elements/ftTemplateElement";

import ftContainerElementsMenu from "@/components/templates/editor/ftContainerElementsMenu";
import draggable from "vuedraggable";

export default {
  name: "avalonBlockContainer",
  props : ['block','index'],
  components : {
    draggable,
    ftText,
    ftImg,
    ftTemplateElement,
    ftContainerElementsMenu,

  },
  data() {
    return {
      selectedCategory : '',

      templateElements : [
        { text: 'AvalonQuoting' , element: 'ftText'  , icon: 'mdi-format-header-1', params: {type: 'heading'}},
      ]


    }
  },
  computed : {
    ...mapState('templates',  [  'editorMode', 'products', 'selectedBlock' , 'templateVars_init', 'templateVars_current'  ]),
    ...mapState('cart',  [  'cart'  ]),
    accentColor() {
      return `color: ${this.parametr('accent_color')}`
    },
    //this is temporary ( 19/09/2021 - 14:59 ) until HEADER BLOCKS will be written in database
    blockTopPaddingHeaderHandle() {
     if ( this.index === 0 && this.parametr('header_template') === 'avalonHeader_var_2' ) {
       return 'pt-16'
     }
     return ''
    },
    products_filtered() {
      var products = this.products

      if( this.selectedCategory ) {
        products = products.filter( (el) => el.category === this.selectedCategory )
      }

      return products

    },
    backgroundImage() {
      if ( this.block.value.background_type === 'img' && this.block.value.background_img) {
        return  `background: url('${this.block.value.background_img}');
                      background-attachment: fixed ;
                      background-size : cover;
                      position:relative`
      } else {
        return ''
      }

    }
  },
  methods : {

      ...mapActions('cart', [ 'ADD_CART_ITEM','REMOVE_CART_ITEM']),

    parametr(varName) {
      let param = '';
      if (  this.editorMode ) { param = this.templateVars_current[varName].value }
      if ( !this.editorMode ) { param = this.templateVars_init[varName]    }
      return param
    },

    //technical
    getMargin(margin) {
      let marginCLass = ''

      if ( margin.mb !== 0 ) {
        marginCLass += + margin.mb > 0 ? ('mb-' + margin.mb) : 'mb-n' + margin.mb*(-1)
        marginCLass += ' '
      }
      if ( margin.mt !== 0 ) {
        marginCLass += + margin.mt > 0 ? ('mt-' + margin.mt) : 'mt-n' + margin.mt*(-1)
        marginCLass += ' '
      }
      if ( margin.ml !== 0 ) {
        marginCLass += + margin.ml > 0 ? ('ml-' + margin.ml) : 'ml-n' + margin.ml*(-1)
        marginCLass += ' '
      }
      if ( margin.mr !== 0 ) {
        marginCLass += + margin.mr > 0 ? ('mr-' + margin.mr) : 'mr-n' + margin.mr*(-1)
        marginCLass += ' '
      }
        return marginCLass
    }


  },
}
</script>

<style scoped>
.headerMenuItem {
  font-size: 0.9rem !important;
  transition: all 0.2s ease;
  text-decoration: none;
  color : #f1f1f1;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
</style>