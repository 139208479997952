<template>
  <v-dialog    v-model="value" :width="editorWidth" persistent>
    <v-card dark >
      <v-card-title class="justify-space-between mt-3">

          <div style="width: 150px">
            <v-btn  text   v-if="editImage" @click="editImage=false" >
              <v-icon class="ml-n4"   >mdi-chevron-left</v-icon>
              Назад
            </v-btn>
          </div>
          <h3 style="line-height: 1.2" class="mt-3 mb-3  font-weight-light text-center">{{ mainTitle  }}</h3>

          <div style="width: 150px" class="d-flex justify-end">
            <v-btn plain icon  @click="value = false"><v-icon>mdi-close</v-icon></v-btn>
          </div>

      </v-card-title>

      <v-card-text   class="px-6 mt-3"    >
        <v-sheet :style="`height: ${contentHeight}px;`" style=" overflow-y: auto" class="px-6" outlined>
          <v-text-field v-if="!remove && type === 'category'"
                        v-model="category"
                        class="mt-10" rounded filled dense placeholder="Value" label="Назва категорії" required />
          <div  v-if="!remove && type === 'products'">
            <v-row v-if="!editImage">
              <v-col cols="4">
                <v-sheet style="border-radius: 25px !important"  >
                  <div class="d-flex justify-space-between align-center mt-10">
                    <h3 class="font-weight-light text-center">Зображення :</h3>
                    <v-btn class="noCaps"  @click="editImage = true" rounded text >{{ imageButtonText }}</v-btn>
                  </div>

                  <imago class="mt-6" ratio="1.41" :src="product.image" />

                </v-sheet>

                <v-select     v-model="product.category" :items="categories_select" hide-details
                              class="mt-9 " rounded filled dense placeholder="Value" label="Категорія" required />
                <v-select     v-model="product.rentedProduct" :items="productsSelect" hide-details
                              class="mt-3" rounded filled dense placeholder="Value" label="Залоговий Продукт" required />
                <v-select     v-model="product.parentProduct" :items="productsSelect" hide-details
                              class="mt-3" rounded filled dense placeholder="Value" label="Батьківський продукт" required />

                <v-text-field v-model="product.min_quantity" :items="productsSelect" hide-details
                              class="mt-3" rounded filled dense placeholder="Value" label="Мінімальна кількість" required />

              </v-col>
              <v-col cols="8">
                <v-row>
                  <v-col cols="9">
                    <v-text-field v-model="product.name"
                                  class="mt-10" hide-details rounded filled dense placeholder="Value" label="Назва Товару" required />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field v-model="product.price"
                                  class="mt-10" hide-details rounded filled dense placeholder="Value" label="Вартість" required />

                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="product.integration_id"
                                  class="" rounded filled dense placeholder="Value" label="Код інтеграції" required />
                  </v-col>
                </v-row>
                <v-textarea   height="90"
                              v-model="product.shortAbout"
                              class="noResize" rounded filled dense placeholder="Value" label="Короткий опис (Склад)" required />
                <v-textarea height="180"
                            v-model="product.fullAbout"
                            class="noResize" rounded filled dense placeholder="Value" label="Детальний опис " required />
              </v-col>
            </v-row>

            <editImage @close="editImage=false" v-model="product.image" v-else />

          </div>
          <div v-if="remove" class="my-5">
            <h3 v-for="(product,i) in input" :key="i"
                style="line-height: 1.2" class="mt-2 font-weight-light text-center"><code>{{ product.name }}</code></h3>
          </div>
        </v-sheet>
      </v-card-text>

      <!-- Navigation-->
      <v-card-actions class="pb-7 pr-6  justify-end">
        <v-btn  :disabled="loading"  @click="value = false"
               large class=" px-8" text rounded elevation="0"  dark>
          <h3 class="font-weight-light">Скасувати</h3>
        </v-btn>
        <v-btn :disabled="loading" :loading="loading"  @click="saveChanges"
               large class=" px-8" rounded outlined elevation="0"  dark>
          <h3 class="font-weight-light">{{ remove ? 'Так' : 'Зберегти' }}</h3>
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import imago from "@/components/UI/imago";
import editImage from "@/components/pages/businessDashboard/UI/editImage";
import {mapActions, mapState} from "vuex";

export default {
  name: "productsEditor",
  props : ['value','type','input','categories','remove','productsSelect'],
  components : {
    imago,
    editImage
  },
  data() {
    return {
      loading : false,

      editImage  :false,

      category : '',
      product : {},
    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
    categories_select(){
      var categories = []
      if (this.categories){
        this.categories.forEach((category)=>{
          categories.push({text:category.name,value:category.id})
        })
      }



      return categories
    },
    imageButtonText() {
      if(!this.product.image) { return 'Завантажити' }
      return 'Змінити'
    },
    mainTitle() {
      if ( this.editImage ) { return 'Редагування зображення'}
      if(!this.remove && this.type === 'category') {
        if (!this.input) { return 'Додати нову категорію' }
        if  (this.input) { return 'Редагувати категорію' }
      }
      if(!this.remove && this.type === 'products') {
        if (!this.input) { return 'Додати новий товар' }
        if  (this.input) { return 'Редагувати товар' }
      }
      if (this.remove && this.type === 'products' ) { return 'Ви впевнені що хчете видалити товар?' }
      return 'Додати нову категорію'
      },
    editorWidth(){
      if (!this.remove && this.type   === 'products') { return 1100 }
      if (!this.remove && this.type   === 'category') { return 600 }
      if (this.remove)                { return 600 }
      return 600
    },
    contentHeight(){
      if ( this.type === 'category' ) { return 140 }
      return 450
    }
  },
  watch: {
    value(value) {
      this.$emit('input',value)
    }
  },
  methods : {
    ...mapActions('products', [ 'GET_PRODUCT','ADD_CATEGORY', 'EDIT_CATEGORY', 'ADD_PRODUCT','EDIT_PRODUCT','DELETE_PRODUCT']),

    saveChanges(){
      if (this.editImage) { this.editImage = false; return }
      if (!this.remove){
        switch(this.type) {
          case 'category' : if (!this.input) { this.newCategory() } else {this.editCategory()}  break;
          case 'products' : if (!this.input) { this.newProduct() } else {this.editProduct()}  break;
        }
      }
      if (this.remove){
        switch(this.type) {
          case 'category' : this.deleteCategory()  ;  break;
          case 'products' : this.deleteProduct()   ;  break;
        }
      }

    },
    newCategory(){
      this.loading = true
      let data = {
        business : this.selectedBusiness.alias,
        name : this.category
      }
      this.ADD_CATEGORY(data).then((out)=>{
        if (out.data) {
          this.notify('Нову категорію збережено')
          this.loading = false;
          this.$emit('input',false)
          this.$emit('onFinish',{
            type:'new_category',
            data:{
              name: this.category,
              id: out.data.id}
          })
        }
      }).catch(()=>{
        this.loading = false
        this.notify('Network error')
      })
    },
    newProduct(){
      this.loading = true
      this.product.business = this.selectedBusiness.alias
      this.ADD_PRODUCT(this.product).then((out)=>{
        if (out.data) {
          this.notify('Новий продукт збережено')
          this.loading = false;
          this.$emit('input',false)
          this.product.id = out.data.id
          this.product.category_id = this.product.category
          this.$emit('onFinish',{
            type:'new_product',
            data:out.data
          })
        }
      }).catch(()=>{
        this.loading = false
        this.notify('Network error')
      })
    },
    editCategory(){
      this.loading = true
      let data = {
        id : this.input.id,
        data : {
          name : this.category
        }
      }
      this.EDIT_CATEGORY(data).then((out)=>{
        if (out.data) {
          this.notify('Нову категорію збережено')
          this.loading = false;
          this.$emit('input',false)
          this.$emit('onFinish',{
            type:'edited_category',
            data:{
              name: this.category,
              id: out.data.id}
          })
        }
      }).catch(()=>{
        this.loading = false
        this.notify('Network error')
      })
    },
    editProduct(){
      this.loading = true
      this.product.business = this.selectedBusiness.alias
      this.EDIT_PRODUCT({id : this.product.id, data: this.product}).then((out)=>{
        if (out.result) {
          this.notify('Нові зміни збережено')
          this.loading = false;
          this.$emit('input',false)
          this.product.category_id = this.product.category
          this.$emit('onFinish',{
            type:'edit_product',
            data:out.data
          })
        }
      }).catch(()=>{
        this.loading = false
        this.notify('Network error')
      })
    },
    deleteCategory(){
    },
    deleteProduct(){
      this.loading = true
      this.product.business = this.selectedBusiness.alias
      let data = {products : []}
      this.input.forEach((product)=>{data.products.push(product.id)})
      this.DELETE_PRODUCT(data).then((out)=>{
        if (out.result) {
          this.notify('Товар видалено')
          this.loading = false;
          this.$emit('input',false)
          this.$emit('onFinish',{
            type:'delete_product',
            data:data.products
          })
        }
      }).catch(()=>{
        this.loading = false
        this.notify('Network error')
      })
    }
  },
  mounted() {
    if (!this.remove &&  this.input && this.type === 'products') {
      this.loading = true
     this.GET_PRODUCT(this.input).then((out)=>{
      if ( out.data) {
        this.product = out.data
        this.product.category =  out.data.category_id
      }
       this.loading = false
     }).then(()=>{  this.loading = false})
    }

    if (!this.remove &&  this.input && this.type === 'category') {
      this.category = this.input.name
    }


  }
}
</script>

<style scoped>

</style>