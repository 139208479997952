<template>
  <v-sheet ref="element" >
    <v-fade-transition>
      <div v-if="!resizing">
        <div class="d-flex justify-space-between align-center">
          <h5>Замовлення</h5>
          <div class="d-flex align-center">
            <ftSelect @input="changeFilter"  :items="filterTypes" >
              <template #default="item">
                <v-btn
                    :dark="filter.warehouse !== ''"
                    :outlined="!filter.warehouse"
                    :color="filter.warehouse ? 'primary lighten-2' : 'grey darken-3' "
                    small rounded class="noCaps mr-2" >
                  <v-icon small>mdi-cash</v-icon>
                  {{filter.warehouse ?  item.text : 'Склад' }}
                </v-btn>
              </template>
            </ftSelect>
            <v-btn small @click="$emit('delete')" icon><v-icon small>mdi-delete</v-icon></v-btn>
          </div>
        </div>
        <apexchart align="left" :height="height -50" :key="chartNum"   :options="chartOptions" :series="series" />
      </div>
    </v-fade-transition>


  </v-sheet>
</template>

<script>
import ftSelect from "@/components/UI/ftSelect";
import apexchart from "vue-apexcharts"
import {mapActions} from "vuex";
export default {
  name: "statisticsOrdersPie",
  props : ['externalFilter','height','width','resizing','value'],
  components : {
    ftSelect,
    apexchart
  },

  data() {
    return {
      chartNum : 0,
      products : [],
      arro : [],
      warehouses : [ { text : 'Всі' , value : '' } ],
      filter : {
        date : [],
        group : { type : 'status', vale : null },
        warehouse : ''
      },

      series: [],
      chartOptions: {
        chart: {
          width: 380,
          align : 'left',
          type: 'pie',
        },
        legend : {
          show : true
        },
        labels: [],
        donut : {
          labels : {show: false},
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 100
            },
            legend: {
              position: 'left'
            }
          }
        }]
      },



    }
  },
  computed : {
    filterTypes() {
     let items = []
     items = [
       { text : 'Статус замовлення' , value : 'status' },
       { text : 'Склади' , value : 'warehouse' },
     ]
     return items;
    }
  },
  watch: {
    externalFilter :{

      handler(filter) {
        if ( filter ) {
          this.mutateFilter()
        }
      },
      deep : true

    },
    filter :  {
      handler(filter) {
        this.getData()
        this.$emit('input',filter)
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('statistics', ['GET_ORDERS_STATISTICS']),
    getData() {
      this.loading = true

      this.filter.type = 'pie'
      this.GET_ORDERS_STATISTICS({filter: this.filter}).then((out) => {
        if (out.data) {
          this.series              = out.data.orders.values
          this.chartOptions.labels = out.data.orders.labels
          this.chartNum++;
          this.warehouses = out.data.warehouses
          this.loading = false;
        }
      }).catch(() => {
        this.loading = false
        this.notify('Network error')
      })
    },
    changeFilter(input) {
      this.filter.group.type = input
    },
    //technical
    getTotalPrice() {
      let price = 0;
      this.products.forEach((product)=>{
        price += parseFloat(product.price)
      })
      return price
    },
    mutateFilter() {
      Object.keys(this.externalFilter).forEach((key)=>{
        this.filter[key] = this.externalFilter[key]
      })
      this.filter = Object.assign({},this.filter)
    }
  },
  mounted() {

    if ( this.externalFilter ) {
      this.mutateFilter()
    }

    if ( this.value )  {
      this.filter = this.value
    }

    this.$emit('PISOK')
    this.getData()
    if (this.date ) {
      this.filter.date = this.date
    }
  }
}
</script>

<style scoped>

</style>