
const actions = {
    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route: body.route,
                method: body.method,
                headers: {'Auth': body.token},
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return false
        }
    },

    async GET_CONTACTS({dispatch,rootState}) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`messenger/contacts`, method:'GET'});
    },
    async GET_CHATS({dispatch,rootState}) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`messenger/chats`, method:'GET'});
    },
    async GET_CHAT({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`messenger/chat`, method:'POST',data:body});
    },
    async SEND_MESSAGE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`messenger/send`, method:'POST',data:body});
    },
    async MARK_READ({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`messenger/read`, method:'POST',data:body});
    },


}

export default {
    namespaced:true,
    actions
}