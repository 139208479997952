export default function breadcrumbs({next, to, store}) {

    var breadcrumbs = []

    to.matched.forEach(item => { breadcrumbs.push({
          text: item.name, disabled: true, href: item.path, })   });

    breadcrumbs[0].disabled = false

    store.commit('dashboard/SET_BREADCRUMBS',breadcrumbs);
    store.commit('dashboard/SET_PAGE_TITLE',to.name);

    return next()

}