<template>
  <div style="transition: all .6s ease" class="px-3 mt-3" >

    <div class="d-flex justify-space-between align-center  pb-1   ">
      <h5 class="text-center font-weight-medium " > Редагувати елемент меню  </h5>
      <v-btn small icon @click="cancelEdit()">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-divider class="mb-3" />

      <v-row class=" mb-n5 mt-3">
        <v-col cols="6">
          <v-text-field v-model="value.title"
                        hide-details
                        dense
                        class="caption"  >
            <template slot="label">
              <span class="caption">Назва</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select     v-model="value.action"
                        dense
                        hide-details
                        class="caption"
                        :items="actions"  >
            <template slot="label">
              <span class="caption">Дія</span>
            </template>
          </v-select>
        </v-col>

        <v-col cols="6">
          <v-select     v-if="value.action === 'page'"
                        v-model="value.route"
                        dense
                        hide-details
                        class="caption"
                        :items="pages"  >
            <template slot="label">
              <span class="caption">Сторінка</span>
            </template>
          </v-select>
        </v-col>

        <v-col cols="6">
          <v-select     v-if="showBlocks && value.action === 'page'"
                        v-model="value.scroll"
                        class="caption"
                        dense
                        hide-details
                        :items="blocks" >
            <template slot="label">
              <span class="caption">Блок</span>
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" class="mt-n5">
          <v-text-field v-if="value.action === 'link'"
                        v-model="value.link"
                        hide-details
                        dense
                        class="caption" >
            <template slot="label">
              <span class="caption">Посилання</span>
            </template>
          </v-text-field>
        </v-col>

      </v-row>


<!--    <v-text-field v-if="!child"-->
<!--                  v-model="value.subtitle"-->
<!--                  class=" mb-3 mx-4 mt-2"-->
<!--                  hide-details-->
<!--                  dense-->
<!--                  filled-->
<!--                  rounded-->
<!--                  label="Підзаголовок" />-->

    <div class="d-flex justify-end align-center mb-5 mt-9 ">
      <v-btn text class="noCaps" small   @click="cancelEdit()" >
        <v-icon>mdi-save</v-icon>
        Cкасувати
      </v-btn>
      <v-btn outlined class="noCaps" small   @click="$emit('editRow')" >
        <v-icon>mdi-save</v-icon>
        Зберегти
      </v-btn>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ftMainMenuEditElement",
  props : ['value','child','pages'],
  data() {
    return {

      buffer : {},

      actions: [
        {text: 'Сторінка',  value: 'page'},
        {text: 'Посилання', value: 'link'},
        {text: 'Підменю',   value: 'submenu'},
      ],

    }
  },
  computed: {
    ...mapState('templates',  [ 'businessAlias' ]),

    pagessssssOLD() {
      return [

        { text: 'Головна',           value: `/${this.businessAlias}` , blocks : [
            { text: 'Головна',       value: 'main'},
            { text: 'Новини',        value: 'news'},
            { text: 'Про компанію',  value: 'about'},
            { text: 'Товари',        value: 'products'},
            { text: 'Контакти',      value: 'contacts'},
          ]},
        { text: 'Всі твоари', value: '/products', blocks : [] },
        { text: 'Контакти',   value: '/contacts', blocks : [] },
      ]
    },

    showBlocks() {
      if ( this.value.route ) {

        let index = this.pages.findIndex(el => el.value === this.value.route)

        if ( index !== -1 ) {
          return this.pages[index].blocks.length > 0
        }
      }
      return false
    },
    blocks() {
      if ( this.value.route ) {
        let index = this.pages.findIndex(el => el.value === this.value.route)
        if ( index !== -1 ) {
          return this.pages[index].blocks
        }
      }
      return []
    }
  },
  watch : {
    value(value){
      this.$emit('input',value)
    }
  },
  methods : {
    cancelEdit() {
      this.$emit('input',this.buffer)
      this.$emit('editRow')
    },
    setBlockRoute(val) {

      console.log(val)
    },
  },
  mounted() {
    this.buffer = JSON.parse( JSON.stringify(this.value) )
    if (this.child) {
      this.actions.pop()
    }
  }
}
</script>

<style scoped>

</style>