<template>
  <v-dialog @click:outside="exit"
            :persistent="persistent"
            class="z-index:999000"
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="modalDash"
            rotateClockwiseIcon="mdi-help-circle-outline">
    <v-card >
      <v-toolbar height="150px" :color="color" dark class="d-flex  justify-center" >
        <v-icon  class="text-h1">{{ icon }}</v-icon>
      </v-toolbar>
      <v-card-text>
        <p class="text-h5 mt-10 text-center"> {{ message }} </p>

        <confirmLogout v-if="showConfirmLogout"/>
        <changeAvatar v-if="showChangeAvatar" />

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState,mapMutations} from "vuex";
import confirmLogout from "@/components/pages/dashboard/UI/modal/modal_content/confirmLogout";
import changeAvatar from "@/components/pages/dashboard/UI/modal/modal_content/changeAvatar";

export default {
  name: "modalDashboard",
  components : {
    confirmLogout,
    changeAvatar
  },
  data() {
    return {
      icon : '',
      message : '',
      color : '',
      persistent : false,

      showConfirmLogout : false,
      showChangeAvatar : false,
    }
  },
  computed: {
    ...mapState('dashboard', ['modalDash','modalType']),
  },
  methods : {
    ...mapMutations('dashboard', ['TOGGLE_DASH_MODAL']),
    exit(){
      if (!this.persistent) {
        this.TOGGLE_DASH_MODAL();
      }
    }
  },
  mounted() {
    switch(this.modalType) {
      case 'CONFIRM_LOGOUT' :
        this.color = 'primary';
        this.message = '';
        this.icon = 'mdi-help-circle-outline';
        this.persistent = false;

        this.showConfirmLogout = true;
        break;

      case 'CHANGE_AVATAR' :
        this.color = 'success';
        this.message = '';
        this.icon = 'mdi-rotate-right';
        this.persistent = true;

        this.showChangeAvatar = true;
        break;
    }
  }
}

</script>

<style scoped>

</style>