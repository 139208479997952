const state = {

    templateLoaded       : false,

    selectedBusiness       : {},

    businessAlias          : '',
    businessName           : '',
    businessTemplate       : '',
    businessСountry        : '',
    page                   : null,
    page_buffer            : null,
    news                   : [],
    products               : [],
    product_categories     : [],
    cart                   : [],
    locations              : [],
    deliveryAllowCountries : [],
    deliveryCountries      : [],

    showCart               : false,
    openEditor             : false,
    openEditorBlockElement : false,
    openEditorBlockDeleteElement : false,
    openEditorBlock        : false,
    openEditorBlockType    : null,
    openEditorGeneral      : false,
    openEditorAddEntity    : false,

    triggerScroll          : 0,

    openImageLibrary       : false,
    imageLibraryFile       : '',
    imageLibrarySenderId   : '',

    selectedPage           : '',
    selectedBlock          : null,
    selectedBlockIndex     : null,
    selectedItem           : {},

    selectedBlockItem      : null,
    selectedBlockRow       : null,
    templateVars_init      : {},
    templateVars_current   : {},
    menuItems              : [],


    editorMode : false,

    templatesConfig : {
        avalon : {
            elements : [
                'NewsElement'
            ]
        }
    }
}
const actions = {

    // AJAX HANDLING
    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },

    async UPLOAD_IMAGE({dispatch,rootState,state},body) {

        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`upload/${state.businessAlias}/products`, method:'POST',data: { fileFormData: body }});
    },

    async GET_TEMPLATES_PUBLIC({dispatch}) {
        return await dispatch('AJAX_SEND',{token:'public',route:`templates`, method:'GET'});
    },
    async ADD_TEMPLATE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`template`, method:'PUT',data: body});
    },
    async GET_TEMPLATE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`template/${body}`, method:'GET'});
    },
    async EDIT_TEMPLATE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`template/${body.uuid}`, method:'PUT', data: body.data});
    },
    async GET_USER_TEMPLATE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`options/template/${body}`, method:'GET'});
    },

    // business template editor

    async ADD_BLOCK({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`${body.alias}/template/block/new`, method:'PUT',data: body.data});
    },
    async SAVE_BLOCK({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`${body.alias}/template/block`, method:'PUT',data: body.data});
    },
    async DELETE_BLOCK({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`${body.alias}/template/block`, method:'DELETE',data: body.data});
    },

    async SAVE_DRAFT_ITEM({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`${body.alias}/template/`, method:'PUT',data: body.data});
    },
    async SAVE_TEMPLATE_EDIT({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`${body.alias}/template/save`, method:'PUT',data: body.data});
    },


    async GET_TEMPLATE_BLOCKS_SELECT({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`template/${body}/blocks/select`, method:'GET'});
    },

}

export default {
    namespaced:true,
    actions,
    state
}