<template>
  <div class="pa-16">
    <div class="d-flex justify-space-between align-center mb-16">
      <h1 class="text-h4 font-weight-light ">Налаштування способів доставки</h1>
      <v-btn @click="saveChanges" rounded outlined dark large  color="deep-purple">
        <v-icon class="mr-2">mdi-check</v-icon>Зберегти</v-btn>
    </div>


    <div style="max-width: 600px">
      <div v-for="(delivery,i) in deliveries" :key="i" >

        <div class="d-flex justify-space-between">
          <div class="d-flex align-center">
            <v-btn v-if="delivery.id !== 'novaPoshta'"
                   @click="expand_properties(delivery.id)" icon><v-icon>
              mdi-chevron-down</v-icon></v-btn>
            <h4 :class="delivery.id === 'novaPoshta' ? 'ml-9' : ''">{{ delivery.name }}</h4>
          </div>
          <v-switch  v-model="delivery.value" color="teal" />
        </div>

        <v-expand-transition>
          <div v-if="showSelf && delivery.id === 'self'" >
            <v-divider />
            <div class="px-6 mt-5 mb-5 ">
              <h3 class="font-weight-light">self</h3>
            </div>
            <v-divider />
          </div>
        </v-expand-transition>

        <v-expand-transition>
          <div v-if="showAddress && delivery.id === 'address'">
            <v-divider />
            <div class="px-6 mt-5 mb-5 ">
              <div class="d-flex justify-space-between align-center">
                <h4>Країни: </h4>
                <v-switch v-model="permissionCountries" label="Всі країни" hide-details color="teal" class="mr-n6 mt-n1" />
              </div>

              <div v-if="!permissionCountries" class=" mt-8">

                <div class="d-flex justify-end">
                  <v-menu offset-y  :close-on-content-click="false"  >

                    <template v-slot:activator="{ on, attrs }">
                      <v-btn filled rounded text color="teal" class="noCaps justify-end"  v-bind="attrs" v-on="on" >
                        <v-icon small>mdi-plus</v-icon>
                        Додати країну
                      </v-btn>
                    </template>

                    <v-sheet >
                      <div class="px-4 pt-3">
                        <v-text-field v-model="countrySearch"
                                      hide-details dense class="caption" append-icon="mdi-magnify" />
                      </div>
                      <v-sheet max-height="220" style="overflow-y: auto">
                        <v-list dense>
                          <v-list-item-group  >
                            <v-list-item  v-for="(item,index) in countriesSelectFiltered" :key="index"
                                          style=" min-height: auto !important; height: 22px !important"
                                          class="py-0"
                                          @click="countrySelect(item)">
                              <v-list-item-title class="py-0 my-0">{{ item.text }}</v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-sheet>
                    </v-sheet>

                  </v-menu>
                </div>


                <v-sheet :class="!country.allCities ? 'my-2' : '' "   rounded :outlined="!country.allCities" v-for="(country,index) in permissionCountriesList" :key="index">
                  <div class="d-flex justify-space-between align-center"
                       :class="!country.allCities ? 'mt-2 pl-2' : '' " >

                    <div class="d-flex align-center">
                      <v-btn @click="deleteCountry(country)" icon x-small><v-icon x-small>mdi-close</v-icon></v-btn>
                      <h5 style="line-height: 1">{{  country.name  }}</h5>
                    </div>

                    <div class="d-flex align-center">
                      <h6 class="font-weight-regular" style="line-height: 1">Всі Міста: </h6>
                      <div>
                        <v-checkbox  class="d-inline" v-model="country.allCities" dense hide-details ></v-checkbox>
                      </div>
                    </div>

                  </div>
                  <v-sheet  rounded class="pa-3" v-if="!country.allCities">

                    <div class="d-flex align-center" >

                      <code> {{ citySearch }}</code>
                      <gmap-autocomplete :key="'address_'+country.cca2"
                                         :value="country.citySearch"
                                         @place_changed="updateGooleLocation($event,index)"
                                         placeholder="Введіть назву міста"
                                         lang="ru"
                                         language="ru"
                                         class="caption"
                                         style="width: 100%"
                                         :sensor="false"
                                         :options="{
                                           fields:[],
                                           sensor : false,
                                           lang : 'ru',
                                           language : 'ru',
                                           types: ['(cities)'],
                                           componentRestrictions:  { country : country.cca2  }  }"

                      />
                    </div>
                    <v-divider />

                    <div class="mt-3" v-for="(city,j) in country.permittedCities" :key="j">
                      <h6>{{  city.name  }}</h6>
                    </div>

                  </v-sheet>
                  <v-divider v-if="!country.allCities" />

                </v-sheet>

                <v-menu v-if="permissionCountriesList.length > 0" offset-y :close-on-content-click="false"  >

                  <template v-slot:activator="{ on, attrs }">
                    <v-btn filled rounded block text color="teal" class="noCaps justify-end text-right mt-6"  v-bind="attrs" v-on="on" >
                      <v-icon small>mdi-plus</v-icon>
                      Додати країну
                    </v-btn>
                  </template>

                  <v-sheet max-height="220" style="overflow-y: auto">
                    <div class="px-4 pt-3">
                      <v-text-field v-model="countrySearch"
                                    hide-details dense class="caption" append-icon="mdi-magnify" />
                    </div>

                    <v-list dense>
                      <v-list-item-group>
                        <v-list-item v-for="(item,index) in countriesSelectFiltered" :key="index"
                                     @click="countrySelect(item)">
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-sheet>

                </v-menu>

              </div>


            </div>
            <v-divider />
          </div>
        </v-expand-transition>



      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "BusinessDashDeliveries",
  data(){
    return {
      loading     : false,

      googleCitiesObject : { },

      countriesSelect : [],
      permissionCountriesList : [],
      countrySearch : '',
      citySearch : '',

      showSelf    : false,
      showAddress : false,

      deliveries          : [],
      businessDelivery    : [],

      permissionCities    : false,
      permissionCountries : false,



      out : null,


    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),

    countriesSelectFiltered() {
      let items = this.countriesSelect;
      if ( !this.loading ) {
        items = items.filter(el => !this.permissionCountriesList.map(el=>el['cca2']).includes(el.value) )
        if ( this.countrySearch ) {
           items = items.filter(el=> el.text.toLowerCase().includes(this.countrySearch.toLowerCase())  )
        }

      }

      return items
    }
  },
  methods : {
    ...mapActions('business', [ 'GET_DELIVERY_TYPES','EDIT_DELIVERY_TYPES']),
    ...mapActions('dashboard', [ 'GET_GMAP_GEOCODE']),

    countrySelect(item) {

      if ( !this.permissionCountriesList.map(el=>el['cca2']).includes(item.value) ) {

        this.permissionCountriesList.push({
          name : item.text  ,
          cca2 : item.value,
          allCities : true,
          permittedCities : []
        })
      } else {
        this.notify('Країна вже є у списку')
      }

    },
    deleteCountry(country) {
      this.notify('Країну видалено')
      let index = this.permissionCountriesList.findIndex(el=>el.cca2 === country.cca2)
      if ( index !== -1 ) { this.permissionCountriesList.splice(index,1) }
      this.notify('Країну видалено')
    },

    saveChanges(){
      let data = {
        deliveries : [],
        deliveryPermissions : [],
        deliveryCountries : this.permissionCountriesList };
      this.deliveries.forEach((delivery)=>{
        if (delivery.value) { data.deliveries.push(delivery.id) }
      })

      if ( this.permissionCities)    { data.deliveryPermissions.push('CITIES') }
      if ( this.permissionCountries) { data.deliveryPermissions.push('COUNTRIES') }

      this.loading = true
      this.EDIT_DELIVERY_TYPES( {alias:this.selectedBusiness.alias,data:data} ).then((out)=>{
        console.log(out)
        this.loading = false
      }).catch(()=>{this.loading = false})
    },
    addPermittedCity(city,index) {

      if ( !this.permissionCountriesList[index].permittedCities.map(el=>el['placeId'])
          .includes(city.placeId) ) {
        this.permissionCountriesList[index].permittedCities.push(city)

        this.notify('Місто додано: ' + city.name)
      } else  {
        this.notify('Місто вже існує в списку')
      }
      this.permissionCountriesList[index].citySearch = ''


    },
    updateGooleLocation($event,index){

      let postal_code = $event.address_components.find(el => el.types.includes('postal_code'))

      let city = {
        postal_code : postal_code.long_name,
        name : $event.name,
        placeId : $event.place_id,
        bounds: $event.geometry.viewport.toJSON(),
        center: $event.geometry.location.toJSON(),
      }
      console.log('ADD CITY')
      console.log(city)
      this.addPermittedCity(city,index)
    },

    //technical
    expand_properties(id) {
      switch(id) {
        case 'address' : return this.showAddress = !this.showAddress;
        case 'self'    : return this.showSelf    = !this.showSelf;
      }
    }
  },
  mounted(){

    this.loading = true
    this.GET_DELIVERY_TYPES(this.selectedBusiness.alias).then((out)=>{
      if (out.result) {
        this.out = out.data
        this.permissionCountriesList = out.data.countries



        this.deliveries        = out.data.deliveries
        this.businessDelivery  = out.data.businessDeliveries
        this.deliveries.forEach((delivery)=>{
          if (this.businessDelivery.length > 0 ) {
            if ( this.businessDelivery.findIndex(el=>el.id === delivery.id) !== -1 ) {
              delivery.value = true
            }
          } else {  delivery.value = false }
        })

        if (out.data.deliveryPermissions) {
              this.permissionCities    =  out.data.deliveryPermissions.includes('CITIES')
              this.permissionCountries =  out.data.deliveryPermissions.includes('COUNTRIES')
        }
        this.loading = false
      }
    }).catch(()=>{this.loading=false})

    fetch('https://restcountries.com/v3.1/all').then((resp) =>  resp.json()).then((out)=>{
      out.forEach((country)=>{
        this.countriesSelect.push(
            { text : country.name.common,
              value : country.cca2
            }
            )
      })
      this.countriesSelect.sort((a, b) => a.text.localeCompare(b.text))
    })
  }
}
</script>

<style scoped>

</style>