export default {
    methods : {
        gAnalyticsInit(id) {
            var oldSripts = document.getElementsByClassName('gTagTag')
            oldSripts.forEach((script)=>{ script.remove() })
            var head = document.getElementsByTagName('head')[0];

            var gTagIncludeScript = document.createElement('script');
            gTagIncludeScript.src = "https://www.googletagmanager.com/gtag/js?id=" + id;
            gTagIncludeScript.async = true
            gTagIncludeScript.classList.add('gTagTag');

            var gTagMainScript = document.createElement('script');
            gTagMainScript.classList.add('gTagTag');
            var inlineScript = document.createTextNode(" window.dataLayer = window.dataLayer || [];\n" +
                " function gtag(){dataLayer.push(arguments);}\n" +
                " gtag('js', new Date());\n" +
                " gtag('config', '" + id +"');");
            gTagMainScript.appendChild(inlineScript);

            head.append(gTagIncludeScript)
            head.append(gTagMainScript)
        },
    }
}