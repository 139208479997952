var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pt-3",attrs:{"outlined":"","rounded":""}},[_c('div',{staticClass:"px-5"},[_c('v-expand-transition',{attrs:{"mode":"out-in"}},[(!_vm.editElement &&
               !_vm.editChildElement &&
               !_vm.deleteElement &&
               !_vm.deleteChildElement)?_c('div',[_c('draggable',{staticClass:"mb-1",attrs:{"forceFallback":true,"handle":".handle","group":"items","tag":"div"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.value),function(item,i){return _c('div',{key:i,staticClass:"mainItem"},[_c('ftMainMenuDisplayElement',{attrs:{"value":_vm.value[i]},on:{"editRow":function($event){
               var i$1 = arguments.length, argsArray = Array(i$1);
               while ( i$1-- ) argsArray[i$1] = arguments[i$1];
return _vm.editAction.apply(void 0, argsArray.concat( [i] ))}}}),(item.action === 'submenu')?_c('v-sheet',{staticClass:"ml-10 mt-6 mb-6 pa-3",class:item.items.length === 0 ? 'pt-8' : '',attrs:{"rounded":"","color":"#343434"}},[_c('draggable',{attrs:{"empty-insert-threshold":10,"handle":".subHandle","forceFallback":true,"group":"items","tag":"div"},model:{value:(item.items),callback:function ($$v) {_vm.$set(item, "items", $$v)},expression:"item.items"}},_vm._l((item.items),function(_,j){return _c('ftMainMenuDisplayElement',{key:j,attrs:{"child":true,"value":_vm.value[i].items[j]},on:{"editRow":function($event){
               var i$1 = arguments.length, argsArray = Array(i$1);
               while ( i$1-- ) argsArray[i$1] = arguments[i$1];
return _vm.editAction.apply(void 0, argsArray.concat( [i],[j] ))}}})}),1),_c('v-divider',{staticClass:"my-1",attrs:{"dark":""}}),_c('div',{staticClass:"d-flex justify-end"},[( !_vm.editElement && !_vm.editChildElement )?_c('v-btn',{staticClass:"noCaps",attrs:{"text":""},on:{"click":function($event){return _vm.addRow(i)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_c('h5',[_vm._v("Додати")])],1):_vm._e()],1)],1):_vm._e()],1)}),0)],1):_vm._e(),(_vm.deleteElement)?_c('ftMainMenuDeleteElement',{on:{"deleteRow":function($event){
               var i = arguments.length, argsArray = Array(i);
               while ( i-- ) argsArray[i] = arguments[i];
return _vm.deleteRow.apply(void 0, argsArray.concat( [_vm.deleteIndex] ))},"closeDelete":function($event){return _vm.closeDelete()}},model:{value:(_vm.value[_vm.deleteIndex]),callback:function ($$v) {_vm.$set(_vm.value, _vm.deleteIndex, $$v)},expression:"value[deleteIndex]"}}):_vm._e(),(_vm.deleteChildElement)?_c('ftMainMenuDeleteElement',{attrs:{"child":true},on:{"deleteRow":function($event){
               var i = arguments.length, argsArray = Array(i);
               while ( i-- ) argsArray[i] = arguments[i];
return _vm.deleteRow.apply(void 0, argsArray.concat( [_vm.deleteIndex],[_vm.deleteChildIndex] ))},"closeDelete":function($event){return _vm.closeDelete()}},model:{value:(_vm.value[_vm.deleteIndex].items[_vm.deleteChildIndex]),callback:function ($$v) {_vm.$set(_vm.value[_vm.deleteIndex].items, _vm.deleteChildIndex, $$v)},expression:"value[deleteIndex].items[deleteChildIndex]"}}):_vm._e(),(_vm.editElement)?_c('ftMainMenuEditElement',{attrs:{"pages":_vm.pagesList},on:{"editRow":function($event){return _vm.editRow(_vm.editIndex)}},model:{value:(_vm.value[_vm.editIndex]),callback:function ($$v) {_vm.$set(_vm.value, _vm.editIndex, $$v)},expression:"value[editIndex]"}}):_vm._e(),(_vm.editChildElement)?_c('ftMainMenuEditElement',{attrs:{"pages":_vm.pagesList,"child":true},on:{"editRow":function($event){return _vm.editRow(_vm.editIndex,_vm.editChildIndex)}},model:{value:(_vm.value[_vm.editIndex].items[_vm.editChildIndex]),callback:function ($$v) {_vm.$set(_vm.value[_vm.editIndex].items, _vm.editChildIndex, $$v)},expression:"value[editIndex].items[editChildIndex]"}}):_vm._e()],1),_c('div',{staticClass:"d-flex justify-end mb-1"},[( !_vm.editElement &&
               !_vm.editChildElement &&
               !_vm.deleteElement &&
               !_vm.deleteChildElement )?_c('v-btn',{staticClass:"noCaps",attrs:{"text":""},on:{"click":function($event){return _vm.addRow()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_c('h5',[_vm._v("Додати")])],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }