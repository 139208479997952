<template>
<div >
  <div v-if="!editorMode">
    <div v-if="subElement !== 'html'" class="avalonFont"
         v-html="value.replace(/\n/gi, '<br>')"
         :class="`font-weight-${params.fontWeight}
                    text-${params.headingSize}
                    text-${params.align}  `"
         :style="`${textColor};
                    line-height : ${params.lineHeight};
                    font-size : ${params.fontSize}px; `" >
    </div>


    <div v-if="params.type === 'html'" v-html="value" ></div>
  </div>

  <div v-else>

<!--    @paste.prevent="pasteText"-->
    <div class="" >
      <div @focusout="editText"
           v-click-outside="closeParamsEdit"
           contenteditable="true"
           @click="showParamsEdit"

           class="mb-0  pa-3 editWindow avalonFont"
           :class="`font-weight-${params.fontWeight}
                    text-${params.headingSize}
                    text-${params.align}
                    `"
           :style="`${textColor};
                    line-height : ${params.lineHeight};
                    font-size : ${params.fontSize}px; `" >
        {{ value }}
      </div>

      <v-menu v-model="displayParamsEdit" absolute
              :position-y="paramsEditY"
              :position-x="paramsEditX"
              :close-on-click="false"
              :close-on-content-click="false" >

        <v-sheet dark  elevation="0" @click="this.displayParamsEdit = true">
          <div class="d-flex">
            <ftParamsBar v-model="$store.state.templates.page.blocks[block].value.items[row][item].params"
                         @delete="deleteItem"
                         @hover="hoverAction"
                         :auto-input="inputParams" />
          </div>
        </v-sheet>

      </v-menu>
      <div ref="paramsEditCanvas" style="position: relative" > </div>

    </div>

  </div>


</div>
</template>

<script>
import {mapState} from "vuex";
import ftParamsBar from "@/components/templates/editor/UI/ftParamsBar";

export default {
  name: "ft-text",
  props : ['value','item','block','row','params','subElement'],
  components : {
      ftParamsBar
    },
  data() {
      return {
        loading : false,
        addData : {},

        displayEdit : false,
        displayParamsEdit : false,

        paramsEditY : 0,
        paramsEditX : 0,

        preventClose : false,

        paramsData : {
           headingSize : null,
           fontWeight : null,
           lineHeight : null,
           color : null
        }

      }
    },

  computed : {
    ...mapState('templates',  ['editorMode', 'templateVars_init', 'templateVars_current' ]),
    accentColor() {
      return `color: ${this.parametr('accent_color')}`
    },
    textColor() {
      let color = 'color : '
      if ( this.subElement === 'heading' ) {
        color += this.params.color ?  this.params.color  : this.accentColor
      }

      return color;
    },
    inputParams() {
      let params = []
      switch (this.subElement) {
        case 'heading' : params = ['margin','align','headingSize','fontWeight','lineHeight','color','display','delete'] ;break;
        case 'text' : params = ['margin','align','fontSize','fontWeight','lineHeight','color','display','delete']    ;break;
      }
      return params
    }
  },
  methods : {
    hoverAction(val) {
        this.preventClose = val
    },
    closeParamsEdit() {
      if ( !this.preventClose && this.displayParamsEdit  ) {
        this.displayParamsEdit = false
      }
    },
    showParamsEdit() {

        this.paramsEditX = this.$refs.paramsEditCanvas.getBoundingClientRect().left
        this.paramsEditY = this.$refs.paramsEditCanvas.getBoundingClientRect().top + 10

        this.displayParamsEdit = true

      },
    openEdit() {
      this.$store.state.templates.openEditorBlockElement   = true
      this.$store.state.templates.selectedBlock     = this.block
      this.$store.state.templates.selectedBlockItem = this.item
      this.$store.state.templates.selectedBlockRow = this.row
    },
    editText(e) {
      this.$store.state.templates.page.blocks[this.block].value.items[this.row][this.item].value = e.target.innerText
    },
    cancelEdit(){
      this.displayEdit = false
      this.reactiveParam = this.templateVars_current[this.input].value
    },
    pasteText(e) {

      this.$store.state.templates.page.blocks[this.block].value.items[this.row][this.item].value = e.clipboardData.getData('Text')
    },
    saveChanges() {
      this.displayEdit = false
    },
    handleNextLine() {
      this.value = this.value.replace(/\n/gi, '<br>')
    },
    parametr(varName) {
      let param = '';
      if (  this.editorMode ) { param = this.templateVars_current[varName].value }
      if ( !this.editorMode ) { param = this.templateVars_init[varName]    }
      return param
    },
    deleteItem(){
      this.$store.state.templates.openEditorBlockElement   = true
      this.$store.state.templates.openEditorBlockDeleteElement   = true
      this.$store.state.templates.selectedBlock     = this.block
      this.$store.state.templates.selectedBlockItem = this.item
      this.$store.state.templates.selectedBlockRow = this.row
    }
  },
  beforeMount() {
      // this.handleNextLine()
  }
}

</script>

<style scoped lang="scss">

.avalonFont {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
}
.sideEditor {
  height: 100%;
  position: fixed;
  left: 0;
  top:0;
  width: 450px;
  z-index: 50000;
}
.editWindow {
  border : 1px #ffffffbb dashed;
}
.editWindow:hover {
  background-color: #ffffff55;
  cursor: pointer;
}
</style>