<template>
  <v-sheet elevation="2" dark >
    <aside class="menu" style="background-color: #242424" :class="{menuActive: sideMenu}">

      <!--    header-->
      <v-layout align-center justify-center class="menu_header">
        <v-fade-transition mode="out-in" >
          <img v-if="sideMenu" height="50px" alt="WeStudy" src="@/assets/brand/logo_fine_white.png" class="container--fluid" key="logo_big">
          <img v-else width="40px"  alt="WeStudy" src="@/assets/brand/logo_white_small.png" class="container--fluid" key="logo_small">
        </v-fade-transition>
      </v-layout>


      <v-divider></v-divider>

      <menuElements />


    </aside>
  </v-sheet>

</template>

<script>
import {mapState} from 'vuex';
import menuElements from "@/components/pages/dashboard/UI/sidebar/menuElements";
export default {
  name: "sideBar",
  components : {
    menuElements
  },
  data: () => ({
    selectedItem: 1,
    items: [
      { text: 'Real-Time', icon: 'mdi-clock' },
      { text: 'Audience', icon: 'mdi-account' },
      { text: 'Conversions', icon: 'mdi-flag' },
    ],
  }),
  computed : {
    ... mapState('dashboard',['sideMenu'])
  }


}
</script>

<style scoped>

.menu_header {
  background-color: #242424;
  height: 70px;
}

.menu {
  position:fixed;
  width:60px;
  height:100%;
  background-color: white;
  color: #2c3e50;
  float:left;
  transition: width 0.3s ease-out;
  overflow-y: auto;
}
.menuActive {
  width:250px !important;
}
.menuActive li {
  padding-left: 35px !important;
}

.menu ul {
  padding:0;
}
.menu li {
  list-style: none;
  margin: 0px;
  padding:5px;
  padding-left:17px;
  transition: all 0.3s ease-out;
}
.menu li a {
  color: #2c3e50;
  text-decoration: none;
}
.menu a:hover {
  transition: all 0.3s ease-out;
  color: antiquewhite;
}
.menu li:hover {
  background-color: #ffffff33;
}
</style>