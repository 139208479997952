<template>
  <div class="headerWrapper"
        :style="loggedIn && userBusinesses.includes(businessAlias)  ? 'top: 35px !important' : 'top:0'">


    <!-- HEADER MAIN-->
    <v-sheet :color="stHeader ? '#000000bb' : '#000000cc'"
             class="d-flex align-center header"
             dark>
      <div class="section-content d-flex justify-space-between align-center px-3">


        <router-link :to="`/${businessAlias}`">
          <img style="height: 50px" class="mt-2" :src="parametr('logo')" alt=""/>
        </router-link>

        <avalonMainMenu input="mainMenu" />

        <div class="d-flex align-center">
          <v-btn v-if="!$store.state.auth.loggedIn"  outlined large rounded text @click="toggleLoginMenu">Увійти</v-btn>

          <v-btn large  icon class="ml-8" @click="showCart">
            <v-badge :content="quantity" :value="quantity" :color="parametr('accent_color')" overlap>
              <v-icon >mdi-cart</v-icon>
            </v-badge>
          </v-btn>

          <notificationMenu v-if="$store.state.auth.loggedIn" class="ml-2" />
          <userDropdown v-if="$store.state.auth.loggedIn"
                        :main_menu="false"
                        class="userDropdown"
                        img_size="0"/>
        </div>




      </div>


    </v-sheet>



  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import notificationMenu from "@/components/UI/header/notificationMenu";
import userDropdown from "@/components/UI/userDropdown";
import avalonMainMenu from "@/components/templates/Avalon/UI/elements/avalonMainMenu";

export default {
  name: "avalonHeader",
  props: ['sticky'],
  components: {
    notificationMenu,
    userDropdown,
    avalonMainMenu,
  },
  data() {
    return {
      scrollPosition: 0,

      mobileMenu: false,
      logo: '',
      accentColor: '',



    }
  },
  computed: {
    ...mapState('templates', [ 'businessAlias', 'editorMode', 'menuItems' , 'templateVars_init', 'templateVars_current']),
    ...mapState('cart', [ 'quantity']),
    ...mapState('auth', ['loggedIn', 'userBusinesses']),

    stHeader() {
      if ( this.scrollPosition > 150) { return true }
      return false
    },
  },
  methods: {
    ...mapMutations(['toggleLoginMenu']),

    buttonAction(action) {
      this.notify(action)
    },
    showCart() {
      this.$store.state.cart.showCart = !this.$store.state.cart.showCart
    },
    menuAction(a, b, c) {
      console.log(a, b, c)
      this.notify('menuAction')
    },
    //technical
    parametr(varName) {
      let param = '';
      if (this.editorMode) {
        param = this.templateVars_current[varName] ? this.templateVars_current[varName].buffer : 'null'
      }
      if (!this.editorMode) {
        param = this.templateVars_init[varName]
      }
      return param
    },

    onScroll() {
      this.scrollPosition = window.top.scrollY
    }

  },

  mounted() {
    this.logo = 'http://localhost:8000/stor/avalon_tmpl_logo.png'

    if (this.parametr('accent_color') != 'null') {
      this.accentColor = this.parametr('accent_color')
    } else {
      this.accentColor = '#fbc02d'
    }





    window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
}
</script>

<style scoped>
.headerWrapper {
  position: fixed;
  top:0px;
  width: 100%;
  z-index : 10;
}
.header {
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  transition: all .6s ease;
}

.stickyHeader {

  position: fixed !important;
  left: 0;
  right: 0;
  z-index: 20;

}

.stickyHeaderEdit {
  top: 36px !important;
}

.section-content {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}

.preHeader {
  padding: 0px 50px;
  background-color: #ffffff00;

  top : 0;
  width: 100%;
  z-index: 21;
}

.w-90vh {
  width: 90%
}
</style>