<template>
  <div :class="displayHide(['sm']) ? 'pa-6 px-8' : 'pa-3' ">
    <div v-if="displayHide('sm')"
         class="d-flex justify-space-between mb-6">
      <div class="d-flex align-center">
        <v-btn outlined  icon class="mr-3" color="blue-grey">
          <v-icon  >mdi-view-dashboard-outline</v-icon>
        </v-btn>
        <div>
          <h3 style="line-height: 1.2"> Фронт Питної Води </h3>
          <h5 class="font-weight-medium">Таблиця</h5>
        </div>
      </div>
      <v-sheet width="200">
        <v-text-field clearable v-model="search" dense outlined hide-details append-icon="mdi-magnify" />
      </v-sheet>
    </div>

    <v-sheet outlined class="pa-6 mt-6" style="border-radius: 15px !important">

      <h3>Всього: {{ total }} </h3>

      <v-data-table :items="items"
                    :headers="headers"
                    :search="search">

        <template v-slot:item="{item}">
          <tr  :style="item.isDeleted ? 'background:#eeb9b9' : '' ">
            <td>
              <h5>{{ item.name }}</h5>
              <h5 class="font-weight-light"> {{ item.email }}</h5>
            </td>
            <td align="center">
              <h5 class="d-flex"><v-icon small>mdi-phone</v-icon> {{ item.phone }}</h5>
            </td>
            <td align="center">
              <h6 class="font-weight-light"><v-icon small>mdi-map</v-icon> {{ item.address }}</h6>
            </td>
            <td align="center">
              <v-btn :href="item.document" target="_blank" outlined color="teal" class="noCaps">
                Документ
                <v-icon small class="ml-2">mdi-download</v-icon>
              </v-btn>
            </td>
            <td align="center">
              <h5 class="font-weight-light"><v-icon small>mdi-calendar</v-icon> {{ item.date }}</h5>
            </td>
            <td align="center">
              <h5><v-icon small>mdi-car</v-icon> {{ item.deliveries_count }}</h5>
            </td>
            <td>
              <v-btn @click="cancelParticipant(item)" :color="!item.isDeleted ? 'green lighten-1' : 'red lighten-1' " icon>
                <v-icon>{{ item.isDeleted ? 'mdi-close-circle' : 'mdi-check-circle'  }}</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      </v-data-table>


    </v-sheet>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "DashAction",
  data() {
    return {
      items : [],
      search : '',
      headers : [
        { text : "Ім'я" , value : 'name' },
        { text : "Телефон" , value : 'phone', align : 'center' },
        { text : "Адреса" , value : 'address', align : 'center' },
        { text : "Документ" , value : 'document', align : 'center' },
        { text : "Дата" , value : 'date', align : 'center' },
        { text : "Доставок" , value : 'deliveries_count', align : 'center' },
        { text : '(+)',  value: 'isDeleted'},
      ]
    }
  },
  computed : {
    total() {
      let total = 0
      if ( this.items.length > 0 ) {
        total = this.items.filter(el => !el.isDeleted ).length
      }
      return total
    }
  },
  methods : {
    ...mapActions('orders',  ['GET_ACTION_PARTICIPANTS','CHANGE_PARTICIPANT' ]),
    cancelParticipant(item) {
      let data = {
        id : item.id,
        isDeleted : !item.isDeleted
      }
      this.CHANGE_PARTICIPANT(data).then(out=>{
        if ( out.result) {
          item.isDeleted = !item.isDeleted
        }
      }).catch(()=>this.notify('Error'))

    }
  },
  mounted() {
    this.GET_ACTION_PARTICIPANTS().then(out=>{
      if (out.result) {
        this.items = out.data
        this.items.reverse()
      }
    })
  }

}
</script>

<style scoped>

</style>