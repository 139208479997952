<template>
  <v-dialog v-if="$store.state.auth.showNewUserDialog"
            v-model="$store.state.auth.showNewUserDialog"
            :fullscreen="displayShow(['xs','sm'])"
            width="600">


    <v-sheet class="pa-4"  :height="displayShow(['xs','sm']) ? '100vh' : '' " >
      <div class="d-flex justify-space-between align-center">
        <h3 class="font-weight-light">Реєстрацію завершено</h3>
        <v-btn @click="$store.state.auth.showNewUserDialog = false"
               small icon><v-icon small>mdi-close</v-icon></v-btn>
      </div>
      <v-divider class="mt-1" />
      <h3 class="mt-15 mb-8 text-center font-weight-light">Обліковий запис успішно зареєстровано!</h3>
      <div  class="d-flex justify-center mb-8 " >
        <v-chip class="text-center " outlined> Ключ  інтеграції: <b>  {{ $store.state.auth.userLinkKey }} </b></v-chip>
      </div>
      <h5 class="text-center font-weight-light">Під'єднайся до нашого телеграм бота! Ти зможеш робити замовлення прямо з Телеграм, а також отримувати оповіщення, щодо виконання замовлень, це дуже зручно!</h5>
      <h5 class="text-center" >Для під'єднання облікового запису до Телеграм бота, введіть ключ інтеграції :  </h5>

      <div  class="d-flex justify-center mb-15" >
        <v-btn target="_blank"
               :href="$store.state.templates.selectedBusiness.telegramAlias ?
                              $store.state.templates.selectedBusiness.telegramAlias :
                              'https://t.me/finetrading_bot'"
               class="noCaps font-weight-regular mt-8"
               color="blue lighten-1"
               dark rounded >
          <v-img width="23"  class="rounded mr-3"  alt="Telegram" src="@/assets/socials/telegram.png"  />
          {{ telegramBotName }}</v-btn>
      </div>


<!--      <v-sheet outlined class="pa-3">-->
<!--        <h5 class="text-center font-weight-light">Ми використовуємо сервіс єдиної авторизації клієнтів FineTrading, тому Ви можете використовувати створений акаунт, на інших сайтах, які користуються FineTrading.-->
<!--          </h5>-->
<!--        <div class="d-flex justify-center ">-->
<!--          <v-btn  text small class="noCaps text-center">Детальніше</v-btn>-->
<!--        </div>-->
<!--      </v-sheet>-->

      <div class="d-flex justify-center mb-1">
        <v-btn @click="$store.state.auth.showNewUserDialog = false"  class="noCaps" small text>Повернутися на сайт</v-btn>
      </div>
      <div class="d-flex justify-center mb-15">
        <router-link style="text-decoration: none" :to="`/dashboard/${$store.state.auth.rootPlace}`">
          <v-btn @click="$store.state.auth.showNewUserDialog = false"   small outlined class="noCaps">Користувацька панель</v-btn>
        </router-link>
      </div>

    </v-sheet>

  </v-dialog>
</template>

<script>
export default {
  name: "newUserDialog",
  computed : {
    telegramBotName(){
      let name = this.$store.state.templates.selectedBusiness.telegramAlias
      if ( name ) {
        name = name.split('/')[name.split('/').length-1]
        return 'Telegram: @' + name
      }
      return 'Telegram Bot'
    }
  }
}
</script>

<style scoped>

</style>