<template>
  <div>
    <transition mode="out-in">
      <!-- LOGIN WINDOW-->
      <div v-if="!signUp && !restoreWindow" key="log">
        <form  class=" compact-form" @submit.prevent="login">
          <!-- LOGIN: EMAIL-->
          <div class="d-flex justify-space-between align-center mb-6">
            <h4 class="">Вхід:</h4>
            <v-btn-toggle background-color="transparent" borderless mandatory v-model="loginType" dense>
              <v-btn active-class="loginTypeButton" color="transparent" class="noCaps">Через Пошту</v-btn>
              <v-btn active-class="loginTypeButton" color="transparent" class="noCaps">За телефоном</v-btn>
            </v-btn-toggle>
          </div>
          <v-text-field  v-if="loginType===0"
                         v-model="email"
                         label="Email"
                         :error-messages="emailError"
                         filled rounded
                         dense
                         required  />
          <v-text-field  v-if="loginType===1"
                         v-model="phoneNumber"
                         :error-messages="emailError"
                         @blur="phoneInputFocus  = false"
                         @focus="phoneInputFocus  = true"
                         class="red--text"
                         @keypress="onlyNumberInput($event)" ref="phone"
                         placeholder="063-111-22-33"
                         label="Телефон"

                        dense filled rounded  >
            <template  slot="prepend-inner" >
              <div class="grey--text text--lighten-1"
                   :style="phoneInputFocus || phoneNumber ? 'margin-top:17px' : 'margin-top:5px' "
                   style="width: 50px; transition: all 0.3s ease ">
                {{ phoneCountryCode }}
              </div>
            </template>
          </v-text-field>
          <v-text-field  v-model="password"
                         label="Пароль"
                         :error-messages="passwordError"
                         type="password"
                         filled rounded
                         dense
                         required />
<!--          <v-radio-group>-->
<!--            <v-radio label="Увійти через FineTrading акаунт" />-->
<!--            <v-radio label="Увійти через Gmail" />-->
<!--            <v-radio label="Увійти через Facebook" />-->
<!--          </v-radio-group>-->

          <v-btn block class="noCaps" text>
            <h3 class="text-right" >
              <a href="#" @click="resetPasswordModal(); restoreWindow = true"
                 class=" link"
                 :class="{link_dark : dark }">
                <span class="white--text">Забули свій пароль?</span>
              </a>
            </h3>
          </v-btn>


          <v-btn :loading="loader"
                 :disabled="loader"
                 x-large block
                 type="submit"
                 outlined
                 class="rounded-0 mt-10 mb-3 mt-10 noCaps" >
            <v-icon class="mr-2">mdi-login-variant</v-icon>
            Увійти
          </v-btn>
          <v-btn :disabled="loader"
                 x-large block
                 @click="signUp=true"
                 text
                 class="rounded-0 noCaps">
            <v-icon class="mr-2">mdi-account-plus-outline</v-icon>
            Зареєструватися
          </v-btn>

        </form>
      </div>
      <!-- REGISTRATION WINDOW-->
      <div v-if="signUp && !restoreWindow" key="sign">
        <form class="compact-form" @submit.prevent="nextStep">
          <h4 class="mb-6">Реєстрація:</h4>
          <transition mode="out-in">
            <!-- REGISTER: EMAIL, PASSWORD -->


            <section key="s1" v-if="step==1">

              <v-text-field v-model="signEmail"
                             label="Email"
                             :error-messages="signEmailError"
                             filled rounded
                             size="sm"
                             required />
              <v-text-field filled rounded  v-model="signPassword" :error-messages="signPasswordError" label="Пароль" type="password" size="sm" required/>
              <v-text-field filled rounded  v-model="signPassword2" :error-messages="signPasswordError" label="Пароль ще раз" type="password" size="sm" required/>
            </section>
            <section key="s2" v-if="step==2">
              <v-text-field filled rounded  v-model="signFirstName"  label="Ім'я" size="sm" required/>
              <v-text-field filled rounded  v-model="signLastName"  label="Прізвище" size="sm" required/>
              <v-text-field  v-model="phoneNumber"
                             :error-messages="emailError"
                             @blur="phoneInputFocus  = false"
                             @focus="phoneInputFocus  = true"
                             class="red--text"
                             @keypress="onlyNumberInput($event)" ref="phone"
                             placeholder="063-111-22-33"
                             label="Телефон"
                             required
                             dense filled rounded  >
                <template  slot="prepend-inner" >
                  <div class="grey--text text--lighten-1"
                       :style="phoneInputFocus || phoneNumber ? 'margin-top:17px' : 'margin-top:5px' "
                       style="width: 50px; transition: all 0.3s ease ">
                    {{ phoneCountryCode }}
                  </div>
                </template>
              </v-text-field>
              <v-menu
                  dark
                  ref="menu"
                  v-model="menuBirth"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      filled rounded
                      v-model="signBirth"
                      label="Дата народження"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      required
                      :error-messages="birthError"
                  />
                </template>
                <v-date-picker
                    light
                    ref="picker"
                    v-model="signBirth"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    no-title
                    locale="uk-UA"
                    @change="birthSave"
                ></v-date-picker>
              </v-menu>
            </section>
            <section key="s3" v-if="step==3">
              <v-text-field mdi-calendar  v-model="signCountry" label="Country" size="sm" required/>
              <v-text-field mdi-calendar  v-model="signProvince" label="Province" size="sm" required/>
              <v-text-field mdi-calendar  v-model="signCity" label="City" size="sm" required/>
              <v-switch v-if="!this.$store.state.templates.businessAlias"    v-model="signBusiness" label="Акаунт для бізнесу" />
            </section>
            <section key="s4" v-if="step==4">
              {{ regOutput }}
            </section>
          </transition>
          <v-row class="mt-10">
            <v-col cols="6">
              <v-btn :disabled="loader" large block text @click="back"  class="noCaps">
                <v-icon class="mr-2">mdi-chevron-left</v-icon>
                Назад
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn :loading="loader"
                     :disabled="loader"
                     large block
                     type="submit"
                     outlined
                     class="noCaps " >
                Далі
                <v-icon class="ml-2">mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </form>
      </div>
      <!-- RESTORE PASSWORD WINDOW-->
      <div v-if="restoreWindow" key="restore">
          <!-- RESTORE: EMAIL-->
          <div class="d-flex justify-space-between align-center mb-6">
            <h4 class="">Відновлення: </h4>
            <v-btn-toggle background-color="transparent" borderless mandatory v-model="restoreType" dense>
              <v-btn value="email" color="transparent" class="noCaps">Через Пошту</v-btn>
              <v-btn value="phone" color="transparent" class="noCaps">За телефоном</v-btn>
            </v-btn-toggle>
          </div>
          <h4 v-if="restoreStep === 0" class="font-weight-light text-center px-3 mt-3 mb-6">
            <span v-if="restoreType==='email'">
               Введіть адресу електронної пошти, на яку зареєстровано ваш обліковий запис, на неї буде відправлено лист із кодом зміни паролю
            </span>
            <span v-if="restoreType==='phone'">
               Введіть номер телефону, на який зареєстровано ваш обліковий запис, на нього буде відправлено SMS із кодом зміни паролю
            </span>

          </h4>
          <h4 v-if="restoreStep === 1" class="font-weight-light text-center px-3 mt-3 mb-9">Введіть код зміни паролю та введіть новий пароль </h4>

          <v-text-field  v-if="restoreStep === 0 & restoreType==='email'"
                         v-model="email"
                         label="Email"
                         :error-messages="emailError"
                         filled rounded
                         dense
                           />
          <v-text-field  v-if="restoreStep === 0 & restoreType==='phone'"
                         v-model="phoneNumber"
                         :error-messages="emailError"
                         @blur="phoneInputFocus  = false"
                         @focus="phoneInputFocus  = true"
                         class="red--text"
                         @keypress="onlyNumberInput($event)" ref="phone"
                         placeholder="063-111-22-33"
                         label="Телефон"

                         dense filled rounded  >
            <template  slot="prepend-inner" >
              <div class="grey--text text--lighten-1"
                   :style="phoneInputFocus || phoneNumber ? 'margin-top:17px' : 'margin-top:5px' "
                   style="width: 50px; transition: all 0.3s ease ">
                {{ phoneCountryCode }}
              </div>
            </template>
          </v-text-field>

          <v-text-field  v-if="restoreStep === 1"
                         v-model="restoreCode"
                         label="Код зміни паролю"
                         :error-messages="restoreCodeError"
                         @input="restoreCodeError = []"
                         filled rounded
                         dense
                          />
          <v-text-field  v-if="restoreStep === 1"
                         v-model="signPassword"
                         label="Новий Пароль"
                         :error-messages="signPasswordError"
                         type="password"
                         filled rounded
                         dense
                         required />
          <v-text-field  v-if="restoreStep === 1"
                         v-model="signPassword2"
                         label="Новий Пароль ще раз"
                         :error-messages="signPasswordError"
                         type="password"
                         filled rounded
                         dense
                         required />

          <v-row class="mt-10">
            <v-col cols="6">
              <v-btn @click="restorePrevStep"
                     :disabled="loading"
                     class="noCaps"
                     large block text >
                <v-icon class="mr-2">mdi-chevron-left</v-icon>
                Назад
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn @click="restoreNextStep"
                     :loading="loading"
                     :disabled="loading"
                     large block
                     outlined
                     class="noCaps " >
                  Далі
                <v-icon class="ml-2">mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>

      </div>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';

export default {
  name: "authWindow",
  props : {
    dark : Boolean,
    noToggle : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {

      loading  : false,

      restoreWindow : false,
      restoreStep : 0,
      restoreCode : '',
      restoreEmail : '',
      restoreCodeError : [],
      restoreType : 'email',

      phoneCountryCode : '+38',
      phoneNumber : '',
      phoneInputFocus : false,

      loginType : 0,
      email: '',
      password: '',
      signEmail: '',
      signPassword: '',
      signPassword2: '',
      signFirstName: '',
      signLastName: '',
      signTelephone: '',
      signBirth: '',
      signCountry: '',
      signProvince: '',
      signCity: '',
      signUp : false,
      signBusiness : false,
      regOutput : '',
      step : 1,
      validLogin: false,
      validSign1: false,
      validSign2: false,
      validSign3: false,
      emailError:[],
      passwordError:[],
      signEmailError:[],
      signPasswordError:[],
      birthError : '',
      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ ,
      menuBirth : false,
    }
  },
  computed : {
    ...mapState(['showLoginMenu']),
    ...mapState('auth',['loader']),

  },
  watch : {
    // validation block
    phoneNumber(value){
      if (value ) {
        if ( value.length !== 10) {
          return this.emailError = ['Номер телефона складається з 10 цифр']
        }
        return this.emailError = []
      }
      return this.emailError = []
    },

    email() {
      this.emailError = [];
    },
    password() {
      this.passwordError = [];
    },
    signPassword2() {
      this.signPasswordError=[];
    },
    signPassword() {
      this.signPasswordError=[];
    },
    signEmail() {
      this.signEmailError=[];
    },
    birthMenu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    showLoginMenu() {
      this.FLUSH_LOADER()
    }
  },
  methods : {
    ...mapActions('auth',['LOGIN','REGISTER','CHECK_EMAIL','GET_USER','REQUEST_PASSWORD','CHANGE_PASSWORD']),
    ...mapMutations('auth',['SET_RESEND_TOKEN','SHOW_MODAL_CONFIRMAION','SHOW_MODAL_CONFIRM_INFO','SHOW_MODAL_PASSWORD_RESET','FLUSH_LOADER']),
    ...mapMutations(['toggleLoginMenu']),
    onlyNumberInput(input){

      input = (input) ? input : window.event;
      var charCode = (input.which) ? input.which : input.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        input.preventDefault();
      } else {
        return true;
      }

    },
    login() {
      this.email = this.email.replace(/ /g,'');
      if(  !this.reg.test(this.email) && this.loginType !== 1 ) {
        this.emailError = ['Не правильна електронна адреса'];
        return
      }

      if(  this.loginType === 1 ) {
        if ( this.phoneNumber.length !== 10) {
          this.emailError = ['Номер телефона складається з 10 цифр'];
          return
        } else {
          this.email = this.phoneCountryCode + this.phoneNumber
        }
      }

        const data = {
          email: this.email,
          password: this.password,
          router :this.$router,
          path   :this.$route.path
        }
        this.LOGIN(data).then((out)=>{
          if (out.result) {
            this.flushLogin();
            if (!this.noToggle) {
              this.toggleLoginMenu();
            }


            // this.GET_USER(out.data.token).then(()=>{
            //   this.flushLogin();
            //   this.toggleLoginMenu();
            // })

          } else {
            switch(out.error) {
              case 'User not found' : this.emailError = ['Користувача не знайдено']; break;
              case 'Wrong password' : this.passwordError = ['Не вірний пароль']; break;
              case 3 :
                this.toggleLoginMenu();
                this.SET_RESEND_TOKEN({token:out.resend_token})
                this.SHOW_MODAL_CONFIRM_INFO();
                this.flushLogin()
                break;
              default : this.emailError['Connection problems'];
            }

          }
        }).catch(()=>{
          this.emailError = ['Connection problems']
        });
    },
    restorePrevStep() {
      this.restoreStep--;
      if (this.restoreStep === -1) {
        this.restoreWindow = false;
        this.restoreStep = 0
      }
    },
    restoreNextStep() {
      switch(this.restoreStep) {
        case 0 :
          var restoredata = {}
          if (this.restoreType === 'email' )  {
            this.email = this.email.replace(/ /g,'');
            if(!this.reg.test(this.email)) {
              this.emailError = ['Не правильна електронна адреса'];
              break;
            }
            restoredata.email = this.email
          }
          if ( this.restoreType === 'phone' ) {
            restoredata.phone = '' + this.phoneCountryCode + this.phoneNumber
          }
              this.loading = true
              this.REQUEST_PASSWORD(restoredata).then((out)=>{
                if ( out.result ) {
                  this.restoreStep++
                } else {
                  this.emailError = ['Користувача не знайдено']
                }
                this.loading = false
              }).catch(()=> {
                    this.emailError = ['Користувача не знайдено']
                this.loading = false
                  }
              )
           break;
        case 1 :
          if ( this.signPassword !== this.signPassword2 ) {
            this.signPasswordError = ['Паролі не співпадають'];
            break;
          }
          this.loading = true
            var data = {
              code : this.restoreCode,
              password : this.signPassword,
              repeat_password : this.signPassword2
            }
          if ( this.restoreType === 'email' ) {
            data.email = this.email
          }
          if ( this.restoreType === 'phone' ) {
            data.phone = '' + this.phoneCountryCode + this.phoneNumber
          }

          this.CHANGE_PASSWORD(data).then((out)=>{
            if  (out.result ) {
              this.notify('Пароль успішно оновлено')
              this.flushRestore()
              this.toggleLoginMenu()

            }
            this.loading = false
          }).catch(()=>{
            this.restoreCodeError = ['Не вірний код зміни паролю']
            this.loading = false
          })
          break;
      }
    },
    nextStep() {
      let registerReady = false
      switch (this.step) {
        // Email and Password

        case 1: var check=0;

          // Validate email
          this.signEmail = this.signEmail.replace(/ /g,'');
          if(!this.reg.test(this.signEmail)) {
            this.signEmailError = ['Не правильна електронна адреса'];
          } else {
            this.CHECK_EMAIL({type : 'email', email : this.signEmail}).then((out)=>{
              if (out.result) { check++;
                if (check === 2) {
                  this.step++
                }
              }
            }).catch(()=>{
                this.signEmailError = ['Користувач з такою адресою вже існує']; }
            )
          }

          // Validate Password
          if (this.signPassword !== this.signPassword2) {
            this.signPasswordError = ['Паролі не співпадають'];
          } else { check++; }

          // just in case ASYNC CHECK EMAIL SUPER FAST
          if (check === 2 ) { this.step++ }
          break;
        // Name,telephone, birthday
        case 2:
          if (!this.signBirth) {
            this.birthError = ["Введіть дату Вашого народження"]
            return
          }
          if ( this.phoneNumber.length !== 10) {
            this.emailError = ['Номер телефона складається з 10 цифр'];
            return
          } else {
            this.signTelephone = this.phoneCountryCode + this.phoneNumber

            this.CHECK_EMAIL({type : 'phone', phone : this.signTelephone}).then((out)=>{
              if (out.result) {
                // this.step++
                this.completeRegister()
                // this.notify('Let do it! ' + registerReady)
              }
            }).catch(()=>{
              this.emailError = ['Користувач з таким номером вже існує'];
              return
            }
            )
          }
          break;

        case 3:


          break;
      }

      if ( registerReady  ) {
    console.log(registerReady)
      }
    },
    completeRegister() {
      var data = {
        name: this.signFirstName,
        sirname: this.signLastName,
        email: this.signEmail,
        password : this.signPassword,
        repeat_password : this.signPassword,
        phone : this.signTelephone,
        birthday : this.signBirth,
        is_businessman : this.signBusiness,
      }

      if ( this.$store.state.templates.businessAlias ) {
        data.subscription = this.$store.state.templates.businessAlias
      }


      this.REGISTER(data).then((out)=>{
        if (out.result) {
          this.signUp = false;
          this.flushRegister()
          this.$store.state.auth.userLinkKey = out.data.link_key
          if (!this.noToggle) {
            this.toggleLoginMenu()
            this.$store.state.auth.showNewUserDialog = true
          }
        } else {
          console.error('Registration error')
        }
      }).catch(()=>{
        console.error('Connection error')
      })
    },
    back() {
      this.step--;
      if (this.step == 0) {
        this.signUp = false;
        this.step = 1
      }
    },
    birthSave (date) {
      this.birthError = []
      this.$refs.menu.save(date)
    },
    flushLogin() {
      this.email = ''
      this.password = ''
    },
    flushRestore() {
      this.restoreCode = '';
      this.restoreCodeError =[]
      this.restoreWindow = false
      this.restoreStep = 0;
      this.email = ''
    },
    flushRegister() {
      this.signUp = false;
      this.step = 1;
      this.signEmail = ''
      this.signPassword = ''
      this.signPassword2 = ''
      this.signFirstName = ''
      this.signLastName = ''
      this.signBirth = ''
      this.signCountry = ''
      this.signProvince = ''
      this.signCity = ''
      this.phoneNumber = ''
      this.emailError = []
    },
    resetPasswordModal() {
     // this.toggleLoginMenu();
     //  this.SHOW_MODAL_PASSWORD_RESET()
      this.flushLogin()

    }
  }
}
</script>


<style scoped>
.link {
  text-decoration: none }
.link_dark {
  color: white !important;
}

/*ANIMATION */
.v-enter {
  opacity: 0 !important;
  transform: translateX(-50px) ;
}
.v-enter-active {

  transition: all 0.2s ease-out;
}
.v-leave-active {
  transition: all 0.2s ease-out;
  opacity: 0 !important;
  transform: translateX(-50px) ;
}
.loginTypeButton:after {
  background-color: #00000000 !important;
  bottom:0;
}
</style>