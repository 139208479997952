<template>
  <div class="pa-8">

    <!-- Header statistics-->
    <v-row >
      <v-col cols="6" sm="6" xs="6" md="6" lg="3" xl="3" class="" v-for="(block,i) in headerBlocks" :key="i">
        <v-sheet height="100" dark :color="block.color" class="py-4 px-4 d-flex align-center">
          <v-icon size="32" class="mr-3">{{ block.icon }}</v-icon>
          <div>
            <div class="mb-0 caption">{{  block.title  }}</div>
            <h3 class="mt-0 font-weight-light">{{ block.content }} </h3>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
    <v-divider class="mt-5" />
    <!-- Filters -->
    <div class="d-flex justify-space-between align-center  pa-3">
      <div class="d-flex">
        <v-chip style="padding : 13px 10px; border-radius: 26px" class="mr-2 "  small outlined color="grey darken-3">
          <v-icon small class="mr-2">mdi-magnify</v-icon>
          <v-text-field class="caption ml-n3" v-model="filter.search" filled background-color="transparent"  style=" padding-bottom:2px; margin-left: 0" placeholder="Пошук" dense hide-details ></v-text-field>
        </v-chip>
        <v-menu ref="dateFilter"  :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on"
                   :dark="filter.date.length >1"
                   :outlined="filter.date.length <2"
                   :color=" filter.date.length >1 ? 'blue lighten-2' : 'grey darken-3' "
                   small rounded class="noCaps mr-2" >
              <v-icon small class="mr-2">mdi-calendar</v-icon>
              {{ filter.date.length > 1 ? `з  ${filter.date[0]} по ${filter.date[1]} ` : 'Весь період' }}
            </v-btn>
          </template>
          <v-date-picker   v-model="filter.date" range
                           no-title
                           min="2020-01-01" max="2150-01-01"
                           @change="$refs.dateFilter.save(filter.date)" />
        </v-menu>
        <v-btn @click="filter.hide = !filter.hide "
               :dark="filter.hide"
               :outlined="!filter.hide"
               :color=" filter.hide ? 'blue lighten-2' : 'grey darken-3' "
               small rounded class="noCaps" >
          <v-icon small>mdi-eye</v-icon>
          Тільки із Замовленнями
        </v-btn>
        <v-btn @click="resetFilter" small rounded text class="noCaps" ><v-icon small>mdi-close</v-icon>Скинути</v-btn>
      </div>
      <v-chip  small dark color="blue darken-3">
        <v-icon small class="mr-2">mdi-account-multiple</v-icon>
         {{ subscriberFiltered.length }}
      </v-chip>

      </div>
    <v-divider />

    <!-- Subscribers Main Table-->
    <v-data-table :items="subscriberFiltered" :headers="headers">
      <template v-slot:item.firstname="{item}">
        <h3>{{ item.firstname}} {{ item.lastname }}</h3>
        <span class="caption"> {{  item.subscribedAt  }} </span>
      </template>
      <template v-slot:item.ordersCount="{item}">
        <v-chip small color="teal lighten-2" dark>
          <v-icon x-small class="mr-1">mdi-cog</v-icon>
            <h4>{{ item.ordersCount }}</h4>
        </v-chip>
      </template>
      <template v-slot:item.contacts="{item}">
        {{ item.email }} <br>
        {{ item.phone}}
      </template>
      <template v-slot:item.totalIncome="{item}">
        <v-chip small color="green lighten-2" dark>
          <v-icon x-small class="mr-1">mdi-cash-multiple</v-icon>
            <h4> {{ item.totalIncome }} грн</h4>
        </v-chip>
      </template>
      <template v-slot:item.rentedProductsCount="{item}">
        <v-chip small color="primary lighten-2" dark>
          <v-icon x-small class="mr-1">mdi-cart</v-icon>
          <h4> {{ item.rentedProductsCount }} шт</h4>
        </v-chip>
      </template>
    </v-data-table>

  </div>






</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "BusinessDasboardSubscribers",
  data(){
    return {
      subscribers : [],
      headers : [
        { text : 'Користувач'        ,  value : 'firstname'                },
        { text : 'Контакти'          ,  value : 'contacts'            },
        { text : 'Замовлень'         ,  value : 'ordersCount'         },
        { text : 'Прибуток'          ,  value : 'totalIncome'         },
        { text : 'Залогові продукти' ,  value : 'rentedProductsCount' },
      ],
      filter : {
        date : [],
        hide : false,
        search : '',
      }
    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),

    headerBlocks() {
      return [
        {title: 'Ваші Клієнти', content: this.subscribers.length, icon: 'mdi-account-multiple', color: 'blue lighten-2'},
        {title: 'Нових за місяць', content: '+' + this.clientsMonth, icon: 'mdi-calendar-month', color: 'teal lighten-2'},
        {title: 'Нових за день', content: '+' + this.clientsDay, icon: 'mdi-calendar', color: 'green lighten-2'},
        {title: 'За період', content: this.clientsPeriod, icon: 'mdi-calendr-range', color: 'teal lighten-2'},
      ]
    },

    dateObjectStart() {
      let date = false
      if ( this.filter.date.length > 1 ) {
        date = new Date(this.filter.date[0])
        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)
      }
      return date
    },
    dateObjectEnd() {
      let date = false
      if ( this.filter.date.length > 1 ) {
        date = new Date(this.filter.date[1])
        date.setHours(23)
        date.setMinutes(59)
        date.setSeconds(59)
      }
      return date
    },
    dateObjectMonth() {
      let date = new Date();
      date.setDate(1)
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      return date
    },
    dateObjectDay() {
      let date = new Date();
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      return date
    },

    subscriberFiltered() {
      let items = JSON.parse(JSON.stringify(this.subscribers) )
      items.forEach((subscriber) => {
            subscriber.orders.forEach((order)=>{
              order.date = new Date(order.date)
            })
      })
      if ( this.filter.date.length > 1 ) {
          items.forEach((subscriber) => {
          subscriber.orders = subscriber.orders.filter(el =>
              el.date >= this.dateObjectStart &&
              el.date <= this.dateObjectEnd
          )
            subscriber.ordersCount = subscriber.orders.length
           let ordersTotal = 0
            subscriber.orders.forEach((order)=>{
              ordersTotal += order.total
            })
            subscriber.totalIncome = ordersTotal
        })
      } else {
        items = JSON.parse(JSON.stringify(this.subscribers) )
      }
      if ( this.filter.hide )  {
        items = items.filter(el=>el.ordersCount > 0)
      }
      if ( this.filter.search )  {
        items = items.filter(
            el=>el.firstname.toLowerCase().includes(this.filter.search.toLowerCase() ) ||
                el.lastname.toLowerCase().includes(this.filter.search.toLowerCase() ) ||
                el.email.toLowerCase().includes(this.filter.search.toLowerCase()
                // el.ordersCount.toString().includes(this.filter.search ) ||
                // el.ordersTotal.toString().includes(this.filter.search.toLowerCase()
                )
        )
      }

      return items
    },

    clientsMonth() {
      let clients = this.subscribers
      clients = clients.filter(el=> new Date(el.subscribedAt) >= this.dateObjectMonth )
      return clients.length
    },
    clientsDay() {
      let clients = this.subscribers
      clients = clients.filter(el=> new Date(el.subscribedAt) >= this.dateObjectDay )
      return clients.length
    },
    clientsPeriod() {
      let clients = this.subscribers
      clients = clients.filter(el=> new Date(el.subscribedAt) >= this.dateObjectStart &&
                                    new Date(el.subscribedAt) <= this.dateObjectEnd)
      return clients.length
    }
  },
  methods : {
    ...mapActions( 'business', [ 'GET_SUBSCRIBERS' ] ),

    resetFilter() {
      this.filter = {
        date : [],
        hide : false,
      }
    }
  },
  mounted() {
    this.loading = true
    this.GET_SUBSCRIBERS(this.selectedBusiness.alias).then((out)=>{
      if (out.data) {
        this.subscribers = out.data
        this.loading = false;
      }
    }).catch(()=>{
      this.loading = false
      this.notify('Network error')
    })
  }

}
</script>

<style scoped>

</style>