const state = {

    accessToken : '',
    resendToken : '',
    loggedIn : false,


    showNewUserDialog : false,

    loader : false,
    modalAuth : false,
    modalType : '',

    userType : null,
    userID : null,
    user : {
        businesses : [],
        managingBusinesses : [],
        locations  : []
    },
    userRentedProducts : null,
    userEmail: '',
    userName : '',
    userBusinesses : [],
    userWarehouses : [],
    userManagingBusinesses : [],
    userLinkKey : '',

    changePasswordSent : false,
    showChangePansword : false,
    changePasswordMailReady: false,
    changePasswordMailResult: false,

    rootPlace : ''

}
const getters = {
    isLoggedIn(state) {
        return state.loggedIn
    },
}
const mutations = {
    TOOGLE_LOADER(state) {
        state.loader = !state.loader;
    },
    FLUSH_LOADER(state) {
        state.loader = false
    },
    SET_TOKEN(state,payload) {
        state.accessToken = payload.token
    },
    SET_RESEND_TOKEN(state,payload) {
        state.resendToken = payload.token
    },
    EDIT_NAME(state,payload) {
        state.userName = payload;
    },
    TOGGLE_AUTH_MODAL(state) {
        state.modalAuth = !state.modalAuth;
    },
    SHOW_MODAL_CONFIRMAION(state) {
        state.modalType = 'AUTH_REGISTER_CONFIRM'
        state.modalAuth = !state.modalAuth;
    },
    SHOW_MODAL_CONFIRM_INFO(state) {
        state.modalType = 'AUTH_CONFIRM_INFO'
        state.modalAuth = !state.modalAuth;
    },
    SHOW_MODAL_PASSWORD_RESET(state) {
        state.modalType = 'AUTH_PASSWORD_RESET'
        state.modalAuth = !state.modalAuth;
    },
    SET_EMAIL_RESULT(state,payload) {
        state.changePasswordMailResult = payload.result;
    },
    TOGGLE_EMAIL_RESULT_READY(state) {
        state.changePasswordMailReady = !state.changePasswordMailReady
    },
    FLUSH_CHANGE_PASSWORD(state) {
        state.changePasswordSent = false;
        state.showChangePansword = false;
        state.changePasswordMailReady= false;
        state.changePasswordMailResult= false;
        state.loader = false;
    },
    LOGOUT(state, router)  {
        state.userID      = null;
        state.authToken   = null;
        state.userEmail   = null;
        state.userName    = null;
        state.loggedIn    = false;
        state.userEmail   = ''
        state.userLinkKey = ''
        state.userType    = null
        state.userID      = null
        state.userRentedProducts     = null
        state.userBusinesses         = []
        state.userWarehouses         = []
        state.userManagingBusinesses = []



   //     this.dispatch('cart/CLEAR_CART', null, {root:true})
        this.dispatch('business/RESET_BUSINESS_STATE')
        state.user = {
            businesses : [],
            managingBusinesses : [],
            locations  : []
        }
        localStorage.clear();
        router.push(`/${state.rootPlace}`)
    }
}
const actions = {

    async AJAX_SEND({rootState,}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },

    async LOGIN({commit,dispatch,rootState}, body) {
        commit('TOOGLE_LOADER');
        try {
            let $router = { router : body.router, path : body.path }

            let inp = {
                email: body.email,
                password: body.password,}
            const data = await rootState.$ajax({
                route: 'user/auth',
                method: 'POST',
                body: inp,
            });
            commit('TOOGLE_LOADER');
            if (data) {
                if (data.result) {
                    commit('SET_TOKEN',{token:data.data.token})
                    state.loggedIn = true;

                    localStorage.setItem('access_token',data.data.token)

                    dispatch('GET_USER',{
                        token : data.data.token,
                        router : $router,
                        redirect : true})
                }
                return data
            }
        } catch (e) {
            commit('TOOGLE_LOADER');
            return false
        }

    },
    async REGISTER({commit,dispatch,rootState}, body) {

        try {
            const data = await rootState.$ajax({
                route: 'user/register',
                method: 'POST',
                body: body,
            });

            if (data) {
                if (data.result) {
                    commit('SET_TOKEN',{token:data.data.token})
                    state.loggedIn = true;

                    localStorage.setItem('access_token',data.data.token)

                    dispatch('GET_USER',{
                        token : data.data.token,
                        redirect : false})
                }
                return data
            }

        } catch (e) {
            return false
        }

    },
    async CHECK_EMAIL({dispatch},body) {
        return await dispatch('AJAX_SEND',{
            token:'public' ,
            route:`user/auth/register/check`,
            method:'POST',
            data : body });
    },

    async GET_USER({dispatch,rootState},body) {
        try {
            var params = {
                route: 'user',
                method: 'POST',
                headers: {'Authorization' : 'Bearer '+body.token },
            }

            if ( rootState.templates.businessAlias ) {
                params.body = { root : rootState.templates.businessAlias }
            }

            var data = await rootState.$ajax(params);


            if (data.result) {


                dispatch('SET_USER',{ data : data.data, router: body.router});
                return true
            } else {
                // rootState.showNotification = true;
                // rootState.notificationMessage = data.error;
            }
        } catch (e) {
            return false
        }
    },
    async REQUEST_PASSWORD({commit,rootState},body) {
        commit('TOOGLE_LOADER')
        if (rootState.templates.selectedBusiness.alias) {
            body.business = rootState.templates.selectedBusiness.alias
        }
        try {
            const data = await rootState.$ajax({
                route: 'user/reset',
                method: 'POST',
                body: body
            });

            if (data.result) {
                commit('TOOGLE_LOADER');
                return data
            } else {
                commit('TOOGLE_LOADER');
                return data
            }
        } catch (e) {
            commit('TOOGLE_LOADER');
            this.$store.commit('TOOGLE_NOTIFICATION',{message:'Server Error'})
            return false
        }
    },
    async REQUEST_CHANGE_CONFIRM({commit,rootState},body) {
        commit('TOOGLE_LOADER')
        try {
            const data = await rootState.$ajax({
                route: 'auth/change/code',
                method: 'POST',
                body: body
            });
            if (data.result) {
                commit('TOOGLE_LOADER');
                return data
            } else {
                commit('TOOGLE_LOADER');
                return data
            }
        } catch (e) {
            commit('TOOGLE_LOADER');
            this.$store.commit('TOOGLE_NOTIFICATION',{message:'Server Error'})
            return false
        }
    },
    async CHANGE_PASSWORD({dispatch,commit},body) {
      let data =  await dispatch('AJAX_SEND',{
            token:'public' ,
            route:`user/reset/confirm`,
            method:'POST',
            data : body });
        if (data) {
            if (data.result) {
                commit('SET_TOKEN',{token:data.data.token})
                state.loggedIn = true;
                localStorage.setItem('access_token',data.data.token)
                dispatch('GET_USER',{
                    token : data.data.token,
                    redirect : false})
            }
        }
        return data
    },
    async RESEND_CONFIRMATION({commit,rootState},body) {
        commit('TOOGLE_LOADER')
        try {
            const data = await rootState.$ajax({
                route: 'auth/confirm/resend',
                method: 'POST',
                body: body
            });
            if (data.result) {
                commit('TOOGLE_LOADER');
                return data
            } else {
                commit('TOOGLE_LOADER');
                return data
            }
        } catch (e) {
            commit('TOOGLE_LOADER');
            this.$store.commit('TOOGLE_NOTIFICATION',{message:'Server Error'})
            return false
        }
    },
    async CONFIRM_ACCOUNT({dispatch,commit,rootState},body) {
        try {
            const data = await rootState.$ajax({
                route: 'auth/confirm',
                method: 'POST',
                body: body
            });
            if (data.result) {
                commit('SET_TOKEN',{token:data.access_token})
                state.loggedIn = true;
                localStorage.setItem('access_token',data.access_token)
                dispatch('GET_USER',data.access_token);
                return data
            } else {
                return data
            }
        } catch (e) {
            return false
        }
    },



    SET_USER({state,rootState},payload) {

    //    let $router = payload.router
        payload = payload.data

        state.loggedIn       = true;
        state.userID         = payload.id;
        state.userEmail      = payload.email;
        state.userName       = payload.lastname+' '+payload.firstname;
        state.userType       = 'new';
        state.user           = payload
        state.userBusinesses = []
        state.userWarehouses = payload.warehouses

        if ( payload.rent_product ) {
            state.userRentedProducts = payload.rent_product
        }

        if (payload.businesses) {
            payload.businesses.forEach((business)=>{
                state.userBusinesses.push(business.alias)
            })
        }
        if (payload.managing_businesses) {
            payload.managing_businesses.forEach((business)=>{
                state.userManagingBusinesses.push(business.alias)
            })
        }


        if ( payload.roles.includes('ROLE_USER') )        { state.userType = 'user' }
        if ( payload.roles.includes('BUSINESS_MANAGER') ) { state.userType = 'manager' }
        if ( payload.roles.includes('ROLE_BUSINESSMAN') ) { state.userType = 'businessman' }
        if ( payload.roles.includes('ROLE_SUPERADMIN') )  { state.userType = 'superadmin' }


        rootState.$socket.emit('subscribe',{
            id         : payload.id,
            businesses : state.userBusinesses,
            warehouses : state.userWarehouses,
            subscriptions : []
        })



        if ( rootState.business.selectedBusiness.alias ) {

            rootState.dashboard.showBusinessToOwner   =
                rootState.auth.userBusinesses.includes(rootState.business.selectedBusiness.alias)
            rootState.dashboard.showBusinessToManager =
                rootState.auth.userManagingBusinesses.includes(rootState.business.selectedBusiness.alias)

        }


    },

}
export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions
}