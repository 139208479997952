<template>
  <div class="pa-16">
    <h3 class="ma-3 text-h2">Active Domain orders</h3>

    <v-simple-table class="mt-8">
      <tbody>
        <tr v-for="(order,i) in domains" :key="i">
          <td>
            <div class="d-flex ma-4">
              <v-icon class="mr-5" large>mdi-link-variant</v-icon>
            <div>
              <h1 class="font-weight-light "> {{ order.domain }}</h1>
              <h2 class="font-weight-light"> {{ order.business_name }} {{ order.order_id }}</h2>
            </div>

            </div>

          </td>
          <td>
            <h2 class="teal--text ma-5"> {{ order.price }} грн</h2>
          </td>
          <td>
            <v-btn large   dark rounded color="blue-grey lighten-2" @click="cancelDomain(order.order_id,order.business_alias)">
              <v-icon class="mr-2">mdi-close</v-icon>
              Скасувати
            </v-btn>
          </td>
          <td>
            <v-btn large   dark rounded color="teal lighten-2" @click="activateDomain(order.order_id,order.order_token)">
              <v-icon block class="mr-2">mdi-car</v-icon>
              parkDomain()
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>


    <v-btn large   dark rounded color="red lighten-2" @click="parkDomain()">
      <v-icon class="mr-2">mdi-cart</v-icon>
      Оплатити
    </v-btn>


  </div>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "DashDomains",
  data() {
    return {
      loading : false,
      domains : []
    }
  },
  methods : {
    ...mapActions('domains', ['GET_DOMAIN_ORDERS','CANCEL_DOMAIN_ORDER','ACTIVATE_DOMAIN_ORDER','PARK_DOMAIN']),

    parkDomain(){
      this.loading = true
      this.PARK_DOMAIN().then((out)=>{
        this.notify('Domain parked successfully ! ' )
        console.log(out)
        this.loading = false
      }).catch(()=>{ this.loading = false })
    },
    activateDomain(order_id, token){
      this.loading = true
      this.ACTIVATE_DOMAIN_ORDER({order_id:order_id,order_token:token}).then((out)=>{
        this.notify('Order No. ' + order_id + ' has been activated ! ' )
        console.log(out)
        this.loading = false
      }).catch(()=>{ this.loading = false })
    },
    cancelDomain(order_id, alias){
      this.loading = true
      this.CANCEL_DOMAIN_ORDER({order_id:order_id,alias:alias}).then((out)=>{
        this.notify('Order No. ' + order_id + ' has been deleted for business : ' + alias + ' ! ' )
        console.log(out)
        this.loading = false
      }).catch(()=>{ this.loading = false })

    }

  },
  mounted() {
    this.loading = true
    this.GET_DOMAIN_ORDERS().then((out)=>{
      if(out.result) {
        this.domains = out.data
      }
      this.loading = false
    }).catch(()=>{ this.loading = false })
  }
}
</script>

<style scoped>

</style>