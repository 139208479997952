<template>
  <v-sheet class="newsEditor" ref="newsEditor">

    <v-sheet  class="header" >
      <div class="d-flex justify-space-between align-center fill-height pr-8">
        <div class="d-flex align-center fill-height">
          <v-sheet dark color="primary  " class="fill-height d-flex align-center justify-center px-4" style="margin-bottom: 1px">
            <v-btn large @click="$emit('close')" icon  ><v-icon x-large>mdi-chevron-left</v-icon></v-btn>
          </v-sheet>

          <h3 class="font-weight-light pl-6">Створити новину</h3>
        </div>
        <div class="d-flex">
          <v-btn :disabled="loading"  text color="primary"  class="mr-4">Попередный перегляд</v-btn>
          <v-btn :loading="loading" :disabled="loading" outlined class="rounded-0" color="primary" @click="publish"> Опублікувати</v-btn>
        </div>
      </div>
    </v-sheet>

    <v-sheet class="sidemenu  " color="grey lighten-4"  >
<!--      <h3 class="font-weight-light bitter pr-3 py-3 text-center">Налаштування</h3>-->
<!--      <v-divider  />-->
      <div class="">


        <div>
          <div class="benefit_header d-flex align-center justify-space-between pa-3 pl-6 pr-6"
               :class="benefits[0].visible ? 'benefit_header_active' : ''"
               @click="benefits[0].visible=!benefits[0].visible" >

            <h4 class=" font-weight-medium ">Головне Зображення</h4>
            <v-icon :dark="benefits[0].visible" icon
                    :class="benefits[0].visible ? 'benefit_button_active' : ''"
            >mdi-plus</v-icon>
          </div>
          <v-expand-transition mode="out-in">
            <div v-show="benefits[0].visible" >
              <div style="font-family: sans-serif; font-size:14px" class="font-weight-light pa-3 mt-3 pl-6 pr-6">




                <v-fade-transition mode="out-in">
                  <imago   :src="$store.state.filesPublic+`news/${addData.uuid}`" width="auto" height="190px" v-if="!$store.state.refresh && !showUploadImage" >
                    <div style="background-color: #459ad2cc;position:absolute; bottom: 0; width: 100%; color:white; font-family:sans-serif"
                         class=" caption font-weight-medium"
                    ><v-btn dark small text block @click="showUploadImage = true" class="py-5">Змінити</v-btn></div>
                  </imago>
                  <div v-if="showUploadImage">
                    <dropzone   @vdropzone-complete="afterUpload"
                              @vdropzone-sending="sendingPhoto"
                              key="change_picture" class="mb-3 "
                              ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" />
                    <v-btn text small color="primary" block @click="showUploadImage = false">Cкасувати</v-btn>
                  </div>

                </v-fade-transition>
              </div>
            </div>
          </v-expand-transition>
        </div>
        <div>
          <div class="benefit_header d-flex align-center justify-space-between pa-3 mt-3 pl-6 pr-6"
               :class="benefits[2].visible ? 'benefit_header_active' : ''"
               @click="benefits[2].visible=!benefits[2].visible" >
            <h4 class=" font-weight-medium ">Категорії та авторство</h4>
            <v-icon :dark="benefits[2].visible" icon
                    :class="benefits[2].visible ? 'benefit_button_active' : ''"
            >mdi-plus</v-icon>
          </div>
          <v-expand-transition mode="out-in">
            <div v-show="benefits[2].visible" >
              <div style="font-family: sans-serif; font-size:14px" class="font-weight-light pa-3 mt-3 pl-6 pr-6">
                <div class="d-flex justify-space-between align-center">
                  <span>Категорія:</span>
                  sdf
                </div>

                <div class="d-flex justify-space-between align-center mt-2">
                  <span>Підрозділ:</span>
                  ddv
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <span>Автор:</span>
                  ddv
                </div>

              </div>
            </div>
          </v-expand-transition>
        </div>
        <div>
          <div class="benefit_header d-flex align-center justify-space-between pa-3 mt-3 pl-6 pr-6"
               :class="benefits[1].visible ? 'benefit_header_active' : ''"
               @click="benefits[1].visible=!benefits[1].visible" >
            <h4 class=" font-weight-medium ">Статус та Видимість</h4>
            <v-icon :dark="benefits[1].visible" icon
                    :class="benefits[1].visible ? 'benefit_button_active' : ''"
            >mdi-plus</v-icon>
          </div>
          <v-expand-transition mode="out-in">
            <div v-show="benefits[1].visible" >
              <div style="font-family: sans-serif; font-size:14px" class="font-weight-light pa-3 mt-3 pl-6 pr-6">
                <div class="d-flex justify-space-between align-center">
                  <span>Статус:</span>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="finet"> <b>{{ addData.status ?  addData.status : 'Чернетка' }}</b> </div>
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item
                            v-for="(item, index) in [{text:'Опублікувати',value:'published'},{text:'Чернетка',value:'draft'}]" :key="index" >
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>

                <div class="d-flex justify-space-between align-center mt-2">
                  <span>Дата:</span>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="finet"> <b>2021-04-21</b> </div>
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item
                            v-for="(item, index) in [{text:'Опублікувати',value:'published'},{text:'Чернетка',value:'draft'}]" :key="index" >
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>
                <div class="mt-8">
                  Постійне посилання:
                </div>
                <v-text-field v-model="addData.alias" outlined full-width hide-details class=" mt-2 " label="alias"></v-text-field>

                <div class="d-flex  mt-3 ">
                  <v-btn text small color="red lighten-2"  class="mt-5 ml-n3">Видалити</v-btn>
                </div>

              </div>
            </div>
          </v-expand-transition>
        </div>
        <div>
          <div class="benefit_header d-flex align-center justify-space-between pa-3 mt-3 pl-6 pr-6"
               :class="benefits[3].visible ? 'benefit_header_active' : ''"
               @click="benefits[3].visible=!benefits[3].visible" >
            <h4 class=" font-weight-medium ">Оповіщення</h4>
            <v-icon :dark="benefits[3].visible" icon
                    :class="benefits[3].visible ? 'benefit_button_active' : ''"
            >mdi-plus</v-icon>
          </div>
          <v-expand-transition mode="out-in">
            <div v-show="benefits[3].visible" >
              <div style="font-family: sans-serif; font-size:14px" class="font-weight-light pa-3 mt-3 pl-6 pr-6">
                <div class="d-flex justify-space-between align-center">
                  <span>Telegram:</span>
                  sdf
                </div>

                <div class="d-flex justify-space-between align-center mt-2">
                  <span>Email:</span>
                  ddv
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <span>Автор:</span>
                  ddv
                </div>

              </div>
            </div>
          </v-expand-transition>
        </div>
        <br><br>



      </div>
    </v-sheet>

    <v-sheet class="content px-8"  ref="unfEl" >
      <VueEditor @text-change="$refs.titleQuill.quill.removeFormat(0,999999999)"
                 @focus="focusTitle" @blur="unfocusTitle"  ref="titleQuill"
                 :class="titleEdit ? 'editTitle' : '' "
                 v-model="addData.title" class="mt-8 mb-4 titleStyle"
                 :editorOptions="{ theme: 'bubble', height :'10px'}" />
      <VueEditor  v-model="addData.content" class="mt-3 mb-16 pa-1 "  @blur="unfocusContent" ref="contentQuill"
                  style=" color:#393939; "
                  :editorOptions="{ theme: 'bubble',bounds : $refs['newsEditor'] }" />
      <br>
    </v-sheet>

  </v-sheet>
</template>

<script>
import {VueEditor} from "vue2-editor";
import vue2Dropzone from "vue2-dropzone";
import {mapActions, mapState} from "vuex";
import imago from "@/components/UI/imago";

export default {
  name: "newsEditor",
  props : ['type','input'],
  components : {
    VueEditor,
    dropzone : vue2Dropzone,
    imago
  },
  data() {
    return {
      loading : false,
      focus : false,
      addData: {},
      titleEdit : false,
      contentEdit : false,
      showUploadImage : false,
      dropzoneOptions: {
        url: this.$store.state.ajaxRoute+'upload/public',
        method: 'POST',
        maxFilesize: 1.5,
        headers: { "Auth": this.$store.state.auth.accessToken },
        addRemoveLinks : true,
        uploadMultiple : false,
        maxFiles : 1
      },
      benefits : [
        {visible:true, title: 'Головне зображення',
          content: 'Миколахвськиий національний університет В.О. Сухомлинського за результатми міністерського рейтиингу ТОП-200 університетів України займає 1 місце серед усіх університетів Миколаївської області'},
        {visible:true,title: 'Основні налаштування',
          content: 'Миколахвськиий національний університет В.О. Сухомлинського за результатми міністерського рейтиингу ТОП-200 університетів України займає 1 місце серед усіх університетів Миколаївської області'},
        {visible:false,title: 'Оповіщення',
          content: 'Миколахвськиий національний університет В.О. Сухомлинського за результатми міністерського рейтиингу ТОП-200 університетів України займає 1 місце серед усіх університетів Миколаївської області'},
        {visible:false,title: 'Категорії',
          content: 'Миколахвськиий національний університет В.О. Сухомлинського за результатми міністерського рейтиингу ТОП-200 університетів України займає 1 місце серед усіх університетів Миколаївської області'},


      ],
    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness'])
  },
  methods : {
    ...mapActions('business', [ 'GET_NEWS','EDIT_NEWS']),


    publish(){
      this.addData.status='published';
      this.loading = true;
      this.EDIT_NEWS(this.addData)
          .then((out) => {
            this.loading = false;
            this.addData = out.data;
            this.$emit('refresh', out, this.type)
          })
          .catch(() => {
            this.loading = false;
          })
    },

    //dropdown upload funtions
    afterUpload() {
      this.$store.commit('REFRESH');
      this.showUploadImage = false;
    },
    sendingPhoto(file, xhr, formData) {
      formData.append('Folder','news');
      formData.append('Name',this.addData.uuid);
    },

    //technical
    focusTitle() {
      this.titleEdit=true
      if(this.addData.title === '<p>Назва Новини</p>') { this.$refs.titleQuill.quill.root.innerHTML = null; this.$set(this.addData,'title', '') }
    },
    unfocusTitle(){
      if(this.addData.title === '') { this.$set(this.addData,'title', 'Назва Новини') }
      this.titleEdit=false
    },
    unfocusContent(){
      if(this.addData.content === '') { this.$set(this.addData,'content', 'Текст новини ...') }
    }

  },
  mounted() {
    this.$store.commit('REFRESH');
    this.$set(this.addData,'title', 'Назва Новини')
    this.$set(this.addData,'content', 'Текст новини ...')
    this.$refs.titleQuill.quill.keyboard.bindings[13].unshift({
      key: 13,
      handler: () => { this.$refs.titleQuill.quill.blur() } });

    // this.$refs.titleQuill.quill.keyboard.addBinding({
    //   key: 13,
    // }, function() {
    //  alert('YOU MADE IT')
    // });

    if(this.type === 'new' && !this.addData.uuid) {
      this.loading = true;
      alert('A NET YA TUT')
      this.EDIT_NEWS(this.addData)
          .then((out) => {
            this.loading = false;
            this.addData = out.data;
            this.$emit('refresh', out, this.type)
          })
          .catch(() => {
            this.loading = false;
          })
    }
    if( this.type === 'edit') {
      this.loading = true;
      alert('YA TUT')
      this.GET_NEWS({business:this.selectedBusiness.alias, news:this.input}).then((out)=>{
        this.addData =  out.data;
        this.$store.commit('REFRESH');
        this.loading = false;
      }).catch(()=>{ this.loading = false;})
    }




  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/quill.bubble.css';
  .newsEditor {
    position : fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
  }
  .header {
    position:fixed;
    width: 100%;
    height: 60px;
    z-index:10;
    border-bottom: 1px solid #dddddd;
  }
  .sidemenu {
    position:fixed;
    top:60px;
    bottom: 0;
    right: 0;
    width: 300px;
    overflow-y:auto;
    border-left: 1px solid #dddddd;
  }
  .content {
    position:relative;
    top:60px;
    bottom: 0;
    left: 0;
    margin-right: 300px;
    overflow-y:auto;
  }
  .titleStyle {
    color:#393939;
    font-weight: 600;
    font-family: 'Bitter',sans-serif !important;
    transition: all 0.4s ease-in-out;
  }
  .editTitle {
    background-color: #efeff8;

  }
  .ql-editor {
    min-height: 50px !important;
  }

.active_benefit .benefit_header {
  background-color: #459ad2 !important;
  color:white !important;
  border : 1px solid #459ad2;
}
.benefit_header {
  color:rgba(0, 0, 0, 0.7) !important;
  border :  1px solid #dddddd;
  transition: all .2s ease;
}
.benefit_header_active {
  background-color: #459ad2;
  color:white !important;
  border :  1px solid #459ad2;
}
.benefit_button_active  {
  transform: rotate(45deg);
}
.benefit_header:hover{
  background-color: #459ad2;
  color:white !important;
  border :  1px solid #459ad2;
  cursor: pointer;
}
.benefit_header:hover i{
  color:white !important;
}


</style>