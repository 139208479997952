const state = {
    data : {}
}
const mutations = {
    SET_DATA(state,payload) {
        state.data = payload
    }
}
const actions = {
    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route: body.route,
                method: body.method,
                headers: {'Auth': body.token},
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return false
        }
    },

    // QUESTION BANKS

    async GET_QUESTION_BANKS({dispatch,rootState}) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: 'tests/banks', method: 'GET'})
    },
    async GET_QUESTION_BANK({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`tests/bank/${body}`, method:'GET'});
    },
    async ADD_QUESTION_BANK({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:'tests/bank', method:'PUT', data: body});
    },
    async ADD_QUESTION({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`tests/bank/question`, method:'PUT',data: body});
    },
    async SAVE_QUESTION({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`tests/bank/question/edit`, method:'PUT',data: body});
    },
    async DELETE_QUESTION({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`tests/bank/question/${body}`, method:'DELETE'});
    },



    // TESTS
    async GET_TESTS({dispatch,rootState}) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: 'tests', method: 'GET'})
    },
    async SAVE_TEST({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: 'test', method: 'PUT',data : body})
    },
    async GET_TEST({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `test/${body}`, method: 'GET'})
    },
    async PREVIEW_TEST({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `test/preview`, method: 'POST', data: body})
    },
    async DELETE_TESTS({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `tests`, method: 'DELETE', data: body})
    },

    // student

    async GET_STUDENT_TEST({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `test/${body}/student`, method: 'GET'})
    },
    async START_TEST({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `test/${body.testId}/start`, method: 'POST',data:body.review})
    },
    async SET_ANSWER({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `test/${body.test}/answer`, method: 'POST',data: body.question})
    },
    async SUBMIT({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `test/${body.testId}/submit`, method: 'POST',data:body.data})
    },

    //technical

    async GET_TESTS_SELECT({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: 'tests/selectData', method: 'POST',data:body})
    },



}

export default {
    namespaced:true,
    state,
    mutations,
    actions
}