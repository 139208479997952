<template>
  <div>
    <div  class="pa-4">
      <v-row >
        <v-col v-for="(block,i) in headerBlocks" :key="i"
               cols="6" sm="6" xs="6" md="6" lg="4" xl="4" class="" >
          <v-sheet height="100" dark :color="block.color" class="py-4 px-4 d-flex align-center wsRoundedHalf">
            <v-icon size="32" class="mr-3">{{ block.icon }}</v-icon>
            <div>
              <div class="mb-0 caption">{{  block.title  }}</div>
              <h3 class="mt-0 font-weight-light">{{ block.content }} </h3>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
      <div class="d-flex justify-space-between mt-6">
        <h2 class="font-weight-light mb-3">Ваші замволення:</h2>
<!--        <v-btn small outlined class="noCaps">Архів</v-btn>-->
      </div>

       <v-simple-table v-if="displayShow(['lg','xl','md']) "
                       class="mt-2 mb-16" dense>
         <thead>
         <tr>
           <th>Замовлення</th>
           <th>Всього</th>
           <th>Доставка</th>
           <th>Оплата</th>
           <th>Статус</th>
         </tr>
         </thead>
         <tbody >
         <tr  v-for="(order,i) in activeOrders" :key="i">
           <td class="caption" >
             <b>№ {{ order.order_id }}</b> <br>
             {{ order.date }}
           </td>
           <td ><h5>{{ getTotalPrice(order) }} грн</h5> </td>
           <td class="caption" >
             <v-chip outlined  small color="grey darken-2">
               <h5> {{ getDeliveryStatusText(order.delivery_status) }}</h5>
             </v-chip>
             </td>
           <td>
              <v-chip v-if="order.payed" class="mr-1 mt-1" small  dark color="green lighten-2">
                <v-icon small class="mr-1">mdi-cash-multiple</v-icon>
                <b>Оплачено</b>
              </v-chip>
              <v-chip  v-if="!order.payed"
                       @click="payForOrder(order)"
                       style="cursor: pointer"
                       v-ripple
                       class="mr-1 mt-1" small  dark color="primary lighten-2">
                <v-icon small class="mr-1">mdi-cash-multiple</v-icon>
                <b>Оплатити</b>
              </v-chip>
            </td>
           <td>
             <v-chip  dark small color="green lighten-2">
               <h5> {{ getStatusText(order.status) }}</h5>
             </v-chip>
           </td>
           <td>
             <v-btn color="grey darken-2" class="noCaps" text  @click="openDetails(order)" > <v-icon small>mdi-eye</v-icon>Деталі</v-btn>
           </td>
         </tr>
         </tbody>
       </v-simple-table>
    <!--  MOBILE VIEW-->
      <div v-else  >
          <v-sheet rounded outlined class="px-6 py-3 mb-5"  v-for="(order,i) in activeOrders" :key="i">
              <div class="pa-1">

                <div class="d-flex justify-space-between align-center">
                  <h3>№ {{ order.order_id }} ({{ getTotalPrice(order) }} грн)</h3>
                  <h3 class="font-weight-light">{{
                      new Date(order.date).getHours() ? formatTime(order.date): ''  }}
                  </h3>
                </div>
                <v-divider class="my-2 mb-4" />

                <h4>Статус: <span class="font-weight-light">{{ getStatusText(order.status)}}</span> </h4>
                <h4 class="mt-2">Доставка: <span class="font-weight-light">{{ getDeliveryStatusText(order.delivery_status) }}</span> </h4>
                <h4 class="mt-2 mb-6">Оплата:
                  <span v-if="order.payed" class="font-weight-light">Оплачено</span>
                  <v-chip v-else
                          @click="payForOrder(order)"
                          color="primary lighten-2"
                          elevation="0"
                          style="cursor: pointer"
                          v-ripple small >
                    <v-icon small class="mr-1">mdi-cash-multiple</v-icon>
                    <b>Оплатити</b>
                  </v-chip>
                </h4>

                <v-btn small color="primary darken-2 mt-8" class="noCaps" outlined rounded  @click="openDetails(order)" >
                  <v-icon small class="mr-2">mdi-eye</v-icon>
                  Деталі
                </v-btn>


              </div>
          </v-sheet>

      </div>
    </div>

    <orderDetails          v-if    =  "displayOrderDetails"
                           v-model =  "displayOrderDetails"
                           :order  =  "orderDetailsInput"  />

  </div>
</template>

<script>
import {mapActions} from "vuex";
import orderDetails from "@/components/pages/businessDashboard/businessOrders/orderDetails";

export default {
  name: "dashBusinessIndex",
  props: ['alias'],
  components : {
    orderDetails,
  },
  data() {
    return {
      loading : false,
      business : {},
      months : {
        ukr : {
          1 : 'Січня',
          2 : 'Лютого',
          3 : 'Березня',
          4 : 'Квітня',
          5 : 'Травня',
          6 : 'Червня',
          7 : 'Липня',
          8 : 'Серпня',
          9 : 'Вересня',
          10 : 'Жовтня',
          11 : 'Листопада',
          12 : 'Грудня',
        }
      },
      activeOrders : [],
      displayOrderDetails: false,
      orderDetailsInput :  {},
    }
  },
  computed : {
    date() {
      let date = new Date();

      return date.getDate() + ' ' + this.months.ukr[date.getMonth()+1]
    },
    headerBlocks() {
      return [
        {title: 'Сьогодні', content: this.date, icon: 'mdi-calendar', color: 'blue-grey '},
        {title: 'Активні Замовлення', content: this.activeOrders.length, icon: 'mdi-account-multiple', color: 'teal'},
        // {title: 'Всього Замовлень', content: 3, icon: 'mdi-cog', color: 'teal lighten-2'},
         ]
    }
  },
  methods : {
    ...mapActions( 'dashboard', [ 'GET_DASHBOARD_USER' ] ),
    ...mapActions  ('liqpay', [ 'GET_BUSINESS_BUTTON' ]),
    openDetails(order) {
      this.displayOrderDetails = true
      this.orderDetailsInput = order
    },
    payForOrder(order) {
      this.GET_BUSINESS_BUTTON({
        business:this.$store.state.business.selectedBusiness.alias,
        data : {
          order_number: order.order_id,
          total       : this.getTotalPrice(order),
          return_url  : location.href
        },
      }).then((button)=>
      {
        this.LIQPAY_checkout(button.data)
      }).catch((error)=>{ console.log(error) })
    },

    getPayedText(payed) {  return payed ? 'Оплачено' : 'Не оплачено'  },
    orderTotalProducts(order) {
      let quantity = 0
      order.products.forEach((item)=>{
        quantity += item.quantity
      })
      return quantity
    },
    getTotalPrice(order) {
      let price = 0;
      order.products.forEach((item)=>{
        price += item.quantity * item.price
      })
      return price
    },
    getStatusText(status) {
      switch ( status ) {
        case -1 : return 'Чекає на підтвердження';
        case  0 : return 'Підтверджено';
        case  1 : return 'Виконано';
        case  2 : return 'Скасовано';
      }

    },
    getDeliveryStatusText(status) {
      switch (status) {
        case 'sent'      :  return 'Відправлено'
        case 'new'       :  return 'Готується';
        case 'recieved'  :  return 'Доставлено';
        default          :  return 'Готується';
      }
    },
  },
  mounted() {
    let data = {
      type : '',
      value: ''
    }
    if ( location.hostname === this.$store.state.hostname ) {
      data.type  = 'alias'
      data.value = this.$route.params.alias
    } else {
      data.type  = 'domain'
      data.value = location.hostname
    }
    this.loading = true;
    this.GET_DASHBOARD_USER(data).then((out)=>{
      if ( out.result ) {
        this.data = out.data
        // this.$store.state.business.selectedBusiness = out.data.business
        this.activeOrders = out.data.orders
      }
      this.loading = false
    }).catch(()=>{this.loading = false})
  }
}
</script>

<style scoped>

</style>