<template>
  <div class="pa-4">
    <v-sheet class=" d-flex justify-space-between align center">
      <h3>Новий звіт</h3>
      <ft-select @input="addElement" :items="elementsList">
        <v-btn text class="noCaps">
          <v-icon >mdi-plus</v-icon>
          Додати елемент</v-btn>
      </ft-select>
    </v-sheet>
    <v-divider class="" />
    <!-- Filters -->
    <div class="d-flex justify-space-between align-center  pa-3">
      <div class="d-flex">
        <v-menu ref="dateFilter"  :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on"
                   :dark="filter.date.length >1"
                   :outlined="filter.date.length <2"
                   :color=" filter.date.length >1 ? 'blue lighten-2' : 'grey darken-3' "
                   small rounded class="noCaps mr-2" >
              <v-icon small class="mr-2">mdi-calendar</v-icon>
              {{ filter.date.length > 1 ? `з  ${filter.date[0]} по ${filter.date[1]} ` : 'Весь період' }}
            </v-btn>
          </template>
          <v-date-picker   v-model="$store.state.statistics.filter.date" range
                           no-title
                           min="2020-01-01" max="2150-01-01"
                           @change="$refs.dateFilter.save(filter.date)" />
        </v-menu>
        <v-btn @click="setPeriod('month')"
            :dark="filter.warehouse !== ''"
            :outlined="!filter.warehouse"
            :color="filter.warehouse ? 'primary lighten-2' : 'grey darken-3' "
            small rounded class="noCaps mr-2" >
          <v-icon small>mdi-cash</v-icon>
          Місяць
        </v-btn>
        <v-btn @click="setPeriod('day')"
            :dark="filter.warehouse !== ''"
            :outlined="!filter.warehouse"
            :color="filter.warehouse ? 'primary lighten-2' : 'grey darken-3' "
            small rounded class="noCaps mr-2" >
          <v-icon small>mdi-cash</v-icon>
          День
        </v-btn>
        <v-btn @click="resetFilter" small rounded text class="noCaps" ><v-icon small>mdi-close</v-icon>Скинути</v-btn>
      </div>
    </div>
    <v-divider />


    <v-sheet>
      <dashboard  :id="'dashExample'">
        <dash-layout v-for="(layout,i) in $store.state.statistics.statisticsDefault" v-bind="layout" :key="layout.breakpoint">
          <dash-item  v-for="(item,j) in layout.items" v-bind.sync="item" :key="j"
                     :minWidth="item.minWidth" @resizing="item.resizing = true" @resizeEnd="test($event,item)">
            <v-sheet :color="item.resizing ? 'grey darken-1' : ''"
                     class="pa-3 fill-height "
                     style="transition: all 0.3s ease"
                     outlined rounded >
              <div v-show="item.item && !item.resizing">
                <component  v-model="item.item.filter"
                            @delete="deleteBlock(i,j)"
                            :height="item.heightPx"
                            :width="item.widthPx"
                            :resizing="item.resizing"
                            :is="item.item.block"
                            :key="item.item.block + '_' +j"
                            :externalFilter="filter"/>
              </div>

            </v-sheet>
          </dash-item>
        </dash-layout>
      </dashboard>
    </v-sheet>

  </div>
</template>

<script>
import {mapActions,mapState} from "vuex";
import ftSelect from "@/components/UI/ftSelect";
import statisticsProducts from "@/components/pages/businessDashboard/businessStatistics/blocks/statisticsProducts";
import statisticsClients from "@/components/pages/businessDashboard/businessStatistics/blocks/statisticsClientsStats";
import statisticsOrders from "@/components/pages/businessDashboard/businessStatistics/blocks/statisticsOrders";
import statisticsWarehouses from "@/components/pages/businessDashboard/businessStatistics/blocks/statisticsWarehouses";
import statisticsOrdersPie from "@/components/pages/businessDashboard/businessStatistics/blocks/statisticsOrdersPie";
import statisticsClientsFull
  from "@/components/pages/businessDashboard/businessStatistics/blocks/statisticsClientsFull";
import statisticsClientsStats
  from "@/components/pages/businessDashboard/businessStatistics/blocks/statisticsClientsStats";

import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash";
export default {
  name: "BusinessDashboardStatistics",
  components : {
    ftSelect,
    statisticsProducts,
    statisticsClients,
    statisticsOrders,
    statisticsWarehouses,
    statisticsOrdersPie,
    statisticsClientsFull,
    statisticsClientsStats,
    Dashboard,
    DashLayout,
    DashItem
  },
  data() {
    return {

      elementsList : [
        { text: 'Замовлення', value:'statisticsOrders'},
        { text: 'Замовлення Діаграма', value:'statisticsOrdersPie'},
        { text: 'Продажі товарів', value:'statisticsProducts'},
        { text: 'Склад', value:'statisticsWarehouses'},
        { text: 'Клієнти Статистика', value:'statisticsClientsStats'},
        { text: 'Клієнти', value:'statisticsClientsFull'},
      ],
      elementsObject : {
        statisticsClientsStats : {
              id: null, x: 0, y: 500,
              width: 8, height: 3 ,
              minWidth : 4,
              minHeight : 2,
              heightPx : 300,
              resizing : false,
              item : { block : 'statisticsClientsStats'}
            },
        statisticsClientsFull : {
              id: null, x: 0, y: 500,
              width: 8, height: 5 ,
              minWidth : 4,
              minHeight : 2,
              heightPx : 300,
              resizing : false,
              item : { block : 'statisticsClientsFull'}
            },
        statisticsOrders :  {
              id: null, x: 0, y:500,
              width: 8, height: 3 ,
              minWidth : 4,
              minHeight : 2,
              heightPx : 300,
              resizing : false,
              item : { block : 'statisticsOrders'}
            },
        statisticsProducts : {
              id: null, x: 0, y: 500,
              width: 8, height: 5 ,
              minWidth : 4,
              minHeight : 2,
              heightPx : 300,
              resizing : false,
              item : { block : 'statisticsProducts'}
            },
        statisticsWarehouses :  {
              id: null, x: 0, y: 500,
              width: 8, height: 5 ,
              minWidth : 4,
              minHeight : 2,
              heightPx : 300,
              resizing : false,
              item : { block : 'statisticsWarehouses'}
            },
        statisticsOrdersPie : {
              id: null, x: 0, y: 500,
              width: 4, height: 4 ,
              minWidth : 4,
              minHeight : 2,
              heightPx : 300,
              resizing : false,
              item : { block : 'statisticsOrdersPie'}
            }
      },

      products : [],
      data : [],
      search : '',
    }
  },
  computed : {
  ...mapState( 'statistics', [ 'filter' ] ),
    y() {
      let y = 0;
      if ( this.$store.state.statistics.statisticsDefault[0].items.length > 0) {
        let el = this.$store.state.statistics.statisticsDefault[0].items.reduce(function(prev, curr) {
          return (prev.y + prev.height) > (curr.y + curr.height)  ? prev : curr;
        });
        y = el.y + el.height
      }
    return y
    }
  },
  watch: {

    filter :  {
      handler(val) {
        if(val.date.length > 1 ) {
          this.getData()
        }
      },
      deep : true

    }
  },
  methods : {
    ...mapActions( 'statistics', [ 'GET_ORDERS_STATISTICS' ] ),

    addElement(element) {
      let item = Object.assign({},this.elementsObject[element])
      // item.id = this.$store.state.statistics.statisticsDefault[0].items.length+1;
      if ( this.$store.state.statistics.statisticsDefault[0].items.length > 0) {
        let el = this.$store.state.statistics.statisticsDefault[0].items.reduce(function(prev, curr) {
          return (prev.id ) > (curr.id)  ? prev : curr;
        });
        item.id=el.id + 1;
      } else {
        item.id=1
      }


      item.y = this.y
      this.notify(item.id)
      this.$store.state.statistics.statisticsDefault[0].items.push(item)
    },
    deleteBlock(i,j) {
      this.$store.state.statistics.statisticsDefault[i].items.splice(j,1)
      this.notify('Елемент видалено')
    },

    test(e,item) {
      item.heightPx = e.heightPx
      item.widthPx = e.widthPx
      item.resizing = false
    },

    getData(){
      this.loading = true
      this.notify('I START')

      // this.GET_ORDERS_STATISTICS({filter : this.filter }).then((out)=>{
      //   if (out.data) {
      //     this.products = out.data.products
      //     this.data = out.data.data
      //     this.loading = false;
      //   }
      // }).catch(()=>{
      //   this.loading = false
      //   this.notify('Network error')
      // })
    },

    //technical
    setPeriod(period) {
      this.filter.date = [];
      this.filter.period = period
    },
    resetFilter() {
      this.filter =  {
        date : []
      }
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>

</style>