export default function makeAjax( baseURL ) {

    return function ajax({route, method, headers = '', body = ''}) {
        const params = {
            method: method,
            headers: {'Content-Type': 'application/json',
                ...headers},
        };

        if (body) {
            if ( body.fileFormData ) {


                let data = new FormData()
                data.append('file', body.fileFormData)

                delete params.headers['Content-Type']
                // params.headers['Content-Type'] = null
                params.body = data

            } else {

                body = JSON.stringify(body);
                body = body.replace(/’/gi, '\'');
                body = unescape(encodeURIComponent(body));
                params['body'] = JSON.stringify(window.btoa( body )); }

            }




        return fetch(baseURL + route,params ).then((resp) => resp.json()).catch()
    }

}