<template>
  <v-dialog v-model="$store.state.templates.openEditorGeneral" >
    <v-sheet dark color="#34343499"
             class="sideMenu py-8 px-6"
             :style="`width: ${editorWidth}px`"
             light style="transition:all 0.5s ease">

      <div class="d-flex  justify-space-between align-center  mt-5">
        <h4 class="font-weight-light">Налаштування сайту: </h4>
        <v-btn dark icon plain color="black" @click="$store.state.templates.openEditorGeneral = false">
          <v-icon small dark color="white">mdi-window-close</v-icon>
        </v-btn>
      </div>

      <v-divider class="mb-3" />


      <v-btn class="d-flex justify-space-between noCaps" block text
             @click="setView('mainMenu'); addVar('mainMenu')"  small >
        <h3 class="" >Головне Меню</h3>
        <v-icon>mdi-chevron-{{ currentView.mainMenu ? 'up' : 'down' }}</v-icon>
      </v-btn>
      <v-expand-transition>
        <div v-if="currentView.mainMenu" class="pt-3">
          <div v-if="$store.state.templates.templateVars_current['mainMenu'].buffer">

            <ftEditorGeneralMainMenu v-if="currentView.mainMenu"
                                     v-model="$store.state.templates.templateVars_current['mainMenu'].buffer"/>

          </div>
        </div>
      </v-expand-transition>

      <v-btn class="d-flex justify-space-between noCaps" block text
             @click="setView('logo')"  small >
        <h3 class="" >Ідентифікація Сайту</h3>
        <v-icon>mdi-chevron-{{ currentView.mainMenu ? 'up' : 'down' }}</v-icon>
      </v-btn>

      <v-expand-transition>
        <div v-if="currentView.logo" class="py-6">
          <v-sheet rounded outlined  class="pt-3 pl-6 px-3">

            <div class="d-flex justify-space-between align-center">
              <h5>Логотип: </h5>
              <v-btn @click="showLogoUpload = true" small outlined class="noCaps">Змінити</v-btn>
            </div>
            <div class="d-flex justify-space-between align-center mt-3">
              <h5>Меню авторизації (колір): </h5>
              <ftColorPicker @input="addVar('loginSideBarColor')"
                             v-model="$store.state.templates.templateVars_current['loginSideBarColor'].buffer"
                             @colapse="accentColorPicker = false"  />
            </div>
            <div class="d-flex justify-space-between align-center mt-3">
              <h5>Header: </h5>
              <v-select v-model="$store.state.templates.templateVars_current['header_template'].buffer"
                        @change="addVar('header_template')"
                        :items="['avalonHeader_var_1','avalonHeader_var_2']"
                        dense hide-details class="caption" style="width: 200px"></v-select>
            </div>



          </v-sheet>
        </div>

      </v-expand-transition>

      <!-- Navigation-->
      <div class="mb-6 pt-1  d-flex justify-end">
        <v-btn :loading="loading" :disabled="loading"  @click="cancelEdit"
               small class="mt-3 noCaps" text  elevation="0"  dark>
          <h3 class="font-weight-light">Скасувати</h3>
        </v-btn>
        <v-btn :disabled="loading"  @click="saveChanges"
               small class="mt-3  noCaps"  outlined   dark>
          <h3 class="font-weight-light">Зберегти</h3>
        </v-btn>
      </div>
      <v-dialog width="600" v-model="showLogoUpload">
        <editImage  @input="addVar('logo')" v-model="$store.state.templates.templateVars_current['logo'].buffer" />
      </v-dialog>


    </v-sheet>

  </v-dialog>
</template>

<script>
import ftEditorGeneralMainMenu from "@/components/templates/editor/mainMenu/ftEditorGeneralMainMenu";
import {mapActions} from "vuex";
import ftColorPicker from "@/components/templates/editor/UI/ftColorPicker";
import editImage from "@/components/pages/businessDashboard/UI/editImage";
export default {
  name: "ftEditorGeneralNew",
  components : {
    ftEditorGeneralMainMenu,
    editImage,
    ftColorPicker
  },
  data() {
    return {
      loading : false,

      varsToSave : [],

      selectedElement : '',
      currentView : {
        mainMenu : false,
        logo : false,
      },
      showLogoUpload : false,
      accentColorPicker : false,
    }
  },
  computed : {
    editorWidth() {
      let width = 400
      if ( this.currentView.mainMenu ) { width= 800 }
      return width
    }
  },
  methods : {
    ...mapActions('templates',  [ 'SAVE_DRAFT_ITEM' ]),
    cancelEdit(){
      this.$store.state.templates.openEditorGeneral = false
    },
    multiSave() {
      this.notify('saving')
      this.varsToSave.forEach((item)=>{
        this.multiSave(item)
        this.notify(item)
      })
    },
    saveChanges() {

      this.varsToSave.forEach((item)=>{
      let data = [
        {
          item  : this.$store.state.templates.templateVars_current[item].item,
          type  : this.$store.state.templates.templateVars_current[item].type,
          value : this.$store.state.templates.templateVars_current[item].buffer
        }
      ]

      this.loading = true
      this.SAVE_DRAFT_ITEM(
          {
            alias: this.$store.state.templates.businessAlias,
            data : {items : data}
          }  )
          .then(()=>{
            if ( this.$store.state.templates.templateVars_current[item] ) {
              this.$store.state.templates.templateVars_current[item].value = data[0].value
            }
            // switch(this.selectedElement) {
            //   case 'mainMenu' :
            //   this.$store.state.templates.templateVars_current[this.selectedElement].value = data[0].value ;break;
            // }
            this.notify('Чернетку збережено')
            this.loading = false
            this.$forceUpdate();
          }).catch(()=>{
        this.notify('Помилка мережі')
            this.loading = false })

      })


    },

    //technical
    setView(element) {
      this.selectedElement = element
      this.currentView[element] = !this.currentView[element]
    },
    addVar(variable) {
      if ( !this.varsToSave.includes(variable) ) { this.varsToSave.push(variable) }
    }
  }
}
</script>

<style scoped>
.sideMenu {
  position:fixed;
  top:0;
  right:0;
  height: 100%;
  z-index: 999999000;
  overflow-y: auto;
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  transition: all .6s ease;
}
</style>