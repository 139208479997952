export default {
    methods : {
        notify(text) {
            this.$store.state.notification = true
            this.$store.state.notification_text = text
        },
        notifyTop(title,text) {

            this.$store.state.notifications.notify = true
            this.$store.state.notifications.notify_title = title
            this.$store.state.notifications.notify_text = text
            setTimeout(() => (this.notification = false), 2000)

            var sound = new Audio('/sounds/notification.mp3')
            sound.play();


        },
    }
}