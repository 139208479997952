<template>

  <v-app >
    <span v-show="false" id="virtual_hidden"></span>

    <router-view></router-view>
    <notification></notification>

  </v-app>

</template>

<script>


import {mapActions} from "vuex";
import notification from "@/components/UI/notification";
export default {
  name: 'App',
  title : 'Fine Trading',
  components: { notification},
  methods: {

    ...mapActions('auth',['GET_USER']),
    ...mapActions(['INIT_SOCKET'])

  },
  created() {

    // DASHBOARD PAGE UPDATE
    if ( this.$route.path.includes('dashboard') ) {
     if (this.$route.params.alias ) {
       this.$store.state.business.selectedBusiness.alias = this.$route.params.alias
     }
    }

    this.INIT_SOCKET(this.$socket)

    this.$store.state.domain =  !(
           location.hostname === 'localhost'     ||
           location.hostname === this.$store.state.hostname  ||
           location.hostname === 'www.' + this.$store.state.hostname

    );

    if ( !this.$store.state.domain ) {
      if (this.$route.params.alias ) {
        this.$store.state.auth.rootPlace = this.$route.params.alias
      }
    }



    if (!this.$store.state.auth.loggedIn) {
      if (localStorage.getItem('access_token')){
        this.$store.state.auth.accessToken = localStorage.getItem('access_token')

        this.GET_USER( {
          token: localStorage.getItem('access_token') ,
          router : {
            router :this.$router,
            path   :this.$route.path
          },
          redirect : false,
        } )
      }
    }


  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1 {
  font-family: Montserrat,Helvetica,Arial,sans-serif ;
}

.wsRounded  {
  border-radius: 30px !important;
}
.wsRoundedHalf  {
  border-radius: 15px !important;
}
.wsRoundedHalfTop  {
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}
.wsRoundedHalfBottom  {
  border-bottom-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}
.wsRoundedHalfRight {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.section-content {
  max-width   : 1100px;
  width       : 100%;
  margin      : auto;
}
#app {
  font-weight: 300;
  background-color: #242424;
  font-family: Montserrat,Helvetica,Arial,sans-serif ;
}
.pointer {
  cursor: pointer;
}

.noCaps {
  text-transform: none !important;
  letter-spacing: unset !important;
}

.compact-form {
  transform: scale(0.875);
  transform-origin: center;
}
.bitter {
  font-family: "Bitter";
}
.finetTable  {
table {
  border-radius: 0 !important;
}
  thead {
    border-radius: 0 !important;
    tr {
      border-radius: 0 !important;
      td {
        border-radius: 0 !important;
      }
    }
    border-radius: 0 !important;
    td {
      border-radius: 0 !important;
    }
  }
td {
  border-radius: 0 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #393939;
}
tr { transition: all .3s ease; }
tr:hover {
  background-color: #459ad2 !important;
  color:white !important;
  border-radius: 0;
  cursor : pointer;
  i{
    color:white !important;
    transition: all .3s ease;
  }
td { color: white;
div span {
  color: white !important;
  border-color : white ! important;
  transition: all .3s ease;
}
}
}




}
.fineBorder {
  border : 2px solid #dddddd;
  border-radius : 5px;
  transition: all .3s ease;
}
.toggle {
  font-size: 12px;
  font-weight: 400;
}
.toggle_item {
  border : 2px solid #dddddd;
  border-radius : 5px;
  transition: all .3s ease;
}
.toggle_item_active {
  background-color: #585858;
  border : 2px solid #585858;
  color : white;
}
.toggle_item:hover {
  background-color: #585858;
  border : 2px solid #585858;
  color : white !important;
  cursor: pointer;
}

@font-face {
  font-family: "Bitter";
  src: local("Bitter"),
  url(~@/assets/fonts/Bitter/Bitter-VariableFont_wght.ttf) format("truetype");
}
</style>
