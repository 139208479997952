<template>
  <div class="d-flex">
    <div v-for="(item,i) in param" :key="i"
         class="mr-4">
      <router-link v-if="item.action === 'page' "
                   :to="{ path : item.route ,
                          hash : item.scroll }"
                   class="text-decoration-none">
        <v-btn text>{{  item.title  }}  </v-btn>
      </router-link>
      <v-btn v-if="item.action === 'link' "
             :href="item.link"
             text>{{  item.title  }}
      </v-btn>
      <div v-if="item.action === 'submenu'">

        <v-menu   open-on-hover offset-y >

          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
                  {{  item.title  }}
              <v-icon small class="ml-1">mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-sheet dark  class="py-3">
            <div v-ripple v-for="(subitem, i) in item.items" :key="i">

              <router-link v-if="subitem.action === 'page' "
                           :to="{ path : editorMode ? (subitem.route + '/edit') : subitem.route   ,
                                  hash : subitem.scroll }"
                           class="headerMenuItem text-decoration-none">
                <div  class="headerMenuItem pl-3 pr-10  py-1"  >
                  <span>{{ subitem.title }}</span>
                </div>
              </router-link>

              <a v-if="subitem.action === 'link'" :href="subitem.link" style="text-decoration: none">
                <div  class="headerMenuItem pl-3 pr-10  py-1"  >
                  <span>{{ subitem.title }}</span>
                </div>
              </a>




            </div>
          </v-sheet>

        </v-menu>



      </div>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "avalonMainMenu",
  props : ['input'],
  computed : {
    ...mapState('templates',  [ 'templateVars_init' , 'templateVars_current','editorMode','openEditor','selectedItem' ]),

    param() {
      return this.editorMode ? this.templateVars_current[this.input].buffer : this.templateVars_init[this.input]
    },
  }
}
</script>

<style scoped>
.headerMenuItem {
  font-size: 1rem !important;
  transition: all 0.2s ease;
  text-decoration: none;
  color : #f1f1f1;
}
.headerMenuItem:hover {
  background-color: #343434;
}
</style>