<template>
  <v-sheet>
    <div class="section-content py-16">

      <!-- Slot for edit block menu-->
      <slot name="edit"></slot>

      <div class="d-flex justify-center align-center">
        <div style="outline: none" class="swiper-button-next" slot="button-next">
          <v-btn icon large>
            <v-icon large>mdi-chevron-left</v-icon>
          </v-btn>
        </div>
        <swiper class="swiper d-flex justify-center align-center" style="width: 100%" :key="swiperKey"  :options="swiperOption">

          <swiper-slide  v-for="(product,i) in products_filtered" :key="i">

            <div>
              <div class="d-flex justify-center">
                <v-avatar class="text-center" size="150">
                  <v-img :src="product.image_medium" ratio="1"/>
                </v-avatar>
              </div>
              <h3 class="text-center font-weight-medium mt-6" :style="accentColor">{{ product.name }} </h3>
              <h4 class="text-center font-weight-light">{{ product.price }} грн</h4>
              <div class="d-flex align-center justify-center mt-2">
                <div v-if="cart[product.id]" class="d-flex mt-3">
                  <v-btn class="mr-3" small icon @click="removeFromCart(product)">
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                  <h3 class="mr-3">{{ cart[product.id].quantity }}</h3>
                  <v-btn small icon @click="addToCart(product)">
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </div>

                <v-btn @click="addToCart(product)" v-else
                       class="noCaps mt-3"
                       :color="parametr('accent_color')"
                       small dark rounded elevation="0">
                  Додати
                </v-btn>

              </div>
            </div>

          </swiper-slide>

          <div class="swiper-pagination d-flex justify-center" slot="pagination">
          </div>
        </swiper>
        <div style=" outline: none;" class="swiper-button-prev" slot="button-prev">
          <v-btn icon large>
            <v-icon large>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>


    </div>
  </v-sheet>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';

// Import Swiper styles
import 'swiper/swiper.scss'; // core Swiper


export default {
  name: "avalonBlockProducts",
  components: {
    Swiper,
    SwiperSlide
  },

  data() {
    return {
      swiperKey : 0,
      selectedCategory: '',
    }
  },
  computed: {
    ...mapState('templates', ['editorMode', 'products', 'product_categories', 'templateVars_init', 'templateVars_current']),
    ...mapState('cart', ['cart']),
    swiperOption() {
      return {
        loop: false,
        slidesPerView : this.productsSlideAmount,
        spaceBetween : 16,
        navigation : {
          nextEl : '.swiper-button-prev',
          prevEl : '.swiper-button-next',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable : true,
        // renderBullet: (idx, className) =>
        //     `<v-btn small href="#" class="noCaps ${className}">
        //     ${idx}
        //     </v-btn>`
        }
      }
    },
    accentColor() {
      return `color: ${this.parametr('accent_color')}`
    },
    productsSlideAmount() {
      // switch (  this.$vuetify.breakpoint.name ) {
      //   case 'xs' : return 2;
      //   case 'sm' : return 3;
      //   case 'md' : return 3;
      //   case 'lg' : return 4;
      //   default : return 4
      // }


      switch (  this.$vuetify.breakpoint.name ) {
        case 'xs' : return 1;
        case 'sm' : return 1;
        case 'md' :
          if (this.products_filtered === 1)  { return 1 }
          if (this.products_filtered === 2)  { return 2 }
          return 4;
        case 'lg' :
          if (this.products_filtered === 1)  { return 1 }
          if (this.products_filtered === 2)  { return 2 }
          return 4;
        case 'xl' :
          if (this.products_filtered === 1)  { return 1 }
          if (this.products_filtered === 2)  { return 2 }
          return 4;
        default :
          if (this.products_filtered === 1)  { return 1 }
          if (this.products_filtered === 2)  { return 2 }
          if (this.products_filtered === 3)  { return 3 }
          return 4
      }



    },
    products_filtered() {
      var products = this.products

      if (this.selectedCategory) {
        products = products.filter((el) => el.category === this.selectedCategory)
      }
      products = products.filter( el => !Object.keys(this.$store.state.cart.cart).includes( el.id ) )

      return products


    }
  },
  methods: {
    ...mapActions('cart', ['ADD_CART_ITEM', 'REMOVE_CART_ITEM']),
    parametr(varName) {
      let param = '';
      if (this.editorMode) {
        param = this.templateVars_current[varName].value
      }
      if (!this.editorMode) {
        param = this.templateVars_init[varName]
      }
      return param
    },
    addToCart(product) {
      // this.$store.state.cart.cart = product
      this.ADD_CART_ITEM(product)
      this.notify(`${product.name} додано до корзини`)
      // this.$forceUpdate()
    },
    removeFromCart(product) {
      this.REMOVE_CART_ITEM(product)
      this.notify(`${product.name} видалено із корзини`)
    }
  },
  watch: {
    productsSlideAmount(){
      this.swiperKey++
    },
    products_filtered() {
      this.swiperKey++;
    },
  },
  mounted() {
    this.swiperKey++;
  }
}
</script>

<style lang="scss" scoped>

</style>