<template>
  <v-sheet class="pt-3" outlined rounded>
    <div class="px-5">

      <v-expand-transition mode="out-in">
        <div v-if="!editElement &&
                 !editChildElement &&
                 !deleteElement &&
                 !deleteChildElement" >

          <draggable  v-model="value"  :forceFallback="true"
                      class="mb-1" handle=".handle"
                      group="items"
                      tag="div">

            <div v-for="(item,i) in value" :key="i"
                 class="mainItem" >

              <!-- MAIN ITEM-->
              <ftMainMenuDisplayElement :value="value[i]" @editRow="editAction(...arguments, i)" />

              <v-sheet v-if="item.action === 'submenu'"
                       :class="item.items.length === 0 ? 'pt-8' : ''"
                       class="ml-10 mt-6 mb-6 pa-3"
                       rounded
                       color="#343434" >

                <draggable v-model="item.items"
                           :empty-insert-threshold="10"
                           handle=".subHandle"
                           :forceFallback="true"
                           group="items"
                           tag="div">

                  <ftMainMenuDisplayElement v-for="(_,j) in item.items" :key="j"
                                            :child="true"
                                            :value="value[i].items[j]"
                                            @editRow="editAction(...arguments,i,j)" />
                </draggable>

                <v-divider class="my-1" dark  />

                <!-- ADD SUBITEM  -->
                <div class="d-flex justify-end">
                  <v-btn  v-if=" !editElement && !editChildElement "
                          @click="addRow(i)"
                          class="noCaps"
                          text  >
                    <v-icon small>mdi-plus</v-icon>
                    <h5>Додати</h5>
                  </v-btn>
                </div>

              </v-sheet>

              <!-- EDIT MENU ITEM  -->

            </div>
          </draggable>

        </div>
        <ftMainMenuDeleteElement    v-if="deleteElement"
                                    v-model="value[deleteIndex]"
                                    @deleteRow="deleteRow(...arguments,deleteIndex)"
                                    @closeDelete="closeDelete()" />
        <ftMainMenuDeleteElement    v-if="deleteChildElement"
                                    :child="true"
                                    v-model="value[deleteIndex].items[deleteChildIndex]"
                                    @deleteRow="deleteRow(...arguments,deleteIndex,deleteChildIndex)"
                                    @closeDelete="closeDelete()" />
        <ftMainMenuEditElement      v-if="editElement"
                                    :pages="pagesList"
                                    v-model="value[editIndex]"
                                    @editRow="editRow(editIndex)"/>
        <ftMainMenuEditElement      v-if="editChildElement"
                                    :pages="pagesList"
                                    :child="true"
                                    v-model="value[editIndex].items[editChildIndex]"
                                    @editRow="editRow(editIndex,editChildIndex)"/>
      </v-expand-transition>


      <!-- ADD NEW MENU ITEM-->
      <div class="d-flex justify-end mb-1">
        <v-btn v-if=" !editElement &&
                 !editChildElement &&
                 !deleteElement &&
                 !deleteChildElement "
               class="noCaps"
               @click="addRow()"
               text  >
          <v-icon small>mdi-plus</v-icon>
          <h5>Додати</h5>
        </v-btn>
      </div>



    </div>
  </v-sheet>

</template>

<script>
import draggable from "vuedraggable";
import ftMainMenuDeleteElement from "@/components/templates/editor/mainMenu/ftMainMenuDeleteElement";
import ftMainMenuEditElement from "@/components/templates/editor/mainMenu/ftMainMenuEditElement";
import ftMainMenuDisplayElement from "@/components/templates/editor/mainMenu/ftMainMenuDisplayElement";
import { mapState , mapActions } from "vuex";

export default {
  name: "ftEditorGeneralMainMenu",
  props : ['value'],
  components: {
    draggable,
    ftMainMenuEditElement,
    ftMainMenuDeleteElement,
    ftMainMenuDisplayElement
  },
  watch: {
    value() {
      this.$emit('input',this.value)
    }
  },
  data() {
    return {

      //delete Row vars
      deleteElement : false,
      deleteIndex : false,
      deleteChildElement : null,
      deleteChildIndex : null,

      //edit Row vars
      editElement : false,
      editChildElement : false,
      editIndex : 0,
      editChildIndex : 0,

      //for pages select
      pagesList : [],
    }
  },
  computed : {
    ...mapState('templates', ['templateVars_current']),
    ...mapState('templates',  [ 'businessAlias' ]),
  },
  methods : {
    ...mapActions('business', ['GET_PAGES_LIST']),

    editAction(type,index,child = null) {
      switch(type) {
        case 'edit'   : this.editRow(index,child); break;
        case 'delete' : this.displayDeleteConfirmation(index,child); break;
      }
    },
    closeDelete() {
      this.deleteElement      = false
      this.deleteIndex        = null
      this.deleteChildElement = false
      this.deleteChildIndex   = null
    },
    displayDeleteConfirmation(index,child = null) {


      if (child === null) {
        this.deleteElement      = true
        this.deleteIndex        = index

      } else {

        this.deleteElement      = false
        this.deleteIndex        = index
        this.deleteChildElement = true
        this.deleteChildIndex   = child

      }

    },
    deleteRow(deleteChild = false, index,child = null){
      alert('yoo pressed me')
      if (child === null) {
        if (!deleteChild) {
          this.value[index].items.forEach((item)=>{
            this.value.push(item)
          })
        }
        this.value.splice(index,1)
      } else {

        console.log('index' + index)
        console.log('child' + child)
        this.value[index].items.splice(child,1)
      }
      this.closeDelete();
      this.notify('Елемент меню видалено')
    },
    addRow(index = null) {

      let newRow = {

            title: '',
            subtitle: '',
            action: 'page',
            route : '',
            link : '',
            scroll: '',
            items : []
      }



      if (index === null) {
        this.value.push(newRow)
        this.editIndex = this.value.length - 1
        this.editElement = true;

      } else {
        this.editIndex = index
        this.value[index].items.push(newRow)
        this.editChildIndex = this.value[index].items.length - 1
        this.editChildElement = true;

      }

    },
    editRow(index,child = null) {
      if (child === null) {
        this.editChildElement = false
        this.editElement = !this.editElement

        this.editIndex = index;

      } else {
        this.editElement = false
        this.editChildElement = !this.editChildElement
        this.editIndex = index;
        this.editChildIndex = child;
      }

    },
    setActionType(index,action) {
      this.value[index].action =  action
    },
  },
  mounted() {
    this.GET_PAGES_LIST(this.businessAlias).then((out) => {
      if ( out.data ) {
        this.pagesList = out.data
      }
    })
  }
}
</script>

<style scoped>
.mainItem {
  border-bottom: 1px solid #545454
}
.editItemBlock {
  background-color: #4c4c4c;
  border-radius:5px
}
.headerMenuItem {
  font-size: 1rem !important;
  transition: all 0.2s ease;
  text-decoration: none;
  color : #f1f1f1;
}
.headerMenuItem:hover {
  background-color: #343434;
}
</style>