<template>
  <v-sheet dark color="teal darken-3" class="pa-16">
    <h1 class="text-h1 font-weight-light">Welcome to {{ business.name }} !</h1>
    <h1 class="text-h3 font-weight-thin">{{ business.shortAbout }}</h1>
    <code >{{  business }}</code>
  </v-sheet>
</template>

<script>
export default {
  name: "MondaoMain",
  props : ['business'],
}
</script>

<style scoped>

</style>