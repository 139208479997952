<template>
  <div class="pa-16">

    <div v-if="!showEditPermissions">
      <div class="d-flex justify-space-between mb-4">
        <h1 class="text-h4 font-weight-light">Ваші Менеджери</h1>
        <v-btn color="teal" dark @click="showAddManager">Додати</v-btn>
      </div>
      <v-simple-table>
        <thead>
        <tr>
          <th>Менеджер</th>
          <th>Права</th>
          <th>Дії</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(manager,i) in managers " :key="i">
          <td>
            <div class="d-flex ma-3">
              <v-avatar color="grey lighten-2"
                        class="mr-3"
                        size="48"  >
                <v-icon large>mdi-account</v-icon>
                <!--              <v-img :src="manager.firstname" ratio="1" />-->
              </v-avatar>
              <div>
                <h4> {{  manager.firstname }} {{  manager.lastname }} </h4>
                {{ manager.email}}
              </div>
            </div>
          </td>
          <td>
            <v-chip v-for="(permission,j) in manager.permissions" :key="j"
                    small dark color="teal lighten-1"
                    class="mr-2 mt-1">
              <v-icon small class="mr-1">{{ getPermissionIcon(permission) }}</v-icon>
              {{  getPermissionName(permission)  }}
            </v-chip>
          </td>
          <td>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" v-on="on" >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(menuItem, index) in editActions" :key="index" @click="editButton(menuItem.action,manager,index)">
                  <v-list-item-title class="font-weight-light">{{ menuItem.label }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-dialog v-model="showEdit" width="700">
        <editManager v-if="showEdit"
                     :users="users"
                     @update="updateManagers"
                     @close="showEdit=false" />
      </v-dialog>
    </div>

    <manageManagerPermissions    v-if="showEditPermissions"
                                 @close="showEditPermissions = false"
                                 v-model="managers[selectedManagerIndex].permissions"
                                 :manager="selectedManager"
                                 :permissions="modulesPermissions" />







  </div>






</template>

<script>
import {mapActions, mapState} from "vuex";
import editManager from "@/components/pages/businessDashboard/businessManagers/editManager";
import manageManagerPermissions from "@/components/pages/businessDashboard/businessManagers/manageManagerPermissions";

export default {
  name: "BusinessDasboardManagers",
  components : {
    editManager,
    manageManagerPermissions
  },
  data(){
    return {
      managers : [],
      users : [],
      showEdit : false,
      showEditPermissions : false,
      selectedManager : {},
      selectedManagerIndex : null,
      modulesPermissions : [],

      editActions : [

        { label: 'Редагувати', action: 'edit'   },
        { label: 'Видалити',   action: 'delete' },

      ],

    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
  },
  methods : {
    ...mapActions( 'business', [ 'GET_MANAGERS' ] ),


    showAddManager() {
      this.showEdit = true
    },
    updateManagers(data){
      this.managers.push(data)
      this.showEdit = false
    },

    editButton(action,item,index) {

      switch(action) {
        case 'edit'         : this.openManagerPemissions(item,index)    ; break;
        case 'delete'       : this.deleteManager(item)            ; break;
      }

    },
    openManagerPemissions(item,index) {
      this.selectedManager = item
      this.selectedManagerIndex = index
      this.showEditPermissions = true
    },
    deleteManager(){
    },

    // techical
    getPermissionName(permission){
      switch(permission) {
        case 'MODULE_NEWS'       : return 'Новини';
        case 'MODULE_PRODUCTS'   : return 'Товари';
        case 'MODULE_ORDERS'     : return 'Замовлення';
        case 'MODULE_WAREHOUSE'  : return 'Склад';
        case 'MODULE_DELIVERY'   : return 'Доставка';
        case 'MODULE_TEMPLATER'  : return 'Дизайн';
        case 'MODULE_STATISTICS' : return 'Статистика';

        default : return permission
      }
    },
    getPermissionIcon(permission){
      switch(permission) {
        case 'MODULE_NEWS'       : return 'mdi-newspaper-variant-outline';
        case 'MODULE_PRODUCTS'   : return 'mdi-cart-variant';
        case 'MODULE_ORDERS'     : return 'mdi-text-box-check-outline';
        case 'MODULE_WAREHOUSE'  : return 'mdi-warehouse';
        case 'MODULE_DELIVERY'   : return 'mdi-warehouse';
        case 'MODULE_TEMPLATER'  : return 'mdi-account';
        case 'MODULE_STATISTICS' : return 'mdi-chart-bar';

        default : return permission
      }
    }
  },
  mounted() {
    this.loading = true
    this.GET_MANAGERS(this.selectedBusiness.alias).then((out)=>{
      if (out.data) {

        this.managers = out.data.managers
        this.users    = out.data.users
        this.modulesPermissions = out.data.modulesPermissions
        this.loading = false;

      }
    }).catch(()=>{
      this.loading = false
      this.notify('Network error')
    })


  }

}
</script>

<style scoped>

</style>