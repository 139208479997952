<template>
  <v-sheet class="pa-16">
    <v-btn text @click="addBlock">
      <v-icon>mdi-plus</v-icon>
      Додати блок
    </v-btn>

  </v-sheet>
</template>

<script>
export default {
  name: "ftEditorFooterAddBlock",
  methods : {
    addBlock () {
      this.$store.state.templates.openEditorBlock = true
      this.$store.state.templates.openEditorBlockType = 'new'
    }
  }
}
</script>

<style scoped>

</style>