<template>
  <v-dialog v-model="$store.state.templates.openEditorBlockElement" :width="editorWidth" >
    <v-sheet dark color="#34343499" class=" py-8 px-6" light style="transition:all 0.5s ease"
              :class="confirmDelete ? '' : 'sideMenu' "
    >

      <div v-if="!confirmDelete"
           class="d-flex  justify-space-between align-center  mt-5">
        <h4 class="font-weight-light">Редагування: {{ elementParams.element }}</h4>
        <v-btn dark icon plain color="black" @click="cancelEdit">
          <v-icon small dark color="white">mdi-window-close</v-icon>
        </v-btn>
      </div>

      <v-divider v-if="!confirmDelete" class="mb-3" />

        <div v-if="!confirmDelete ">

          <ftParamsBar v-model="$store.state.templates.page.blocks[selectedBlock].value.items[selectedBlockRow][selectedBlockItem].params"
                       class="text-center"
                       @delete="deleteItem"
                       @hover="hoverAction"
                       :auto-input="['margin','align', 'display','delete']" />
          <h4 class="mt-3" >Параметри елемента: {{ elementParams.element }}</h4>

          <div v-if="elementType === 'ftTemplateElement' ">

            <ftEditorBlockElementButton   v-if="subElementType === 'Button' "
                                          class="mt-3"
                                          :selectedBlock="selectedBlock"
                                          :selectedBlockRow="selectedBlockRow"
                                          :selectedBlockItem="selectedBlockItem" />
            <ftEditorBLockElementDelivery v-if="subElementType === 'DeliveryField'"
                                          class="mt-3"
                                          :selectedBlock="selectedBlock"
                                          :selectedBlockRow="selectedBlockRow"
                                          :selectedBlockItem="selectedBlockItem" />
            <ftEditorBLockElementSocials  v-if="subElementType === 'Socials'"
                                          class="mt-3"
                                          :selectedBlock="selectedBlock"
                                          :selectedBlockRow="selectedBlockRow"
                                          :selectedBlockItem="selectedBlockItem" />

          </div>
        </div>

        <div v-if="confirmDelete">
          <h4 class="text-center mb-2">Підтвердіть операцію видалення</h4>
          <div class="d-flex justify-center mt-8" >
            <v-btn small class="noCaps" text @click="cancelEdit">Скасувати </v-btn>
            <v-btn small class="noCaps" outlined @click="deleteElement">Підвтердити </v-btn>
          </div>

        </div>





    </v-sheet>



  </v-dialog>
</template>

<script>


import {mapState,mapActions} from "vuex";
import ftEditorBlockElementButton from "@/components/templates/editor/elements/ftEditorBlockElementButton";
import ftParamsBar from "@/components/templates/editor/UI/ftParamsBar";
import ftEditorBLockElementDelivery from "@/components/templates/editor/elements/ftEditorBLockElementDelivery";
import ftEditorBLockElementSocials from "@/components/templates/editor/elements/ftEditorBLockElementSocials";

export default {
  name: "ftEditorBlockElement",
  components : {
    ftEditorBlockElementButton,
    ftEditorBLockElementDelivery,
    ftEditorBLockElementSocials,
    ftParamsBar
  },
  data(){
    return {
      loading : false,
      confirmDelete : false,
    }
  },
  computed : {
    ...mapState('templates',  [ 'selectedItem', 'openEditorBlockDeleteElement', 'products' , 'selectedBlock', 'selectedBlockItem' , 'selectedBlockRow' , 'openEditorBlockElement' ]),
    editorWidth(){
      let width = 400 ;
      if (this.elementParams.type) {
        switch(this.elementParams.type) {
          case 'html'    : width=1000 ; break;
          case 'text'    : width=800  ; break;
          case 'heading' : width=700  ; break;

        }
      }
      return width
    },
    elementType() {
      return this.$store.state.templates.page.blocks[this.selectedBlock].value.items[this.selectedBlockRow][this.selectedBlockItem].type
    },
    subElementType() {
      return this.$store.state.templates.page.blocks[this.selectedBlock].value.items[this.selectedBlockRow][this.selectedBlockItem].subElement
    },
    elementParams() {
      return this.$store.state.templates.page.blocks[this.selectedBlock].value.items[this.selectedBlockRow][this.selectedBlockItem].params
    },
    saveText() {
      if ( !this.confirmDelete ) { return 'Зберегти' }
      if (  this.confirmDelete ) { return 'Видалити' }
      return 'Зберегти'
    },


  },
  methods : {
    ...mapActions('templates',  [ 'SAVE_BLOCK' ]),
    cancelEdit(){
      this.$store.state.templates.openEditorBlockElement = false
      this.$store.state.templates.openEditorBlockDeleteElement = false
    },

    hoverAction(val) {
      this.preventClose = val
    },
    deleteItem(){
      this.confirmDelete = true
    },
    saveChanges() {

      if ( this.confirmDelete ) { this.deleteElement()  }

      this.SAVE_BLOCK(
          {
            alias: this.$store.state.templates.businessAlias,
            data : {
              id : this.$store.state.templates.page.blocks[this.selectedBlock].id,
              page : this.$store.state.templates.page.blocks[this.selectedBlock].page,
              draft : true,
              value : this.$store.state.templates.page.blocks[this.selectedBlock].value
            }
          }  )
          .then((out)=>{
            if(out.result) {
              this.notify(!this.confirmDelete ? 'Чернетку збережено' : 'Елемент видалено')
              this.$store.state.templates.openEditorBlockElement = false
            }
      }).catch( () => {
        this.notify("Помилка зв'язку")
      })

    },
    deleteElement() {
      this.$store.state
          .templates.page.blocks[this.selectedBlock]
          .value.items[this.selectedBlockRow].splice(this.selectedBlockItem,1)
      this.cancelEdit()
    }

  },
  beforeMount() {
    this.confirmDelete = this.openEditorBlockDeleteElement
  },
  beforeDestroy() {
    this.cancelEdit()
  }
}
</script>

<style  lang="scss">

@import '~@/assets/css/quill.bubble.css';

.noResize textarea {
  resize: none !important;
}
.sideMenu {
  position:fixed;
  top:0;
  right:0;
  height: 100%;
  width: 500px;
  z-index: 999999000;
  overflow-y: auto;
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  transition: all .6s ease;
}
</style>