const actions = {
    async AJAX_SEND({rootState,}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },
    async GET_BUSINESS_ORDERS({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken ,route:`order/${body}`, method:'GET'});
    },
    async EDIT_BUSINESS_ORDER({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken ,route:`order/${body.id}`, method:'PUT',data : body.data});
    },
    async GET_LAST_ORDER({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken ,route:`order/${body}/last`, method:'GET'});
    },

    async NEW_PARTICIPANT({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{
            token:rootState.auth.accessToken ,
            route:`action/new_participant`,
            method:'POST',
            data : body
        });
    },
    async GET_ACTION_PARTICIPANTS({dispatch,rootState}) {
        return await dispatch('AJAX_SEND',{
            token:rootState.auth.accessToken ,
            route:`action/participants`,
            method:'GET',
        });
    },
    async CHANGE_PARTICIPANT({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{
            token:rootState.auth.accessToken ,
            route:`action/participant`,
            method:'POST',
            data : body
        });
    },







}

export default {
    namespaced:true,
    actions,
}