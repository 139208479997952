<template>
  <div>
    <v-sheet height="2000" class="pa-16" color="purple ligthen-2" dark>
      <h2 v-for="i in 50" :key="i" class="text-center">{{i}}) This is Avalon news baby</h2>
    </v-sheet>
  </div>
</template>

<script>
export default {
name: "news"
}
</script>

<style scoped>

</style>