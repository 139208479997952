<template>
  <v-sheet class="pa-16" color="orange ligthen-2" dark>
    <h2 class="text-center">This is  PAGES baby</h2>
  </v-sheet>
</template>

<script>
export default {
  name: "TemplateLoaderNews"
}
</script>

<style scoped>

</style>