<template>
  <v-sheet dark class="pa-2">

    <div class="d-flex justify-center">

      <v-tabs centered    dark >
        <v-tab  dark>Завантажити</v-tab>
          <v-tab-item  dark style="background-color: #1e1e1e; height: 420px" >
            <v-sheet dark class="pt-8" >
              <v-row >
                <v-col cols="4">
                  <h5 class="mb-6 text-center">Поточне зображення :</h5>
                  <imago style="border: 3px solid #444" ratio="1.4" :src="value" ></imago>
                </v-col>
                <v-col cols="8">
                  <h5 class="mb-6 text-center">Завантажити зображення:</h5>
                  <dropzone style="border: 3px solid #444; height: 220px; background-color: #343434"
                            @vdropzone-complete="afterUpload"
                            @vdropzone-error="uploadError"
                            key="change_picture"
                            ref="myVueDropzone" id="dropzone"
                            :options="dropzoneOptions" />
                </v-col>
              </v-row>
            </v-sheet>
          </v-tab-item>
        <v-tab dark @click="openLibrary()" >Бібліотека</v-tab>
          <v-tab-item dark style="background-color: #1e1e1e; height: 420px ">
          <v-sheet dark class="pt-6" style="height: 550px; overflow-y: auto">

            <div >
              <div style="width: 300px" >
                <v-select v-model="filter" filled dense hide-details rounded  :items="['Товари','Новини','Всі']"></v-select>
              </div>
            </div>

            <v-row class="pt-6" >
              <v-col v-for="(img,i) in library" :key="i" cols="2">
                <v-hover v-slot="{ hover }">
                  <imago
                         :src="img.thumb_small"
                         ratio="1.4"
                         style="border: 3px solid #444"
                         v-ripple >

                    <v-fade-transition>

                      <div v-if="hover"
                           @click="setPicture(img.url)"
                           style="background-color: #000000aa;  cursor: pointer"
                           class="fill-height d-flex align-center justify-center">
                        Обрати
                      </div>

                    </v-fade-transition>

                  </imago>
                </v-hover>
              </v-col>
            </v-row>
          </v-sheet>
        </v-tab-item>
      </v-tabs>

    </div>

  </v-sheet>
</template>

<script>
import imago from "@/components/UI/imago";
import vue2Dropzone from "vue2-dropzone";
import { mapState,mapActions } from "vuex";
export default {
  name: "editImage",
  props : ['value'],
  components : {
    imago,
    dropzone : vue2Dropzone,
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
  },
  watch :{
    value(value) {
      this.$emit('input',value)
    }
  },
  data(){
    return {
      loading : false,
      showLibrary : false,
      library : [],
      filter : '',
      dropzoneOptions: {
        url: this.$store.state.ajaxRoute+'upload/' + this.$store.state.business.selectedBusiness.alias + '/products',
        method: 'POST',
        maxFilesize: 2.5,
        headers: {
          "Authorization": 'Bearer '+this.$store.state.auth.accessToken },
        addRemoveLinks : true,
        uploadMultiple : false,
        maxFiles : 1,
        thumbnailWidth: 140, // px
        thumbnailHeight: 140,

      },
    }
  },
  methods : {
    ...mapActions('business', ['GET_BUSINESS_IMAGES_LIBRARY']),
    openLibrary(){
      this.showLibrary = !this.showLibrary
      if ( this.showLibrary ) {
        this.GET_BUSINESS_IMAGES_LIBRARY(this.selectedBusiness.alias).then((out)=>{
          if ( out.result) {
            this.library = out.data.images
          }
          this.loading = false
          console.log(out)
        }).catch(()=>{this.loading = false})
      }
    },
    afterUpload(response){
      this.value = JSON.parse(response.xhr.response).data.url
      this.setPicture(this.value)
      this.$refs['myVueDropzone'].removeAllFiles();

    },
    uploadError(_,message) {
      this.notify('Помилка завантаження: ' + message)
      this.$refs['myVueDropzone'].removeAllFiles();
    },
    setPicture(url) {
      this.value = url
      this.showLibrary = false
      this.$emit('input',url)
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>