<template>

  <div class="user_dropdown d-flex align-center font-weight-light"
       :style="dark ? '' : 'border-color : #ffffff22'">

    <v-menu offset-y transition="slide-y-transition">

      <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="!main_menu" small class="d-flex font-weight-medium noCaps align-center"  :color="btnColor" text v-bind="attrs" v-on="on" >
              <span> {{ userText }}</span>
              <v-icon  small v-if="loggedIn" >mdi-chevron-down</v-icon>
          </v-btn>
        <v-btn v-else class="ml-3 my-1 px-3 noCaps" text rounded elevation="0" dark small color="blue darken-2" v-bind="attrs" v-on="on">
          <v-icon v-if="!loggedIn" class="mr-2" small  >mdi-login</v-icon>{{ loggedIn ? userEmail : 'Login' }}
        </v-btn>

      </template>

      <v-card class="rounded-0">

        <div v-if="loggedIn === true"
             v-ripple
             style="cursor: pointer"
             @click="$router.push(`/dashboard/${$store.state.auth.rootPlace}`)"
             class="d-flex pa-5 align-center">

          <v-avatar
              size="48" key="profile_picture" >
            <v-img :src="$store.state.filesPublic+'users/avatars/'+$store.state.auth.userID">
              <template slot="placeholder">
                <v-img src="@/assets/placeholder/user_blank.jpg" />
              </template>
            </v-img>
          </v-avatar>

          <div class="ml-5">
            <h3 class="h6 mt-5">{{ userName }}</h3>
            <p  class="caption">{{ userEmail }}</p>
          </div>

        </div>

        <v-divider></v-divider>
        <v-list nav dense >
          <v-list-item-group color="primary" >
            <v-list-item v-if="!loggedIn" @click="toggleLoginMenu">
              <v-list-item-icon>
                <v-icon >mdi-login</v-icon>
              </v-list-item-icon>
              <v-list-item-content>

                <v-list-item-title >Увійти / Зареєструватись </v-list-item-title>

              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="loggedIn" @click="$router.push(`/dashboard/${$store.state.auth.rootPlace}`)">
              <v-list-item-icon>
                <v-icon >mdi-view-dashboard-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>

                <v-list-item-title >Користувацька панель </v-list-item-title>

              </v-list-item-content>
            </v-list-item>
            <!-- back to site-->
            <v-list-item v-if="showBackToSite" @click="$router.push(`/${$store.state.auth.rootPlace}`)">
              <v-list-item-icon>
                <v-icon >mdi-information-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>

                <v-list-item-title >Повернутися на сайт </v-list-item-title>

              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="loggedIn" @click="logout">
              <v-list-item-icon>
                <v-icon >mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>

                <v-list-item-title >Вийти з профілю </v-list-item-title>

              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

      </v-card>
    </v-menu>


  </div>

</template>

<script>
import {mapMutations,mapState} from 'vuex';
export default {
  name: "userDropdown",
  props : {
    img_size: {
      type : String,
      default: '48'
    },
    dark : Boolean,
    main_menu : {
      type : Boolean,
      default : false
    }
  },
  computed: {
    ...mapState('auth',['loggedIn','userName','userEmail']),
    showBackToSite(){
      return this.$route.path.includes('dashboard')
    },
    btnColor() {
      if (this.dark) {
        return 'dark'
      } else {
        return 'white'
      }

    },
    userText(){
      if ( this.loggedIn ) {
        return this.userEmail ? this.userEmail : this.userName
      }
      return 'Login'
    }
  },
  methods : {
    ...mapMutations(['toggleLoginMenu']),
    ...mapMutations('auth',['LOGOUT']),

    logout() {
      this.LOGOUT(this.$router);
    }
  }
}
</script>

<style scoped>

.user_dropdown {
  height:100%;
}

</style>