const state = {
    selectedBusiness : {
        alias : '',
        modules : [],
        managerPermissions : [],
        userPermissions : {
            permissions : [],
            modulePermissionsObject : {}
        }
    }
}
const actions = {
    async AJAX_SEND({rootState,}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },

    // BUSINESS MANAGE
    async ADD_BUSINESS({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`business`, method:'POST',data: body});
    },
    async GET_BUSINESS_DASHBOARD({dispatch,rootState},body) {


        let request = await dispatch('AJAX_SEND',{ token:rootState.auth.accessToken,route:`business/dashboard`, method:'POST', data : body });
        window.document.title = request.data.name
        state.selectedBusiness = request.result ? request.data : {}

        rootState.dashboard.showBusinessToOwner   = rootState.auth.userBusinesses.includes(request.data.alias)
        rootState.dashboard.showBusinessToManager = rootState.auth.userManagingBusinesses.includes(request.data.alias)
        rootState.templates.products = request.data.products
        rootState.cart.deliveries = []
        rootState.cart.payments = []
        if ( request.data.deliveries) {
            request.data.deliveries.forEach( (delivery)=>{
                rootState.cart.deliveries.push({text:delivery.name,value:delivery.id})
            })
        }
        if (request.data.payments) {
            request.data.payments.forEach( (payment)=>{
                rootState.cart.payments.push({text:payment.name,value:payment.id})
            })
        }

        rootState.templates.businessAlias = request.data.alias;
        rootState.templates.product_categories = request.data.product_categories
        rootState.templates.locations = request.data.locations
        rootState.templates.deliveryAllowCountries = request.data.deliveryAllowCountries
        rootState.templates.businessCountry = request.data.country
        rootState.templates.deliveryCountries = request.data.deliveryCountries

        if ( rootState.auth.loggedIn ) {
            rootState.dashboard.showBusinessToOwner   = rootState.auth.userBusinesses.includes(request.data.alias)
            rootState.dashboard.showBusinessToManager = rootState.auth.userManagingBusinesses.includes(request.data.alias)
        }
    },

    async GET_BUSINESS({dispatch,rootState},body) {

        if (rootState.templates.editorMode) {
            return await dispatch('AJAX_SEND',{ token:rootState.auth.accessToken,route:`business/admin`, method:'POST', data :  body });
        } else {
            return await dispatch('AJAX_SEND',{ token:'public',route:`business/public`, method:'POST' , data :  body });
        }

    },

    // BUSINESS DASHBOARD
    async GET_DASHBOARD_BUSINESS({dispatch},body) {
        return await dispatch('AJAX_SEND',{token:'public',route:`business/${body}`, method:'GET'});
    },

    // BUSINESS SETTINGS GENERAL
    async GET_GENERAL_SETTINGS({rootState,dispatch},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`options/general/${body}`, method:'GET'});
    },
    async SAVE_GENERAL_SETTINGS({rootState,dispatch},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`business/${body.alias}`, method:'PUT', data:body.data});
    },
    // BUSINESS  PAYMENTS
    async GET_PAYMENT_TYPES({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken ,route:`options/payment/${body}`, method:'GET'});
    },
    async EDIT_PAYMENT_TYPES({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken ,route:`options/payment/${body.alias}`, method:'PUT', data: body.data});
    },
    // BUSINESS  DELIVERY
    async GET_DELIVERY_TYPES({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken ,route:`options/delivery/${body}`, method:'GET'});
    },
    async EDIT_DELIVERY_TYPES({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken ,route:`options/delivery/${body.alias}`, method:'PUT', data: body.data});
    },

    // BUSINESS  USERS
    async GET_MANAGERS({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken ,route:`business/${body}/managers`, method:'GET'});
    },
    async ADD_MANAGER({dispatch,rootState,state},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken ,route:`business/${state.selectedBusiness.alias}/manager`, method:'PUT',data:body});
    },
    async GET_SUBSCRIBERS({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken ,route:`business/${body}/subscribers`, method:'GET'});
    },
    async EDIT_MANAGER_PERMISSIONS({dispatch,rootState,state},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken ,route:`business/${state.selectedBusiness.alias}/manager`, method:'POST',data:body});
    },


    // BUSINESS  NEWS
    async GET_NEWS_ALL({dispatch},body) {
        return await dispatch('AJAX_SEND',{token:'public',route:`news/${body}`, method:'GET'});
    },
    async GET_NEWS({dispatch},body) {
        return await dispatch('AJAX_SEND',{token:'public',route:`news/${body.business}/${body.news}`, method:'GET'});
    },
    async CREATE_NEWS({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`news`,data:body, method:'POST'});
    },
    async EDIT_NEWS({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{ token:rootState.auth.accessToken,route:`news/${body.id}`, data: body.data, method:'PUT'});
    },

    // BUSINESS  PAGES
    async GET_PAGES_ALL({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{ token: rootState.auth.accessToken,route:`pages/${body}`, method:'GET'});
    },
    async GET_PAGES_LIST({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{ token: rootState.auth.accessToken,route:`pages/${body}/list`, method:'GET'});
    },
    async GET_PAGE_PUBLIC({dispatch,rootState},body) {
        if (rootState.templates.editorMode) {
            return await dispatch('AJAX_SEND',{ token: rootState.auth.accessToken ,route:`page/admin`, method:'POST', data : body});
        } else {
            return await dispatch('AJAX_SEND',{ token: 'public' ,route:`page/public`, method:'POST', data : body});
        }


    },
    async GET_PAGE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{ token:rootState.auth.accessToken,route:`news/${body.business}/${body.news}/admin`, method:'GET'});
    },
    async ADD_PAGE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`page`,data:body, method:'POST'});
    },
    async EDIT_PAGES({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`news/${body.id}`, data: body.data, method:'PUT'});
    },

    // BUSINESS  FILES
    async GET_BUSINESS_IMAGES_LIBRARY({rootState,dispatch},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`library/images/${body}`, method:'GET'});
    },

    //MODULES
    async GET_MODULES({dispatch,rootState,state}) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`modules/${state.selectedBusiness.alias}`, method:'GET'});
    },
    async UPDATE_MODULES({dispatch,rootState,state},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`modules/${state.selectedBusiness.alias}`,data:{modules : body}, method:'POST'});
    },

    //BUSINESS SERVICES KEYS
    async UPDATE_BUSINESS_SERVICES_KEYS({dispatch,rootState,state},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`business/${state.selectedBusiness.alias}/serviceKeys`,data:body, method:'POST'});
    },



    // technical

    RESET_BUSINESS_STATE({state}) {
        state.selectedBusiness = {
            alias : '',
                modules : [],
                managerPermissions : [],
                userPermissions : {
                permissions : [],
                    modulePermissionsObject : {}
            }
        }
    }

}

export default {
    namespaced:true,
    actions,
    state
}