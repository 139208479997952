<template>
  <div class="pt-8">

    Ласкаво просимо

<!--    <v-row >-->
<!--      <v-col cols="6" sm="6" xs="6" md="6" lg="3" xl="3" class="" v-for="(block,i) in headerBlocks" :key="i">-->
<!--        <v-sheet dark :color="block.color" class="py-4 px-4 d-flex">-->
<!--          <v-icon size="32" class="mr-3">{{ block.icon }}</v-icon>-->
<!--          <div>-->
<!--            <div class="mb-0 caption">{{  block.title  }}</div>-->
<!--            <h3 class="mt-0 font-weight-light">{{ block.content }} </h3>-->
<!--          </div>-->

<!--        </v-sheet>-->
<!--      </v-col>-->
<!--    </v-row>-->

<!--    <v-row>-->
<!--      <v-col cols="12" md="12" lg="8" xl="8">-->
<!--        <v-sheet dark class="pa-2 px-6">-->
<!--          <h3 class="font-weight-light">Останні замовлення</h3>-->
<!--        </v-sheet>-->
<!--        <v-simple-table>-->
<!--          <tbody >-->
<!--          <tr v-for="i in 6" :key="i">-->
<!--            <td class="caption font-weight-light py-2">-->
<!--              (12:24) Андрій Петрович : +380637534330 <br>-->
<!--              Бузника 15 Миколахв ld nlkds fldksmf lksdaf </td>-->

<!--            <td> <v-chip small color="teal lighten-2" dark>-->
<!--              <v-icon x-small class="mr-2">mdi-account</v-icon>-->
<!--              <h4>Jeff Bezos</h4>-->
<!--            </v-chip></td>-->
<!--            <td> <v-chip small color="blue lighten-2" dark>-->
<!--              <v-icon x-small class="mr-2">mdi-cog</v-icon>-->
<!--              <h4>Готується</h4>-->
<!--            </v-chip></td>-->
<!--            <td> <v-chip small color="green lighten-2" dark>-->
<!--              <v-icon x-small class="mr-2">mdi-cash-multiple</v-icon>-->
<!--              <h4>370 грн</h4>-->
<!--            </v-chip></td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </v-simple-table>-->
<!--      </v-col>-->
<!--      <v-col cols="12" md="12" lg="4" xl="4"    >-->
<!--        <v-sheet dark class="pa-2 px-6 mb-4">-->
<!--          <h3 class="font-weight-light">Останні події</h3>-->
<!--        </v-sheet>-->
<!--        <v-simple-table dense>-->
<!--          <tbody>-->
<!--          <tr v-for="i in 5" :key="i + 'pis'">-->
<!--            <td class="caption font-weight-light">12:24</td>-->
<!--            <td class="caption font-weight-light">Замовлення доставлено 1243242</td>-->
<!--          </tr>-->
<!--          <tr v-for="i in 3" :key="i + 'sis'">-->
<!--            <td class="caption font-weight-light">12:24</td>-->
<!--            <td class="caption font-weight-light">Переміщення по складу (Cклад МАтвеевка -> Водій 1)</td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </v-simple-table>-->
<!--      </v-col>-->
<!--    </v-row>-->

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "dashBusinessIndex",
  props: ['alias'],
  data() {
    return {
      loading : false,
      business : {},
      data : {
        activeOrders : 0 ,
        dayCash : 0
      },
    }
  },
  computed : {
    headerBlocks() {
      return [
        {title: 'Сьогодні', content: '12 Cічня', icon: 'mdi-cog', color: ''},
        {title: 'Нові клієнти', content: `+ 123`, icon: 'mdi-account-multiple', color: ''},
        {title: 'Активні Замовлення', content: ` ${ this.data.activeOrders }`, icon: 'mdi-cog', color: ''},
        {title: 'Продажі', content: `${ this.data.dayCash } грн`, icon: 'mdi-cash-multiple', color: 'green lighten-2'},
      ]
    }
  },
  methods : {
    ...mapActions( 'dashboard', [ 'GET_DASHBOARD_ADMIN' ] ),
  },
  mounted() {
    this.loading = true;
    this.GET_DASHBOARD_ADMIN().then((out)=>{
      if ( out.result ) {
        this.data = out.data
      }
      this.loading = false
    }).catch(()=>{this.loading = false})
  }
}
</script>

<style scoped>

</style>