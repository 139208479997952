<template>
  <v-sheet color="blue-grey darken-1" v-if="displayCondition" elevation="2" dark >
    <v-sheet class="menu" color="blue-grey darken-1" :class="menuStyleClass">
      <!--    header-->


      <menuElementsBusiness />


    </v-sheet>
  </v-sheet>

</template>

<script>
import {mapState} from 'vuex';

import menuElementsBusiness from "@/components/pages/businessDashboard/UI/sidebarBusiness/menuElementsBusiness";
export default {
  name: "sideBarBusiness",
  components : {
    menuElementsBusiness
  },
  data: () => ({
    selectedItem: 1,
    items: [
      { text: 'Real-Time', icon: 'mdi-clock' },
      { text: 'Audience', icon: 'mdi-account' },
      { text: 'Conversions', icon: 'mdi-flag' },
    ],
  }),
  computed : {
    ... mapState('dashboard',['sideMenu']),

    displayCondition() {
      if ( this.displayShow(['sm'])) {
       return this.sideMenu
      }
      return true
    },
    
    menuStyleClass() {
      if ( this.sideMenu ) {
        if ( this.displayShow(['sm'])) {
          return 'menuActiveMobile'
        }
        return 'menuActive'
      }
      return ''
    }
  }


}
</script>

<style scoped>

.menu_header {
  background-color: #242424;
  height: 70px;
}

.menu {
  position:fixed;
  width:60px;
  height:100%;
  background-color: white;
  color: #2c3e50;
  float:left;
  transition: all 0.3s ease-out;
  overflow-y: auto;
}
.menuActiveMobile {
  position: fixed;
  display: block;
  z-index: 999999;
  width: 100%;
  transition: all 0.3s ease-out;
}
.menuActive {
  width:250px !important;
  transition: all 0.3s ease-out;
}
.menuActive li {
  padding-left: 35px !important;
}

.menu ul {
  padding:0;
}
.menu li {
  list-style: none;
  margin: 0px;
  padding:5px;
  padding-left:17px;
  transition: all 0.3s ease-out;
}
.menu li a {
  color: #2c3e50;
  text-decoration: none;
}
.menu a:hover {
  transition: all 0.3s ease-out;
  color: antiquewhite;
}
.menu li:hover {
  background-color: #ffffff33;
}
</style>