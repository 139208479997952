export default {
    methods : {
        getPermission(roles) {

            var access = false
            roles.forEach( (role)=> {
                if (role === 'All') { access = true }
                if (this.$store.state.auth.userType === role) { access = true }
            })
            return access;

        }
    }
}