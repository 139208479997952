<template >
<div style="background-color: #262626 !important">

  <ftEditor v-if="editorMode && openEditor"/>

<!--  <ftEditorGeneral v-if="editorMode && openEditorGeneral" />-->
  <ftEditorGeneralNew  v-if="editorMode && openEditorGeneral" />

  <ftEditorBlockElement v-if="editorMode && $store.state.templates.openEditorBlockElement"   />
  <ftEditorBlock v-if="editorMode && openEditorBlock" />
  <ftEditorAddEntity v-if="openEditorAddEntity" />

  <v-fade-transition mode="out-in" >

    <loadingTemplate  v-if="loading "
                      key="loading" />

    <v-sheet  v-if=" !template && !loading "
              class="loadingTemplate d-flex align-center justify-center fill-height"
              color="#262626" dark  >

      <div>
        <h1 class="text-h2 font-weight-thin">Очікуйте старт незабаром </h1>


<!--        <div class="d-flex justify-center">-->
<!--          <v-btn filled class="mt-8" x-large to="/" text large dark>-->
<!--            <h1  class="text-h4 font-weight-thin">До Головної </h1>-->
<!--          </v-btn>-->
<!--        </div>-->

      </div>


    </v-sheet>

    <avalon  v-if="  template === 'avalon' && !loading "
             :business="business"
             key="avalon" />
    <mondao  v-if="  template === 'mondao' && !loading"
             :business="business"
             key="mondao" />
    <clearWater v-if="template === 'clearWater' && !loading"
                :business="business"
                key="clearWater" />

  </v-fade-transition>










</div>
</template>

<script>
import loadingTemplate from "@/components/templates/loadingTemplate";
import ftEditor from "@/components/templates/editor/ftEditor";
import ftEditorBlockElement from "@/components/templates/editor/elements/ftEditorBlockElement";
// import ftEditorGeneral from "@/components/templates/editor/ftEditorGeneral";
import ftEditorGeneralNew from "@/components/templates/editor/ftEditorGeneralNew";
import ftEditorBlock from "@/components/templates/editor/elements/ftEditorBlock";
import ftEditorAddEntity from "@/components/templates/editor/ftEditorAddEntity";

import avalon from "@/components/templates/Avalon/AvalonMain";
import mondao from "@/components/templates/Mondao/MondaoMain";
import clearWater from "@/components/templates/ClearWater/ClearWaterMain";

import {mapActions, mapState} from "vuex";
export default {
  name: "TemplateLoader",
  props : ['alias'],
  components : {
    ftEditor,
    // ftEditorGeneral,
    ftEditorGeneralNew,
    ftEditorBlockElement,
    ftEditorBlock,
    ftEditorAddEntity,

    loadingTemplate,

    avalon,
    mondao,
    clearWater,

  },
  data(){
    return {
      loading : true,

      template : null,
      business : null,
    }
  },
  computed : {
    ...mapState('templates',  [
      'editorMode',
      'templateLoaded' ,
      'openEditor',
      'openEditorGeneral',
      'openEditorBlock',
      'openEditorBlockElement',
      'openEditorAddEntity',
      ]),
    hhost(){
      return location.hostname
    }
  },
  methods : {
    ...mapActions('business',  [ 'GET_BUSINESS' ]),

    prepareBusinessData(out) {
      window.document.title = out.data.name
      this.$store.state.templates.selectedBusiness = out.data;
      this.$store.state.templates.businessAlias = out.data.alias;
      this.$store.state.business.selectedBusiness.alias = out.data.alias;
      this.$store.state.business.selectedBusiness.modules = out.data.modules;
      this.template = out.data.template
      this.$store.state.templates.templateAlias = out.data.template
      this.business = out.data.alias
      this.$store.state.templates.businessName = out.data.name;
      this.$store.state.templates.businessTemplate = out.data.template;
      this.$store.state.templates.products = out.data.products
      this.$store.state.templates.product_categories = out.data.product_categories
      this.$store.state.templates.news = out.data.news
      this.$store.state.templates.news.reverse()
      this.$store.state.templates.locations = out.data.locations
      this.$store.state.templates.deliveryAllowCountries = out.data.deliveryAllowCountries
      this.$store.state.templates.businessCountry = out.data.country
      this.$store.state.templates.deliveryCountries = out.data.deliveryCountries

      if ( out.data.gTag ) {
        this.gAnalyticsInit(out.data.gTag )
      }

      if ( out.data.products ) {
        this.$store.state.templates.rentedProducts = out.data.products.filter(el=> el.parentProduct)
      }


      if ( out.data.deliveries) {
        this.$store.state.cart.deliveries = []
        out.data.deliveries.forEach( (delivery)=>{
          this.$store.state.cart.deliveries.push({text:delivery.name,value:delivery.id})
        })
      }

      if (out.data.payments) {
        this.$store.state.cart.payments = []
        out.data.payments.forEach( (payment)=>{
          this.$store.state.cart.payments.push({text:payment.name,value:payment.id})
        })
      }

    },
    prepareBusinessTemplate(out,editorMode) {

        let objectType = editorMode ? 'templateVars_current' : 'templateVars_init'
        this.$store.state.templates[ objectType ] = out.data.template_object

    }
  },
  beforeMount() {
    this.loading = true;

    let editorMode = this.$route.path.includes('/edit')

    this.$store.state.templates.editorMode = editorMode
    let data = {}
    if ( this.$store.state.domain ) {
      data.domain =  location.hostname
    } else {
     data.alias = this.alias
    }

    this.GET_BUSINESS( data ) . then((out)=>{

          this.prepareBusinessData( out )
          this.prepareBusinessTemplate( out , editorMode )

          this.loading = false;

    }).catch((  )=>{
      this.loading = false;
    })

  }
}
</script>

<style scoped>
.loadingTemplate {
  position : absolute;
  top : 0;
  bottom : 0;
  left : 0;
  right : 0;
}
</style>