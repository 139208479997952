<template>
  <div class="title d-flex justify-space-between align-center">
    <div>
      <span class="text-h4 font-weight-light">{{ pageTitle }}</span>
<!--      <h5 class="text-h5 font-weight-light text&#45;&#45;secondary">{{ pageTitle }}</h5>-->
    </div>

    <v-breadcrumbs class="breadcrumbs" :items="breadcrumbs" divider="/" large>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
name: "titleBread",
  computed : {
    ...mapState('dashboard', ['breadcrumbs','pageTitle'])
  }
}
</script>

<style scoped>
.title {
  padding :15px 25px;
}

.breadcrumbs {
  color : #459ad2 !important;
  font-weight: 300 !important;
}


</style>