<template>
<div>
  <v-sheet color="grey lighten-3"  class="d-flex justify-center  pa-6">
   <div class="main_section d-flex justify-space-between align-center">
     <h2 class="bitter font-weight-medium">Новини Університету</h2>
     <h4 class="caption font-weight-light" style="font-family: sans-serif">ГОЛОВНА \ НОВИНИ</h4>
   </div>

  </v-sheet>

  <v-sheet class="d-flex justify-center pa-16">
    <div class="main_section">
      <v-row>
        <v-col cols="8">
          <div v-if="Object.keys(this.news).length > 0" class="pr-6">
            <imago   :src="$store.state.filesPublic+`news/${news.uuid}`" width="auto" height="400px" >
              <div style="background-color: #459ad2cc;position:absolute; bottom: 0; width: 100%; color:white; font-family:sans-serif"
                   class="  font-weight-medium pa-3"
              ><h4>{{  news.date  }}</h4></div>
            </imago>
            <v-divider class="mt-7" />
            <div class="d-flex justify-space-between align-center">
              <div class="caption font-weight-light d-flex py-3 text--secondary">
                <v-icon x-small color="grey lighten-2" class="mr-2">mdi-eye</v-icon>
                <span class="mr-3">1159</span>
                <v-divider vertical />
                <span class="mx-3">{{ news.author }}</span>
                <v-divider vertical />
                <span class="mx-3">{{ news.date }}</span>
                <v-divider vertical />
                <span class="mx-3">{{ news.category }}</span>
                <v-divider v-if="news.category" vertical />
              </div>
              <div class="d-flex">
                <div class="fineBorder px-1 "><v-icon small color="grey lighten-1">mdi-facebook</v-icon></div>
                <div class="fineBorder px-1 ml-1"><v-icon small color="grey lighten-1">mdi-twitter</v-icon></div>
                <div class="fineBorder px-1 ml-1"><v-icon small color="grey lighten-1">mdi-telegram</v-icon></div>
              </div>
            </div>
            <v-divider/>

            <h1 class="bitter font-weight-medium mt-6 mb-6" style="line-height: 1.2">{{ news.title }}</h1>



            <div class="newsContent" v-html="news.content"></div>

            <v-divider class="mt-5" />
            <div class="d-flex justify-space-between align-center">
              <div class="caption font-weight-light d-flex py-3 text--secondary">
                <v-icon x-small color="grey lighten-2" class="mr-2">mdi-eye</v-icon>
                <span class="mr-3">1159</span>
                <v-divider vertical />
                <span class="mx-3">{{ news.author }}</span>
                <v-divider vertical />
                <span class="mx-3">{{ news.date }}</span>
                <v-divider vertical />
                <span class="mx-3">{{ news.category }}</span>
                <v-divider v-if="news.category" vertical />
              </div>
              <div class="d-flex">
                <div class="fineBorder px-1 "><v-icon small color="grey lighten-1">mdi-facebook</v-icon></div>
                <div class="fineBorder px-1 ml-1"><v-icon small color="grey lighten-1">mdi-twitter</v-icon></div>
                <div class="fineBorder px-1 ml-1"><v-icon small color="grey lighten-1">mdi-telegram</v-icon></div>
              </div>
            </div>
            <v-divider/>

            <div class="d-flex mt-8 mb-3">
              <v-hover   v-slot="{ hover }" >
                <v-sheet v-ripple :color="hover ? 'primary lighten-2' : 'grey lighten-3'" :dark="hover" class="d-flex align-center pa-3 py-5 mr-1"
                         style="transition: all 0.2s ease;cursor:pointer">
                  <v-icon class="mr-3">mdi-chevron-left</v-icon>
                  <div>
                    <h5 class="font-weight-light mb-1">Попередня Стаття</h5>
                    <div class="font-weight-medium" style="line-height: 1.3">Кількість Китайських студентів університету стрімко збільшується</div>
                  </div>
                </v-sheet>
              </v-hover>
              <v-hover   v-slot="{ hover }" >
                <v-sheet v-ripple :color="hover ? 'primary lighten-2' : 'grey lighten-3'" :dark="hover" class="d-flex align-center pa-3 py-5 mr-1"
                         style="transition: all 0.2s ease;cursor:pointer">
                  <div>
                    <h5 class="font-weight-light mb-1 text-right">Наступна Стаття</h5>
                    <div class="font-weight-medium text-right" style="line-height: 1.3">Кількість Китайських студентів університету стрімко збільшується</div>
                  </div>
                  <v-icon class="ml-3">mdi-chevron-right</v-icon>
                </v-sheet>
              </v-hover>
            </div>

            <v-divider class="mt-8" />
            <v-divider />

          </div>
          <v-skeleton-loader v-else type="card, article, paragraph" />

          <div class="d-flex mt-10 " >
            <v-sheet color="grey lighten-3" height="50" class="py-3 px-3 mr-5"><v-icon size="30" dark>mdi-account</v-icon></v-sheet>
            <v-sheet color="grey lighten-3" height="150" width="100%" class="pa-3 px-5 mr-7" >
              <span class="caption" style="font-family: sans-serif; width:100%">Щоб залишити коментар <a
                href="#">увійдить</a> у свій обліковий запис або зареєструйтеся</span></v-sheet>
          </div>


        </v-col>
        <v-col cols="4">

          <div class="pl-0 pr-10">
            <h2 class=" font-weight-medium mb-5 mt-n2 bitter">Новини</h2>
            <v-sheet color="white" class="fill-height ">


              <div v-for="(news,i) in recentNews" :key="news.uuid">
                <v-divider v-if="i!=0" />
                <div class="d-flex align-center mb-3 mt-3">
                  <div>
                    <imago :src="$store.state.filesPublic+`news/${news.uuid}`" width="60px" height="60px"></imago>
                  </div>
                  <div class="ml-3">
                    <div class="caption text--secondary">{{  news.date  }}</div>
                    <router-link :to="`/news/${news.alias}`" class="sideNews">
                    <div>{{  news.title  }}</div>
                    </router-link>

                  </div>
                </div>
              </div>





            </v-sheet>

            <h2 class=" font-weight-medium mt-5 bitter">Пошук Спеціальності</h2>
            <v-text-field class="mt-3 "  outlined label="Спеціальність" append-icon="mdi-magnify"></v-text-field>
            <v-btn outlined class="mb-8" block color="primary lighten-2"> Подати заявку</v-btn>
            <div class="news pa-4">

            <h2 class=" font-weight-medium bitter">Підписуйся</h2>

              <div class="d-flex  align-center mt-6  ">
                <v-icon color="primary" size="22" class="mr-2">mdi-telegram</v-icon>
                <h4 class="font-weight-light">Telegram Bot</h4>
              </div>


            <div class="d-flex  align-center mt-2">
              <v-icon color="primary" size="22" class="mr-2">mdi-facebook</v-icon>
              <h4 class="font-weight-light">Facebook Account</h4>
            </div>
            <div class="d-flex  align-center mt-2">
              <v-icon color="primary" size="22" class="mr-2">mdi-instagram</v-icon>
              <h4 class="font-weight-light">Instagram Account</h4>
            </div>







            </div>

          </div>

        </v-col>

      </v-row>
    </div>
  </v-sheet>
</div>
</template>

<script>
import {mapActions} from "vuex";
import imago from "@/components/UI/imago";

export default {
  name: "SingleNews",
  props: ['alias'],
  components : {
    imago
  },
  data() {
    return {
      news : {},
      recentNews : [],
      categoryNews : [],
    }
  },
  methods : {
    ...mapActions('news', [ 'GET_NEWS_PUBLIC','EDIT_NEWS']),
  },
  async mounted(){
    this.loading = true
    this.GET_NEWS_PUBLIC(this.alias).then((out)=>{
      this.news =  out.data;
      this.recentNews = out.recent_news
      this.categoryNews = out.category_news
      this.loading = false;
    }).catch(()=>{ this.loading = false;})
  }
}
</script>

<style lang="scss" scoped>
.main_section {
  width:1100px
}
.sideNews  {
  text-decoration: none;
  line-height: 1.5 ;
  font-size: 0.75rem;
  font-weight: 400;

  font-family: sans-serif;
  color: #393939;
}
</style>