export default {
    methods : {
        formatTime(inputTime) {

            var time = new Date(inputTime)
            // var check_day = (new Date() - time)/ (1000 * 60 * 60 * 24);

            // if (check_day < 1) {
            //     var hours = time.getHours();
            //     var minutes = time.getMinutes();
            //     if (hours<10) { hours = `0${hours}`}
            //     if (minutes<10) { minutes = `0${minutes}`}
            //     time = `${hours}:${minutes}`;
            // } else {
                time = `${time.getDate()}.${time.getMonth()} - ${time.getHours()}:${time.getMinutes()}`;
            // }


            return time;

        }
    }
}