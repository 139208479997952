<template>
  <v-list dense color="#242424">
    <v-list-item-group color="white" v-model="selectedItem">

      <div v-for="(section, i) in authData" :key="i">

<!--        <div v-if="section.allow">-->
          <v-subheader v-if="sideMenu" class="ml-3">{{ section.section }}</v-subheader>
          <div v-for="(item, j) in section.items" :key="j" >

            <router-link v-if="item.action.type === 'route'" :to="item.action.path" class="white--text " style="text-decoration: none">
                <v-list-item >
                    <v-list-item-icon :class="[{'ml-5': sideMenu},{'mr-5': sideMenu}]">
                        <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title >
                            {{ item.text}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <div v-else>
              <v-list-item v-if="!item.children" >
                <v-list-item-icon :class="[{'ml-5': sideMenu},{'mr-5': sideMenu}]">
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title >
                    {{ item.text}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-group color="white" v-else :value="false" >

                  <template v-slot:activator >
                      <v-list-item-icon :class="[{'ml-5': sideMenu},{'mr-5': sideMenu}]">
                          <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-title >{{ item.text }}</v-list-item-title>
                  </template>

                  <div v-if="sideMenu">

                   <router-link v-for="(subitem,i) in item.children" :key="i" :to="subitem.action.path" class="white--text" style="text-decoration: none" >
                      <v-list-item>
                            <v-list-item-title class="ml-16 font-weight-light">{{ subitem.text }}</v-list-item-title>
                      </v-list-item>
                    </router-link>

                </div>

              </v-list-group>
            </div>





          </div>
<!--        </div>-->

      </div>

    </v-list-item-group>
  </v-list>
</template>

<script>

import {mapMutations, mapState} from 'vuex';

export default {
  name: "menuElements",
  data: () => ({
    selectedItem: 0,
    items: [
      {
        section: 'Main',
        items: [
          {
            text: 'Dashboard',
            icon: 'mdi-view-dashboard-outline',
            action: {type: 'route', path: '/dashboard'}
          },
          { text: 'Templater', auth: ['superadmin'], icon: 'mdi-account', action: {type: 'route', path: '/dashboard/templater'} },
          { text: 'Domains', auth: ['superadmin'], icon: 'mdi-link-variant', action: {type: 'route', path: '/dashboard/domains'} },
          { text: 'Users', auth: ['Admin','Partner'], icon: 'mdi-account', action: {type: 'route', path: '/dashboard/applicants'} },
          { text: 'Businesses', auth: ['Admin','Partner'], icon: 'mdi-account', action: {type: 'route', path: '/dashboard/applicants'} },
          { text: 'News', auth: ['superadmin'], icon: 'mdi-newspaper-variant-outline', action: {type: 'route', path: '/dashboard/news'} },


        ]
      },
      {
        section : 'My Business', auth: ['businessman','superadmin'],
        items : [
          { text: 'Manage', auth: ['businessman','superadmin'], icon: 'mdi-cog', action: {type: 'route', path: '/dashboard/business'}},
        ]
      },
      {
        section : 'My Managing', auth: ['businessman','superadmin','manager'],
        items : [ ]
      },
      {
        section: 'Profile',
        items: [
          // { text: 'Messenger', icon: 'mdi-email-outline', action: {type: 'route', path: '/dashboard/messenger'} },
          { text: 'My Profile', icon: 'mdi-badge-account-horizontal-outline', action: {type: 'route', path: '/dashboard/profile'}},
          { text: 'Logout', icon: 'mdi-logout-variant', action: {type: 'logout'}}
        ]
      },

    ]
  }),
  computed: {
    ...mapState('dashboard', ['sideMenu']),
    ...mapState('auth', ['userType']),

    authData() {

      if (this.$store.state.auth.user.businesses ) {
        this.$store.state.auth.user.businesses.forEach((business)=>{
          this.items[1].items.unshift({
            text: business.name,
            auth: ['businessman','superadmin','manager'],
            icon: 'mdi-briefcase-outline',
            action: {type: 'route', path: `/dashboard/${business.alias}`},
          })
        })
      }
      if (this.$store.state.auth.user.managing_businesses ) {
        this.$store.state.auth.user.managing_businesses.forEach((business)=>{
          let index = this.items.findIndex(el=>el.section === 'My Managing')
          if (index) {
            this.items[index].items.unshift({
              text: business.name,
              auth: ['businessman','superadmin','manager'],
              icon: 'mdi-briefcase-outline',
              action: {type: 'route', path: `/dashboard/${business.alias}`},
            })
          }

        })
      }

      var data = new Array();

      this.items.forEach((section,i) => {
        var filteredItems = [];
        let allowSection = true

        if (section.auth) {
          if (section.auth.includes(this.$store.state.auth.userType)) {
            allowSection = true
          } else { allowSection = false }
        }

        console.log(this.items[i])

        if (allowSection) {
          section.items.forEach((item) => {
            var allow = false;

            if (item.auth) {
              item.auth.forEach((uType) => {
                if (uType === this.userType) {
                  allow = true;
                }
              });
              if (allow) {  filteredItems.push(item) }

            } else { filteredItems.push(item) }

          })

          if (filteredItems.length > 0) {
            data.push({section: section.section, items: filteredItems})
          }
        }


      })


      return data;
    }
  },
  methods: {
    ...mapMutations('dashboard', ['SHOW_MODAL_CONFIRM_LOGOUT', 'SET_PAGE_TITLE']),

    select(item) {
      switch (item.action.type) {
        case 'route':
          this.SET_PAGE_TITLE(item.text);
          this.$router.push(item.action.path);

          break;

        case 'logout':
          this.logout()
          break;
      }

    },
    logout() {
      this.SHOW_MODAL_CONFIRM_LOGOUT();
    },
  }
}
</script>

<style scoped>

</style>