import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import factory from '@/factory';

import UUID from "vue-uuid";
import notifications from "@/mixins/notifications";
import checkPermissions from "@/mixins/auth/checkPermissions";
import formatTime from "@/mixins/formatTime";
import liqpay from "@/mixins/liqpay";
import googleMix from "@/mixins/googleMix";
import templatesConfig from "@/mixins/templatesConfig";
import modulesFunctions from "@/mixins/modulesFunctions";
import { VueMasonryPlugin } from "vue-masonry";
import * as VueGoogleMaps from 'vue2-google-maps'
import VueApexCharts from 'vue-apexcharts'
import VueResponsiveDash from 'vue-responsive-dash'
import device from "vue-device-detector"



Vue.use(device)
Vue.use(VueApexCharts)

Vue.use(UUID);
Vue.use(VueResponsiveDash)
Vue.use(VueMasonryPlugin);
Vue.use(VueGoogleMaps, {
  load: {
    language: 'uk',
    key: 'AIzaSyDDT5_XouOTy8gxBKvPUVA421aKji55f7w',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
})


Vue.mixin(checkPermissions)
Vue.mixin(formatTime)
Vue.mixin(notifications)
Vue.mixin(liqpay)
Vue.mixin(templatesConfig)
Vue.mixin(modulesFunctions)
Vue.mixin(googleMix)

Vue.config.productionTip = false

const { ajax,router,store,socket } = factory

Vue.use(socket)

new Vue({
  vuetify,
  ajax,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
