<template>
<div >
  <div style="height: 600px; width: 100%; border:1px solid #dddddd">
    <div style=" position: relative" class="fill-height">

      <div style="width: 300px; height:49px;">
        <v-tabs hide-slider dense background-color="#459ad2"  dark v-model="selectedTab" centered>
          <v-tab > <v-icon>mdi-message-outline</v-icon> </v-tab>
          <v-tab @click="getUsers"><v-icon>mdi-account</v-icon></v-tab>
          <v-tab><v-icon>mdi-account-group</v-icon></v-tab>
        </v-tabs>
      </div>
      <div style="border-right: 1px solid #dddddd ;position:absolute; top:48px; bottom: 0px; width: 300px; overflow-y: auto ">
              <div v-if="selectedTab == 0">
                <v-list >
                  <v-list-item-group mandatory v-model="selectedChat">

                    <v-list-item v-for="(item,i) in chats" :key="i" @click="viewChat(item.uuid)">
                      <v-list-item-content>

                        <div class="d-flex justify-space-between">
                          <div class="d-flex align-center pr-5">
                            <v-avatar size="26"  class="mr-3">
                              <v-img :src="$store.state.filesPublic+'users/avatars/'+item.avatar" >
                                <template slot="placeholder">
                                  <v-img src="@/assets/placeholder/user_blank.jpg" />
                                </template>
                              </v-img>
                            </v-avatar>
                            <div>
                              <h4 class="mb-n2 caption">{{ item.name}} </h4>
                              <span  class="caption text--secondary mt-0">{{ item.last_message }} </span>
                            </div>
                          </div>
                          <v-scroll-y-transition>
                            <v-chip v-if="item.new_messages>0"  small dark color="primary lighten-3">{{ item.new_messages }}</v-chip>
                          </v-scroll-y-transition>
                        </div>



                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
              <div v-if="selectedTab == 1">
                <v-list>
                  <v-list-item-group>

                    <v-list-item v-for="(item,i) in users" :key="i.uuid" @click="viewContact(item)">
                      <v-list-item-content>

                        <div class="d-flex align-center pr-5">
                          <v-avatar size="26"  class="mr-3">
                            <v-img :src="$store.state.filesPublic+'users/avatars/'+item.uuid" >
                              <template slot="placeholder">
                                <v-img src="@/assets/placeholder/user_blank.jpg" />
                              </template>
                            </v-img>
                          </v-avatar>
                          <div>
                            <h4 class="mb-n2 caption">{{ item.firstname}} {{ item.lastname}}</h4>
                            <span class="caption text--secondary mt-0">{{ item.user_type }} 2 minutes ago</span>
                          </div>

                        </div>


                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
              <div v-if="selectedTab == 2">Groups will be here</div>
      </div>

        <div key="user" v-if="selectedTabChat == 1" class="pa-6"
             style="position:absolute; top:0; bottom: 0; left: 300px; right:0;  overflow-y: auto;">


          <div class="text-center mb-6">
            <v-avatar size="120"  class="mr-3 mt-3">
              <v-img :src="$store.state.filesPublic+`users/avatars/${selectedContact.uuid}`" >
                <template slot="placeholder">
                  <v-img src="@/assets/placeholder/user_blank.jpg" />
                </template>
              </v-img>
            </v-avatar>
            <h3 class="mt-3 mb-3"> {{ selectedContact.firstname  }} {{ selectedContact.lastname  }}
            </h3>

            <v-btn color="primary lighten-2" dark small rounded  elevation="2"  class="mr-6" @click="getChat('user',selectedContact.uuid)">
              <v-icon small class="mr-2" >mdi-message-outline</v-icon> Message</v-btn>
<!--            <v-btn color="primary lighten-2" dark small rounded elevation="2"  class="mr-6" >-->
<!--              <v-icon small class="mr-2" >mdi-information-outline</v-icon> Info</v-btn>-->
          </div>


<!--          <v-simple-table style="background-color: ghostwhite">-->
<!--            <tbody>-->
<!--            <tr>-->
<!--              <td style="width: 50px"><v-subheader>Role:</v-subheader></td>-->
<!--              <td>Administrator</td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--          </v-simple-table>-->

<!--          <div style="background-color: ghostwhite">-->
<!--            <v-tabs style="background-color: ghostwhite" centered v-model="selectedTabUser" class="mt-2">-->
<!--              <v-tab>Files</v-tab>-->
<!--              <v-tab>Media</v-tab>-->
<!--            </v-tabs>-->

<!--            <v-simple-table dense style="background-color: ghostwhite" class="mt-3">-->
<!--              <tbody>-->
<!--              <tr v-for="file in 15" :key="file">-->
<!--                <td><v-icon small class="mr-2">mdi-file</v-icon>Super metodichka filtered 1.jpg</td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </v-simple-table>-->

<!--          </div>-->

        </div>

        <div id="message_view" ref="message_view_ref"  v-if="selectedTabChat == 0" class="pa-6"
             style="position:absolute; top:0; bottom: 190px; left: 300px; right:0;  overflow-y: auto; ">


          <div v-if="messages.length>0">
            <div v-for="(message,i) in messages" :key="message.uuid" class="d-flex "
                 :class="{'justify-end' : !authorUser(message.author)  }" >

              <div>
                <div class="d-flex align-center mb-1" :class="authorUser(message.author) ? '' : 'justify-end'">
                  <div v-if="i == 0 ||  messages[i-1].author !== message.author">
                    <span v-if="!authorUser(message.author)"  class="mr-1 caption text--secondary ">{{ message.author_name  }} </span>
                    <v-avatar size="20"  >
                      <v-img :src="$store.state.filesPublic+`users/avatars/${message.author}`" >
                        <template slot="placeholder">
                          <v-img src="@/assets/placeholder/user_blank.jpg" />
                        </template>
                      </v-img>
                    </v-avatar>
                    <span v-if="authorUser(message.author)"  class="ml-1 caption text--secondary">{{ message.author_name  }} </span>
                  </div>
                </div>
                <v-sheet class="pa-3 " width="auto" rounded :color="authorUser(message.author) ? 'green lighten-4' : 'blue-grey lighten-4'"   >


                  <div class="d-flex " :class="authorUser(message.author) ? '' : 'justify-end'">
                    <h5 v-html="message.message"></h5>
                  </div>

                  <div class="d-flex  " :class="authorUser(message.author) ? '' : 'justify-end'">
                    <span class="caption text--secondary">{{ message.created_at }}</span>
                  </div>


                </v-sheet>
              </div>






            </div>
          </div>




        </div>
        <div  v-if="selectedTabChat == 0" class="pa-6"
           style="position:absolute; bottom:0; height: 170px; left: 300px; right:0;background-color: #ececf5;
           border-top: 1px solid #dddddd;">

        <div class="d-flex align-end">
          <v-textarea :disabled="loading" @keydown.enter="handleEnter" v-model="chatMessage" class="mb-n2" dense
                      label="Enter your message"
          ></v-textarea>

            <v-btn :loading="loading" :disabled="loading" small outlined color="primary lighten-2" class="ml-3 mb-3"
                   @click="sendMessage()"
            >Send</v-btn>


        </div>

      </div>


    </div>
  </div>
</div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
//import card from "@/components/pages/dashboard/UI/card";

export default {
  name: "DashMessenger",
  props : ['showUser'],
  components : {
  //  card
  },
  data(){
    return {

      loading : false,
      selectedTab : null,
      selectedTabUser : null,
      selectedTabChat : null,
      users : [],
      chats : [],
      messages : [],
      selectedContact : null,
      selectedChat:0,
      chatMessage : '',

      popka : ['soska'],

    }
  },
  computed : {
    ...mapState('auth',['accessToken']),
    ...mapState('dashboard',['newMessage']),


  },
  watch: {
    notification (val) {
      if (!val) return setTimeout(() => (this.notification = false), 1000)
    },
    newMessage (val) {
      this.recieveMessage(val)
    }
  },
  methods : {
    ...mapActions('messenger',['GET_CONTACTS','GET_CHATS','GET_CHAT','SEND_MESSAGE','MARK_READ']),
    ...mapMutations('dashboard', ['SET_MESSAGE_COUNT','DELETE_UNREAD_MESSAGE']),
    getUsers() {
      if( this.users.length == 0) {
        this.loading = true
        this.GET_CONTACTS().then((res)=>{
          this.users = res.data;
          this.loading = false
        }).catch((error)=>{ this.loading = false; console.log(error) })
      }
    },
    viewContact(item) {
      this.selectedTabChat = 1;
      this.selectedContact = item;
    },
    getChat(type,data){
      console.log(type,data)
      this.loading = true

      if(type === 'user') {
        var search = -1;
        if (this.chats.length>0) {
           search = this.chats.findIndex(el => el.contact_id === data )
        }

        this.selectedTabChat = 0;
        this.selectedTab = 0;
        this.GET_CHAT({type:type,uuid:data}).then((res)=>{

          if ( search == -1) {
            this.chats.unshift({
              uuid: res.chat_id,
              contact_id: data,
              name: this.selectedContact.firstname+' '+this.selectedContact.lastname,
              last_message : 'No Messages',
              avatar:data,
              type: 'personal'
            })
            this.selectedChat = 0;
            this.messages = [];

          } else {
            this.selectedChat = search
            this.DELETE_UNREAD_MESSAGE({
              count:this.$store.state.dashboard.messages-this.chats[this.selectedChat].new_messages,
              user:this.chats[this.selectedChat].contact_id})
            this.chats[this.selectedChat].new_messages=0;
            this.messages = res.data;
            this.messages.forEach((mess,i)=>{

              var message_time = new Date(mess.created_at)
              var check_day = (new Date() - message_time)/ (1000 * 60 * 60 * 24);


              if (check_day < 1) {
                var hours = message_time.getHours();
                var minutes = message_time.getMinutes();
                if (hours<10) { hours = `0${hours}`}
                if (minutes<10) { minutes = `0${minutes}`}
                mess = `${hours}:${minutes}`;
              } else {
                mess = `${message_time.getDate()}.${message_time.getMonth()}.${message_time.getFullYear()}`;
              }

              this.messages[i].created_at = mess;
              this.messages[i].message = this.messages[i].message.replace(/\n/gi, '<br>');

            })
            this.$nextTick(() => {
              var container = this.$el.querySelector("#message_view");
              container.scrollTop = container.scrollHeight;
            } );
          }


          this.loading = false
        }).catch((error)=>{ this.loading = false; console.log(error) })
      }



    },
    viewChat(uuid) {
      console.log('THIS IS VIEW CHAT')
      this.loading = true
     this.messages = [];
      this.GET_CHAT({type:'chat',uuid:uuid}).then((res)=>{
        this.selectedTabChat = 0;
        this.messages = res.data;

        this.DELETE_UNREAD_MESSAGE({
          count:this.$store.state.dashboard.messages-this.chats[this.selectedChat].new_messages,
          user:this.chats[this.selectedChat].contact_id})
        this.chats[this.selectedChat].new_messages=0;

        this.messages.forEach((mess,i)=>{



          var message_time = new Date(mess.created_at)
          var check_day = (new Date() - message_time)/ (1000 * 60 * 60 * 24);


          if (check_day < 1) {
            var hours = message_time.getHours();
            var minutes = message_time.getMinutes();
            if (hours<10) { hours = `0${hours}`}
            if (minutes<10) { minutes = `0${minutes}`}
            mess = `${hours}:${minutes}`;
          } else {
            mess = `${message_time.getDate()}.${message_time.getMonth()}.${message_time.getFullYear()}`;
          }

          this.messages[i].created_at = mess;
          this.messages[i].message = this.messages[i].message.replace(/\n/gi, '<br>');


        })

        this.loading = false


        this.$nextTick(() => {
          var container = this.$el.querySelector("#message_view");
          container.scrollTop = container.scrollHeight;
        } );



      }).catch((error)=>{ this.loading = false; console.log(error) })

    },

    sendMessage(){

      this.loading = true
      this.SEND_MESSAGE({chat_id:this.chats[this.selectedChat].uuid,message:this.chatMessage}).then((res)=>{
        if(res.result) {
          this.chatMessage = '';
          const message_time = new Date( res.data.created_at );
          var hours = message_time.getHours();
          var minutes = message_time.getMinutes();
          if (hours<10) { hours = `0${hours}`}
          if (minutes<10) { minutes = `0${minutes}`}
          res.data.created_at = `${hours}:${minutes}`;
          res.data.author_name = this.$store.state.auth.userName;
          res.data.message = res.data.message.replace(/\n/gi, '<br>');
          this.messages.push(res.data)

          this.$nextTick(() => {
            var container = this.$el.querySelector("#message_view");
            container.scrollTop = container.scrollHeight;
          } );


          if (this.selectedChat != 0) {
            const chat = this.chats[this.selectedChat];
            this.chats.splice(this.selectedChat, 1);
            this.chats.unshift(chat);
          }
          this.selectedChat = 0;

        }
        this.loading = false
      }).catch((error)=>{ this.loading = false; console.log(error) })

    },
    recieveMessage(input){

     var chat_index = this.chats.findIndex(el => el.uuid === input.chat_id );
      if (chat_index == this.selectedChat) {

        var message_time = new Date(input.created_at)
        var hours = message_time.getHours();
        var minutes = message_time.getMinutes();
        if (hours<10) { hours = `0${hours}`}
        if (minutes<10) { minutes = `0${minutes}`}
        input.created_at = `${hours}:${minutes}`;
        input.message = input.message.replace(/\n/gi, '<br>');


        this.messages.push(input)
        this.MARK_READ(input.uuid)



        this.DELETE_UNREAD_MESSAGE({
          count:this.$store.state.dashboard.messages-1,
          user:this.chats[this.selectedChat].contact_id})
      } else {

        if(chat_index == -1) {
          this.chats.unshift({
            uuid: input.chat_id,
            contact_id: input.author,
            name: input.author_name,
            last_message : input.short_message,
            avatar:input.author,
            type: input.type,
            new_messages : 1,
          })
          this.selectedChat++;
        }
        this.chats[chat_index].new_messages++;
        this.chats[chat_index].last_message = input.short_message;
        const chat = this.chats[chat_index];
        this.chats.splice(chat_index, 1);
        this.chats.unshift(chat);
        this.selectedChat++;


      }

      this.$nextTick(() => {
        var container = this.$el.querySelector("#message_view");
        container.scrollTop = container.scrollHeight;
      } );
    },
    handleEnter(e){
      if(!e.shiftKey) { this.sendMessage()}
    },

    // technical

    authorUser(uuid){
      if(this.$store.state.auth.userID === uuid) { return true } else {
        return false
      }
    },
    checkMargin(i) {
     if (i>0 || this.messages[i+1].author === this.messages[i].author ) {
       return false
     }  else { return true }
    },
    notify(title,text) {
      this.notification = true
      this.notification_title = title
      this.notification_text = text
    },
  },
  mounted() {



    // var channel = this.$pusher.subscribe(`message-channel.${this.$store.state.auth.userID}`);
    // console.log(`message-channel.${this.$store.state.auth.userID}`)
    // var self = this;
    // channel.bind('new-message', function(data) {
    // self.recieveMessage(data.message);
    // });


    this.loading = true
    this.GET_CHATS().then((res)=>{
      this.chats = res.data;
      this.loading = false
      if(this.showUser) {
        const index = this.chats.findIndex(el=> el.contact_id === this.showUser)
        this.selectedChat = index;
        this.$router.replace('/dashboard/messenger')
        this.viewChat(this.chats[index].uuid)
      } else {
        console.log('im here')
        this.viewChat(this.chats[0].uuid)
      }

    }).catch((error)=>{ this.loading = false; console.log(error) })
  },
  // beforeDestroy() {
  //   this.$pusher.unsubscribe(`message-channel.${this.$store.state.auth.userID}`)
  // }
}
</script>

<style lang="scss" scoped>

.message_user {
  background-color: #cdeac1;
}

.master_wrapper {
  height: 100%;
}
.messenger_wrapper {
  position: relative;
  background-color: darkslateblue ;
  height: 100%;



  //padding: 100px;


}
.messenger_bottom {
  background-color: red;
  position: absolute;
  bottom:56px;
  min-height: 55px;
  max-height: 350px;
  left: 300px;
  right: 0;
  overflow-y: auto;

}
.messenger_left {
  position: absolute;
  width: 300px ;
  top: 0;
  bottom: 0;
  background-color: yellow;

}
.messenger_right {
  position: absolute;
  background-color: green;
  overflow-y: auto;
  left: 300px;
  right:0;
  top: 0;
  bottom: 110px;
}
</style>